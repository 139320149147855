import './Header.scss';
import { Navbar, Container, Nav, Button, Spinner, Form, Stack, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectLoadingState } from '../loading/loadingSlice';
import { Gear, QuestionSquareFill, Share } from 'react-bootstrap-icons';
import { getUserMeAsync, selectUser } from '../user/userSlice';
import { useTranslation } from 'react-i18next';
import { selectAccount, selectAccountsList } from '../account/redux/accountsSlice';
import { SearchAccount } from './components/SearchAccount';
import { ReactComponent as BeastIcon } from '../../images/beast.svg';
import { useEffect, useState } from 'react';
import { CLIENT_URL } from '../../configs/config';
import { showToast } from '../toast/redux/toastSlice';
import BmcImg from './bmc-logo.png';

declare global {
  interface Window {
    smartlook(arg0: string, arg1: string, arg2: { region: string }): void;
    smartlook(arg0: string, arg1: { forms: boolean; numbers: boolean; emails: boolean; ips: boolean }): void;
  }
}

export function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingState);
  const accountsList = useSelector(selectAccountsList);
  const selectedAccount = useSelector(selectAccount);

  const [expanded, setExpanded] = useState(false);

  // we want to record in Smartlook only pages with header
  useEffect(() => {
    window.smartlook('init', 'ad307987c5466c65dd52961e7100d63d517b1729', { region: 'eu' });
    window.smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
  }, []);

  const logout = () => {
    localStorage.removeItem('jwt');
    dispatch(getUserMeAsync());
    navigate('/');
  };

  const glowingTogglerCss = `.navbar-toggler.collapsed {
    animation: red-glow 800ms ease-out infinite alternate;
    outline: none;
  }`;

  return (
    <Navbar expand="lg" className="bg-metal mb-3" expanded={expanded}>
      {accountsList.length === 0 && <style>{glowingTogglerCss}</style>}

      <Container fluid className="header-container">
        <Navbar.Brand
          className="header-icon-container"
          onClick={() => {
            navigate('/');
            setExpanded(false);
          }}
        >
          <BeastIcon />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)} />

        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link
              className="me-3"
              onClick={() => {
                if (selectedAccount == null) {
                  navigate('/');
                } else {
                  navigate(`/accounts/${selectedAccount.server}/${selectedAccount.wotId}`);
                }
                setExpanded(false);
              }}
            >
              <span className="header-menu-item label-no-wrap">{t('Header.Account', 'Account')}</span>
            </Nav.Link>

            <NavDropdown title={<span className="header-menu-item label-no-wrap">{t('Header.Tanks', 'Tanks')}</span>}>
              <NavDropdown.Item
                onClick={() => {
                  if (selectedAccount == null) {
                    navigate('/');
                  } else {
                    navigate(`/accounts/${selectedAccount.server}/${selectedAccount.wotId}/hangar`);
                  }
                  setExpanded(false);
                }}
              >
                {t('Header.Hangar', 'Hangar')}
              </NavDropdown.Item>

              <NavDropdown.Item
                onClick={() => {
                  navigate('/tankopedia');
                  setExpanded(false);
                }}
              >
                {t('Header.Tankopedia', 'Tankopedia')}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className="me-3"
              onClick={() => {
                navigate('/widgets');
                setExpanded(false);
              }}
            >
              <span className="header-menu-item label-no-wrap">{t('Header.Widgets', 'Widgets')}</span>
            </Nav.Link>

            <NavDropdown title={<span className="header-menu-item label-no-wrap">{t('Header.Find', 'Find')}</span>}>
              <NavDropdown.Item
                onClick={() => {
                  navigate('/find/clan');
                  setExpanded(false);
                }}
              >
                {t('Header.FindClan', 'Clan')}
              </NavDropdown.Item>

              <NavDropdown.Item
                onClick={() => {
                  navigate('/find/platoon');
                  setExpanded(false);
                }}
              >
                {t('Header.FindPlatoon', 'Platoon')}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={<span className="header-menu-item label-no-wrap">{t('Header.Top', 'Top')}</span>}>
              <NavDropdown.Item
                onClick={() => {
                  navigate('/top/tanks');
                  setExpanded(false);
                }}
              >
                {t('Header.TopTanks', 'Tanks')}
              </NavDropdown.Item>

              <NavDropdown.Item
                onClick={() => {
                  navigate('/top/accounts');
                  setExpanded(false);
                }}
              >
                {t('Header.TopAccounts', 'Accounts')}
              </NavDropdown.Item>
            </NavDropdown>

            {
              <Nav.Link
                className="me-3"
                onClick={() => {
                  navigate('/tournaments');
                  setExpanded(false);
                }}
              >
                <span className="header-menu-item label-no-wrap">{t('Header.Tournaments', 'Tournaments')}</span>
              </Nav.Link>
            }

            <Nav.Link
              className="me-3"
              onClick={() => {
                navigate('/mods');
                setExpanded(false);
              }}
            >
              <span className="header-menu-item label-no-wrap">{t('Header.Mods', 'Mods')}</span>
            </Nav.Link>

            <Nav.Link
              className="me-3"
              onClick={() => {
                navigate('/blog');
                setExpanded(false);
              }}
            >
              <span className="header-menu-item label-no-wrap">{t('Header.Blog', 'Blog')}</span>
            </Nav.Link>
          </Nav>

          <div className="header-input-container">
            <SearchAccount onSelected={() => setExpanded(false)} />
          </div>

          {accountsList != null && accountsList.length > 0 && (
            <div className="header-input-container">
              <Form.Select
                onChange={(event) => {
                  const [server, wotId] = event.target.value.split(':');
                  navigate(`/accounts/${server}/${wotId}`);
                  setExpanded(false);
                }}
                value={`${selectedAccount?.server ?? ''}:${selectedAccount?.wotId ?? ''}`}
              >
                {accountsList.map((a) => (
                  <option key={a.wotId} value={`${a.server}:${a.wotId}`}>
                    ({a.server}) {a.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          <Stack gap={1} direction="horizontal" className="header-buttons-container">
            {selectedAccount != null && (
              <Button
                onClick={() => {
                  void navigator.clipboard.writeText(`${CLIENT_URL ?? ''}${location.pathname}?share=true`);
                  dispatch(
                    showToast({
                      title: t('Header.ShareToastHeader', 'Copied!'),
                      message: t('Header.ShareToastMessage', 'Link has been copied to the clipboard.'),
                      variant: 'success',
                    }),
                  );
                  setExpanded(false);
                }}
                variant="link"
              >
                <Share size={25} />
              </Button>
            )}

            <Button
              href="https://t.me/+RnwcS2QLOGhhMmVi"
              target="_blank"
              variant="link"
              onClick={() => {
                setExpanded(false);
              }}
            >
              <QuestionSquareFill size={25} color="#0d6efd" />
            </Button>

            {user != null && (
              <Button
                onClick={() => {
                  navigate('/settings');
                  setExpanded(false);
                }}
                variant="link"
              >
                <Gear size={25} />
              </Button>
            )}

            <Button
              onClick={() => {
                navigate('/subscription');
                setExpanded(false);
              }}
              variant="link"
            >
              <img src={BmcImg} alt="Subscription" style={{ width: '35px' }} className="rounded-circle" />
            </Button>

            {user == null && (
              <Button
                onClick={() => {
                  navigate('/login');
                  setExpanded(false);
                }}
                variant="link"
              >
                <strong className="label-no-wrap">{t('Header.Login', 'Log in')}</strong>
              </Button>
            )}

            {user != null && (
              <Button
                onClick={() => {
                  logout();
                  setExpanded(false);
                }}
                variant="link"
              >
                <span className="label-no-wrap">{t('Header.Logout', 'Log out')}</span>
              </Button>
            )}

            <div className="header-spinner-container">
              {loading.some((l) => l.value === true) && <Spinner animation="border" variant="secondary" size="sm" />}
            </div>
          </Stack>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
