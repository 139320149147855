import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Hangar } from './hangar/Hangar';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './user/userSlice';
import { Settings } from './settings/Settings';
import { useEffect } from 'react';
import { getSettingsAsync, resetSettings } from './settings/redux/settingsSlice';
import { Account } from './account/Account';
import {
  addAccountAsync,
  getMyAccountsAsync,
  resetAccounts,
  selectAccount,
  selectAccountFiltered,
  selectAccountsList,
  setSelectedAccountFiltered,
} from './account/redux/accountsSlice';
import { ResetPassword } from './login/ResetPassword';
import {
  getAccountTanksAsync,
  resetAccountTanks,
  selectAccountTanks,
  selectAccountTanksFiltered,
  selectSelectedAccountTank,
  setAccountTanksFiltered,
  setSelectedAccountTankFiltered,
} from './account/redux/accountTanksSlice';
import { WidgetSettings } from './widgets/WidgetSettings';
import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap';
import { hideToast, selectToast } from './toast/redux/toastSlice';
import { resetCoreStatistics, setAccountStats, setAccountStatsFiltered } from './statistics/redux/coreStatisticsSlice';
import { resetFilter, selectFilter } from './filter/redux/filterSlice';
import { getTanksAsync, selectTanks } from './tanks/tanksSlice';
import { Login } from './login/Login';
import { WotCallback } from './login/WotCallback';
import { getServerStatisticsAsync, selectServerStatistics } from './statistics/redux/serverStatisticsSlice';
import {
  resetRemainingHpRateStatistics,
  setRemainingHpRate,
  setRemainingHpRateFiltered,
  setRemainingHpRateServer,
} from './statistics/redux/remainingHpRateStatisticsSlice';
import {
  resetBattleLifeTimeStatistics,
  setBattleLifeTime,
  setBattleLifeTimeFiltered,
  setBattleLifeTimeServer,
} from './statistics/redux/battleLifeTimeStatisticsSlice';
import { resetMasteryStatistics, setMastery, setMasteryServer } from './statistics/redux/masteryStatisticsSlice';
import { WotServer, WotServerValues } from '../utils/wot-server.type';
import { TopTanks } from './top/tanks/TopTanks';
import { TopAccounts } from './top/accounts/TopAccounts';
import { Subscription } from './subscription/Subscription';
import { SubscriptionWaiting } from './subscription/SubscriptionWaiting';
import { FindClan } from './find/clan/FindClans';
import { resetClans } from './find/clan/clansSlice';
import { YourClan } from './find/clan/YourClan';
import { resetPlatoons } from './find/platoon/platoonsSlice';
import { FindPlatoon } from './find/platoon/FindPlatoons';
import { YourPlatoon } from './find/platoon/YourPlatoon';
import { BlogList } from './blog/BlogList';
import { Blog01 } from './blog/Blog01';
import { Blog02 } from './blog/Blog02';
import { Blog03 } from './blog/Blog03';
import { Blog04 } from './blog/Blog04';
import { Blog05 } from './blog/Blog05';
import { Blog06 } from './blog/Blog06';
import { Blog07 } from './blog/Blog07';
import { Blog08 } from './blog/Blog08';
import { Blog09 } from './blog/Blog09';
import { Blog10 } from './blog/Blog10';
import { Blog11 } from './blog/Blog11';
import { Blog12 } from './blog/Blog12';
import { Blog13 } from './blog/Blog13';
import { Blog14 } from './blog/Blog14';
import { Blog15 } from './blog/Blog15';
import { Blog16 } from './blog/Blog16';
import { Blog17 } from './blog/Blog17';
import { Mods } from './mods/Mods';
import { Tankopedia } from './tankopedia/Tankopedia';
import { WelcomeModal } from './welcome/WelcomeModal';
import { Tournaments } from './tournaments/Tournaments';
import { TrovoCallback } from './login/trovo/TrovoCallback';

export function Main() {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { wotId, server } = useParams<{ wotId: string; server: WotServer }>();

  const user = useSelector(selectUser);
  const toast = useSelector(selectToast);
  const accounts = useSelector(selectAccountsList);
  const selectedAccount = useSelector(selectAccount);
  const selectedAccountFiltered = useSelector(selectAccountFiltered);
  const filter = useSelector(selectFilter);
  const accountTanks = useSelector(selectAccountTanks);
  const accountTanksFiltered = useSelector(selectAccountTanksFiltered);
  const selectedAccountTank = useSelector(selectSelectedAccountTank);
  const tanks = useSelector(selectTanks);
  const serverStats = useSelector(selectServerStatistics(selectedAccount?.server));

  useEffect(() => {
    if (user != null) {
      dispatch(getSettingsAsync());
      dispatch(getMyAccountsAsync());
    } else {
      dispatch(resetSettings());
      dispatch(resetFilter());
      dispatch(resetAccounts());
      dispatch(resetAccountTanks());
      dispatch(resetCoreStatistics());
      dispatch(resetRemainingHpRateStatistics());
      dispatch(resetBattleLifeTimeStatistics());
      dispatch(resetMasteryStatistics());
      dispatch(resetClans());
      dispatch(resetPlatoons());
    }
  }, [dispatch, user]);

  // load account when wotId and server are in url
  useEffect(() => {
    if (
      wotId != null &&
      wotId !== '' &&
      !isNaN(Number(wotId)) &&
      server != null &&
      WotServerValues.includes(server) &&
      +wotId !== selectedAccount?.wotId
    ) {
      dispatch(addAccountAsync(+wotId, server));
    }
  }, [selectedAccount?.wotId, dispatch, server, wotId]);

  // load tanks
  useEffect(() => {
    dispatch(getTanksAsync());
  }, [dispatch]);

  // load account when there is no account selected but there are some accounts
  useEffect(() => {
    const url = new URLSearchParams(search);
    const share = url.get('share');

    if (accounts.length > 0 && selectedAccount == null && share !== 'true') {
      dispatch(addAccountAsync(accounts[0].wotId, accounts[0].server));
    }
  }, [accounts, dispatch, search, selectedAccount]);

  // load account tanks when account is selected
  useEffect(() => {
    if (selectedAccount != null) {
      dispatch(getAccountTanksAsync(selectedAccount.wotId, selectedAccount.server));
    }
  }, [dispatch, selectedAccount]);

  // load server stats when account is selected
  useEffect(() => {
    if (selectedAccount?.server != null) {
      dispatch(getServerStatisticsAsync(selectedAccount.server));
    }
  }, [selectedAccount?.server, dispatch]);

  // filter selected account data
  useEffect(() => {
    if (selectedAccount != null) {
      dispatch(setSelectedAccountFiltered({ account: selectedAccount, filter }));
    }
  }, [dispatch, filter, selectedAccount]);

  // filter account tanks
  useEffect(() => {
    dispatch(setAccountTanksFiltered({ accountTanks, filter, tanks }));
  }, [accountTanks, dispatch, filter, tanks]);

  // filter selected account tank data
  useEffect(() => {
    if (selectedAccountTank == null) {
      dispatch(setSelectedAccountTankFiltered(undefined));
    } else {
      dispatch(setSelectedAccountTankFiltered(accountTanksFiltered.find((tank) => tank.wotId === selectedAccountTank.wotId)));
    }
  }, [accountTanksFiltered, dispatch, selectedAccountTank]);

  // calculate account statistics
  useEffect(() => {
    dispatch(setAccountStats(selectedAccount));
  }, [selectedAccount, dispatch]);

  // calculate filtered account statistics
  useEffect(() => {
    dispatch(setAccountStatsFiltered({ accountFiltered: selectedAccountFiltered, filter, accountTanksFiltered }));
  }, [accountTanksFiltered, dispatch, filter, selectedAccountFiltered]);

  // calculate remaining HP rate statistics
  useEffect(() => {
    dispatch(setRemainingHpRate({ accountTanks, tanks }));
  }, [accountTanks, dispatch, tanks]);

  // calculate remaining HP rate statistics filtered
  useEffect(() => {
    dispatch(setRemainingHpRateFiltered({ accountTanksFiltered, tanks, filterTimeRange: filter.timeRange }));
  }, [accountTanksFiltered, dispatch, filter.timeRange, tanks]);

  // calculate remaining HP rate statistics server
  useEffect(() => {
    dispatch(setRemainingHpRateServer({ serverStats, tanks }));
  }, [dispatch, serverStats, tanks]);

  // calculate battle life time statistics
  useEffect(() => {
    dispatch(setBattleLifeTime({ accountTanks, account: selectedAccount }));
  }, [accountTanks, dispatch, selectedAccount]);

  // calculate battle life time filtered statistics
  useEffect(() => {
    dispatch(setBattleLifeTimeFiltered({ accountTanksFiltered, accountFiltered: selectedAccountFiltered, filter }));
  }, [accountTanksFiltered, dispatch, filter, selectedAccountFiltered]);

  // calculate battle life time statistics server
  useEffect(() => {
    dispatch(setBattleLifeTimeServer({ serverStats }));
  }, [dispatch, serverStats]);

  // calculate mastery statistics
  useEffect(() => {
    dispatch(setMastery({ account: selectedAccount }));
  }, [dispatch, selectedAccount]);

  // calculate mastery server
  useEffect(() => {
    dispatch(setMasteryServer({ serverStats }));
  }, [dispatch, serverStats]);

  return (
    <>
      <ToastContainer className="p-3 position-fixed" position="top-end">
        <Toast show={toast.show} onClose={() => dispatch(hideToast())} delay={5000} autohide bg={toast.variant}>
          <Toast.Header>
            <strong className="me-auto">{toast.title}</strong>
          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <WelcomeModal />

      <Container fluid>
        <Row>
          <Routes>
            {user == null && (
              <Route
                path="login"
                element={
                  <Col md={{ span: 6, offset: 3 }}>
                    <Login />
                  </Col>
                }
              />
            )}

            {user == null && (
              <Route
                path="reset-password/:token"
                element={
                  <Col md={{ span: 6, offset: 3 }}>
                    <ResetPassword />
                  </Col>
                }
              />
            )}

            {user != null && (
              <Route
                path="settings"
                element={
                  <Col md={{ span: 6, offset: 3 }}>
                    <Settings />
                  </Col>
                }
              />
            )}

            <Route
              path="blog"
              element={
                <Col>
                  <BlogList />
                </Col>
              }
            />

            <Route
              path="blog/01-Wargaming-WOT-vs-Wargaming-WOT-Blitz-Exploring-the-Differences"
              element={
                <Col>
                  <Blog01 />
                </Col>
              }
            />

            <Route
              path="blog/02-Mastering-Account-Development-Strategy-in-World-of-Tanks-Blitz-Rise-to-the-Top"
              element={
                <Col>
                  <Blog02 />
                </Col>
              }
            />

            <Route
              path="blog/03-Mastering-Crew-Skills-in-World-of-Tanks-Blitz-Enhance-Your-Tank-Performance"
              element={
                <Col>
                  <Blog03 />
                </Col>
              }
            />

            <Route
              path="blog/04-Mastering-Consumables-and-Equipment-in-World-of-Tanks-Blitz-Elevate-Your-Tank-Performance"
              element={
                <Col>
                  <Blog04 />
                </Col>
              }
            />

            <Route
              path="blog/05-Mastering-Vehicle-Trees-in-World-of-Tanks-Blitz-A-Comprehensive-Guide"
              element={
                <Col>
                  <Blog05 />
                </Col>
              }
            />

            <Route
              path="blog/06-Unleashing-Power-on-the-Battlefield-Exploring-Premium-Vehicles-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog06 />
                </Col>
              }
            />

            <Route
              path="blog/07-Exploring-Game-Modes-in-World-of-Tanks-Blitz-Unleash-Your-Strategic-Prowess"
              element={
                <Col>
                  <Blog07 />
                </Col>
              }
            />

            <Route
              path="blog/08-Uniting-Forces-Exploring-Clans-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog08 />
                </Col>
              }
            />

            <Route
              path="blog/09-Mastering-Teamplay-Exploring-Platoons-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog09 />
                </Col>
              }
            />

            <Route
              path="blog/10-Decoding-Win-Rate-The-Key-to-Success-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog10 />
                </Col>
              }
            />

            <Route
              path="blog/11-Mastering-Battle-Styles-Unleash-Your-Gameplay-Potential-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog11 />
                </Col>
              }
            />

            <Route
              path="blog/12-Unleashing-Devastation-Understanding-Damage-Dealt-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog12 />
                </Col>
              }
            />

            <Route
              path="blog/13-Unleash-Your-Inner-Commander-Understanding-Battles-Count-and-Player-Engagement-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog13 />
                </Col>
              }
            />

            <Route
              path="blog/14-Mastering-Vehicle-Battle-Roles-A-Guide-to-Success-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog14 />
                </Col>
              }
            />

            <Route
              path="blog/15-Legendary-Commanders-Unveiling-the-Heroes-of-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog15 />
                </Col>
              }
            />

            <Route
              path="blog/16-Mastering-the-Market-A-Purchasing-Strategy-Guide-in-World-of-Tanks-Blitz"
              element={
                <Col>
                  <Blog16 />
                </Col>
              }
            />

            <Route
              path="blog/17-Unleashing-the-Beast-Exploring-the-World-of-Tanks-Blitz-YouTube-Streams-by-Beast"
              element={
                <Col>
                  <Blog17 />
                </Col>
              }
            />

            <Route
              path="mods"
              element={
                <Col>
                  <Mods />
                </Col>
              }
            />

            <Route
              path="subscription"
              element={
                <Col md={{ span: 6, offset: 3 }}>
                  <Subscription />
                </Col>
              }
            />

            <Route
              path="subscription/waiting"
              element={
                <Col md={{ span: 6, offset: 3 }}>
                  <SubscriptionWaiting />
                </Col>
              }
            />

            <Route
              path="widgets/*"
              element={
                <Col lg={{ span: 6, offset: 3 }}>
                  <WidgetSettings />
                </Col>
              }
            />

            <Route
              path="wot-callback/:action/:wotServer"
              element={
                <Col>
                  <WotCallback />
                </Col>
              }
            />

            <Route
              path="/"
              element={
                <Col>
                  <Account />
                </Col>
              }
            />

            <Route
              path="hangar/*"
              element={
                <Col>
                  <Hangar />
                </Col>
              }
            />

            <Route
              path="tankopedia/*"
              element={
                <Col>
                  <Tankopedia />
                </Col>
              }
            />

            <Route
              path="tournaments/*"
              element={
                <Col>
                  <Tournaments />
                </Col>
              }
            />

            <Route
              path="top/tanks"
              element={
                <Col>
                  <TopTanks />
                </Col>
              }
            />

            <Route
              path="top/accounts"
              element={
                <Col>
                  <TopAccounts />
                </Col>
              }
            />

            <Route
              path="find/clan"
              element={
                <Col>
                  <FindClan />
                </Col>
              }
            />

            <Route
              path="find/clan/your"
              element={
                <Col md={{ span: 6, offset: 3 }}>
                  <YourClan />
                </Col>
              }
            />

            <Route
              path="find/platoon"
              element={
                <Col>
                  <FindPlatoon />
                </Col>
              }
            />

            <Route
              path="find/platoon/your"
              element={
                <Col md={{ span: 6, offset: 3 }}>
                  <YourPlatoon />
                </Col>
              }
            />

            <Route
              path="trovo-callback"
              element={
                <Col md={{ span: 6, offset: 3 }}>
                  <TrovoCallback />
                </Col>
              }
            />

            <Route
              path="*"
              element={
                <Col>
                  <Account />
                </Col>
              }
            />
          </Routes>
        </Row>
      </Container>
    </>
  );
}
