import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Legend, Tooltip, BarElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarChart(props: {
  labels: string[];
  datasets: Array<{
    label: string;
    data: Array<number | string | undefined>;
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
  }>;
}) {
  return (
    <div className="chart-container">
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top' as const,
            },
          },
          maintainAspectRatio: false,
          scales: {
            xAxis: {
              ticks: {
                maxTicksLimit: 10,
                autoSkip: true,
              },
            },
          },
        }}
        data={{
          labels: props.labels,
          datasets: props.datasets,
        }}
      />
    </div>
  );
}
