import { useEffect, useState } from 'react';
import { Form, Button, InputGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrovoApiService } from '../../../../services/api/trovo-api.service';
import { LogService } from '../../../../services/log.service';

export function WidgetTrovoFollowersSettings() {
  const { t } = useTranslation();

  const [channelName, setChannelName] = useState<string>();
  const [followers, setFollowers] = useState<number | undefined>(0);
  const [widgetSettings, setWidgetSettings] = useState<{
    streamingTime?: number;
    additionalFollowers?: number;
    additionalTime?: number;
  }>({ streamingTime: 120, additionalFollowers: 10, additionalTime: 1 });

  useEffect(() => {
    const run = async () => {
      try {
        let followers = 0;
        if (channelName != null && channelName !== '') {
          const svc = new TrovoApiService();
          const response = await svc.getChannel(channelName);
          followers = response.data?.followers ?? 0;
        }

        setFollowers(followers);
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    void run();
  }, [channelName]);

  const run = () => {
    window
      .open(
        `/widgets/run/trovo/followers?startingFollowers=${followers ?? 0}&streamingTime=${
          widgetSettings.streamingTime ?? 0
        }&additionalFollowers=${widgetSettings.additionalFollowers ?? 0}&additionalTime=${
          widgetSettings.additionalTime ?? 0
        }&startingTime=${new Date().toISOString()}&channelName=${channelName ?? ''}`,
        '_blank',
      )
      ?.focus();
  };

  const toNumber = (value: string) => {
    return value == null || value === '' ? undefined : +value;
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoFollowersSettings.ChannelNameLabel', 'Channel name')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('WidgetTrovoFollowersSettings.ChannelNamePlaceholder', 'Trovo channel name')}
          required
          value={channelName}
          onChange={(event) => setChannelName(event.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoFollowersSettings.FollowersLabel', 'Starting number of followers')}</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder={t('WidgetTrovoFollowersSettings.FollowersPlaceholder', 'starting followers')}
            required
            value={followers}
            onChange={(event) => setFollowers(toNumber(event.target.value))}
          />
          <InputGroup.Text>{t('WidgetTrovoFollowersSettings.FollowersPostfix', 'followers')}</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoFollowersSettings.StreamingTimeLabel', 'Starting streaming time')}</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder={t('WidgetTrovoFollowersSettings.StreamingTimePlaceholder', 'streaming time')}
            required
            value={widgetSettings?.streamingTime}
            onChange={(event) => setWidgetSettings({ ...widgetSettings, streamingTime: toNumber(event.target.value) })}
          />
          <InputGroup.Text>{t('WidgetTrovoFollowersSettings.StreamingTimePostfix', 'minutes')}</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoFollowersSettings.AdditionalStreamingTimeLabel', 'Additional streaming time')}</Form.Label>

        <Stack gap={3} direction="horizontal">
          <InputGroup>
            <Form.Control
              type="number"
              placeholder={t('WidgetTrovoFollowersSettings.AdditionalStreamingTimeFollowersPlaceholder', 'new followers')}
              required
              value={widgetSettings?.additionalFollowers}
              onChange={(event) => setWidgetSettings({ ...widgetSettings, additionalFollowers: toNumber(event.target.value) })}
            />
            <InputGroup.Text>{t('WidgetTrovoFollowersSettings.AdditionalStreamingTimeFollowersPostfix', 'followers')}</InputGroup.Text>
          </InputGroup>

          <InputGroup>
            <Form.Control
              type="number"
              placeholder={t('WidgetTrovoFollowersSettings.AdditionalStreamingTimeMinutesPlaceholder', 'additional minutes')}
              required
              value={widgetSettings?.additionalTime}
              onChange={(event) => setWidgetSettings({ ...widgetSettings, additionalTime: toNumber(event.target.value) })}
            />
            <InputGroup.Text>{t('WidgetTrovoFollowersSettings.AdditionalStreamingTimeMinutesPostfix', 'minutes')}</InputGroup.Text>
          </InputGroup>
        </Stack>
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        onClick={() => run()}
        disabled={channelName == null || channelName === ''}
        className="float-end"
      >
        {t('WidgetTrovoFollowersSettings.StartButton', 'Start')}
      </Button>
    </>
  );
}
