import { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Form, InputGroup, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPlatoonsListAsync, resetPlatoons, selectPlatoonsList } from './platoonsSlice';
import { selectAccountsList } from '../../account/redux/accountsSlice';
import { Helmet } from 'react-helmet';
import { IAccountShortApiView } from '../../../services/api/interfaces/account.interface';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../user/userSlice';
import { selectSubscriptionValid } from '../../subscription/redux/subscriptionSlice';
import { getTransparency } from '../../../utils/get-transparency.util';
import { FEATURE_FREE_PLATOON_SEARCH } from '../../../configs/config';
import { WotServer } from '../../../utils/wot-server.type';

interface ITableRow {
  wotAccountId: number;
  wotServer: WotServer;
  name: string;
  expiresAt: Date;
  comment?: string;
  battles: number;
  battlesBackgroundColor?: string;
  avgDamage: number;
  avgDamageBackgroundColor?: string;
  winRate: number;
  winRateBackgroundColor?: string;
}

const PageSize = 100;

export function FindPlatoon() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isValidSubscription = useSelector(selectSubscriptionValid);
  const accountsList = useSelector(selectAccountsList);
  const platoons = useSelector(selectPlatoonsList);

  const [page, setPage] = useState(1);

  const [tableData, setTableData] = useState<Array<ITableRow>>([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'expiresAt' | 'comment' | 'battles' | 'avgDamage' | 'winRate';
    desc: boolean;
  }>({
    column: 'winRate',
    desc: true,
  });

  const [account, setAccount] = useState<IAccountShortApiView | undefined>(undefined);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (account == null && accountsList.filter((a) => a.my === true).length > 0) {
      setAccount(accountsList.filter((a) => a.my === true)[0]);
    }
  }, [account, accountsList]);

  useEffect(() => {
    if (account != null) {
      dispatch(getPlatoonsListAsync(account.wotId, account.server));
    } else {
      dispatch(resetPlatoons());
    }
  }, [account, dispatch]);

  // prepare table data
  useEffect(() => {
    const calculatedTableData: ITableRow[] = platoons.map((p) => {
      return {
        wotAccountId: p.wotAccountId,
        wotServer: p.wotServer,
        name: p.accountName,
        expiresAt: new Date(p.expiresAt),
        comment: p.recruiting.comment,
        battles: p.stats.battles,
        avgDamage: +p.stats.avgDamage.toFixed(0),
        winRate: +p.stats.winRate.toFixed(2),
      };
    });

    // set background colors
    const limits = {
      minBattles: undefined as number | undefined,
      maxBattles: undefined as number | undefined,
      minWins: undefined as number | undefined,
      maxWins: undefined as number | undefined,
      minDamage: undefined as number | undefined,
      maxDamage: undefined as number | undefined,
    };
    calculatedTableData?.forEach((t) => {
      if (limits.minBattles == null || t.battles < limits.minBattles) {
        limits.minBattles = t.battles;
      }
      if (limits.maxBattles == null || t.battles > limits.maxBattles) {
        limits.maxBattles = t.battles;
      }
      if (limits.minWins == null || t.winRate < limits.minWins) {
        limits.minWins = t.winRate;
      }
      if (limits.maxWins == null || t.winRate > limits.maxWins) {
        limits.maxWins = t.winRate;
      }
      if (limits.minDamage == null || t.avgDamage < limits.minDamage) {
        limits.minDamage = t.avgDamage;
      }
      if (limits.maxDamage == null || t.avgDamage > limits.maxDamage) {
        limits.maxDamage = t.avgDamage;
      }
    });

    calculatedTableData?.forEach((t) => {
      t.battlesBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.battles, limits.minBattles ?? 0, limits.maxBattles ?? 0)})`;
      t.winRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.winRate, limits.minWins ?? 0, limits.maxWins ?? 0)})`;
      t.avgDamageBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgDamage, limits.minDamage ?? 0, limits.maxDamage ?? 0)})`;
    });

    const sortedTableData: ITableRow[] = calculatedTableData.sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        case 'expiresAt':
          return sorting.desc ? b.expiresAt.getTime() - a.expiresAt.getTime() : a.expiresAt.getTime() - b.expiresAt.getTime();
        case 'comment':
          return sorting.desc ? (b.comment ?? '').localeCompare(a.comment ?? '') : (a.comment ?? '').localeCompare(b.comment ?? '');
        case 'battles':
          return sorting.desc ? b.battles - a.battles : a.battles - b.battles;
        case 'avgDamage':
          return sorting.desc ? b.avgDamage - a.avgDamage : a.avgDamage - b.avgDamage;
        case 'winRate':
          return sorting.desc ? b.winRate - a.winRate : a.winRate - b.winRate;
        default:
          return sorting.desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
      }
    });

    setTableData(sortedTableData);
  }, [platoons, sorting.column, sorting.desc]);

  return (
    <Container fluid>
      <Helmet>
        <title>{t('FindPlatoons.Title', 'Wargaming WoT Blitz Beast - Find platoon')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('FindPlatoons.Header', 'Find platoon')}</h1>
        </Col>
        <Col>
          <div className="filter-container">
            <div>
              <Form.Select
                onChange={(event) => {
                  const account = accountsList.find((a) => a.wotId === +event.target.value);
                  setAccount(account);
                }}
                value={account?.wotId}
              >
                {accountsList
                  .filter((a) => a.my === true)
                  .map((a) => (
                    <option key={a.wotId} value={a.wotId}>
                      ({a.server}) {a.name}
                    </option>
                  ))}
              </Form.Select>
            </div>

            <div>
              <InputGroup>
                <Button
                  variant="outline-secondary"
                  disabled={page === 1}
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  {'<'}
                </Button>
                <Form.Control value={page} disabled className="table-page-input" />
                <Button
                  variant="outline-secondary"
                  disabled={page * PageSize >= tableData.length}
                  onClick={() => {
                    if (page * PageSize < tableData.length) {
                      setPage(page + 1);
                    }
                  }}
                >
                  {'>'}
                </Button>
              </InputGroup>
            </div>

            <div>
              <Button
                variant="success"
                onClick={() => {
                  if (FEATURE_FREE_PLATOON_SEARCH || isValidSubscription === true) {
                    navigate('/find/platoon/your');
                  } else {
                    navigate('/subscription');
                  }
                }}
              >
                {t('FindPlatoons.YourPlatoon', 'Your Platoon')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'comment', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnComment', 'Comment')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'expiresAt', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnExpiresIn', 'Expires in')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'winRate', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnWinRate', 'Win rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battles', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnBattles', 'Battles')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'avgDamage', desc: !sorting.desc })}>
                    {t('FindPlatoons.ColumnDamage', 'Avg. damage')}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                [...tableData].slice((page - 1) * PageSize, page * PageSize).map((platoon, i) => (
                  <tr key={platoon.name}>
                    <td>{(page - 1) * PageSize + i + 1}</td>
                    <td>
                      <Link to={`/accounts/${platoon.wotServer}/${platoon.wotAccountId}`}>{platoon.name}</Link>
                    </td>
                    <td>{platoon.comment}</td>
                    <td>
                      {platoon.expiresAt == null || platoon.expiresAt.getTime() - Date.now() <= 0
                        ? t('FindPlatoons.RecruitingExpirationExpired', 'expired')
                        : `${Math.floor((platoon.expiresAt.getTime() - Date.now()) / (1000 * 60 * 60))} ${t(
                            'FindPlatoons.RecruitingExpirationHours',
                            'hours',
                          )} ${(((platoon.expiresAt.getTime() - Date.now()) % (1000 * 60 * 60)) / (1000 * 60)).toFixed(0)} ${t(
                            'FindPlatoons.RecruitingExpirationMinutes',
                            'minutes',
                          )}`}
                    </td>
                    <td style={{ backgroundColor: platoon.winRateBackgroundColor }}>{platoon.winRate}</td>
                    <td style={{ backgroundColor: platoon.battlesBackgroundColor }}>{platoon.battles}</td>
                    <td style={{ backgroundColor: platoon.avgDamageBackgroundColor }}>{platoon.avgDamage}</td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    {t('FindPlatoons.NoTableRows', 'There are no platoons available for your account. Try to add your own!')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
