import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { IAccountTankApiView } from '../../../services/api/interfaces/account-tanks.interface';
import { IServerStatisticsApiView, IServerTankStatistics } from '../../../services/api/interfaces/server-statistics.interface';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { TimeRanges } from '../../filter/redux/filterSlice';
import { getMaxPossibleTankHp } from '../../tanks/tank.utils';

export interface RemainingHpRateStatisticsState {
  accountRemainingHpRate?: number;
  accountRemainingHpRateFiltered?: number;
  accountRemainingHpRateServer?: number;
  accountTanksRemainingHpRate: Array<{ wotId: number; rhpr: number; battles: number }>;
  accountTanksRemainingHpRateFiltered: Array<{ wotId: number; rhpr: number; battles: number }>;
  accountTanksRemainingHpRateServer: Array<{ wotId: number; rhpr: number; battles: number }>;
}

const InitialState: RemainingHpRateStatisticsState = {
  accountRemainingHpRate: undefined,
  accountRemainingHpRateFiltered: undefined,
  accountRemainingHpRateServer: undefined,
  accountTanksRemainingHpRate: [],
  accountTanksRemainingHpRateFiltered: [],
  accountTanksRemainingHpRateServer: [],
};

export const remainingHpRateStatisticsSlice = createSlice({
  name: 'remainingHpRateStatistics',
  initialState: InitialState,
  reducers: {
    resetRemainingHpRateStatistics: () => InitialState,
    setRemainingHpRate: (state, action: PayloadAction<{ accountTanks: IAccountTankApiView[]; tanks: ITankApiView[] }>) => {
      state.accountTanksRemainingHpRate = calculateRhpr(action.payload.accountTanks, action.payload.tanks, false);

      if (state.accountTanksRemainingHpRate.length === 0) {
        state.accountRemainingHpRate = undefined;
      } else {
        state.accountRemainingHpRate =
          state.accountTanksRemainingHpRate.reduce((acc, cur) => acc + cur.rhpr * cur.battles, 0) /
          (state.accountTanksRemainingHpRate.reduce((acc, cur) => acc + cur.battles, 0) || 1);
      }
    },
    setRemainingHpRateFiltered: (
      state,
      action: PayloadAction<{ accountTanksFiltered: IAccountTankApiView[]; tanks: ITankApiView[]; filterTimeRange: TimeRanges }>,
    ) => {
      state.accountTanksRemainingHpRateFiltered = calculateRhpr(
        action.payload.accountTanksFiltered,
        action.payload.tanks,
        action.payload.filterTimeRange !== 'all',
      );

      if (state.accountTanksRemainingHpRateFiltered.length === 0) {
        state.accountRemainingHpRateFiltered = undefined;
      } else {
        state.accountRemainingHpRateFiltered =
          state.accountTanksRemainingHpRateFiltered.reduce((acc, cur) => acc + cur.rhpr * cur.battles, 0) /
          (state.accountTanksRemainingHpRateFiltered.reduce((acc, cur) => acc + cur.battles, 0) || 1);
      }
    },
    setRemainingHpRateServer: (
      state,
      action: PayloadAction<{ serverStats: IServerStatisticsApiView | undefined; tanks: ITankApiView[] }>,
    ) => {
      if (action.payload.serverStats == null) {
        state.accountTanksRemainingHpRateServer = [];
        state.accountRemainingHpRateServer = undefined;
        return;
      }

      state.accountTanksRemainingHpRateServer = calculateRhprServer(action.payload.serverStats.tanks, action.payload.tanks);

      state.accountRemainingHpRateServer =
        state.accountTanksRemainingHpRateServer.reduce((acc, cur) => acc + cur.rhpr * cur.battles, 0) /
        (state.accountTanksRemainingHpRateServer.reduce((acc, cur) => acc + cur.battles, 0) || 1);
    },
  },
});

export const { resetRemainingHpRateStatistics, setRemainingHpRate, setRemainingHpRateFiltered, setRemainingHpRateServer } =
  remainingHpRateStatisticsSlice.actions;

function calculateRhpr(
  accountTanks: IAccountTankApiView[],
  tanks: ITankApiView[],
  useDiff: boolean,
): Array<{ wotId: number; rhpr: number; battles: number }> {
  const result = new Array<{ wotId: number; rhpr: number; battles: number }>();

  for (const accountTank of accountTanks) {
    const tank = tanks.find((t) => t.wotId === accountTank.wotId);
    if (tank == null || accountTank.snapshots.length === 0 || (useDiff && accountTank.snapshots.length < 2)) {
      continue;
    }

    const basicSnapshot = useDiff ? accountTank.snapshots[0] : undefined;

    const avgDamageReceivedPerBattle =
      (accountTank.snapshots[accountTank.snapshots.length - 1].regular.damageReceived - (basicSnapshot?.regular.damageReceived ?? 0)) /
      (accountTank.snapshots[accountTank.snapshots.length - 1].regular.battles - (basicSnapshot?.regular.battles ?? 0) || 1);

    let rhpr = 0;
    if (avgDamageReceivedPerBattle !== tank.hp && avgDamageReceivedPerBattle !== getMaxPossibleTankHp(tank)) {
      rhpr = (getMaxPossibleTankHp(tank) - avgDamageReceivedPerBattle) / getMaxPossibleTankHp(tank);
    }

    const battles = accountTank.snapshots[accountTank.snapshots.length - 1].regular.battles - (basicSnapshot?.regular.battles ?? 0);

    result.push({ wotId: accountTank.wotId, rhpr, battles });
  }

  return result;
}

function calculateRhprServer(
  serverTanks: IServerTankStatistics[],
  tanks: ITankApiView[],
): Array<{ wotId: number; rhpr: number; battles: number }> {
  const result = new Array<{ wotId: number; rhpr: number; battles: number }>();

  for (const serverTank of serverTanks) {
    const tank = tanks.find((t) => t.wotId === serverTank.wotId);
    if (tank == null) {
      continue;
    }

    const rhpr =
      (getMaxPossibleTankHp(tank) - serverTank.statistics.regular.damageReceived / (serverTank.statistics.regular.battles || 1)) /
      getMaxPossibleTankHp(tank);

    const battles = serverTank.statistics.regular.battles;

    result.push({ wotId: serverTank.wotId, rhpr, battles });
  }

  return result;
}

export const selectAccountRemainingHpRate = (state: RootState) => state.statistics.remainingHpStatistics.accountRemainingHpRate;
export const selectAccountRemainingHpRateFiltered = (state: RootState) =>
  state.statistics.remainingHpStatistics.accountRemainingHpRateFiltered;
export const selectAccountRemainingHpRateServer = (state: RootState) => state.statistics.remainingHpStatistics.accountRemainingHpRateServer;
export const selectAccountTanksRemainingHpRate = (state: RootState) => state.statistics.remainingHpStatistics.accountTanksRemainingHpRate;
export const selectAccountTanksRemainingHpRateFiltered = (state: RootState) =>
  state.statistics.remainingHpStatistics.accountTanksRemainingHpRateFiltered;
export const selectAccountTanksRemainingHpRateServer = (state: RootState) =>
  state.statistics.remainingHpStatistics.accountTanksRemainingHpRateServer;

export default remainingHpRateStatisticsSlice.reducer;
