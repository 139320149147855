import { Accordion, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import { useEffect, useState } from 'react';
import { IStatistics } from '../../statistics/interfaces';
import { useSelector } from 'react-redux';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { IAccountStatisticsData, ITankStatisticsData } from '../../../services/api/interfaces/server-statistics.interface';
import {
  selectAccountRemainingHpRate,
  selectAccountRemainingHpRateServer,
  selectAccountTanksRemainingHpRate,
  selectAccountTanksRemainingHpRateServer,
} from '../../statistics/redux/remainingHpRateStatisticsSlice';
import { selectAccount } from '../../account/redux/accountsSlice';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Legend, Tooltip);

export function OverviewCard(props: { stats?: IStatistics; tank?: ITankApiView }) {
  const { t } = useTranslation();

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));
  const accountRhpr = useSelector(selectAccountRemainingHpRate);
  const accountRhprServer = useSelector(selectAccountRemainingHpRateServer);
  const accountTanksRhpr = useSelector(selectAccountTanksRemainingHpRate);
  const accountTanksRhprServer = useSelector(selectAccountTanksRemainingHpRateServer);

  const [data, setData] = useState({
    winRateRatio: 0,
    damageRateRatio: 0,
    fragsRareRatio: 0,
    spotsRateRatio: 0,
    survivalRateRatio: 0,
    remainingHpRateRatio: 0,
  });

  const [activeAccordionItemKey, setActiveAccordionItemKey] = useState(
    localStorage.getItem('overview_card_active_accordion_item_key') ?? 'none',
  );

  useEffect(() => {
    if (serverStats == null || props.stats == null) {
      setData({
        winRateRatio: 0,
        damageRateRatio: 0,
        fragsRareRatio: 0,
        spotsRateRatio: 0,
        survivalRateRatio: 0,
        remainingHpRateRatio: 0,
      });
      return;
    }

    let serverStatsItem: IAccountStatisticsData | ITankStatisticsData | undefined = serverStats.account.statistics;
    if (props.tank != null) {
      serverStatsItem = serverStats.tanks.find((t) => t.wotId === props.tank?.wotId)?.statistics;
    }

    if (serverStatsItem == null) {
      setData({
        winRateRatio: 0,
        damageRateRatio: 0,
        fragsRareRatio: 0,
        spotsRateRatio: 0,
        survivalRateRatio: 0,
        remainingHpRateRatio: 0,
      });
      return;
    }

    const winRate = props.stats.regular.wins / (props.stats.regular.battles || 1);
    const winRateServer = serverStatsItem.regular.wins / (serverStatsItem.regular.battles || 1);

    const damageRate = props.stats.regular.damageDealt / (props.stats.regular.damageReceived || 1);
    const damageRateServer = serverStatsItem.regular.damageDealt / (serverStatsItem.regular.damageReceived || 1);

    const fragsRate = props.stats.regular.frags / (props.stats.regular.battles || 1);
    const fragsRateServer = serverStatsItem.regular.frags / (serverStatsItem.regular.battles || 1);

    const spotsRate = props.stats.regular.spotted / (props.stats.regular.battles || 1);
    const spotsRateServer = serverStatsItem.regular.spotted / (serverStatsItem.regular.battles || 1);

    const survivalRate = props.stats.regular.survivedBattles / (props.stats.regular.battles || 1);
    const survivalRateServer = serverStatsItem.regular.survivedBattles / (serverStatsItem.regular.battles || 1);

    let remainingHpRate = accountRhpr;
    let remainingHpRateServer = accountRhprServer;

    if (props.tank != null) {
      remainingHpRate = accountTanksRhpr.find((t) => t.wotId === props.tank?.wotId)?.rhpr;
      remainingHpRateServer = accountTanksRhprServer.find((t) => t.wotId === props.tank?.wotId)?.rhpr;
    }

    setData({
      winRateRatio: winRate / (winRateServer || 1),
      damageRateRatio: damageRate / (damageRateServer || 1),
      fragsRareRatio: fragsRate / (fragsRateServer || 1),
      spotsRateRatio: spotsRate / (spotsRateServer || 1),
      survivalRateRatio: survivalRate / (survivalRateServer || 1),
      remainingHpRateRatio: (remainingHpRate ?? 0) / (remainingHpRateServer || 1),
    });
  }, [accountRhpr, accountRhprServer, accountTanksRhpr, accountTanksRhprServer, props.stats, props.tank, serverStats]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{t('RadialCard.Header', 'Overview')}</h2>
      </Card.Header>

      {props.tank != null && props.tank.image != null && props.tank.image !== '' && <Card.Img variant="top" src={props.tank.image} />}

      <Card.Body>
        <div className="chart-container">
          <Radar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top' as const,
                },
              },
              maintainAspectRatio: false,
              scales: {
                r: {
                  beginAtZero: true,
                },
              },
            }}
            data={{
              labels: [
                t('RadialCard.WinRateLabel', 'Win rate'),
                t('RadialCard.DamageRateLabel', 'Damage rate'),
                t('RadialCard.FragsRateLabel', 'Frags rate'),
                t('RadialCard.SpotsRateLabel', 'Spots rate'),
                t('RadialCard.SurvivalRateLabel', 'Survival rate'),
                t('RadialCard.RemainingHpLabel', 'Remaining HP rate'),
              ],
              datasets: [
                {
                  label: t('OverviewCard.StatsLable', 'Account statistics'),
                  data: [
                    data.winRateRatio,
                    data.damageRateRatio,
                    data.fragsRareRatio,
                    data.spotsRateRatio,
                    data.survivalRateRatio,
                    data.remainingHpRateRatio,
                  ],
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  borderWidth: 1,
                },
                {
                  label: t('OverviewCard.ServerStatsLable', 'Average server statistics'),
                  data: [1, 1, 1, 1, 1, 1],
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>

        <Accordion
          className="mt-3"
          activeKey={activeAccordionItemKey}
          onSelect={(eventKey) => {
            setActiveAccordionItemKey(eventKey as string);
            if (eventKey == null) {
              localStorage.setItem('overview_card_active_accordion_item_key', 'none');
            } else {
              localStorage.setItem('overview_card_active_accordion_item_key', eventKey as string);
            }
          }}
        >
          <Accordion.Item eventKey="info">
            <Accordion.Header>{t('OverviewCard.InfoHeader', 'Learn more about the chart')}</Accordion.Header>
            <Accordion.Body>
              <Trans t={t} i18nKey="OverviewCard.InfoBody">
                <p>
                  The Overview card shows all the account or tank major metrics compared to an average metric for the same game server.
                  Server values are taken as a value of 1 so you can figure out what are your strong and weak areas and how your values are
                  compared to each other.
                </p>
                <p>
                  Good players always have higher than average values but having some single metric too high often means degradation of the
                  others. So try to keep your stats balanced to be really cool in all aspects.
                </p>
                <p>
                  You can learn more about raising your skill on our{' '}
                  <a href="https://www.youtube.com/BeastWorldoftanksblitz?utm_source=beastwot.com" target="_blank" rel="noreferrer">
                    Youtube channel
                  </a>
                </p>
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}
