import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/rootReducer';
import { AppThunk } from '../../redux/store';
import { AuthApiService } from '../../services/api/auth-api.service';
import { IUserApiView } from '../../services/api/interfaces/user.interface';
import { UserApiService } from '../../services/api/user-api.service';
import { LogService } from '../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../loading/loadingSlice';
import { showToast } from '../toast/redux/toastSlice';

export interface UserState {
  me?: IUserApiView;
}

const InitialState: UserState = {
  me: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: InitialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserApiView | undefined>) => {
      state.me = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

let refreshInterval: NodeJS.Timer | null = null;
export const getUserMeAsync = (): AppThunk => async (dispatch) => {
  try {
    // cancel previous refresh
    if (refreshInterval != null) {
      clearInterval(refreshInterval);
      refreshInterval = null;
    }

    dispatch(startLoading(LoadingParts.UserMeLoading));

    const svc = new UserApiService();
    const response = await svc.getMe();

    if (response?.data != null) {
      refreshInterval = setInterval(() => {
        const run = async () => {
          const authSvc = new AuthApiService();
          const response = await authSvc.refresh();

          if (response.success !== true) {
            dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
          } else {
            if (response.data != null) {
              localStorage.setItem('jwt', response.data);
              LogService.debug('JWT token refreshed');
              dispatch(getUserMeAsync());
            }
          }
        };

        void run();
      }, 1000 * 60 * 20); // 20 mins
    }

    dispatch(setUser(response?.data ?? undefined));
  } catch (e) {
    LogService.error(`Error while loading user`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.UserMeLoading));
  }
};

export const selectUser = (state: RootState) => state.user.me;

export default userSlice.reducer;
