import { Accordion, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../account/redux/accountsSlice';
import { selectFilter } from '../../filter/redux/filterSlice';
import { useEffect, useState } from 'react';
import { IChartValue, IStatistics } from '../../statistics/interfaces';
import { BarChart } from '../charts/BarChart';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';

export function BattlesCard(props: {
  stats?: IStatistics;
  statsFiltered?: IStatistics;
  chart?: IChartValue[];
  filteredSnapshotsLength?: number;
  tank?: ITankApiView;
}) {
  const { t } = useTranslation();

  const account = useSelector(selectAccount);
  const filter = useSelector(selectFilter);
  const serverStats = useSelector(selectServerStatistics(account?.server));

  const [battlesPerYear, setBattlesPerYear] = useState<number | undefined>(undefined);
  const [battlesPerYearFiltered, setBattlesPerYearFiltered] = useState<number | undefined>(undefined);
  const [battlesPerYearServer, setBattlesPerYearServer] = useState<number | undefined>(undefined);

  const [barChartData, setBarChartData] = useState<Array<number | undefined> | undefined>(undefined);

  const [activeAccordionItemKey, setActiveAccordionItemKey] = useState(
    localStorage.getItem('battles_card_active_accordion_item_key') ?? 'none',
  );

  // calculate battles per year
  useEffect(() => {
    if (props.stats == null || account?.createdAt == null) {
      setBattlesPerYear(undefined);
      return;
    }

    const months =
      new Date().getMonth() -
      new Date(account.createdAt).getMonth() +
      12 * (new Date().getFullYear() - new Date(account.createdAt).getFullYear());

    setBattlesPerYear(((props.stats.regular.battles + (props.stats.rating?.battles ?? 0)) / months) * 12);
  }, [account?.createdAt, props.stats]);

  // calculate battles per year filtered
  useEffect(() => {
    if (props.statsFiltered == null || props.filteredSnapshotsLength == null || props.filteredSnapshotsLength < 2) {
      // we show all battles if there are less than 2 snapshots
      // this is because we need at least 2 snapshots to calculate the difference
      setBattlesPerYearFiltered(undefined);
      return;
    }

    const battles = props.statsFiltered.regular.battles + (props.statsFiltered.rating?.battles ?? 0);

    switch (filter.timeRange) {
      case 'all':
        setBattlesPerYearFiltered(battlesPerYear);
        break;
      case '1y':
        setBattlesPerYearFiltered(battles);
        break;
      case '6m':
        setBattlesPerYearFiltered((battles / 6) * 12);
        break;
      case '3m':
        setBattlesPerYearFiltered((battles / 3) * 12);
        break;
      case '1m':
        setBattlesPerYearFiltered(battles * 12);
        break;
      case '2w':
        setBattlesPerYearFiltered(battles * 2 * 12);
        break;
      case '1w':
        setBattlesPerYearFiltered(battles * 4 * 12);
        break;
    }
  }, [battlesPerYear, filter.timeRange, props.filteredSnapshotsLength, props.statsFiltered]);

  // calculate battles per year for a server
  useEffect(() => {
    if (props.tank == null && serverStats != null) {
      setBattlesPerYearServer(
        ((serverStats.account.statistics.regular.battles + serverStats.account.statistics.rating.battles) /
          serverStats.account.statistics.weeks) *
          52,
      );
    } else {
      setBattlesPerYearServer(undefined);
    }
  }, [props.tank, serverStats]);

  // prepare bar chart data
  useEffect(() => {
    if (props.chart == null) {
      setBarChartData(undefined);
      return;
    }

    const chartData = new Array<number | undefined>();
    let prev = undefined;
    for (const chartValue of props.chart) {
      if (chartValue.stats == null) {
        chartData.push(undefined);
      } else if (prev == null) {
        prev = chartValue.stats.regular.battles + (chartValue.stats.rating?.battles ?? 0);
        chartData.push(filter.timeRange === 'all' ? undefined : prev);
      } else {
        const battles = chartValue.stats.regular.battles + (chartValue.stats.rating?.battles ?? 0);
        chartData.push(battles - prev >= 0 ? battles - prev : 0);
        prev = chartValue.stats.regular.battles + (chartValue.stats.rating?.battles ?? 0);
      }
    }

    setBarChartData(chartData);
  }, [filter.timeRange, props.chart]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{t('BattlesCard.Header', 'Battles')}</h2>
      </Card.Header>

      <Card.Body>
        <Table bordered hover responsive size="sm" className="table-normal-header">
          <thead>
            <tr>
              <th></th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.TotalTooltip', 'Total value for the account')}</Tooltip>}>
                  <span>
                    <strong>{t('CardTableHeader.Total', 'Total')}</strong>
                  </span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.FilteredTooltip', 'Filtered value for the account')}</Tooltip>}>
                  <span>{t('CardTableHeader.Filtered', 'Filtered')}</span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger
                  overlay={<Tooltip>{t('CardTableHeader.ServerStatsTooltip', 'Average value for the whole server')}</Tooltip>}
                >
                  <span>{t('CardTableHeader.ServerStats', 'Server')}</span>
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t('BattlesCard.BattlesPerYearLabel', 'Battles per year')}</strong>
              </td>
              <td
                className={
                  battlesPerYear == null || battlesPerYearServer == null
                    ? undefined
                    : battlesPerYearServer < battlesPerYear
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{battlesPerYear?.toFixed(0) ?? '-'}</strong>
              </td>
              <td
                className={
                  battlesPerYearFiltered == null || battlesPerYearServer == null
                    ? undefined
                    : battlesPerYearServer < battlesPerYearFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {battlesPerYearFiltered?.toFixed(0) ?? '-'}
              </td>
              <td>{battlesPerYearServer?.toFixed(0) ?? '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('BattlesCard.RegularBattlesLabel', 'Regular battles')}</strong>
              </td>
              <td>
                <strong className="increase-font-size">{props.stats?.regular.battles ?? '-'}</strong>
              </td>
              <td>{props.statsFiltered?.regular.battles ?? '-'}</td>
              <td>-</td>
            </tr>
            {props.tank == null && (
              <tr>
                <td>
                  <strong>{t('BattlesCard.RatingBattlesLabel', 'Rating battles')}</strong>
                </td>
                <td>
                  <strong className="increase-font-size">{props.stats?.rating?.battles ?? '-'}</strong>
                </td>
                <td>{props.statsFiltered?.rating?.battles ?? '-'}</td>
                <td>-</td>
              </tr>
            )}
            <tr>
              <td>
                <strong>{t('BattlesCard.StartDateLabel', 'Start date')}</strong>
              </td>
              <td>
                <strong className="increase-font-size">
                  {account?.createdAt == null ? '-' : new Date(account.createdAt).toLocaleDateString()}
                </strong>
              </td>
              <td>
                {props.statsFiltered?.regular.battles == null
                  ? '-'
                  : filter.timeRange !== 'all'
                  ? new Date(filter.startDate).toLocaleDateString()
                  : account?.createdAt == null
                  ? '-'
                  : new Date(account.createdAt).toLocaleDateString()}
              </td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>

      <Card.Body>
        <BarChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('BattlesCard.BattlesChartLabel', 'Battles count (including Rating)'),
              data: barChartData ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderWidth: 1,
            },
          ]}
        />

        <Accordion
          className="mt-3"
          activeKey={activeAccordionItemKey}
          onSelect={(eventKey) => {
            setActiveAccordionItemKey(eventKey as string);
            if (eventKey == null) {
              localStorage.setItem('battles_card_active_accordion_item_key', 'none');
            } else {
              localStorage.setItem('battles_card_active_accordion_item_key', eventKey as string);
            }
          }}
        >
          <Accordion.Item eventKey="info">
            <Accordion.Header>{t('BattlesCard.InfoHeader', 'Learn more about the chart')}</Accordion.Header>
            <Accordion.Body>
              <Trans t={t} i18nKey="BattlesCard.InfoBody">
                <p>
                  No one is born a good player. It is learned by practicing more and more while taking useful info from reliable sources.
                  That is why you should consider how many battles you play per year to understand how fast is your progress.
                </p>
                <p>
                  But it is just a game after all, do not take it too seriously. Have fun, play when you feel it is right to do, and enjoy
                  the process.
                </p>
                <p>
                  Join our regular{' '}
                  <a href="https://www.youtube.com/@BeastWotb/streams?utm_source=beastwot.com" target="_blank" rel="noreferrer">
                    live streams
                  </a>{' '}
                  and letʼs have fun together!
                </p>
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}
