import { Alert, Button, Form, InputGroup, Modal, Stack, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITournamentAccountBonusSep2023ApiView } from '../../../../../services/api/interfaces/tournament.interface';
import { selectAccount } from '../../../../account/redux/accountsSlice';
import { selectUser } from '../../../../user/userSlice';
import { LoadingParts, selectLoadingState } from '../../../../loading/loadingSlice';
import { SearchKnownAccount } from '../../../../components/SearchKnownAccount';
import { IAccountShortApiView } from '../../../../../services/api/interfaces/account.interface';
import DatePicker from 'react-datepicker';
import { addTournamentAccountBonusAsync, deleteTournamentAccountBonusAsync, selectTournamentBonuses } from '../../../tournamentsSlice';
import { toRoman } from '../../../../../utils/number.util';
import { useNavigate } from 'react-router-dom';

const PageSize = 100;

export function TournamentDetailsSep2023Bonuses() {
  type TableRow = {
    accountName: string;
    accountId: string;
    bonusId: string;
    wotId: number;
    server: string;
    trovoNickName?: string | undefined;
    date: string;
    source: string;
    tankLevel: number;
    parts: number;
    comment?: string | undefined;
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);
  const loading = useSelector(selectLoadingState);

  const tournamentWithBonuses = useSelector(selectTournamentBonuses);

  const [bonusResults, setBonusResults] = useState<ITournamentAccountBonusSep2023ApiView[]>([]);
  const [tableRows, setTableRows] = useState<TableRow[]>([]);
  const [sorting, setSorting] = useState<{
    column: 'accountName' | 'wotId' | 'server' | 'trovoNickName' | 'date' | 'source' | 'tankLevel' | 'parts' | 'comment';
    desc: boolean;
  }>({
    column: 'accountName',
    desc: false,
  });
  const [page, setPage] = useState(1);

  const [addingAccount, setAddingAccount] = useState<IAccountShortApiView | undefined>(undefined);
  const [addingDate, setAddingDate] = useState<Date>(new Date());
  const [addingSource, setAddingSource] = useState<'trovo' | 'manual'>('manual');
  const [addingTankLevel, setAddingTankLevel] = useState<5 | 6 | 7 | 8 | 9 | 10>(5);
  const [addingTankParts, setAddingTankParts] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | -10>(1);
  const [addingComment, setAddingComment] = useState<string | undefined>(undefined);

  const [deleting, setDeleting] = useState<TableRow | undefined>(undefined);

  useEffect(() => {
    if (user?.role === 'admin') {
      setBonusResults(tournamentWithBonuses?.bonuses ?? []);
    } else {
      setBonusResults(tournamentWithBonuses?.bonuses.filter((x) => x.accountId === account?.id) ?? []);
    }
  }, [account?.id, tournamentWithBonuses?.bonuses, user?.role]);

  useEffect(() => {
    const rows = bonusResults
      .map((bonus) => ({
        accountName: bonus.name,
        accountId: bonus.accountId,
        bonusId: bonus.id,
        wotId: bonus.wotId,
        server: bonus.server,
        trovoNickName: bonus.trovoNickName,
        date: bonus.date,
        source: bonus.source,
        tankLevel: bonus.value.tankLevel,
        parts: bonus.value.parts,
        comment: bonus.comment,
      }))
      .sort((a, b) => {
        switch (sorting.column) {
          case 'accountName':
            return sorting.desc
              ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
              : (a.accountName ?? '').localeCompare(b.accountName ?? '');
          case 'wotId':
            return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
          case 'server':
            return sorting.desc ? (b.server ?? '').localeCompare(a.server ?? '') : (a.server ?? '').localeCompare(b.server ?? '');
          case 'trovoNickName':
            return sorting.desc
              ? (b.trovoNickName ?? '').localeCompare(a.trovoNickName ?? '')
              : (a.trovoNickName ?? '').localeCompare(b.trovoNickName ?? '');
          case 'date':
            return sorting.desc ? (b.date ?? '').localeCompare(a.date ?? '') : (a.date ?? '').localeCompare(b.date ?? '');
          case 'source':
            return sorting.desc ? (b.source ?? '').localeCompare(a.source ?? '') : (a.source ?? '').localeCompare(b.source ?? '');
          case 'tankLevel':
            return sorting.desc ? (b.tankLevel ?? 0) - (a.tankLevel ?? 0) : (a.tankLevel ?? 0) - (b.tankLevel ?? 0);
          case 'parts':
            return sorting.desc ? (b.parts ?? 0) - (a.parts ?? 0) : (a.parts ?? 0) - (b.parts ?? 0);
          case 'comment':
            return sorting.desc ? (b.comment ?? '').localeCompare(a.comment ?? '') : (a.comment ?? '').localeCompare(b.comment ?? '');
          default:
            return sorting.desc
              ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
              : (a.accountName ?? '').localeCompare(b.accountName ?? '');
        }
      });

    setTableRows(rows);
  }, [bonusResults, sorting.column, sorting.desc]);

  const resetAdding = () => {
    setAddingAccount(undefined);
    setAddingDate(new Date());
    setAddingSource('manual');
    setAddingTankLevel(5);
    setAddingTankParts(1);
    setAddingComment(undefined);
  };

  return (
    <>
      <Modal show={deleting != null} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{t('TournamentDetails.DeletingModalTitle', 'Deleting bonus')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'TournamentDetails.DeletingModalBody',
            'You are about to delete a tournament account bonus for {{name}}. This action could not be undone. Are you sure?',
            {
              name: deleting?.accountName,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            {t('TournamentDetails.DeletingCancelButton', 'Cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch(
                deleteTournamentAccountBonusAsync(tournamentWithBonuses?.id ?? '', deleting?.accountId ?? '', deleting?.bonusId ?? ''),
              );
              setDeleting(undefined);
            }}
          >
            {t('TournamentDetails.DeletingDeleteButton', 'Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      {user?.role !== 'admin' && account != null && account.my === true && account.trovoUserInfo == null && (
        <Stack direction="horizontal" gap={3}>
          <Button className="mb-3" onClick={() => navigate('/settings')}>
            {t('TournamentDetails.ConnectTrovoButton', 'Connect Trovo')}
          </Button>

          <Alert variant="warning">
            <p className="mb-2">
              {t(
                'TournamentDetails.TrovoLoginWarning',
                'Your account is not connected to Trovo. You will not be able to receive Trovo bonuses. Please, connect your Trovo account.',
              )}
            </p>
          </Alert>
        </Stack>
      )}

      {user?.role === 'admin' && (
        <InputGroup className="mb-2">
          <Button
            variant="outline-secondary"
            disabled={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            {'<'}
          </Button>
          <Form.Control value={page} disabled className="table-page-input" />
          <Button
            variant="outline-secondary"
            disabled={page * PageSize >= tableRows.length}
            onClick={() => {
              if (page * PageSize < tableRows.length) {
                setPage(page + 1);
              }
            }}
          >
            {'>'}
          </Button>
        </InputGroup>
      )}

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'accountName', desc: !sorting.desc })}>
                {t('TournamentDetails.AccountColumn', 'Account')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'wotId', desc: !sorting.desc })}>
                {t('TournamentDetails.IdColumn', 'ID')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'server', desc: !sorting.desc })}>
                {t('TournamentDetails.ServerColumn', 'Server')}
              </Button>
            </th>
            {user?.role === 'admin' && (
              <th>
                <Button variant="link" onClick={() => setSorting({ column: 'trovoNickName', desc: !sorting.desc })}>
                  {t('TournamentDetails.TrovoColumn', 'Trovo')}
                </Button>
              </th>
            )}
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'date', desc: !sorting.desc })}>
                {t('TournamentDetails.DateColumn', 'Date')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'source', desc: !sorting.desc })}>
                {t('TournamentDetails.SourceColumn', 'Source')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'tankLevel', desc: !sorting.desc })}>
                {t('TournamentDetails.TankLevelColumn', 'Tank level')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'parts', desc: !sorting.desc })}>
                {t('TournamentDetails.TankPartsColumn', 'Tank parts')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'comment', desc: !sorting.desc })}>
                {t('TournamentDetails.CommentColumn', 'Comment')}
              </Button>
            </th>
            {user?.role === 'admin' && <th></th>}
          </tr>
        </thead>
        <tbody>
          {user?.role === 'admin' && (
            <tr>
              <td></td>
              <td>
                <SearchKnownAccount subject="name" onSelected={(selected) => setAddingAccount(selected)} selected={addingAccount} />
              </td>
              <td>
                <SearchKnownAccount subject="wotId" onSelected={(selected) => setAddingAccount(selected)} selected={addingAccount} />
              </td>
              <td>{addingAccount?.server}</td>
              <td>
                <SearchKnownAccount subject="trovo" onSelected={(selected) => setAddingAccount(selected)} selected={addingAccount} />
              </td>
              <td>
                <DatePicker
                  selected={addingDate}
                  onChange={(date: Date) => {
                    setAddingDate(date);
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={new Date(tournamentWithBonuses?.startDate ?? '')}
                />
              </td>
              <td>
                <Form.Select onChange={(event) => setAddingSource(event.target.value as 'trovo' | 'manual')} value={addingSource}>
                  <option value="trovo">{t('TournamentDetails.TrovoBonus', 'Trovo')}</option>
                  <option value="manual">{t('TournamentDetails.ManualBonus', 'Manual')}</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  onChange={(event) => setAddingTankLevel(+event.target.value as 5 | 6 | 7 | 8 | 9 | 10)}
                  value={addingTankLevel}
                >
                  <option value="5">V</option>
                  <option value="6">VI</option>
                  <option value="7">VII</option>
                  <option value="8">VIII</option>
                  <option value="9">IX</option>
                  <option value="10">X</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  onChange={(event) => setAddingTankParts(+event.target.value as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | -10)}
                  value={addingTankParts}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="-10">-10</option>
                </Form.Select>
              </td>
              <td>
                <Form.Control type="text" value={addingComment ?? ''} onChange={(value) => setAddingComment(value.target.value)} />
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    variant="success"
                    onClick={() => {
                      dispatch(
                        addTournamentAccountBonusAsync(tournamentWithBonuses?.id ?? '', addingAccount?.id ?? '', {
                          date: addingDate,
                          source: addingSource,
                          tankLevel: addingTankLevel,
                          parts: addingTankParts,
                          comment: addingComment ?? '',
                        }),
                      );
                      resetAdding();
                    }}
                    disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true) || addingAccount == null}
                  >
                    {t('TournamentDetails.SaveAddingBonusButton', 'Save')}
                  </Button>

                  <Button
                    className="ms-2"
                    variant="outline-danger"
                    onClick={() => resetAdding()}
                    disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true)}
                  >
                    {t('TournamentDetails.CancelAddingBonusButton', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}

          {tableRows.slice((page - 1) * PageSize, page * PageSize).map((row, i) => (
            <tr key={i}>
              <td>{(page - 1) * PageSize + i + 1}</td>
              <td>{row.accountName}</td>
              <td>{row.wotId}</td>
              <td>{row.server}</td>
              {user?.role === 'admin' && <td>{row.trovoNickName}</td>}
              <td>{new Date(row.date).toLocaleDateString()}</td>
              <td>{row.source}</td>
              <td>{toRoman(row.tankLevel)}</td>
              <td>{row.parts}</td>
              <td>{row.comment}</td>
              {user?.role === 'admin' && (
                <td>
                  <Button
                    variant="outline-danger"
                    onClick={() => setDeleting(row)}
                    disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true)}
                  >
                    {t('TournamentDetails.DeleteBonusButton', 'Delete')}
                  </Button>
                </td>
              )}
            </tr>
          ))}

          {bonusResults.length === 0 && (
            <tr>
              <td colSpan={user?.role === 'admin' ? 10 : 9} className="text-center">
                {t('TournamentDetails.NoTableRows', 'There are no tournament results yet.')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
