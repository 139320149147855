import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { IPlatoonApiView } from './interfaces/platoon.interfacets';
import { ListResponseApiView, SingleEntityResponseApiView } from './interfaces/response.interface';

export class PlatoonsApiService {
  async getList(wotAccountId: number, wotServer: WotServer): Promise<ListResponseApiView<IPlatoonApiView>> {
    const response = await fetchApi(`platoons/list?wotAccountId=${wotAccountId}&wotServer=${wotServer}`, {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<IPlatoonApiView>;
    return body;
  }

  async update(
    wotAccountId: number,
    wotServer: WotServer,
    expiresAt: Date,
    recruiting: { battles?: number; winRate?: number; avgDamage?: number; comment?: string },
  ): Promise<SingleEntityResponseApiView<IPlatoonApiView>> {
    const response = await fetchApi('platoons', {
      method: 'POST',
      body: JSON.stringify({ wotAccountId, wotServer, recruiting, expiresAt: expiresAt.toISOString() }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IPlatoonApiView>;
    return body;
  }

  async get(wotAccountId: number, wotServer: WotServer): Promise<SingleEntityResponseApiView<IPlatoonApiView | undefined>> {
    const response = await fetchApi(`platoons?wotAccountId=${wotAccountId}&wotServer=${wotServer}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IPlatoonApiView | undefined>;
    return body;
  }
}
