import { Accordion, Card, OverlayTrigger, Stack, Table, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { IChartValue, IStatistics } from '../../statistics/interfaces';
import { useEffect, useState } from 'react';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { useSelector } from 'react-redux';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { LineChart } from '../charts/LineChart';
import {
  selectAccountRemainingHpRate,
  selectAccountRemainingHpRateFiltered,
  selectAccountRemainingHpRateServer,
  selectAccountTanksRemainingHpRate,
  selectAccountTanksRemainingHpRateFiltered,
  selectAccountTanksRemainingHpRateServer,
} from '../../statistics/redux/remainingHpRateStatisticsSlice';
import {
  selectAccountBattleLifeTime,
  selectAccountBattleLifeTimeAccurate,
  selectAccountBattleLifeTimeFiltered,
  selectAccountBattleLifeTimeServer,
} from '../../statistics/redux/battleLifeTimeStatisticsSlice';
import { selectAccountMastery, selectAccountMasteryServer } from '../../statistics/redux/masteryStatisticsSlice';
import { ExclamationSquareFill } from 'react-bootstrap-icons';
import { selectAccount } from '../../account/redux/accountsSlice';

export function BattleStyleCard(props: { stats?: IStatistics; statsFiltered?: IStatistics; chart?: IChartValue[]; tank?: ITankApiView }) {
  const { t } = useTranslation();

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));

  const accountRhpr = useSelector(selectAccountRemainingHpRate);
  const accountRhprFiltered = useSelector(selectAccountRemainingHpRateFiltered);
  const accountRhprServer = useSelector(selectAccountRemainingHpRateServer);
  const accountTanksRhpr = useSelector(selectAccountTanksRemainingHpRate);
  const accountTanksRhprFiltered = useSelector(selectAccountTanksRemainingHpRateFiltered);
  const accountTanksRhprServer = useSelector(selectAccountTanksRemainingHpRateServer);

  const accountBattleLifeTime = useSelector(selectAccountBattleLifeTime);
  const accountBattleLifeTimeFiltered = useSelector(selectAccountBattleLifeTimeFiltered);
  const accountBattleLifeTimeAccurate = useSelector(selectAccountBattleLifeTimeAccurate);
  const accountBattleLifeTimeServer = useSelector(selectAccountBattleLifeTimeServer);

  const accountMastery = useSelector(selectAccountMastery);
  const accountMasteryServer = useSelector(selectAccountMasteryServer);

  const [fragsRate, setFragsRate] = useState<number | undefined>(undefined);
  const [fragsRateFiltered, setFragsRateFiltered] = useState<number | undefined>(undefined);
  const [fragsRateServer, setFragsRateServer] = useState<number | undefined>(undefined);

  const [spottedRate, setSpottedRate] = useState<number | undefined>(undefined);
  const [spottedRateFiltered, setSpottedRateFiltered] = useState<number | undefined>(undefined);
  const [spottedRateServer, setSpottedRateServer] = useState<number | undefined>(undefined);

  const [survivalRate, setSurvivalRate] = useState<number | undefined>(undefined);
  const [survivalRateFiltered, setSurvivalRateFiltered] = useState<number | undefined>(undefined);
  const [survivalRateServer, setSurvivalRateServer] = useState<number | undefined>(undefined);

  const [remainingHpRate, setRemainingHpRate] = useState<number | undefined>(undefined);
  const [remainingHpRateFiltered, setRemainingHpRateFiltered] = useState<number | undefined>(undefined);
  const [remainingHpRateServer, setRemainingHpRateServer] = useState<number | undefined>(undefined);

  const [activeAccordionItemKey, setActiveAccordionItemKey] = useState(
    localStorage.getItem('battle_style_card_active_accordion_item_key') ?? 'none',
  );

  // calculate frags, spotted and survival rates
  useEffect(() => {
    if (props.stats == null) {
      setFragsRate(undefined);
      setSpottedRate(undefined);
      setSurvivalRate(undefined);
      return;
    }

    setFragsRate(props.stats.regular.frags / (props.stats.regular.battles || 1));
    setSpottedRate(props.stats.regular.spotted / (props.stats.regular.battles || 1));
    setSurvivalRate(props.stats.regular.survivedBattles / (props.stats.regular.battles || 1));
  }, [props.stats]);

  // calculate frags, spotted and survival rates filtered
  useEffect(() => {
    if (props.statsFiltered == null) {
      setFragsRateFiltered(undefined);
      setSpottedRateFiltered(undefined);
      setSurvivalRateFiltered(undefined);
      return;
    }

    setFragsRateFiltered(props.statsFiltered.regular.frags / (props.statsFiltered.regular.battles || 1));
    setSpottedRateFiltered(props.statsFiltered.regular.spotted / (props.statsFiltered.regular.battles || 1));
    setSurvivalRateFiltered(props.statsFiltered.regular.survivedBattles / (props.statsFiltered.regular.battles || 1));
  }, [props.statsFiltered]);

  // calculate frags, spotted and survival rates server
  useEffect(() => {
    if (serverStats == null) {
      setFragsRateServer(undefined);
      setSpottedRateServer(undefined);
      setSurvivalRateServer(undefined);
      return;
    }

    if (props.tank == null) {
      setFragsRateServer(serverStats.account.statistics.regular.frags / (serverStats.account.statistics.regular.battles || 1));
      setSpottedRateServer(serverStats.account.statistics.regular.spotted / (serverStats.account.statistics.regular.battles || 1));
      setSurvivalRateServer(serverStats.account.statistics.regular.survivedBattles / (serverStats.account.statistics.regular.battles || 1));
    } else {
      const tankStats = serverStats.tanks.find((tankStats) => tankStats.wotId === props.tank?.wotId);
      if (tankStats != null) {
        setFragsRateServer(tankStats.statistics.regular.frags / (tankStats.statistics.regular.battles || 1));
        setSpottedRateServer(tankStats.statistics.regular.spotted / (tankStats.statistics.regular.battles || 1));
        setSurvivalRateServer(tankStats.statistics.regular.survivedBattles / (tankStats.statistics.regular.battles || 1));
      } else {
        setFragsRateServer(undefined);
        setSpottedRateServer(undefined);
        setSurvivalRateServer(undefined);
      }
    }
  }, [props.tank, serverStats]);

  // calculate rhp rate
  useEffect(() => {
    if (props.tank == null) {
      setRemainingHpRate(accountRhpr);
    } else {
      const tankRhpr = accountTanksRhpr.find((t) => t.wotId === props.tank?.wotId);

      setRemainingHpRate(tankRhpr == null ? undefined : tankRhpr.rhpr);
    }
  }, [accountRhpr, accountTanksRhpr, props.tank]);

  // calculate rhp rate filtered
  useEffect(() => {
    if (props.tank == null) {
      setRemainingHpRateFiltered(accountRhprFiltered);
    } else {
      const tankRhpr = accountTanksRhprFiltered.find((t) => t.wotId === props.tank?.wotId);

      setRemainingHpRateFiltered(tankRhpr == null ? undefined : tankRhpr.rhpr);
    }
  }, [accountRhprFiltered, accountTanksRhprFiltered, props.tank]);

  // calculate rhp rate server
  useEffect(() => {
    if (props.tank == null) {
      setRemainingHpRateServer(accountRhprServer);
    } else {
      const tankRhpr = accountTanksRhprServer.find((t) => t.wotId === props.tank?.wotId);

      setRemainingHpRateServer(tankRhpr == null ? undefined : tankRhpr.rhpr);
    }
  }, [accountRhprServer, accountTanksRhprServer, props.tank]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{t('BattleStyleCard.Header', 'Battles style')}</h2>
      </Card.Header>

      <Card.Body>
        <Table bordered hover responsive size="sm" className="table-normal-header">
          <thead>
            <tr>
              <th></th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.TotalTooltip', 'Total value for the account')}</Tooltip>}>
                  <span>
                    <strong>{t('CardTableHeader.Total', 'Total')}</strong>
                  </span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.FilteredTooltip', 'Filtered value for the account')}</Tooltip>}>
                  <span>{t('CardTableHeader.Filtered', 'Filtered')}</span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger
                  overlay={<Tooltip>{t('CardTableHeader.ServerStatsTooltip', 'Average value for the whole server')}</Tooltip>}
                >
                  <span>{t('CardTableHeader.ServerStats', 'Server')}</span>
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t('BattleStyleCard.FragsRateLabel', 'Frags rate')}</strong>
              </td>
              <td
                className={
                  fragsRateServer == null || fragsRate == null
                    ? undefined
                    : fragsRateServer <= fragsRate
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{fragsRate?.toFixed(2) ?? '-'}</strong>
              </td>
              <td
                className={
                  fragsRateServer == null || fragsRateFiltered == null
                    ? undefined
                    : fragsRateServer <= fragsRateFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {fragsRateFiltered?.toFixed(2) ?? '-'}
              </td>
              <td>{fragsRateServer?.toFixed(2) ?? '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('BattleStyleCard.SpottedRateLabel', 'Spotted rate')}</strong>
              </td>
              <td
                className={
                  spottedRateServer == null || spottedRate == null
                    ? undefined
                    : spottedRateServer <= spottedRate
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{spottedRate?.toFixed(2) ?? '-'}</strong>
              </td>
              <td
                className={
                  spottedRateServer == null || spottedRateFiltered == null
                    ? undefined
                    : spottedRateServer <= spottedRateFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {spottedRateFiltered?.toFixed(2) ?? '-'}
              </td>
              <td>{spottedRateServer?.toFixed(2) ?? '-'}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('BattleStyleCard.SurvivalRateLabel', 'Survival rate')}</strong>
              </td>
              <td
                className={
                  survivalRateServer == null || survivalRate == null
                    ? undefined
                    : survivalRateServer <= survivalRate
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{survivalRate == null ? '-' : (survivalRate * 100).toFixed(2)}</strong>
              </td>
              <td
                className={
                  survivalRateServer == null || survivalRateFiltered == null
                    ? undefined
                    : survivalRateServer <= survivalRateFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {survivalRateFiltered == null ? '-' : (survivalRateFiltered * 100).toFixed(2)}
              </td>
              <td>{survivalRateServer == null ? '-' : (survivalRateServer * 100).toFixed(2)}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('BattleStyleCard.RemainingHpRateLabel', 'Remaining HP rate')}</strong>
              </td>
              <td
                className={
                  remainingHpRateServer == null || remainingHpRate == null
                    ? undefined
                    : remainingHpRateServer <= remainingHpRate
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{remainingHpRate == null ? '-' : (remainingHpRate * 100).toFixed(2)}</strong>
              </td>
              <td
                className={
                  remainingHpRateServer == null || remainingHpRateFiltered == null
                    ? undefined
                    : remainingHpRateServer <= remainingHpRateFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {remainingHpRateFiltered == null ? '-' : (remainingHpRateFiltered * 100).toFixed(2)}
              </td>
              <td>{remainingHpRateServer == null ? '-' : (remainingHpRateServer * 100).toFixed(2)}</td>
            </tr>
            {props.tank == null && (
              <tr>
                <td>
                  <Stack direction="horizontal" gap={1}>
                    <strong>{t('BattleStyleCard.BattleLifeTimeLabel', 'Battle life time')}</strong>
                    {accountBattleLifeTimeAccurate === false && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {t(
                              'BattleStyleCard.BattleLifeTimeAccurateTooltip',
                              'The value might not be accurate. Please log in to the site or link the account in Settings to get the accurate value.',
                            )}
                          </Tooltip>
                        }
                      >
                        <div className="table-cell-icon">
                          <ExclamationSquareFill size={20} color="orange" />
                        </div>
                      </OverlayTrigger>
                    )}
                  </Stack>
                </td>
                <td
                  className={
                    accountBattleLifeTimeServer == null || accountBattleLifeTime == null || accountBattleLifeTime === 0
                      ? undefined
                      : accountBattleLifeTimeServer <= accountBattleLifeTime
                      ? 'success-background'
                      : 'warning-background'
                  }
                >
                  <strong className="increase-font-size">
                    {accountBattleLifeTime == null || accountBattleLifeTime === 0
                      ? '-'
                      : accountBattleLifeTime > 7 * 60
                      ? '~7m'
                      : `${Math.floor(accountBattleLifeTime / 60)}m ${(accountBattleLifeTime % 60).toFixed(0)}s`}
                  </strong>
                </td>
                <td
                  className={
                    accountBattleLifeTimeServer == null || accountBattleLifeTimeFiltered == null || accountBattleLifeTimeFiltered === 0
                      ? undefined
                      : accountBattleLifeTimeServer <= accountBattleLifeTimeFiltered
                      ? 'success-background'
                      : 'warning-background'
                  }
                >
                  {accountBattleLifeTimeFiltered == null || accountBattleLifeTimeFiltered === 0
                    ? '-'
                    : accountBattleLifeTimeFiltered > 7 * 60
                    ? '~7m'
                    : `${Math.floor(accountBattleLifeTimeFiltered / 60)}m ${(accountBattleLifeTimeFiltered % 60).toFixed(0)}s`}
                </td>
                <td>
                  {accountBattleLifeTimeServer == null || accountBattleLifeTimeServer === 0
                    ? '-'
                    : accountBattleLifeTimeServer > 7 * 60
                    ? '~7m'
                    : `${Math.floor(accountBattleLifeTimeServer / 60)}m ${(accountBattleLifeTimeServer % 60).toFixed(0)}s`}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>

      {props.tank == null && (
        <Card.Body>
          <Table bordered hover responsive size="sm" className="table-normal-header">
            <thead>
              <tr>
                <th></th>
                <th>
                  <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.TotalTooltip', 'Total value for the account')}</Tooltip>}>
                    <span>{t('CardTableHeader.Total', 'Total')}</span>
                  </OverlayTrigger>
                </th>
                <th>
                  <OverlayTrigger
                    overlay={<Tooltip>{t('CardTableHeader.ServerStatsTooltip', 'Average value for the whole server')}</Tooltip>}
                  >
                    <span>{t('CardTableHeader.ServerStats', 'Server')}</span>
                  </OverlayTrigger>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{t('BattleStyleCard.MasteryLabel', 'Battles per Master')}</strong>
                </td>
                <td
                  className={
                    accountMasteryServer?.masteryBattles == null
                      ? undefined
                      : accountMastery?.masteryBattles == null || accountMasteryServer.masteryBattles < accountMastery.masteryBattles
                      ? 'warning-background'
                      : 'success-background'
                  }
                >
                  <strong>{accountMastery?.masteryBattles == null ? '-' : accountMastery.masteryBattles.toFixed(0)}</strong>
                </td>
                <td>{accountMasteryServer?.masteryBattles == null ? '-' : accountMasteryServer.masteryBattles.toFixed(0)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('BattleStyleCard.MasteryILabel', 'Battles per Master I')}</strong>
                </td>
                <td
                  className={
                    accountMasteryServer?.masteryIBattles == null
                      ? undefined
                      : accountMastery?.masteryIBattles == null || accountMasteryServer.masteryIBattles < accountMastery.masteryIBattles
                      ? 'warning-background'
                      : 'success-background'
                  }
                >
                  <strong>{accountMastery?.masteryIBattles == null ? '-' : accountMastery.masteryIBattles.toFixed(0)}</strong>
                </td>
                <td>{accountMasteryServer?.masteryIBattles == null ? '-' : accountMasteryServer.masteryIBattles.toFixed(0)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('BattleStyleCard.MasteryIILabel', 'Battles per Master II')}</strong>
                </td>
                <td
                  className={
                    accountMasteryServer?.masteryIIBattles == null
                      ? undefined
                      : accountMastery?.masteryIIBattles == null || accountMasteryServer.masteryIIBattles < accountMastery.masteryIIBattles
                      ? 'warning-background'
                      : 'success-background'
                  }
                >
                  <strong>{accountMastery?.masteryIIBattles == null ? '-' : accountMastery.masteryIIBattles.toFixed(0)}</strong>
                </td>
                <td>{accountMasteryServer?.masteryIIBattles == null ? '-' : accountMasteryServer.masteryIIBattles.toFixed(0)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t('BattleStyleCard.MasteryIIILabel', 'Battles per Master III')}</strong>
                </td>
                <td
                  className={
                    accountMasteryServer?.masteryIIIBattles == null
                      ? undefined
                      : accountMastery?.masteryIIIBattles == null ||
                        accountMasteryServer.masteryIIIBattles < accountMastery.masteryIIIBattles
                      ? 'warning-background'
                      : 'success-background'
                  }
                >
                  <strong>{accountMastery?.masteryIIIBattles == null ? '-' : accountMastery.masteryIIIBattles.toFixed(0)}</strong>
                </td>
                <td>{accountMasteryServer?.masteryIIIBattles == null ? '-' : accountMasteryServer.masteryIIIBattles.toFixed(0)}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      )}

      <Card.Body>
        <LineChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('BattleStyleCard.FragsRateChartLabel', 'Frags rate'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : (i.stats.regular.frags / (i.stats.regular.battles || 1)).toFixed(2),
                ) ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: t('BattleStyleCard.SpottedRateChartLabel', 'Spotted rate'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : (i.stats.regular.spotted / (i.stats.regular.battles || 1)).toFixed(2),
                ) ?? [],
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ]}
        />
      </Card.Body>

      <Card.Body>
        <LineChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('BattleStyleCard.SurvivalRateChartLabel', 'Survival rate'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : ((i.stats.regular.survivedBattles / (i.stats.regular.battles || 1)) * 100).toFixed(2),
                ) ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
          ]}
        />

        <Accordion
          className="mt-3"
          activeKey={activeAccordionItemKey}
          onSelect={(eventKey) => {
            setActiveAccordionItemKey(eventKey as string);
            if (eventKey == null) {
              localStorage.setItem('battle_style_card_active_accordion_item_key', 'none');
            } else {
              localStorage.setItem('battle_style_card_active_accordion_item_key', eventKey as string);
            }
          }}
        >
          <Accordion.Item eventKey="info">
            <Accordion.Header>{t('BattleStyleCard.InfoHeader', 'Learn more about the chart')}</Accordion.Header>
            <Accordion.Body>
              <Trans t={t} i18nKey="BattleStyleCard.InfoBody">
                <p>
                  A perfect battle style is what really makes a player a good one. How many frags do you make, how many enemies you can spot
                  and how long can you stay in a battle - everything is important. The more skilled player you are, the more chances your
                  team has to win.
                </p>
                <p>
                  But also keep in mind the battle role of a tank you play. Remember to support your teammates to let them stay alive and
                  contribute to your team success. The game already calculates a mastery score for each of your battle but it is much more
                  valuable to get your allies compliments rather than numbers on your result page.
                </p>
                <p>
                  See our{' '}
                  <a
                    href="https://www.youtube.com/playlist?list=PLcaggObhc7NpnQqgniYB3zzSFrT4HdY9k&utm_source=beastwot.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    tips and tricks videos
                  </a>{' '}
                  to become a better player.
                </p>
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}
