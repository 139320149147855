import { combineReducers } from '@reduxjs/toolkit';
import coreStatisticsReducer from './coreStatisticsSlice';
import serverStatisticsReducer from './serverStatisticsSlice';
import remainingHpStatisticsReducer from './remainingHpRateStatisticsSlice';
import battleLifeTimeStatisticsReducer from './battleLifeTimeStatisticsSlice';
import masteryStatisticsReducer from './masteryStatisticsSlice';

const statisticsReducer = combineReducers({
  serverStatistics: serverStatisticsReducer,
  coreStatistics: coreStatisticsReducer,
  remainingHpStatistics: remainingHpStatisticsReducer,
  battleLifeTimeStatistics: battleLifeTimeStatisticsReducer,
  masteryStatistics: masteryStatisticsReducer,
});

export type RootState = ReturnType<typeof statisticsReducer>;

export default statisticsReducer;
