import { useState } from 'react';
import { Accordion, Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

export function Mods() {
  const { t } = useTranslation();

  const [showDisclaimer, setShowDisclaimer] = useState(true);

  return (
    <Container>
      <Helmet>
        <title>{t('Mods.Title', 'Wargaming WoT Blitz Beast - Mods, skins, sounds')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('Mods.Header', 'Mods')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Alert className="mt-2" variant="info" dismissible onClose={() => setShowDisclaimer(false)} show={showDisclaimer}>
            <Trans t={t} i18nKey="Mods.Disclaimer">
              <p>ATTENTION!</p>
              <p>
                We know that Wot Blitz developers are strongly against mods that give a battle advantage like in aiming, tank module skins,
                transparent objects, etc..
              </p>
              <p>
                There is no chance to see such mods here since we agree that <b>the game should remain fair and balanced.</b>
              </p>
              <p>
                However, since Wot Blitz does not officially allow mods, you can download them at your own risk. We are not responsible for
                any possible consequences.
              </p>
            </Trans>
          </Alert>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.SessionPcTitle', 'Session (Windows, macOS)')}</Card.Title>
              <Card.Text>{t('Mods.SessionText', 'A session mod allows you to follow your recent battle statistics, such as an amount of battles, a win rate, an average damage, etc. You can reset a session data anytime and start a battle session from zero.')}</Card.Text>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="map">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.SessionPcInstructions">
                      <p>
                        <em><strong>Supported platforms:</strong> Windows (Steam and Wargaming Game Center), macOS (App Store and Steam).</em>
                      </p>
                      <p>
                        <strong>Installation:</strong>
                      </p>
                      <ol>
                        <li>extract a modification zip file to any location and open the extracted folder;</li>
                        <li>Windows:
                          <ol>
                            <li>double-click on the "install-windows" file;</li>
                            <li>if a warning window appears, click "More info" and "Run anyway";</li>
                          </ol>
                        </li>
                        <li>macOS:
                          <ol>
                            <li>open "System Settings" (in the menu with the Apple logo);</li>
                            <li>go to "Privacy & Security" section;</li>
                            <li>go to "App Management" or "Full Disk Access";</li>
                            <li>grant permission for "Terminal" app;</li>
                            <li>go back to the extracted folder and right-click on the "install-macos" file and select "Open";</li>
                          </ol>
                        </li>
                        <li>launch the game.</li>
                      </ol>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://blitzinsider.com/download/session/pc"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.SessionAndroidTitle', 'Session (Android)')}</Card.Title>
              <Card.Text>{t('Mods.SessionText', 'A session mod allows you to follow your recent battle statistics, such as an amount of battles, a win rate, an average damage, etc. You can reset a session data anytime and start a battle session from zero.')}</Card.Text>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="map">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.SessionAndroidInstructions">
                      <p>
                        <em><strong>Note 1:</strong> logging in to the game is possible only using an email address and a password (Google and Facebook login methods aren't available).</em>
                      </p>
                      <p>
                        <em><strong>Note 2:</strong> purchases using a real money are possible only using an original game app, so don't delete it if you need it.</em>
                      </p>
                      <p>
                        <strong>Installation:</strong>
                      </p>
                      <ol>
                        <li>install a modification APK file (you may be prompted to agree to install an app from an unknown source - just agree);</li>
                        <li>launch the game - the one with a lightning icon.</li>
                      </ol>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://blitzinsider.com/download/session/android"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.CamouflagesTitle', 'Camouflages')}</Card.Title>
              <Card.Text>
                {t(
                  'Mods.CamouflagesText',
                  'Custom camouflage allows you to make the game unique and funny. Choose one and see your tanks in a new way!',
                )}
              </Card.Text>

              <Accordion className="mt-3">
                <Accordion.Item eventKey="camo">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.CamouflagesInstructions">
                      <p>
                        Unarchive selected camo and place the files into a folder specific to your operation system. Please backup the
                        folder content before to be able to restore the original look.
                      </p>
                      <p>
                        <b>Android</b> - Android/data/net.wargaming.wot.blitz/files/packs
                      </p>
                      <p>
                        <b>Steam, WGC</b> - initial path depends on a platform. Look for a folder that contains 3d and UI
                        subfolders.
                      </p>
                      <p>
                        <b>macOS</b> - /Applications/World of Tanks Blitz.app/Contents/Resources/Data
                      </p>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://github.com/beastwot/mods-releases/raw/master/v10.3/camouflages/beast-wot-blitz-10.3-camouflages.zip"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.VoiceoversTitle', 'Voiceovers')}</Card.Title>
              <Card.Text>
                {t('Mods.VoiceoversText', 'Set up voiceovers from Beast and make your game experience like never before!')}
              </Card.Text>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="voice">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.VoiceoversInstructions">
                      <p>
                        Unarchive the package and place the files into a folder specific to your operation system. Please backup the folder
                        content before to be able to restore the original state.
                      </p>
                      <p>
                        <b>Android</b> - Android/data/net.wargaming.wot.blitz/files/packs
                      </p>
                      <p>
                        <b>Steam, WGC</b> - initial path depends on a platform. Look for a folder that contains WwiseSound
                        and UI subfolders.
                      </p>
                      <p>
                        <b>macOS</b> - /Applications/World of Tanks Blitz.app/Contents/Resources/Data
                      </p>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://github.com/beastwot/mods-releases/raw/master/v10.3/voiceovers/beast-wot-blitz-10.3-voiceover_crew_1.zip"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.Map3Title', 'Map x3')}</Card.Title>
              <Card.Text>{t('Mods.Map3Text', 'Increase your minimap size to be 3 times bigger! You will never miss it again!')}</Card.Text>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="map">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.MapInstructions">
                      <p>
                        Unarchive the package and place the files into a folder specific to your operation system. Please backup the folder
                        content before to be able to restore the original state.
                      </p>
                      <p>
                        <b>Steam, WGC</b> - initial path depends on a platform. Look for a folder that contains UI
                        subfolder.
                      </p>
                      <p>
                        <b>macOS</b> - /Applications/World of Tanks Blitz.app/Contents/Resources/Data
                      </p>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://github.com/beastwot/mods-releases/raw/master/v11.1.1/mods/beast-wot-blitz-11.1.1-minimap-3x-pc.zip"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t('Mods.Map2Title', 'Map x2')}</Card.Title>
              <Card.Text>{t('Mods.Map2Text', 'Increase your minimap size to be 2 times bigger! You will never miss it again!')}</Card.Text>
              <Accordion className="mt-3">
                <Accordion.Item eventKey="map">
                  <Accordion.Header>{t('Mods.InstructionsHeader', 'How to install the mod')}</Accordion.Header>
                  <Accordion.Body>
                    <Trans t={t} i18nKey="Mods.MapInstructions">
                      <p>
                        Unarchive the package and place the files into a folder specific to your operation system. Please backup the folder
                        content before to be able to restore the original state.
                      </p>
                      <p>
                        <b>Steam, WGC</b> - initial path depends on a platform. Look for a folder that contains UI
                        subfolder.
                      </p>
                      <p>
                        <b>macOS</b> - /Applications/World of Tanks Blitz.app/Contents/Resources/Data
                      </p>
                    </Trans>
                    <p>
                      {t('Mods.SessionInstructionsHelpText', 'If you have any problems with the mod, ')}<a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">{t('Mods.SessionInstructionsHelpLink', 'visit our channel.')}</a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Button
                className="mt-3"
                target="_blank"
                href="https://github.com/beastwot/mods-releases/raw/master/v11.1.1/mods/beast-wot-blitz-11.1.1-minimap-2x-pc.zip"
              >
                {t('Mods.DownloadButton', 'Download')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
