import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { IAccountApiView } from '../../../services/api/interfaces/account.interface';
import { IServerStatisticsApiView } from '../../../services/api/interfaces/server-statistics.interface';

export interface MasteryStatisticsState {
  accountMastery?: {
    masteryBattles?: number;
    masteryIBattles?: number;
    masteryIIBattles?: number;
    masteryIIIBattles?: number;
  };
  accountMasteryServer?: {
    masteryBattles?: number;
    masteryIBattles?: number;
    masteryIIBattles?: number;
    masteryIIIBattles?: number;
  };
}

const InitialState: MasteryStatisticsState = {
  accountMastery: undefined,
  accountMasteryServer: undefined,
};

export const masteryStatisticsSlice = createSlice({
  name: 'masteryStatistics',
  initialState: InitialState,
  reducers: {
    resetMasteryStatistics: () => InitialState,
    setMastery: (state, action: PayloadAction<{ account: IAccountApiView | undefined }>) => {
      if (action.payload.account == null || action.payload.account.snapshots.length === 0) {
        state.accountMastery = undefined;
        return;
      }

      state.accountMastery = {
        masteryBattles:
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMastery == null ||
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMastery === 0
            ? undefined
            : (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].regular.battles +
                (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].rating?.battles ?? 0)) /
              (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMastery || 1),
        masteryIBattles:
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryI == null ||
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryI === 0
            ? undefined
            : (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].regular.battles +
                (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].rating?.battles ?? 0)) /
              (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryI || 1),
        masteryIIBattles:
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryII == null ||
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryII === 0
            ? undefined
            : (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].regular.battles +
                (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].rating?.battles ?? 0)) /
              (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryII || 1),
        masteryIIIBattles:
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryIII == null ||
          action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryIII === 0
            ? undefined
            : (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].regular.battles +
                (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].rating?.battles ?? 0)) /
              (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].mastery?.markOfMasteryIII || 1),
      };
    },
    setMasteryServer: (state, action: PayloadAction<{ serverStats: IServerStatisticsApiView | undefined }>) => {
      if (action.payload.serverStats == null) {
        state.accountMasteryServer = undefined;
        return;
      }

      state.accountMasteryServer = {
        masteryBattles:
          action.payload.serverStats.account.statistics.mastery.markOfMastery === 0
            ? undefined
            : (action.payload.serverStats.account.statistics.regular.battles +
                action.payload.serverStats.account.statistics.rating.battles) /
              action.payload.serverStats.account.statistics.mastery.markOfMastery,
        masteryIBattles:
          action.payload.serverStats.account.statistics.mastery.markOfMasteryI === 0
            ? undefined
            : (action.payload.serverStats.account.statistics.regular.battles +
                action.payload.serverStats.account.statistics.rating.battles) /
              action.payload.serverStats.account.statistics.mastery.markOfMasteryI,
        masteryIIBattles:
          action.payload.serverStats.account.statistics.mastery.markOfMasteryII === 0
            ? undefined
            : (action.payload.serverStats.account.statistics.regular.battles +
                action.payload.serverStats.account.statistics.rating.battles) /
              action.payload.serverStats.account.statistics.mastery.markOfMasteryII,
        masteryIIIBattles:
          action.payload.serverStats.account.statistics.mastery.markOfMasteryIII === 0
            ? undefined
            : (action.payload.serverStats.account.statistics.regular.battles +
                action.payload.serverStats.account.statistics.rating.battles) /
              action.payload.serverStats.account.statistics.mastery.markOfMasteryIII,
      };
    },
  },
});

export const { resetMasteryStatistics, setMastery, setMasteryServer } = masteryStatisticsSlice.actions;

export const selectAccountMastery = (state: RootState) => state.statistics.masteryStatistics.accountMastery;
export const selectAccountMasteryServer = (state: RootState) => state.statistics.masteryStatistics.accountMasteryServer;

export default masteryStatisticsSlice.reducer;
