import { useEffect, useState } from 'react';
import { Button, Form, Card, Stack, Col, Row, FormGroup, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { selectAccountsList } from '../../account/redux/accountsSlice';
import { IAccountShortApiView } from '../../../services/api/interfaces/account.interface';
import { getPlatoonAsync, selectPlatoon, setPlatoon, updatePlatoonAsync } from './platoonsSlice';
import { useNavigate } from 'react-router-dom';
import { selectSubscriptionValid } from '../../subscription/redux/subscriptionSlice';
import { selectUser } from '../../user/userSlice';
import { FEATURE_FREE_PLATOON_SEARCH } from '../../../configs/config';

type ExpiresInOptions = 'no_change' | 'expired' | '30m' | '1h' | '2h' | '3h' | '4h' | '5h' | '6h' | '8h' | '10h';

export function YourPlatoon() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isValidSubscription = useSelector(selectSubscriptionValid);
  const accountsList = useSelector(selectAccountsList);
  const platoon = useSelector(selectPlatoon);

  const [account, setAccount] = useState<IAccountShortApiView | undefined>(undefined);

  const [expiresIn, setExpiresIn] = useState<ExpiresInOptions>('no_change');

  const [expiresAt, setExpiresAt] = useState<Date | undefined>(undefined);
  const [recruitingComment, setRecruitingComment] = useState<string | undefined>(undefined);
  const [recruitingBattles, setRecruitingBattles] = useState<number | undefined>(undefined);
  const [recruitingWinRate, setRecruitingWinRate] = useState<number | undefined>(undefined);
  const [recruitingAvgDamage, setRecruitingAvgDamage] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (!FEATURE_FREE_PLATOON_SEARCH && isValidSubscription !== true) {
      navigate('/subscription');
    }
  }, [navigate, isValidSubscription]);

  useEffect(() => {
    if (account == null && accountsList.filter((a) => a.my === true).length > 0) {
      setAccount(accountsList.filter((a) => a.my === true)[0]);
    }
  }, [account, accountsList]);

  useEffect(() => {
    if (account != null) {
      dispatch(getPlatoonAsync(account.wotId, account.server));
    } else {
      dispatch(setPlatoon(undefined));
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (platoon != null) {
      setExpiresAt(new Date(platoon.expiresAt));
      setRecruitingComment(platoon.recruiting.comment);
      setRecruitingBattles(platoon.recruiting.battles);
      setRecruitingWinRate(platoon.recruiting.winRate);
      setRecruitingAvgDamage(platoon.recruiting.avgDamage);
    } else {
      setExpiresAt(undefined);
      setRecruitingComment(undefined);
      setRecruitingBattles(undefined);
      setRecruitingWinRate(undefined);
      setRecruitingAvgDamage(undefined);
    }
  }, [platoon]);

  useEffect(() => {
    if (expiresIn === 'no_change') {
      return;
    } else if (expiresIn === 'expired') {
      setExpiresAt(new Date());
    } else if (expiresIn === '30m') {
      const date = new Date();
      date.setMinutes(date.getMinutes() + 30);
      setExpiresAt(date);
    } else if (expiresIn === '1h') {
      const date = new Date();
      date.setHours(date.getHours() + 1);
      setExpiresAt(date);
    } else if (expiresIn === '2h') {
      const date = new Date();
      date.setHours(date.getHours() + 2);
      setExpiresAt(date);
    } else if (expiresIn === '3h') {
      const date = new Date();
      date.setHours(date.getHours() + 3);
      setExpiresAt(date);
    } else if (expiresIn === '4h') {
      const date = new Date();
      date.setHours(date.getHours() + 4);
      setExpiresAt(date);
    } else if (expiresIn === '5h') {
      const date = new Date();
      date.setHours(date.getHours() + 5);
      setExpiresAt(date);
    } else if (expiresIn === '6h') {
      const date = new Date();
      date.setHours(date.getHours() + 6);
      setExpiresAt(date);
    } else if (expiresIn === '8h') {
      const date = new Date();
      date.setHours(date.getHours() + 8);
      setExpiresAt(date);
    } else if (expiresIn === '10h') {
      const date = new Date();
      date.setHours(date.getHours() + 10);
      setExpiresAt(date);
    }
  }, [expiresIn]);

  return (
    <Container>
      <Helmet>
        <title>{t('YourPlatoon.Title', 'Wargaming WoT Blitz Beast - Your platoon')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('YourPlatoon.Header', 'Your platoon')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h2>{t('YourPlatoon.AccountHeader', 'Account')}</h2>

              <FormGroup as={Row}>
                <Form.Label column sm={3}>
                  {t('YourPlatoon.AccountName', 'Name')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    onChange={(event) => {
                      const account = accountsList.find((a) => a.wotId === +event.target.value);
                      setAccount(account);
                    }}
                    value={account?.wotId}
                  >
                    {accountsList
                      .filter((a) => a.my === true)
                      .map((a) => (
                        <option key={a.wotId} value={a.wotId}>
                          ({a.server}) {a.name}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </FormGroup>
            </Card.Body>

            <Card.Body>
              <h2>{t('YourPlatoon.Recruiting', 'Recruiting')}</h2>

              <FormGroup as={Row}>
                <Form.Label column sm={3}>
                  {t('YourPlatoon.RecruitingExpiration', 'Expires in')}
                </Form.Label>
                <Col sm={9}>
                  <Stack direction="horizontal" gap={3}>
                    <span
                      className={`p-2 label-no-wrap ${
                        expiresAt == null || expiresAt.getTime() - Date.now() <= 1000 * 60 ? 'label-warning' : 'label-success'
                      }`}
                    >
                      {expiresAt == null || expiresAt.getTime() - Date.now() <= 1000 * 60
                        ? t('YourPlatoon.RecruitingExpirationExpired', 'expired')
                        : `${Math.floor((expiresAt.getTime() - Date.now()) / (1000 * 60 * 60))} ${t(
                            'YourPlatoon.RecruitingExpirationHours',
                            'hours',
                          )} ${(((expiresAt.getTime() - Date.now()) % (1000 * 60 * 60)) / (1000 * 60)).toFixed(0)} ${t(
                            'YourPlatoon.RecruitingExpirationMinutes',
                            'minutes',
                          )}`}
                    </span>
                    <Form.Select
                      onChange={(event) => {
                        setExpiresIn(event.target.value as ExpiresInOptions);
                      }}
                      value={expiresIn}
                      disabled={platoon == null}
                    >
                      <option key="no_change" value="no_change" disabled>
                        {t('YourPlatoon.RecruitingExpiresInNoChange', 'No change')}
                      </option>
                      <option key="expired" value="expired">
                        {t('YourPlatoon.RecruitingExpiresInExpired', 'Expired')}
                      </option>
                      <option key="30m" value="30m">
                        {t('YourPlatoon.RecruitingExpiresInMinutes', '{{value}} minutes', { value: 30 })}
                      </option>
                      <option key="1h" value="1h">
                        {t('YourPlatoon.RecruitingExpiresIn1h', '1 hour')}
                      </option>
                      <option key="2h" value="2h">
                        {t('YourPlatoon.RecruitingExpiresIn2h', '{{value}} hours', { value: 2 })}
                      </option>
                      <option key="3h" value="3h">
                        {t('YourPlatoon.RecruitingExpiresIn2h', '{{value}} hours', { value: 3 })}
                      </option>
                      <option key="4h" value="4h">
                        {t('YourPlatoon.RecruitingExpiresIn2h', '{{value}} hours', { value: 4 })}
                      </option>
                      <option key="5h" value="5h">
                        {t('YourPlatoon.RecruitingExpiresInHours', '{{value}} hours', { value: 5 })}
                      </option>
                      <option key="6h" value="6h">
                        {t('YourPlatoon.RecruitingExpiresInHours', '{{value}} hours', { value: 6 })}
                      </option>
                      <option key="8h" value="8h">
                        {t('YourPlatoon.RecruitingExpiresInHours', '{{value}} hours', { value: 8 })}
                      </option>
                      <option key="10h" value="10h">
                        {t('YourPlatoon.RecruitingExpiresInHours', '{{value}} hours', { value: 10 })}
                      </option>
                    </Form.Select>
                  </Stack>
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.RecruitingComment', 'Comment')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    value={recruitingComment ?? ''}
                    onChange={(value) => setRecruitingComment(value.target.value)}
                    maxLength={100}
                  />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.RecruitingWinRate', 'Win rate')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="number"
                    value={recruitingWinRate ?? ''}
                    min={0}
                    max={100}
                    onChange={(value) =>
                      setRecruitingWinRate(value.target.value == null || value.target.value === '' ? undefined : +value.target.value)
                    }
                    isInvalid={recruitingWinRate != null && (recruitingWinRate < 0 || recruitingWinRate > 100)}
                  />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.RecruitingBattles', 'Battles')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="number"
                    value={recruitingBattles ?? ''}
                    min={0}
                    step={500}
                    onChange={(value) =>
                      setRecruitingBattles(value.target.value == null || value.target.value === '' ? undefined : +value.target.value)
                    }
                    isInvalid={recruitingBattles != null && recruitingBattles < 0}
                  />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.RecruitingAvgDamage', 'Avg. damage')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="number"
                    value={recruitingAvgDamage ?? ''}
                    min={0}
                    step={100}
                    onChange={(value) =>
                      setRecruitingAvgDamage(value.target.value == null || value.target.value === '' ? undefined : +value.target.value)
                    }
                    isInvalid={recruitingAvgDamage != null && recruitingAvgDamage < 0}
                  />
                </Col>
              </FormGroup>
            </Card.Body>

            <Card.Body>
              <h2>{t('YourPlatoon.YourStats', 'Your stats')}</h2>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.YourWinRate', 'Win rate')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={platoon?.stats.winRate.toFixed(2) ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.YourBattles', 'Battles')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={platoon?.stats.battles ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourPlatoon.YourAvgDamage', 'Avg. damage')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={platoon?.stats.avgDamage.toFixed(0) ?? ''} readOnly />
                </Col>
              </FormGroup>

              <Button
                className="mt-3 float-end"
                variant="primary"
                type="button"
                disabled={
                  account == null ||
                  platoon == null ||
                  expiresAt == null ||
                  (recruitingComment != null && recruitingComment.length > 100) ||
                  (recruitingWinRate != null && (recruitingWinRate < 0 || recruitingWinRate > 100)) ||
                  (recruitingBattles != null && recruitingBattles < 0) ||
                  (recruitingAvgDamage != null && recruitingAvgDamage < 0)
                }
                onClick={() => {
                  if (account != null && platoon != null && expiresAt != null) {
                    dispatch(
                      updatePlatoonAsync(account.wotId, account.server, expiresAt, {
                        comment: recruitingComment,
                        winRate: recruitingWinRate,
                        avgDamage: recruitingAvgDamage,
                        battles: recruitingBattles,
                      }),
                    );
                    navigate('/find/platoon');
                  }
                }}
              >
                {t('YourPlatoon.SaveButton', 'Save')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
