import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/rootReducer';
import { AppThunk } from '../../redux/store';
import { LogService } from '../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../loading/loadingSlice';
import { showToast } from '../toast/redux/toastSlice';
import { IWotApiTank } from '../../services/api/interfaces/wot-api-tanks.interface';
import { WotServer } from '../../utils/wot-server.type';
import { WotApiService } from '../../services/api/wot-api.service';

export interface WotTanksState {
  eu: { items: IWotApiTank[]; lang?: 'ru' | 'en' };
  com: { items: IWotApiTank[]; lang?: 'ru' | 'en' };
  asia: { items: IWotApiTank[]; lang?: 'ru' | 'en' };
}

const InitialState: WotTanksState = {
  eu: { items: [] },
  com: { items: [] },
  asia: { items: [] },
};

export const wotTanksSlice = createSlice({
  name: 'wotTanks',
  initialState: InitialState,
  reducers: {
    setWotTanks: (state, action: PayloadAction<{ items: IWotApiTank[]; server: WotServer; lang: 'ru' | 'en' }>) => {
      state[action.payload.server].items = action.payload.items;
      state[action.payload.server].lang = action.payload.lang;
    },
  },
});

export const { setWotTanks } = wotTanksSlice.actions;

export const getWotTanksAsync =
  (server: WotServer, lang: 'ru' | 'en'): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().wotTanks[server].items.length > 0 && getState().wotTanks[server].lang === lang) {
        return;
      }

      dispatch(startLoading(LoadingParts.WotTanksLoading));

      const svc = new WotApiService();
      const response = await svc.getTanks(server, undefined, lang);

      if (response.status !== 'ok' || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.error?.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setWotTanks({ items: response.data, server, lang }));
      }
    } catch (e) {
      LogService.error(`Error while loading WoT tanks`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTanksLoading));
    }
  };

export const selectWotTanks = (server: WotServer) => (state: RootState) => {
  return state.wotTanks[server].items;
};

export default wotTanksSlice.reducer;
