import { useEffect, useState } from 'react';
import { Form, Button, InputGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addAccountAsync, selectAccount } from '../../../account/redux/accountsSlice';

export function WidgetBlitzCreditsSettings() {
  const { t } = useTranslation();
  const selectedAccount = useSelector(selectAccount);
  const dispatch = useDispatch();

  const [url, setUrl] = useState('');
  const [targerCredits, setTargetCredits] = useState('');

  const [isTargerCreditsInvalid, setIsTargetCreditsInvalid] = useState(false);
  const [isSelectedAccountInvalid, setIsSelectedAccountInvalid] = useState(false);

  useEffect(() => {
    setIsTargetCreditsInvalid(
      targerCredits == null ||
        targerCredits === '' ||
        targerCredits === '0' ||
        isNaN(parseInt(targerCredits)) ||
        parseInt(targerCredits) <= (selectedAccount?.snapshots[selectedAccount.snapshots.length - 1].private?.credits ?? 0),
    );
  }, [selectedAccount, targerCredits]);

  useEffect(() => {
    setIsSelectedAccountInvalid(
      selectedAccount == null ||
        selectedAccount.snapshots.length === 0 ||
        selectedAccount.snapshots[selectedAccount.snapshots.length - 1].private == null,
    );
  }, [selectedAccount]);

  useEffect(() => {
    setUrl('');

    if (isSelectedAccountInvalid || isTargerCreditsInvalid) {
      return;
    }

    const credits = selectedAccount?.snapshots[selectedAccount.snapshots.length - 1].private?.credits;

    setUrl(
      `/widgets/run/blitz/credits?wotAccountId=${selectedAccount?.wotId ?? ''}&wotAccountServer=${selectedAccount?.server ?? ''}&credits=${
        credits ?? ''
      }&targetCredits=${targerCredits}`,
    );
  }, [isSelectedAccountInvalid, isTargerCreditsInvalid, selectedAccount, targerCredits]);

  const refreshCredits = () => {
    if (selectedAccount != null) {
      dispatch(addAccountAsync(selectedAccount.wotId, selectedAccount.server));
    }
  };

  return (
    <>
      {isSelectedAccountInvalid && (
        <Alert variant="warning">
          {t(
            'WidgetBlitzCreditsSettings.NoCreditsWarning',
            'In order to use the widget please log in to the site with a Game account or link it in the Settings.',
          )}
        </Alert>
      )}

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetBlitzCreditsSettings.GameAccount', 'Game account')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('WidgetBlitzCreditsSettings.GameAccountPlaceholder', 'Please select an account at the top...')}
          required
          readOnly={true}
          isInvalid={isSelectedAccountInvalid}
          value={selectedAccount == null ? '' : `(${selectedAccount.server}) ${selectedAccount.name}`}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetBlitzCreditsSettings.Credits', 'Credits')}</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={t('WidgetBlitzCreditsSettings.CreditsPlaceholder', 'Amount of credits is not available...')}
            required
            readOnly={true}
            isInvalid={isSelectedAccountInvalid}
            value={
              isSelectedAccountInvalid === true
                ? ''
                : `${selectedAccount?.snapshots[selectedAccount.snapshots.length - 1].private?.credits.toLocaleString() ?? ''}`
            }
          />

          <Button variant="primary" onClick={() => refreshCredits()}>
            {t('WidgetBlitzCreditsSettings.RefreshCreditsButton', 'Refresh')}
          </Button>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetBlitzCreditsSettings.TargetCredits', 'Target credits')}</Form.Label>

        <Form.Control
          type="number"
          min={0}
          step={1000000}
          placeholder={t('WidgetBlitzCreditsSettings.TargetCreditsPlaceholder', 'Enter the target credits amount...')}
          required
          value={targerCredits}
          onChange={(e) => setTargetCredits(e.target.value)}
          isInvalid={isTargerCreditsInvalid}
          disabled={isSelectedAccountInvalid === true}
        />
      </Form.Group>

      {(isSelectedAccountInvalid === true || url === '' || isTargerCreditsInvalid === true) && (
        <Button variant="primary" type="submit" disabled className="float-end">
          {t('WidgetBlitzCreditsSettings.StartButton', 'Start')}
        </Button>
      )}

      {isSelectedAccountInvalid === false && url !== '' && isTargerCreditsInvalid === false && (
        <Button
          variant="primary"
          type="submit"
          href={url}
          target="_blank"
          disabled={selectedAccount == null || url === ''}
          className="float-end"
        >
          {t('WidgetBlitzCreditsSettings.StartButton', 'Start')}
        </Button>
      )}
    </>
  );
}
