import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { ITournamentAccountBonusSep2023ApiView, ITournamentAccountsApiView } from './interfaces/tournament.interface';

export class TournamentSep2023ApiService {
  async participate(tournamentId: string, accountId: string): Promise<SingleEntityResponseApiView<ITournamentAccountsApiView>> {
    const response = await fetchApi(`tournaments/sep2023/${tournamentId}/accounts/${accountId}/participate`, {
      method: 'POST',
      body: JSON.stringify({ timezoneOffset: new Date().getTimezoneOffset() }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentAccountsApiView>;
    return body;
  }

  async check(tournamentId: string, accountId: string): Promise<SingleEntityResponseApiView<ITournamentAccountsApiView>> {
    const response = await fetchApi(`tournaments/sep2023/${tournamentId}/accounts/${accountId}/check`, {
      method: 'POST',
      body: JSON.stringify({ timezoneOffset: new Date().getTimezoneOffset() }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentAccountsApiView>;
    return body;
  }

  async reward(tournamentId: string, accountId: string): Promise<SingleEntityResponseApiView<ITournamentAccountsApiView>> {
    const response = await fetchApi(`tournaments/sep2023/${tournamentId}/accounts/${accountId}/reward`, {
      method: 'POST',
      body: JSON.stringify({ timezoneOffset: new Date().getTimezoneOffset() }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentAccountsApiView>;
    return body;
  }

  async addBonus(
    tournamentId: string,
    accountId: string,
    payload: { date: Date; source: 'trovo' | 'manual'; comment: string; tankLevel: 5 | 6 | 7 | 8 | 9 | 10; parts: number },
  ): Promise<SingleEntityResponseApiView<ITournamentAccountBonusSep2023ApiView>> {
    const response = await fetchApi(`tournaments/sep2023/${tournamentId}/accounts/${accountId}/bonuses`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentAccountBonusSep2023ApiView>;
    return body;
  }

  async deleteBonus(tournamentId: string, accountId: string, bonusId: string): Promise<SingleEntityResponseApiView<void>> {
    const response = await fetchApi(`tournaments/sep2023/${tournamentId}/accounts/${accountId}/bonuses/${bonusId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<void>;
    return body;
  }
}
