import { Accordion, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { IStatistics, IChartValue } from '../../statistics/interfaces';
import { IAccountStatisticsData, ITankStatisticsData } from '../../../services/api/interfaces/server-statistics.interface';
import { useEffect, useState } from 'react';
import { LineChart } from '../charts/LineChart';
import { BarChart } from '../charts/BarChart';
import { useSelector } from 'react-redux';
import { selectFilter } from '../../filter/redux/filterSlice';

export function WinsCard(props: {
  stats?: IStatistics;
  statsFiltered?: IStatistics;
  chart?: IChartValue[];
  serverStats?: IAccountStatisticsData | ITankStatisticsData;
}) {
  const { t } = useTranslation();

  const filter = useSelector(selectFilter);

  const [winRate, setWinRate] = useState<number | undefined>(undefined);
  const [winRateFiltered, setWinRateFiltered] = useState<number | undefined>(undefined);
  const [winRateServer, setWinRateServer] = useState<number | undefined>(undefined);

  const [winsBarChartData, setWinsBarChartData] = useState<Array<number | undefined> | undefined>(undefined);
  const [lossesBarChartData, setLossesBarChartData] = useState<Array<number | undefined> | undefined>(undefined);

  const [activeAccordionItemKey, setActiveAccordionItemKey] = useState(
    localStorage.getItem('wins_card_active_accordion_item_key') ?? 'none',
  );

  useEffect(() => {
    if (props.stats == null) {
      setWinRate(undefined);
      return;
    }

    setWinRate(props.stats.regular.wins / (props.stats.regular.battles || 1));
  }, [props.stats]);

  useEffect(() => {
    if (props.statsFiltered == null) {
      setWinRateFiltered(undefined);
      return;
    }

    setWinRateFiltered(props.statsFiltered.regular.wins / (props.statsFiltered.regular.battles || 1));
  }, [props.statsFiltered]);

  useEffect(() => {
    if (props.serverStats == null) {
      setWinRateServer(undefined);
      return;
    }

    setWinRateServer(props.serverStats.regular.wins / (props.serverStats.regular.battles || 1));
  }, [props.serverStats]);

  useEffect(() => {
    if (props.chart == null) {
      setWinsBarChartData(undefined);
      setLossesBarChartData(undefined);
      return;
    }

    const winsChartData = new Array<number | undefined>();
    const lossesChartData = new Array<number | undefined>();
    let prevWins = undefined;
    let prevLosses = undefined;

    for (const chartValue of props.chart) {
      if (chartValue.stats?.regular.wins == null) {
        winsChartData.push(undefined);
      } else if (prevWins == null) {
        prevWins = chartValue.stats.regular.wins;
        winsChartData.push(filter.timeRange === 'all' ? undefined : prevWins);
      } else {
        winsChartData.push(chartValue.stats.regular.wins - prevWins);
        prevWins = chartValue.stats.regular.wins;
      }

      if (chartValue.stats?.regular.losses == null) {
        lossesChartData.push(undefined);
      } else if (prevLosses == null) {
        prevLosses = chartValue.stats.regular.losses;
        lossesChartData.push(filter.timeRange === 'all' ? undefined : prevLosses);
      } else {
        lossesChartData.push(chartValue.stats.regular.losses - prevLosses);
        prevLosses = chartValue.stats.regular.losses;
      }
    }

    setWinsBarChartData(winsChartData);
    setLossesBarChartData(lossesChartData);
  }, [filter.timeRange, props.chart]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{t('WinsCard.Header', 'Wins')}</h2>
      </Card.Header>

      <Card.Body>
        <Table bordered hover responsive size="sm" className="table-normal-header">
          <thead>
            <tr>
              <th></th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.TotalTooltip', 'Total value for the account')}</Tooltip>}>
                  <span>
                    <strong>{t('CardTableHeader.Total', 'Total')}</strong>
                  </span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.FilteredTooltip', 'Filtered value for the account')}</Tooltip>}>
                  <span>{t('CardTableHeader.Filtered', 'Filtered')}</span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger
                  overlay={<Tooltip>{t('CardTableHeader.ServerStatsTooltip', 'Average value for the whole server')}</Tooltip>}
                >
                  <span>{t('CardTableHeader.ServerStats', 'Server')}</span>
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t('WinsCard.WinRateLabel', 'Win rate')}</strong>
              </td>
              <td
                className={
                  winRateServer == null || winRate == null
                    ? undefined
                    : winRateServer < winRate
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{winRate == null ? '-' : (winRate * 100).toFixed(2)}</strong>
              </td>
              <td
                className={
                  winRateServer == null || winRateFiltered == null
                    ? undefined
                    : winRateServer < winRateFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {winRateFiltered == null ? '-' : (winRateFiltered * 100).toFixed(2)}
              </td>
              <td>{winRateServer == null ? '-' : (winRateServer * 100).toFixed(2)}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('WinsCard.WinsLabel', 'Wins')}</strong>
              </td>
              <td>
                <strong className="increase-font-size">{props.stats?.regular.wins ?? '-'}</strong>
              </td>
              <td>{props.statsFiltered?.regular.wins ?? '-'}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>
                <strong>{t('WinsCard.LosesLabel', 'Loses')}</strong>
              </td>
              <td>
                <strong className="increase-font-size">{props.stats?.regular.losses ?? '-'}</strong>
              </td>
              <td>{props.statsFiltered?.regular.losses ?? '-'}</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>

      <Card.Body>
        <LineChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('WinsCard.WinRateChartLabel', 'Win rate'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : ((i.stats.regular.wins * 100) / (i.stats.regular.battles || 1)).toFixed(2),
                ) ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
          ]}
        />
      </Card.Body>

      <Card.Body>
        <BarChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('WinsCard.WinsChartLabel', 'Wins'),
              data: winsBarChartData ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderWidth: 1,
            },
            {
              label: t('WinsCard.LosesChartLabel', 'Loses'),
              data: lossesBarChartData ?? [],
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              borderWidth: 1,
            },
          ]}
        />

        <Accordion
          className="mt-3"
          activeKey={activeAccordionItemKey}
          onSelect={(eventKey) => {
            setActiveAccordionItemKey(eventKey as string);
            if (eventKey == null) {
              localStorage.setItem('wins_card_active_accordion_item_key', 'none');
            } else {
              localStorage.setItem('wins_card_active_accordion_item_key', eventKey as string);
            }
          }}
        >
          <Accordion.Item eventKey="info">
            <Accordion.Header>{t('WinsCard.InfoHeader', 'Learn more about the chart')}</Accordion.Header>
            <Accordion.Body>
              <Trans t={t} i18nKey="WinsCard.InfoBody">
                <p>
                  Win rate is the key metric in the game. It basically shows not only how good you are in dealing damage or gaining frags,
                  but also how useful for a team you are. Nobody is able to win by themselves and should play as a team to get a win.
                </p>
                <p>
                  Each team is balanced in a way that different tanks should play different roles in order to succeed. Light and medium
                  tanks are fast and furious to play as scouts, disrupt enemy lines and support the offensive. Heavy tanks are the ones to
                  deter enemy attacks with massive armor and a lot of health points. And anti-tanks are powerful enough to make significant
                  damage in the exact right place and time.
                </p>
                <p>
                  See our{' '}
                  <a
                    href="https://www.youtube.com/watch?v=t_1CLtcKMiU&list=PLcaggObhc7NrygjBMdM650Cwu2MKha1zO&index=4&utm_source=beastwot.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    video guides
                  </a>{' '}
                  to learn how to win.
                </p>
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}
