import { Button, Card, Form, Accordion, InputGroup, Alert } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AuthApiService } from '../../services/api/auth-api.service';
import { getUserMeAsync, selectUser } from '../user/userSlice';
import { LogService } from '../../services/log.service';
import { showToast } from '../toast/redux/toastSlice';
import { WotServer } from '../../utils/wot-server.type';
import { useNavigate } from 'react-router-dom';
import { CLIENT_URL, FEATURE_ENABLE_OTHER_LOGIN, WOT_SERVER_APPLICATION_ID } from '../../configs/config';

export function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [wotServer, setWotServer] = useState<WotServer>('eu');

  const [showRegistration, setShowRegistration] = useState(false);
  const [registrationEmail, setRegistrationEmail] = useState<string>('');
  const [registrationPassword, setRegistrationPassword] = useState<string>('');

  const [showResetPassword, setShowResetPassowrd] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');

  useEffect(() => {
    if (user != null) {
      navigate('/');
    }
  }, [navigate, user]);

  const handleLocalLogin = async () => {
    const svc = new AuthApiService();
    const response = await svc.loginLocal(email, password);

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      if (response.data != null) {
        localStorage.setItem('jwt', response.data);
        LogService.debug('Authenticated');
        dispatch(getUserMeAsync());
      }
    }
  };

  const handleWotLogin = () => {
    const url = `https://api.worldoftanks.${wotServer}/wot/auth/login/?application_id=${WOT_SERVER_APPLICATION_ID ?? ''}&redirect_uri=${
      CLIENT_URL ?? ''
    }/wot-callback/login/${wotServer}&expires_at=${60 * 60 * 24 * 14}`;

    window.location.replace(url);
  };

  const handleRegistration = async () => {
    const svc = new AuthApiService();
    const response = await svc.register(registrationEmail, registrationPassword);

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      if (response.data != null) {
        localStorage.setItem('jwt', response.data);
        LogService.debug('Registered');
        dispatch(getUserMeAsync());
      }
    }
  };

  const handlePasswordReset = async () => {
    const svc = new AuthApiService();
    const response = await svc.requestPasswordReset(resetPasswordEmail);

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      dispatch(
        showToast({
          title: t('Login.NewPasswordToastHeader', 'New password requested'),
          message: t('Login.NewPasswordToastMessage', 'Please check the email for the details.'),
          variant: 'success',
        }),
      );
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>{t('Login.GameAuthTitle', 'Log in with Game account')}</Card.Title>

          <Form className="mt-3">
            <InputGroup className="wot-login-form-group">
              <Form.Select
                className="wot-login-server-select"
                onChange={(event) => setWotServer(event.target.value as WotServer)}
                value={wotServer}
              >
                <option value="eu">EU</option>
                <option value="com">NA</option>
                <option value="asia">ASIA</option>
              </Form.Select>

              <Button variant="primary" onClick={() => handleWotLogin()}>
                {t('Login.LoginButton', 'Log in')}
              </Button>
            </InputGroup>
          </Form>
          <Alert variant="info" className="mt-3">
            <Trans t={t} i18nKey="Login.GameAuthInfo">
              If you see a wrong account after login please visit{' '}
              <a
                href={`https://${wotServer === 'com' ? 'na' : wotServer}.wotblitz.com?utm_source=beastwot.com`}
                target="_blank"
                rel="noreferrer"
              >
                the Game website
              </a>{' '}
              and re-login with a proper account there first.
            </Trans>
          </Alert>
        </Card.Body>
      </Card>

      {FEATURE_ENABLE_OTHER_LOGIN && (
        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t('Login.OtherOptionsHeader', 'Or choose some other option')}</Accordion.Header>
            <Accordion.Body>
              <Card>
                <Card.Body>
                  <Card.Title>{t('Login.EmailTitle', 'Log in with email')}</Card.Title>

                  <Form>
                    <Form.Control
                      type="email"
                      placeholder={t('Login.EmailPlaceholder', 'Enter email')}
                      className="mb-3"
                      autoComplete="email"
                      required
                      isInvalid={!email}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />

                    <Form.Control
                      type="password"
                      placeholder={t('Login.PasswordPlaceholder', 'Password')}
                      className="mb-3"
                      autoComplete="current-password"
                      required
                      isInvalid={!password}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />

                    <Button
                      variant="primary"
                      disabled={!email || !password}
                      onClick={() => {
                        void handleLocalLogin();
                      }}
                    >
                      {t('Login.LoginButton', 'Log in')}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>

              <h5 className="text-muted mt-3 mb-3">{t('Login.Or', 'OR')}</h5>

              <Card>
                <Card.Body>
                  <Card.Title>{t('Login.RegistraitionTitle', 'Register a new account by email')}</Card.Title>

                  {showRegistration && (
                    <Form>
                      <Form.Control
                        type="email"
                        placeholder={t('Login.EmailPlaceholder', 'Enter email')}
                        className="mb-3"
                        autoComplete="email"
                        required
                        isInvalid={!registrationEmail}
                        value={registrationEmail}
                        onChange={(event) => setRegistrationEmail(event.target.value)}
                      />

                      <Form.Control
                        type="password"
                        placeholder={t('Login.PasswordPlaceholder', 'Password')}
                        className="mb-3"
                        autoComplete="new-password"
                        required
                        isInvalid={!registrationPassword}
                        value={registrationPassword}
                        onChange={(event) => setRegistrationPassword(event.target.value)}
                      />

                      <Button
                        variant="primary"
                        disabled={!registrationEmail || !registrationPassword}
                        onClick={() => {
                          void handleRegistration();
                        }}
                      >
                        {t('Login.RegistrationButton', 'Registration')}
                      </Button>
                    </Form>
                  )}

                  {showRegistration === false && (
                    <Button variant="primary" onClick={() => setShowRegistration(true)}>
                      {t('Login.RegistrationButton', 'Registration')}
                    </Button>
                  )}
                </Card.Body>
              </Card>

              <h5 className="text-muted mt-3 mb-3">{t('Login.Or', 'OR')}</h5>

              <Card>
                <Card.Body>
                  <Card.Title>{t('Login.ResetPasswordTitle', 'Reset password by email')}</Card.Title>

                  {showResetPassword && (
                    <Form>
                      <Form.Control
                        type="email"
                        placeholder={t('Login.EmailPlaceholder', 'Enter email')}
                        className="mb-3"
                        autoCapitalize="email"
                        required
                        isInvalid={!resetPasswordEmail}
                        value={resetPasswordEmail}
                        onChange={(event) => setResetPasswordEmail(event.target.value)}
                      />

                      <Button
                        variant="primary"
                        disabled={!resetPasswordEmail}
                        onClick={() => {
                          void handlePasswordReset();
                        }}
                      >
                        {t('Login.RequestResetPasswordButton', 'Request')}
                      </Button>
                    </Form>
                  )}

                  {showResetPassword === false && (
                    <Button variant="primary" onClick={() => setShowResetPassowrd(true)}>
                      {t('Login.ResetPasswordButton', 'Reset')}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
}
