import styles from './WelcomeModal.module.scss';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSubscriptionValid } from '../subscription/redux/subscriptionSlice';
import { useNavigate } from 'react-router-dom';

export function WelcomeModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [show, setShow] = useState(true);

  useEffect(() => {
    // if subscription is valid
    if (isValidSubscription === true) {
      setShow(false);
      return;
    }
  }, [isValidSubscription]);

  const handleSubscribe = () => {
    navigate('/subscription');
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('WelcomeModal.Title', 'Welcome to the site!')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <Trans t={t} i18nKey="WelcomeModal.Body">
          <p>We are happy to see you on the site!</p>
          <p>
            Here you will find a lot of useful stuff like <b>detailed account statistics, mods, widgets, clans, platoons, and more</b>.
          </p>
          <p>
            <b>Please log in</b> to let us track your account and help you become a better player.
          </p>
          <p>
            We will appreciate it if you can <b>create a subscription and support our project</b>. With your donations, we will be able to
            deliver much more and make the site even better.
          </p>
          <p>
            <b>Have a fun time!</b>
          </p>
        </Trans>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(false)}>
          {t('WelcomeModal.Ok', 'Understood')}
        </Button>

        <Button variant="success" onClick={() => handleSubscribe()}>
          {t('WelcomeModal.Subscribe', 'Subscribe')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
