import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { TankTiers, TankTypes } from '../../../services/api/interfaces/tank.interface';

export type TimeRanges = 'all' | '1y' | '6m' | '3m' | '1m' | '2w' | '1w';

export interface FilterState {
  tankTypes: Array<TankTypes>;
  tankTiers: Array<TankTiers>;
  timeRange: TimeRanges;
  startDate: string;
  limitTankBattleCount: number;
}

const InitialStartDate = new Date();
InitialStartDate.setMonth(InitialStartDate.getMonth() - 1);
const InitialState: FilterState = {
  tankTypes: [],
  tankTiers: [],
  timeRange: '1m',
  startDate: InitialStartDate.toISOString(),
  limitTankBattleCount: 100,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: InitialState,
  reducers: {
    resetFilter: () => InitialState,
    setFilterTankTypes: (state, action: PayloadAction<Array<TankTypes>>) => {
      state.tankTypes = action.payload;
    },
    setFilterTankTiers: (state, action: PayloadAction<Array<TankTiers>>) => {
      state.tankTiers = action.payload;
    },
    setFilterTimeRange: (state, action: PayloadAction<{ timeRange: TimeRanges; accountCreatedAt: string }>) => {
      state.timeRange = action.payload.timeRange;
      state.startDate = action.payload.timeRange !== 'all' ? getStartingDate(action.payload.timeRange) : action.payload.accountCreatedAt;
    },
    setTankBattleCountLimit: (state, action: PayloadAction<number>) => {
      state.limitTankBattleCount = action.payload;
    },
  },
});

export const { resetFilter, setFilterTankTypes, setFilterTankTiers, setFilterTimeRange, setTankBattleCountLimit } = filterSlice.actions;

function getStartingDate(timeRange: TimeRanges): string {
  const startingDate = new Date();
  switch (timeRange) {
    case '1w':
      startingDate.setDate(startingDate.getDate() - 7);
      break;
    case '2w':
      startingDate.setDate(startingDate.getDate() - 14);
      break;
    case '1m':
      startingDate.setMonth(startingDate.getMonth() - 1);
      break;
    case '3m':
      startingDate.setMonth(startingDate.getMonth() - 3);
      break;
    case '6m':
      startingDate.setMonth(startingDate.getMonth() - 6);
      break;
    case '1y':
      startingDate.setFullYear(startingDate.getFullYear() - 1);
      break;
  }
  return startingDate.toISOString();
}

export const selectFilter = (state: RootState) => state.filter;

export default filterSlice.reducer;
