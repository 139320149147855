import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Image1 from './images/1.jpg';
import Image2 from './images/2.webp';
import Image3 from './images/3.jpeg';
import Image4 from './images/4.jpeg';
import Image5 from './images/5.jpg';
import Image6 from './images/6.jpeg';
import Image7 from './images/7.jpeg';
import Image8 from './images/8.jpg';
import Image9 from './images/9.jpeg';
import Image10 from './images/10.jpeg';
import Image11 from './images/11.jpeg';
import Image12 from './images/12.jpeg';
import Image13 from './images/13.jpeg';
import Image14 from './images/14.png';
import Image15 from './images/15.jpeg';
import Image16 from './images/16.jpeg';
import Image17 from './images/17.jpeg';

export function BlogList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Helmet>
        <title>{t('Blog.Title', 'Wargaming WoT Blitz Beast - Blog')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('Blog.Header', 'Blogs')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Img variant="top" src={Image1} />
            <Card.Body>
              <Card.Title>{t('Blog.1.Header', 'Wargaming WOT vs. Wargaming WOT Blitz: Exploring the Differences')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.1.Intro',
                  'Wargaming, a renowned name in the world of online gaming, offers two popular tank warfare titles: Wargaming World of Tanks (WOT) and Wargaming World of Tanks Blitz (WOT Blitz). While both games share the core theme of tank battles, they possess distinct features and gameplay experiences. In this blog post, we will delve into the differences between Wargaming WOT and Wargaming WOT Blitz, highlighting their unique aspects and helping you choose the game that suits your preferences.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('01-Wargaming-WOT-vs-Wargaming-WOT-Blitz-Exploring-the-Differences')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image4} />
            <Card.Body>
              <Card.Title>
                {t('Blog.4.Header', 'Mastering Consumables and Equipment in World of Tanks Blitz: Elevate Your Tank Performance')}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.4.Intro',
                  'World of Tanks Blitz offers a diverse range of consumables and equipment options that can significantly enhance your tank performance on the battlefield. These essential tools provide tactical advantages, improve survivability, and maximize your firepower. In this comprehensive guide, we will explore the various consumables and equipment available in Wargaming World of Tanks Blitz, offering insights into their effects, optimal usage, and strategic considerations to help you dominate the Blitz battlefield.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('04-Mastering-Consumables-and-Equipment-in-World-of-Tanks-Blitz-Elevate-Your-Tank-Performance')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image7} />
            <Card.Body>
              <Card.Title>{t('Blog.7.Header', 'Exploring Game Modes in World of Tanks Blitz: Unleash Your Strategic Prowess')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.7.Intro',
                  'World of Tanks Blitz offers a captivating gaming experience with its diverse range of game modes. Whether you are a fan of intense battles or prefer cooperative gameplay, these game modes provide thrilling challenges and strategic opportunities. In this comprehensive guide, we will delve into the game modes available in Wargaming World of Tanks Blitz, exploring their unique features, objectives, and strategic considerations. Understanding the intricacies of each game mode will empower you to make tactical decisions, adapt to different scenarios, and emerge victorious on the battlefield.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('07-Exploring-Game-Modes-in-World-of-Tanks-Blitz-Unleash-Your-Strategic-Prowess')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image10} />
            <Card.Body>
              <Card.Title>{t('Blog.10.Header', 'Decoding Win Rate: The Key to Success in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.10.Intro',
                  'Win rate is a crucial metric that reflects a player success and effectiveness in World of Tanks Blitz. Achieving a high win rate requires a combination of skill, strategy, and teamwork. In this comprehensive guide, we will explore the significance of win rate in Wargaming World of Tanks Blitz, examining its impact on player progression, strategies to improve win rate, and the role of teamwork in securing victories. Understanding the intricacies of win rate will empower you to become a formidable force on the battlefield and lead your team to triumph.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('10-Decoding-Win-Rate-The-Key-to-Success-in-World-of-Tanks-Blitz')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image13} />
            <Card.Body>
              <Card.Title>
                {t(
                  'Blog.13.Header',
                  'Unleash Your Inner Commander: Understanding Battles Count and Player Engagement in World of Tanks Blitz',
                )}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.13.Intro',
                  'In the adrenaline-fueled world of World of Tanks Blitz, battles count serves as a measure of a player engagement and progression in the game. It reflects the number of battles fought and showcases the dedication and commitment of players to conquer the virtual battlefield. In this comprehensive guide, we will delve into the significance of battles count in Wargaming World of Tanks Blitz, its impact on player progression, factors that influence the frequency of play, and strategies to optimize your gaming experience. Whether you are a seasoned commander or a fresh recruit, understanding battles count and player engagement is essential for maximizing your enjoyment and success in the game.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() =>
                  navigate('13-Unleash-Your-Inner-Commander-Understanding-Battles-Count-and-Player-Engagement-in-World-of-Tanks-Blitz')
                }
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image16} />
            <Card.Body>
              <Card.Title>{t('Blog.16.Header', 'Mastering the Market: A Purchasing Strategy Guide in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.16.Intro',
                  'In the exhilarating world of World of Tanks Blitz, strategic decision-making extends beyond the battlefield. Making smart purchases can significantly impact your gaming experience, providing you with valuable resources, enhanced vehicles, and exclusive features. In this comprehensive blog post, we will delve into the art of purchasing in Wargaming World of Tanks Blitz, discussing the benefits of strategic buying, key considerations for making informed decisions, and tips to optimize your purchases. Whether you are a new player or a seasoned commander, this guide will help you navigate the in-game market and maximize your investment.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('16-Mastering-the-Market-A-Purchasing-Strategy-Guide-in-World-of-Tanks-Blitz')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Img variant="top" src={Image2} />
            <Card.Body>
              <Card.Title>
                {t('Blog.2.Header', 'Mastering Account Development Strategy in World of Tanks Blitz: Rise to the Top')}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.2.Intro',
                  'Achieving success in World of Tanks Blitz requires not only skillful tank warfare but also a well-crafted account development strategy. By implementing a comprehensive plan and making strategic decisions, you can accelerate your progress, unlock powerful tanks, and establish yourself as a formidable force on the battlefield. In this blog post, we will guide you through the best practices for account development in Wargaming World of Tanks Blitz, ensuring you stay ahead of the competition.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('02-Mastering-Account-Development-Strategy-in-World-of-Tanks-Blitz-Rise-to-the-Top')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image5} />
            <Card.Body>
              <Card.Title>{t('Blog.5.Header', 'Mastering Vehicle Trees in World of Tanks Blitz: A Comprehensive Guide')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.5.Intro',
                  'In World of Tanks Blitz, the vehicle tree system serves as the backbone of progression, offering players a vast array of tanks to unlock and command. Understanding the vehicle tree is crucial for planning your path, unlocking powerful tanks, and dominating the battlefield. In this comprehensive guide, we will explore the vehicle tree system in Wargaming World of Tanks Blitz, providing insights into its structure, progression mechanics, and strategic considerations to help you navigate through the diverse tank lines and make informed choices.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('05-Mastering-Vehicle-Trees-in-World-of-Tanks-Blitz-A-Comprehensive-Guide')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image8} />
            <Card.Body>
              <Card.Title>{t('Blog.8.Header', 'Uniting Forces: Exploring Clans in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.8.Intro',
                  'Clans play a vital role in the immersive multiplayer experience of World of Tanks Blitz. These communities foster camaraderie, teamwork, and competitive spirit among tank commanders. In this comprehensive guide, we will delve into the world of clans in Wargaming World of Tanks Blitz, exploring their benefits, activities, and strategic considerations. Understanding the dynamics of clans will empower you to find your place within a community, participate in clan activities, and forge strong bonds with fellow tankers.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('08-Uniting-Forces-Exploring-Clans-in-World-of-Tanks-Blitz')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image11} />
            <Card.Body>
              <Card.Title>
                {t('Blog.11.Header', 'Mastering Battle Styles: Unleash Your Gameplay Potential in World of Tanks Blitz')}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.11.Intro',
                  'In World of Tanks Blitz, players have the opportunity to develop and refine their unique battle style. A battle style encompasses an individual player approach, tactics, and preferences on the virtual battlefield. In this comprehensive guide, we will explore the significance of battle styles in Wargaming World of Tanks Blitz, how they impact gameplay, strategies to develop and adapt your battle style, and the importance of finding a playstyle that suits your strengths. Understanding and mastering your battle style will empower you to become a formidable force and achieve greater success in the game.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('11-Mastering-Battle-Styles-Unleash-Your-Gameplay-Potential-in-World-of-Tanks-Blitz')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image14} />
            <Card.Body>
              <Card.Title>{t('Blog.14.Header', 'Mastering Vehicle Battle Roles: A Guide to Success in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.14.Intro',
                  'In the fast-paced world of World of Tanks Blitz, understanding the different battle roles of vehicles is crucial for achieving victory on the virtual battlefield. Each vehicle has a specific purpose and role that contributes to team synergy and tactical success. In this comprehensive guide, we will explore the various battle roles of vehicles in Wargaming World of Tanks Blitz, their unique characteristics, strategies to excel in each role, and the importance of team coordination. Whether you prefer the heavy armor of a tank or the stealthy maneuverability of a tank destroyer, mastering vehicle battle roles will elevate your gameplay and lead you to triumph.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('14-Mastering-Vehicle-Battle-Roles-A-Guide-to-Success-in-World-of-Tanks-Blitz')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image17} />
            <Card.Body>
              <Card.Title>
                {t('Blog.17.Header', 'Unleashing the Beast: Exploring the World of Tanks Blitz YouTube Streams by Beast')}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.17.Intro',
                  'In the thrilling universe of World of Tanks Blitz, live streaming has become a popular and immersive way for players to connect, learn, and be entertained. Among the many talented streamers in the community, one name stands out: Beast. In this comprehensive blog post, we will delve into the captivating world of Beast, a renowned YouTube streamer in Wargaming World of Tanks Blitz. We will explore his unique style, engaging content, informative gameplay, and the impact he has on the community. Whether you are a fan of Beast or new to the world of tank commanding, this guide will provide you with insights into the captivating live streams of Beast and how they contribute to the excitement of World of Tanks Blitz.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('17-Unleashing-the-Beast-Exploring-the-World-of-Tanks-Blitz-YouTube-Streams-by-Beast')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Img variant="top" src={Image3} />
            <Card.Body>
              <Card.Title>{t('Blog.3.Header', 'Mastering Crew Skills in World of Tanks Blitz: Enhance Your Tank Performance')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.3.Intro',
                  'In the exhilarating world of World of Tanks Blitz, having a skilled and experienced crew can significantly impact your tank performance and your chances of victory. Understanding the importance of crew skills and allocating them wisely is essential for maximizing your tank capabilities. In this blog post, we will delve into the various crew skills available in Wargaming World of Tanks Blitz, providing insights into their effects and optimization strategies.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('03-Mastering-Crew-Skills-in-World-of-Tanks-Blitz-Enhance-Your-Tank-Performance')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image6} />
            <Card.Body>
              <Card.Title>
                {t('Blog.6.Header', 'Unleashing Power on the Battlefield: Exploring Premium Vehicles in World of Tanks Blitz')}
              </Card.Title>
              <Card.Text>
                {t(
                  'Blog.6.Intro',
                  'Premium vehicles play a significant role in World of Tanks Blitz, offering unique advantages and exciting gameplay experiences. These tanks, available for purchase with real or in-game currency, provide tank commanders with enhanced attributes, increased credit earnings, and diverse playstyles. In this comprehensive guide, we will delve into the world of premium vehicles in Wargaming World of Tanks Blitz, highlighting their benefits, customization options, and strategic considerations to help you make informed decisions and dominate the battlefield.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('06-Unleashing-Power-on-the-Battlefield-Exploring-Premium-Vehicles-in-World-of-Tanks-Blitz')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image9} />
            <Card.Body>
              <Card.Title>{t('Blog.9.Header', 'Mastering Teamplay: Exploring Platoons in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.9.Intro',
                  'Teamwork is a crucial element in achieving success on the battlefield of World of Tanks Blitz. Platoons, small groups of tank commanders who join forces, provide an avenue for coordinated gameplay, communication, and strategic dominance. In this comprehensive guide, we will delve into the world of platoons in Wargaming World of Tanks Blitz, exploring their benefits, formation, tactics, and strategic considerations. Understanding the dynamics of platoons will empower you to forge strong alliances, coordinate with teammates, and outmaneuver your opponents on the digital battlefield.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('09-Mastering-Teamplay-Exploring-Platoons-in-World-of-Tanks-Blitz')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image12} />
            <Card.Body>
              <Card.Title>{t('Blog.12.Header', 'Unleashing Devastation: Understanding Damage Dealt in World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.12.Intro',
                  'Damage dealt is a critical metric that showcases a player effectiveness and impact on the battlefield in World of Tanks Blitz. It reflects the amount of damage inflicted on enemy tanks and plays a crucial role in determining the outcome of battles. In this comprehensive guide, we will delve into the significance of damage dealt in Wargaming World of Tanks Blitz, how it contributes to player progression, strategies to increase damage output, and the importance of understanding tank strengths and weak points. Mastering the art of dealing damage will elevate your gameplay and lead you to victory.',
                )}
              </Card.Text>
              <Button
                variant="link"
                onClick={() => navigate('12-Unleashing-Devastation-Understanding-Damage-Dealt-in-World-of-Tanks-Blitz')}
              >
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Img variant="top" src={Image15} />
            <Card.Body>
              <Card.Title>{t('Blog.15.Header', 'Legendary Commanders: Unveiling the Heroes of World of Tanks Blitz')}</Card.Title>
              <Card.Text>
                {t(
                  'Blog.15.Intro',
                  'Damage dealt is a critical metric that showcases a player effectiveness and impact on the battlefield in World of Tanks Blitz. It reflects the amount of damage inflicted on enemy tanks and plays a crucial role in determining the outcome of battles. In this comprehensive guide, we will delve into the significance of damage dealt in Wargaming World of Tanks Blitz, how it contributes to player progression, strategies to increase damage output, and the importance of understanding tank strengths and weak points. Mastering the art of dealing damage will elevate your gameplay and lead you to victory.',
                )}
              </Card.Text>
              <Button variant="link" onClick={() => navigate('15-Legendary-Commanders-Unveiling-the-Heroes-of-World-of-Tanks-Blitz')}>
                {t('Blog.ReadMoreButton', 'Read more')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
