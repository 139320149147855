import './SpinWheel.scss';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WheelComponent from 'react-wheel-of-prizes';
import { useState } from 'react';
import { toRoman } from '../../../../../utils/number.util';
// import 'react-wheel-of-prizes/dist/index.css';

export function SpinWheel(props: { winTankLevel: number; onFinished: () => void }) {
  const { t } = useTranslation();

  const [done, setDone] = useState(false);

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="spin-wheel-container"
    >
      <Modal.Header>
        <Modal.Title>{t('TournamentDetails.SpinWheelGetYourReward', 'Get your reward!')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WheelComponent
          segments={['V', 'VI', 'VII', 'VIII', 'IX', 'X']}
          segColors={['#B71C1C', '#0D47A1', '#FFA000', '#6A1B9A', '#E65100', '#C2185B']}
          winningSegment={toRoman(props.winTankLevel)}
          onFinished={() => setDone(true)}
          primaryColor="green"
          contrastColor="white"
          buttonText={t('TournamentDetails.SpinWheelButton', 'Spin')}
          isOnlyOnce={true}
          size={280}
          upDuration={500}
          // downDuration={1}
          // fontFamily="Arial"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={!done} onClick={() => props.onFinished()}>
          {t('TournamentDetails.SpinWheelThanksButton', 'Thanks!')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
