import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { IAccountTankApiView, IAccountTanksApiView } from '../../../services/api/interfaces/account-tanks.interface';
import { AccountTanksApiService } from '../../../services/api/account-tanks-api.service';
import { LogService } from '../../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { FilterState } from '../../filter/redux/filterSlice';
import { WotServer } from '../../../utils/wot-server.type';

export interface AccountTanksState {
  list: IAccountTankApiView[];
  listFiltered: IAccountTankApiView[];
  selected?: IAccountTankApiView;
  selectedFiltered?: IAccountTankApiView;
}

const InitialState: AccountTanksState = {
  list: [],
  listFiltered: [],
  selected: undefined,
  selectedFiltered: undefined,
};

export const accountTanksSlice = createSlice({
  name: 'accountTanks',
  initialState: InitialState,
  reducers: {
    resetAccountTanks: () => InitialState,
    setAccountTanks: (state, action: PayloadAction<IAccountTanksApiView | undefined>) => {
      state.list = action.payload?.tanks ?? [];
    },
    setAccountTanksFiltered: (
      state,
      action: PayloadAction<{ accountTanks: IAccountTankApiView[]; filter: FilterState; tanks: ITankApiView[] }>,
    ) => {
      let filtered = action.payload.accountTanks.map((t) => ({ ...t }));

      // filter by tank type
      if (action.payload.filter.tankTypes.length !== 0 && action.payload.filter.tankTypes.length !== 4) {
        filtered = filtered.filter((ts) => {
          const tank = action.payload.tanks.find((t) => t.wotId === ts.wotId);
          if (tank == null) {
            LogService.error(`Tank not found. WotId: ${ts.wotId}`);
            return false;
          }

          return action.payload.filter.tankTypes.includes(tank.type);
        });
      }

      // filter by tank tier
      if (action.payload.filter.tankTiers.length !== 0 && action.payload.filter.tankTiers.length !== 10) {
        filtered = filtered.filter((ts) => {
          const tank = action.payload.tanks.find((t) => t.wotId === ts.wotId);
          if (tank == null) {
            LogService.error(`Tank not found. WotId: ${ts.wotId}`);
            return false;
          }

          return action.payload.filter.tankTiers.includes(tank.tier);
        });
      }

      // filter by date
      if (action.payload.filter.timeRange !== 'all') {
        filtered.forEach(
          (f) =>
            (f.snapshots = f.snapshots
              .filter((s) => s.lastBattleTime >= action.payload.filter.startDate)
              .sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime))),
        );
        filtered = filtered.filter((f) => f.snapshots.length > 0);
      }

      state.listFiltered = filtered;
    },
    setSelectedAccountTank: (state, action: PayloadAction<IAccountTankApiView | undefined>) => {
      state.selected = action.payload;
    },
    setSelectedAccountTankFiltered: (state, action: PayloadAction<IAccountTankApiView | undefined>) => {
      state.selectedFiltered = action.payload;
    },
  },
});

export const { resetAccountTanks, setAccountTanks, setSelectedAccountTank, setAccountTanksFiltered, setSelectedAccountTankFiltered } =
  accountTanksSlice.actions;

export const getAccountTanksAsync =
  (wotId: number, server: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.AccountTanksLoading));

      const svc = new AccountTanksApiService();
      const response = await svc.get(wotId, server);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        if (response.data != null) {
          response.data?.tanks.forEach((t) => t.snapshots.sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime)));
          dispatch(setAccountTanks(response.data));
        }
      }
    } catch (e) {
      LogService.error(`Error while loading account tanks`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.AccountTanksLoading));
    }
  };

export const selectAccountTanks = (state: RootState) => state.accountTanks.list;
export const selectAccountTanksFiltered = (state: RootState) => state.accountTanks.listFiltered;
export const selectSelectedAccountTank = (state: RootState) => state.accountTanks.selected;
export const selectSelectedAccountTankFiltered = (state: RootState) => state.accountTanks.selectedFiltered;

export default accountTanksSlice.reducer;
