import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IFondyCheckoutApiView, ISubscriptionApiView, PaymentPlan } from './interfaces/subscription.interface';

export class SubscriptionApiService {
  async get(): Promise<SingleEntityResponseApiView<ISubscriptionApiView>> {
    const response = await fetchApi('subscriptions', {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ISubscriptionApiView>;
    return body;
  }

  async changeFondyStatus(status: 'active' | 'deactivated'): Promise<SingleEntityResponseApiView<ISubscriptionApiView>> {
    const response = await fetchApi('subscriptions/fondy/status', {
      method: 'POST',
      body: JSON.stringify({ status }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ISubscriptionApiView>;
    return body;
  }

  async getFondyCheckout(paymentPlan: PaymentPlan, language: string): Promise<SingleEntityResponseApiView<IFondyCheckoutApiView>> {
    const response = await fetchApi('subscriptions/fondy/checkout', {
      method: 'POST',
      body: JSON.stringify({ paymentPlan, language }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IFondyCheckoutApiView>;
    return body;
  }

  async checkBmc(email: string): Promise<SingleEntityResponseApiView<ISubscriptionApiView | null>> {
    const response = await fetchApi('subscriptions/bmc/check', {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ISubscriptionApiView>;
    return body;
  }
}
