import { useEffect } from 'react';
import { NODE_ENV } from '../../configs/config';
import { LogService } from '../../services/log.service';
import { useSelector } from 'react-redux';
import { selectSubscriptionValid } from '../subscription/redux/subscriptionSlice';

declare global {
  interface Window {
    adsbygoogle: Array<unknown>;
  }
}

export function GoogleAd(props: { unit: 'footer' | 'header' | 'account-stats' | 'tank-stats' | 'widget-header' | 'widget-footer' }) {
  const isValidSubscription = useSelector(selectSubscriptionValid);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      LogService.error(e as Error);
    }
  }, []);

  return (
    <>
      {isValidSubscription !== true && (
        <div style={{ textAlign: 'center', backgroundColor: NODE_ENV === 'development' ? 'red' : undefined }}>
          {props.unit === 'footer' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="4952021343"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          )}

          {props.unit === 'header' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'inline-block', width: '728px', height: '90px' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="2241495197"
            ></ins>
          )}

          {props.unit === 'account-stats' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="4049400886"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          )}

          {props.unit === 'tank-stats' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="2736319214"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          )}

          {props.unit === 'widget-header' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'inline-block', width: '728px', height: '90px' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="4010713724"
            ></ins>
          )}

          {props.unit === 'widget-footer' && (
            <ins
              className="adsbygoogle"
              style={{ display: 'inline-block', width: '728px', height: '90px' }}
              data-ad-client="ca-pub-9889369983384017"
              data-ad-slot="5559212074"
            ></ins>
          )}
        </div>
      )}
    </>
  );
}
