import './TopAccounts.scss';
import { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Form, Alert, InputGroup, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WotServer } from '../../../utils/wot-server.type';
import { getTopAccountsAsync, selectTopAccounts } from './topAccountsSlice';
import { Link } from 'react-router-dom';
import { selectAccount } from '../../account/redux/accountsSlice';
import { Helmet } from 'react-helmet';
import { TimeRanges } from '../../filter/redux/filterSlice';
import { IAccountSnapshotApiView } from '../../../services/api/interfaces/account.interface';
import { getTransparency } from '../../../utils/get-transparency.util';

interface ITableRow {
  wotId: number;
  lastBattleTime: string;
  battles: number;
  battlesBackgroundColor?: string;
  name: string;
  winRate: number;
  winRateBackgroundColor?: string;
  avgDamage: number;
  avgDamageBackgroundColor?: string;
  fragsRate: number;
  fragsRateBackgroundColor?: string;
  survivalRate: number;
  survivalRateBackgroundColor?: string;
  mastery: number;
  masteryBackgroundColor?: string;
  avgBattleLifeTime?: number;
  avgBattleLifeTimeBackgroundColor?: string;
}

const PageSize = 100;

export function TopAccounts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showRules, setShowRules] = useState(true);

  const [wotServer, setWotServer] = useState<WotServer>('eu');
  const [battlesLimit, setBattlesLimit] = useState(1000);
  const [page, setPage] = useState(1);
  const [place, setPlace] = useState<number | undefined>(undefined);
  const [timeRange, setTimeRange] = useState<TimeRanges>('all');
  const [noobs, setNoobs] = useState<boolean>(false);

  const topAccounts = useSelector(selectTopAccounts(wotServer));
  const selectedAccount = useSelector(selectAccount);

  const [tableDataUnsorted, setTableDataUnsorted] = useState<Array<ITableRow>>([]);
  const [tableData, setTableData] = useState<Array<ITableRow>>([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'lastBattle' | 'battles' | 'win' | 'damage' | 'frags' | 'survival' | 'mastery' | 'battleLifeTime';
    desc: boolean;
  }>({
    column: 'win',
    desc: true,
  });

  useEffect(() => {
    if (topAccounts == null) {
      dispatch(getTopAccountsAsync(wotServer));
    }
  }, [dispatch, topAccounts, wotServer]);

  useEffect(() => {
    if (selectedAccount != null && selectedAccount.server === wotServer) {
      const currentPlace = tableData.findIndex((t) => t.wotId === selectedAccount.wotId);
      setPlace(currentPlace === -1 ? undefined : currentPlace + 1);
    } else {
      setPlace(undefined);
    }
  }, [selectedAccount, tableData, wotServer]);

  // prepare table data
  useEffect(() => {
    const calculatedTableData = topAccounts?.accounts
      .map((a) => {
        switch (timeRange) {
          case '1y':
            return { a, basicSnapshot: a.timed.oneYear };
          case '6m':
            return { a, basicSnapshot: a.timed.sixMonths };
          case '3m':
            return { a, basicSnapshot: a.timed.threeMonths };
          case '1m':
            return { a, basicSnapshot: a.timed.oneMonth };
          case '2w':
            return { a, basicSnapshot: a.timed.twoWeeks };
          case '1w':
            return { a, basicSnapshot: a.timed.oneWeek };
          default:
            return { a, basicSnapshot: undefined };
        }
      })
      .filter((x) => timeRange === 'all' || x.basicSnapshot != null)
      .filter((x) => noobs === true || x.a.snapshot.regular.battles > 5000)
      .map((x) => {
        const snapshot = getSnapshotsDiff(x.a.snapshot, x.basicSnapshot);

        return { a: x.a, snapshot };
      })
      .filter((x) => x.snapshot.regular.battles >= battlesLimit)
      .map((x) => {
        return {
          wotId: x.a.wotId,
          lastBattleTime: x.snapshot.lastBattleTime,
          battles: x.snapshot.regular.battles,
          battlesBackgroundColor: undefined as string | undefined,
          name: x.a.name,
          winRate: x.snapshot.regular.wins / (x.snapshot.regular.battles || 1),
          winRateBackgroundColor: undefined as string | undefined,
          avgDamage: x.snapshot.regular.damageDealt / (x.snapshot.regular.battles || 1),
          avgDamageBackgroundColor: undefined as string | undefined,
          fragsRate: x.snapshot.regular.frags / (x.snapshot.regular.battles || 1),
          fragsRateBackgroundColor: undefined as string | undefined,
          survivalRate: x.snapshot.regular.survivedBattles / (x.snapshot.regular.battles || 1),
          survivalRateBackgroundColor: undefined as string | undefined,
          mastery: (x.snapshot.regular.battles + (x.snapshot.rating?.battles ?? 0)) / (x.snapshot.mastery?.markOfMastery || 1),
          masteryBackgroundColor: undefined as string | undefined,
          avgBattleLifeTime:
            x.snapshot.private == null
              ? undefined
              : x.snapshot.private.battleLifeTime / (x.snapshot.regular.battles + (x.snapshot.rating?.battles ?? 0) || 1),
          avgBattleLifeTimeBackgroundColor: undefined as string | undefined,
        };
      });

    // set background colors
    const limits = {
      minBattle: undefined as number | undefined,
      maxBattle: undefined as number | undefined,
      minWin: undefined as number | undefined,
      maxWin: undefined as number | undefined,
      minDamage: undefined as number | undefined,
      maxDamage: undefined as number | undefined,
      minFrags: undefined as number | undefined,
      maxFrags: undefined as number | undefined,
      minSurvival: undefined as number | undefined,
      maxSurvival: undefined as number | undefined,
      minMastery: undefined as number | undefined,
      maxMastery: undefined as number | undefined,
      minBattleLifeTime: undefined as number | undefined,
      maxBattleLifeTime: undefined as number | undefined,
    };
    calculatedTableData?.forEach((t) => {
      if (limits.minBattle == null || t.battles < limits.minBattle) {
        limits.minBattle = t.battles;
      }
      if (limits.maxBattle == null || t.battles > limits.maxBattle) {
        limits.maxBattle = t.battles;
      }
      if (limits.minWin == null || t.winRate < limits.minWin) {
        limits.minWin = t.winRate;
      }
      if (limits.maxWin == null || t.winRate > limits.maxWin) {
        limits.maxWin = t.winRate;
      }
      if (limits.minDamage == null || t.avgDamage < limits.minDamage) {
        limits.minDamage = t.avgDamage;
      }
      if (limits.maxDamage == null || t.avgDamage > limits.maxDamage) {
        limits.maxDamage = t.avgDamage;
      }
      if (limits.minFrags == null || t.fragsRate < limits.minFrags) {
        limits.minFrags = t.fragsRate;
      }
      if (limits.maxFrags == null || t.fragsRate > limits.maxFrags) {
        limits.maxFrags = t.fragsRate;
      }
      if (limits.minSurvival == null || t.survivalRate < limits.minSurvival) {
        limits.minSurvival = t.survivalRate;
      }
      if (limits.maxSurvival == null || t.survivalRate > limits.maxSurvival) {
        limits.maxSurvival = t.survivalRate;
      }
      if (limits.minMastery == null || (t.mastery != null && t.mastery < limits.minMastery)) {
        limits.minMastery = t.mastery;
      }
      if (limits.maxMastery == null || (t.mastery != null && t.mastery > limits.maxMastery)) {
        limits.maxMastery = t.mastery;
      }
      if (t.avgBattleLifeTime != null) {
        if (limits.minBattleLifeTime == null || t.avgBattleLifeTime < limits.minBattleLifeTime) {
          limits.minBattleLifeTime = t.avgBattleLifeTime;
        }
        if (limits.maxBattleLifeTime == null || t.avgBattleLifeTime > limits.maxBattleLifeTime) {
          limits.maxBattleLifeTime = t.avgBattleLifeTime;
        }
      }
    });

    calculatedTableData?.forEach((t) => {
      t.battlesBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.battles, limits.minBattle ?? 0, limits.maxBattle ?? 0)})`;
      t.winRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.winRate, limits.minWin ?? 0, limits.maxWin ?? 0)})`;
      t.avgDamageBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgDamage, limits.minDamage ?? 0, limits.maxDamage ?? 0)})`;
      t.fragsRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.fragsRate, limits.minFrags ?? 0, limits.maxFrags ?? 0)})`;
      t.survivalRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(
        t.survivalRate,
        limits.minSurvival ?? 0,
        limits.maxSurvival ?? 0,
      )})`;
      t.masteryBackgroundColor = `rgba(89, 207, 70, ${getTransparency(
        t.mastery ?? 0,
        limits.minMastery ?? 0,
        limits.maxMastery ?? 0,
        true,
      )})`;
      if (t.avgBattleLifeTime != null) {
        t.avgBattleLifeTimeBackgroundColor = `rgba(89, 207, 70, ${
          getTransparency(t.avgBattleLifeTime, limits.minBattleLifeTime ?? 0, limits.maxBattleLifeTime ?? 0) ?? 0
        })`;
      }
    });

    setTableDataUnsorted(calculatedTableData ?? []);
  }, [battlesLimit, noobs, timeRange, topAccounts?.accounts]);

  useEffect(() => {
    const sortedTableData = [...tableDataUnsorted].sort((a, b) => {
      switch (sorting.column) {
        case 'battles':
          return sorting.desc ? b.battles - a.battles : a.battles - b.battles;
        case 'damage':
          return sorting.desc ? b.avgDamage - a.avgDamage : a.avgDamage - b.avgDamage;
        case 'name':
          return sorting.desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        case 'lastBattle':
          return sorting.desc ? b.lastBattleTime.localeCompare(a.lastBattleTime) : a.lastBattleTime.localeCompare(b.lastBattleTime);
        case 'win':
          return sorting.desc ? b.winRate - a.winRate : a.winRate - b.winRate;
        case 'frags':
          return sorting.desc ? b.fragsRate - a.fragsRate : a.fragsRate - b.fragsRate;
        case 'survival':
          return sorting.desc ? b.survivalRate - a.survivalRate : a.survivalRate - b.survivalRate;
        case 'mastery':
          return sorting.desc ? b.mastery - a.mastery : a.mastery - b.mastery;
        case 'battleLifeTime':
          return sorting.desc
            ? (b.avgBattleLifeTime ?? -1) - (a.avgBattleLifeTime ?? -1)
            : (a.avgBattleLifeTime ?? -1) - (b.avgBattleLifeTime ?? -1);
        default:
          return sorting.desc ? b.winRate - a.winRate : a.winRate - b.winRate;
      }
    });

    setTableData(sortedTableData);
  }, [sorting.column, sorting.desc, tableDataUnsorted]);

  const getSnapshotsDiff = (a: IAccountSnapshotApiView, b?: IAccountSnapshotApiView): IAccountSnapshotApiView => {
    const result: IAccountSnapshotApiView = {
      ...a,
      regular: { ...a.regular },
      rating: a.rating == null ? undefined : { ...a.rating },
      mastery: a.mastery == null ? undefined : { ...a.mastery },
      private: a.private == null ? undefined : { ...a.private },
    };

    if (b != null) {
      result.regular.battles -= b.regular.battles;
      result.regular.wins -= b.regular.wins;
      result.regular.losses -= b.regular.losses;
      result.regular.damageDealt -= b.regular.damageDealt;
      result.regular.damageReceived -= b.regular.damageReceived;
      result.regular.survivedBattles -= b.regular.survivedBattles;
      result.regular.spotted -= b.regular.spotted;
      result.regular.frags -= b.regular.frags;

      if (result.rating != null && b.rating != null) {
        result.rating.battles -= b.rating.battles;
      }

      if (result.mastery != null && b.mastery != null) {
        result.mastery.markOfMastery -= b.mastery.markOfMastery;
        result.mastery.markOfMasteryI -= b.mastery.markOfMasteryI;
        result.mastery.markOfMasteryII -= b.mastery.markOfMasteryII;
        result.mastery.markOfMasteryIII -= b.mastery.markOfMasteryIII;
      }

      if (result.private != null && b.private != null) {
        result.private.battleLifeTime -= b.private.battleLifeTime;
        result.private.credits -= b.private.credits;
      }
    }

    return result;
  };

  return (
    <Container fluid>
      <Helmet>
        <title>{t('TopAccounts.Title', 'Wargaming WoT Blitz Beast - Top players statistics')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('TopAccounts.Header', 'Top accounts')}</h1>
        </Col>
        <Col>
          <div className="filter-container">
            <div className="position-container">
              <span>{t('TopAccounts.PositionLabel', 'Your position: ')}</span>
              <span>
                {place ?? '-'} / {tableData.length}
              </span>
            </div>

            <div>
              <Form.Select onChange={(event) => setTimeRange(event.target.value as TimeRanges)} value={timeRange}>
                <option value="1w">{t('TopAccounts.TimeRangeFilterLabel1w', '1 week')}</option>
                <option value="2w">{t('TopAccounts.TimeRangeFilterLabel2w', '2 weeks')}</option>
                <option value="1m">{t('TopAccounts.TimeRangeFilterLabel1m', '1 month')}</option>
                <option value="3m">{t('TopAccounts.TimeRangeFilterLabel3m', '3 months')}</option>
                <option value="6m">{t('TopAccounts.TimeRangeFilterLabel6m', '6 months')}</option>
                <option value="1y">{t('TopAccounts.TimeRangeFilterLabel1y', '1 year')}</option>
                <option value="all">{t('TopAccounts.TimeRangeFilterLabelAll', 'All')}</option>
              </Form.Select>
            </div>

            <div>
              <Form.Select onChange={(event) => setBattlesLimit(+event.target.value)} value={battlesLimit}>
                <option value="100">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: 100 })}</option>
                <option value="500">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: 500 })}</option>
                <option value="1000">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: '1K' })}</option>
                <option value="5000">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: '5K' })}</option>
                <option value="10000">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: '10K' })}</option>
              </Form.Select>
            </div>

            <div className="pt-2">
              <Form.Check
                type="checkbox"
                label={t('TopAccounts.ShowNoobs', 'Noobs')}
                checked={noobs}
                onChange={() => {
                  setNoobs(!noobs);
                }}
              />
            </div>

            <div>
              <Form.Select onChange={(event) => setWotServer(event.target.value as WotServer)} value={wotServer}>
                <option value="eu">EU</option>
                <option value="com">NA</option>
                <option value="asia">ASIA</option>
              </Form.Select>
            </div>

            <div>
              <InputGroup>
                <Button
                  variant="outline-secondary"
                  disabled={page === 1}
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  {'<'}
                </Button>
                <Form.Control value={page} disabled className="table-page-input" />
                <Button
                  variant="outline-secondary"
                  disabled={page * PageSize >= tableData.length}
                  onClick={() => {
                    if (page * PageSize < tableData.length) {
                      setPage(page + 1);
                    }
                  }}
                >
                  {'>'}
                </Button>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>

      {showRules && (
        <Row>
          <Col>
            <Alert className="mt-2" variant="info" onClose={() => setShowRules(false)} dismissible>
              {t(
                'TopAccounts.RulesAlert',
                'Only accounts that are logged in with a Game account are shown in the rating. The table is updated once a day.',
              )}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'lastBattle', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnLastBattle', 'Last battle')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battles', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnTotalBattles', 'Total battles')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'win', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnWinRate', 'Win rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'damage', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnAvgDamage', 'Avg. damage')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'frags', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnFragsRate', 'Frags rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'survival', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnSurvivalRate', 'Survival rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'mastery', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnMastery', 'Battles per Master')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battleLifeTime', desc: !sorting.desc })}>
                    {t('TopAccounts.ColumnBattleLifeTime', 'Avg. life time')}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                [...tableData].slice((page - 1) * PageSize, page * PageSize).map((account, i) => (
                  <tr key={account.wotId}>
                    <td>{(page - 1) * PageSize + i + 1}</td>
                    <td>
                      <Link to={`/accounts/${wotServer}/${account.wotId ?? ''}`}>{account.name}</Link>
                    </td>
                    <td>{new Date(account.lastBattleTime).toLocaleDateString()}</td>
                    <td style={{ backgroundColor: account.battlesBackgroundColor }}>{account.battles}</td>
                    <td style={{ backgroundColor: account.winRateBackgroundColor }}>{(account.winRate * 100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: account.avgDamageBackgroundColor }}>{account.avgDamage.toFixed(0)}</td>
                    <td style={{ backgroundColor: account.fragsRateBackgroundColor }}>{account.fragsRate.toFixed(2)}</td>
                    <td style={{ backgroundColor: account.survivalRateBackgroundColor }}>{(account.survivalRate * 100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: account.masteryBackgroundColor }}>{account.mastery.toFixed(0)}</td>
                    <td style={{ backgroundColor: account.avgBattleLifeTimeBackgroundColor }}>
                      {account.avgBattleLifeTime == null
                        ? '-'
                        : account.avgBattleLifeTime > 7 * 60
                        ? '~7m'
                        : `${Math.floor(account.avgBattleLifeTime / 60)}m ${(account.avgBattleLifeTime % 60).toFixed(0)}s`}
                    </td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    {t('TopAccounts.NoTableRows', 'There are no accounts matching the selected filters.')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
