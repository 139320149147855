import '../../../Widget.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WotApiService } from '../../../../../services/api/wot-api.service';
import { WotServer } from '../../../../../utils/wot-server.type';
import { LogService } from '../../../../../services/log.service';
import { sleep } from '../../../../../utils/sleep.util';
import { useSelector } from 'react-redux';
import { selectSubscriptionValid } from '../../../../subscription/redux/subscriptionSlice';
import CrabImg from '../../../../../images/crab.gif';
import { getBlitzSessionDetailedWidgetBasicStats } from './widget-blitz-session-detailed.util';

type TankStats = { id: number; battles: number; wins: number; damage: number };

export function WidgetBlitzSessionDetailed() {
  const { t } = useTranslation();
  const { search } = useLocation();

  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [current, setCurrent] = useState<TankStats[]>([]);

  const [session, setSession] = useState<{
    battles: number;
    winRate: number;
    avgDamage: number;
    tanks: { id: number; name: string; battles: number; winRate: number; avgDamage: number }[];
  }>({
    battles: 0,
    winRate: 0,
    avgDamage: 0,
    tanks: [],
  });

  const [query, setQuery] = useState<{
    wotAccountId?: number;
    wotAccountServer?: string;
    showSiteName?: boolean;
  }>({
    wotAccountId: undefined,
    wotAccountServer: undefined,
    showSiteName: undefined,
  });

  const [baseValues, setBaseValues] = useState<TankStats[]>([]);

  const [showingSiteName, setShowingSiteName] = useState<boolean>(false);
  const [tankNames, setTankNames] = useState<{ id: number; name: string }[]>([]);

  // parse query string
  useEffect(() => {
    const url = new URLSearchParams(search);

    const wotAccountIdQuery = url.get('wotAccountId');
    const wotAccountServerQuery = url.get('wotAccountServer');
    const showSiteNameQuery = url.get('showSiteName');

    const showSiteName = showSiteNameQuery === 'false' ? false : true;

    setQuery({
      wotAccountId: wotAccountIdQuery == null ? 0 : +wotAccountIdQuery,
      wotAccountServer: wotAccountServerQuery == null ? '' : wotAccountServerQuery,
      showSiteName,
    });
  }, [search]);

  // get tanks from local storage
  useEffect(() => {
    const run = async () => {
      if (query.wotAccountId == null || query.wotAccountServer == null) {
        return;
      }

      try {
        let storedStats = getBlitzSessionDetailedWidgetBasicStats(query.wotAccountId, query.wotAccountServer as WotServer);

        // if there are no stored basics, load the current
        if (storedStats == null || storedStats.tanks == null || storedStats.tanks.length === 0) {
          const svc = new WotApiService();
          let wotTanksStats = await svc.getAccountTanksStats(query.wotAccountId, query.wotAccountServer as WotServer, [
            'tank_id',
            'all.battles',
            'all.wins',
            'all.damage_dealt',
          ]);

          while (wotTanksStats.status !== 'ok' || wotTanksStats.data == null) {
            await sleep(1000);
            wotTanksStats = await svc.getAccountTanksStats(query.wotAccountId, query.wotAccountServer as WotServer, [
              'tank_id',
              'all.battles',
              'all.wins',
              'all.damage_dealt',
            ]);
          }

          const tanksStats = wotTanksStats.data.map((x) => {
            return {
              id: x.tank_id,
              battles: x.all.battles,
              wins: x.all.wins,
              damage: x.all.damage_dealt,
            };
          });

          storedStats = {
            wotAccountId: query.wotAccountId,
            wotAccountServer: query.wotAccountServer as WotServer,
            tanks: tanksStats,
          };
        }

        setBaseValues(storedStats.tanks);
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    void run();
  }, [query.wotAccountId, query.wotAccountServer]);

  // get current stats
  useEffect(() => {
    const getStats = async () => {
      if (query.wotAccountId == null || query.wotAccountServer == null) {
        return;
      }

      try {
        const svc = new WotApiService();
        const tanksStats = await svc.getAccountTanksStats(query.wotAccountId, query.wotAccountServer as WotServer, [
          'tank_id',
          'all.battles',
          'all.wins',
          'all.damage_dealt',
        ]);

        if (tanksStats.status === 'ok' && tanksStats.data != null) {
          const tanks = tanksStats.data.map((x) => {
            return {
              id: x.tank_id,
              battles: x.all.battles,
              wins: x.all.wins,
              damage: x.all.damage_dealt,
            };
          });

          setCurrent(tanks);
        } else {
          LogService.error(`Error getting stats for account ${query.wotAccountId} on server ${query.wotAccountServer}`);
          LogService.error(JSON.stringify(tanksStats));
        }
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    const interval = setInterval(() => {
      void getStats();
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [query.wotAccountId, query.wotAccountServer]);

  // update session
  useEffect(() => {
    const totalBattles = current.reduce((acc, x) => acc + x.battles, 0) - baseValues.reduce((acc, x) => acc + x.battles, 0);
    const totalWins = current.reduce((acc, x) => acc + x.wins, 0) - baseValues.reduce((acc, x) => acc + x.wins, 0);
    const totalDamage = current.reduce((acc, x) => acc + x.damage, 0) - baseValues.reduce((acc, x) => acc + x.damage, 0);

    if (totalBattles <= 0 || totalBattles === session.battles) {
      return;
    }

    const playedTanks = current
      .filter((c) => {
        const baseTank = baseValues.find((q) => q.id === c.id);
        return c.battles !== 0 && (baseTank == null || baseTank.battles < c.battles);
      })
      .map((c) => {
        const baseTank = baseValues.find((q) => q.id === c.id);

        const tankName = tankNames.find((x) => x.id === c.id)?.name ?? `${c.id}`;

        const battlesDiff = c.battles - (baseTank?.battles ?? 0);
        const winsDiff = c.wins - (baseTank?.wins ?? 0);
        const damageDiff = c.damage - (baseTank?.damage ?? 0);

        return {
          id: c.id,
          name: tankName,
          battles: battlesDiff,
          winRate: winsDiff / (battlesDiff || 1),
          avgDamage: damageDiff / (battlesDiff || 1),
        };
      })
      .sort((a, b) => b.battles - a.battles);

    setSession({
      battles: totalBattles,
      winRate: totalWins / totalBattles,
      avgDamage: totalDamage / totalBattles,
      tanks: playedTanks,
    });
  }, [baseValues, current, session.battles, tankNames]);

  // show site name
  useEffect(() => {
    const run = async () => {
      if (showingSiteName === true) {
        if (isValidSubscription === true && query.showSiteName === false) {
          setShowingSiteName(false);
        } else {
          await sleep(1000 * 5);
          setShowingSiteName(false);
        }
      }
    };

    void run();
  }, [query.showSiteName, showingSiteName, isValidSubscription]);

  useEffect(() => {
    const run = async () => {
      if (showingSiteName === false) {
        if (isValidSubscription !== true || query.showSiteName === true) {
          await sleep(1000 * 30);
          setShowingSiteName(true);
        }
      }
    };

    void run();
  }, [query.showSiteName, showingSiteName, isValidSubscription]);

  // get tank names
  useEffect(() => {
    const run = async () => {
      if (query.wotAccountServer == null) {
        return;
      }

      try {
        const svc = new WotApiService();
        const wotTanks = await svc.getTanks(query.wotAccountServer as WotServer, ['tank_id', 'name']);
        const wotTankNames = wotTanks.data?.map((x) => {
          return { id: x.tank_id, name: x.name };
        });

        setTankNames([{ id: 18241, name: 'Bourrasque' }, { id: 24849, name: 'Kryos' }, ...(wotTankNames ?? [])]);
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    void run();
  }, [query.wotAccountServer]);

  return (
    <div className="widget-container no-background">
      <table className="table-normal-header">
        <thead>
          <tr>
            <th></th>
            <th>{t('WidgetBlitzSessionDetailed.Battles', 'Battles')}</th>
            <th>{t('WidgetBlitzSessionDetailed.Damage', 'Damage')}</th>
            <th>{t('WidgetBlitzSessionDetailed.Wins', 'Win rate')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{t('WidgetBlitzSessionDetailed.Total', 'Total')}</strong>
            </td>

            {!showingSiteName && (
              <>
                <td>
                  <strong>{session.battles}</strong>
                </td>
                <td>
                  <strong>{session.avgDamage.toFixed(0)}</strong>
                </td>
                <td>
                  <strong>{(session.winRate * 100).toFixed(2)}%</strong>
                </td>
              </>
            )}
            {showingSiteName && (
              <td colSpan={3} className="text-center">
                <img src={CrabImg} alt="crab" className="me-2"></img>
                <span>beastwot.com</span>
                <img src={CrabImg} alt="crab" className="ms-2"></img>
              </td>
            )}
          </tr>
          {session.tanks.map((x) => (
            <tr key={x.id}>
              <td>{x.name}</td>

              {!showingSiteName && (
                <>
                  <td>{x.battles}</td>
                  <td>{x.avgDamage.toFixed(0)}</td>
                  <td>{(x.winRate * 100).toFixed(2)}%</td>
                </>
              )}

              {showingSiteName && (
                <td colSpan={3} className="text-center">
                  <img src={CrabImg} alt="crab" className="me-2"></img>
                  <span>beastwot.com</span>
                  <img src={CrabImg} alt="crab" className="ms-2"></img>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
