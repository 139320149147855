import { Alert, Button, Form, InputGroup, Stack, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ITournamentAccountSep2023ApiView } from '../../../../../services/api/interfaces/tournament.interface';
import { selectAccount } from '../../../../account/redux/accountsSlice';
import {
  checkSep2023Async,
  participateSep2023Async,
  rewardSep2023Async,
  selectRecentReward,
  selectTournamentAccounts,
  setRecentReward,
} from '../../../tournamentsSlice';
import { LoadingParts, selectLoadingState } from '../../../../loading/loadingSlice';
import { selectUser } from '../../../../user/userSlice';
import { toRoman } from '../../../../../utils/number.util';
import { SpinWheel } from './SpinWheel';

const RequiredWins = 15;
const MaxBattlesParts = 20;
const PageSize = 100;

export function TournamentDetailsSep2023Battles() {
  type TableRow = {
    accountName: string;
    wotId: number;
    server: string;
    date: string;
    wins: number | undefined;
    tankLevel?: number | undefined;
    parts?: number | undefined;
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);
  const loading = useSelector(selectLoadingState);
  const recentReward = useSelector(selectRecentReward);

  const tournamentWithAccounts = useSelector(selectTournamentAccounts);

  const [tournamentAccounts, setTournamentAccounts] = useState<ITournamentAccountSep2023ApiView[]>([]);
  const [tableRows, setTableRows] = useState<TableRow[]>([]);
  const [sorting, setSorting] = useState<{
    column: 'accountName' | 'wotId' | 'server' | 'date' | 'wins' | 'tankLevel' | 'parts';
    desc: boolean;
  }>({
    column: 'accountName',
    desc: false,
  });
  const [page, setPage] = useState(1);

  const [topControl, setTopControl] = useState<'participate' | 'check' | 'reward' | 'completed' | undefined>(undefined);

  useEffect(() => {
    if (user?.role === 'admin') {
      setTournamentAccounts(tournamentWithAccounts?.accounts ?? []);
    } else {
      setTournamentAccounts(tournamentWithAccounts?.accounts.filter((x) => x.accountId === account?.id) ?? []);
    }
  }, [account?.id, tournamentWithAccounts?.accounts, user?.role]);

  useEffect(() => {
    if (tournamentWithAccounts == null) {
      setTopControl(undefined);
      return;
    }

    if (user?.role == null || user.role === 'admin') {
      setTopControl(undefined);
      return;
    }

    if (account == null) {
      setTopControl(undefined);
      return;
    }

    if (tournamentWithAccounts.startDate > new Date().toISOString()) {
      setTopControl(undefined);
      return;
    }

    if (tournamentWithAccounts.endDate < new Date().toISOString()) {
      setTopControl('completed');
      return;
    }

    const todayReward = tournamentAccounts[0]?.results.find((r) => new Date(r.date).toDateString() === new Date().toDateString());

    if (todayReward == null && (tournamentAccounts[0] == null || tournamentAccounts[0].results.length < MaxBattlesParts)) {
      setTopControl('participate');
      return;
    }

    if (
      todayReward != null &&
      (todayReward.parts == null || todayReward.tankLevel == null) &&
      todayReward.endWins != null &&
      todayReward.endWins - todayReward.startWins >= RequiredWins
    ) {
      setTopControl('reward');
      return;
    }

    if (todayReward != null && todayReward.parts != null && todayReward.tankLevel != null) {
      setTopControl(undefined);
      return;
    }

    setTopControl('check');
  }, [account, tournamentAccounts, tournamentWithAccounts, user?.role]);

  useEffect(() => {
    const rows = tournamentAccounts
      .map((a) =>
        a.results.flatMap((r) => {
          return { wotId: a.wotId, accountName: a.name, server: a.server, ...r };
        }),
      )
      .flat()
      .map((r) => {
        return {
          ...r,
          wins: r.endWins != null && r.startWins != null ? r.endWins - r.startWins : undefined,
        };
      })
      .sort((a, b) => {
        switch (sorting.column) {
          case 'accountName':
            return sorting.desc
              ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
              : (a.accountName ?? '').localeCompare(b.accountName ?? '');
          case 'wotId':
            return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
          case 'server':
            return sorting.desc ? (b.server ?? '').localeCompare(a.server ?? '') : (a.server ?? '').localeCompare(b.server ?? '');
          case 'date':
            return sorting.desc ? (b.date ?? '').localeCompare(a.date ?? '') : (a.date ?? '').localeCompare(b.date ?? '');
          case 'wins':
            return sorting.desc ? (b.wins ?? 0) - (a.wins ?? 0) : (a.wins ?? 0) - (b.wins ?? 0);
          case 'tankLevel':
            return sorting.desc ? (b.tankLevel ?? 0) - (a.tankLevel ?? 0) : (a.tankLevel ?? 0) - (b.tankLevel ?? 0);
          case 'parts':
            return sorting.desc ? (b.parts ?? 0) - (a.parts ?? 0) : (a.parts ?? 0) - (b.parts ?? 0);
          default:
            return sorting.desc
              ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
              : (a.accountName ?? '').localeCompare(b.accountName ?? '');
        }
      });

    setTableRows(rows);
  }, [sorting.column, sorting.desc, tournamentAccounts]);

  return (
    <>
      {topControl === 'participate' && (
        <Button
          className="mb-3"
          variant="success"
          onClick={() => dispatch(participateSep2023Async(tournamentWithAccounts?.id ?? '', account?.id ?? ''))}
          disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true)}
        >
          {t('TournamentDetails.ParticipateButton', 'Participate today')}
        </Button>
      )}

      {topControl === 'check' && (
        <Stack direction="horizontal" gap={3}>
          <Button
            disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true)}
            onClick={() => {
              dispatch(checkSep2023Async(tournamentWithAccounts?.id ?? '', account?.id ?? ''));
            }}
            variant="primary"
            className="mb-3"
          >
            {t('TournamentDetails.CheckButton', 'Check wins')}
          </Button>

          <Alert variant="warning">
            <p className="mb-0">
              {t(
                'TournamentDetails.ParticipateInfo',
                'Do not forget to come back to the site and check your reward today! It won`t be available tomorrow.',
              )}
            </p>
          </Alert>
        </Stack>
      )}

      {topControl === 'reward' && (
        <Button
          className="mb-3"
          disabled={loading.some((l) => l.key === LoadingParts.TournamentLoading && l.value === true)}
          onClick={() => {
            dispatch(rewardSep2023Async(tournamentWithAccounts?.id ?? '', account?.id ?? ''));
          }}
          variant="success"
        >
          {t('TournamentDetails.RewardButton', 'Get reward')}
        </Button>
      )}

      {user?.role !== 'admin' && topControl === 'completed' && (
        <Alert variant="warning">
          <p className="mb-0">
            <Trans t={t} i18nKey="TournamentDetails.CompletedBattlesInfo">
              We have given away all the rewards that you could get by playing battles. Next, you can receive tank parts on{' '}
              <a href="https://trovo.live/Beast_WoT?utm_source=beastwot.com" target="_blank" rel="noopener noreferrer">
                Trovo
              </a>{' '}
              streams and in the{' '}
              <a href="https://t.me/BEAST_WB?utm_source=beastwot.com" target="_blank" rel="noopener noreferrer">
                Telegram
              </a>{' '}
              channel. Thank you to everyone who participated!
            </Trans>
          </p>
        </Alert>
      )}

      {user?.role === 'admin' && (
        <InputGroup className="mb-2">
          <Button
            variant="outline-secondary"
            disabled={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            {'<'}
          </Button>
          <Form.Control value={page} disabled className="table-page-input" />
          <Button
            variant="outline-secondary"
            disabled={page * PageSize >= tableRows.length}
            onClick={() => {
              if (page * PageSize < tableRows.length) {
                setPage(page + 1);
              }
            }}
          >
            {'>'}
          </Button>
        </InputGroup>
      )}

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'accountName', desc: !sorting.desc })}>
                {t('TournamentDetails.AccountColumn', 'Account')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'wotId', desc: !sorting.desc })}>
                {t('TournamentDetails.IdColumn', 'ID')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'server', desc: !sorting.desc })}>
                {t('TournamentDetails.ServerColumn', 'Server')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'date', desc: !sorting.desc })}>
                {t('TournamentDetails.DateColumn', 'Date')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'wins', desc: !sorting.desc })}>
                {t('TournamentDetails.WinsColumn', 'Wins')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'tankLevel', desc: !sorting.desc })}>
                {t('TournamentDetails.TankLevelColumn', 'Tank level')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'parts', desc: !sorting.desc })}>
                {t('TournamentDetails.TankPartsColumn', 'Tank parts')}
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRows.slice((page - 1) * PageSize, page * PageSize).map((row, i) => (
            <tr key={i}>
              <td>{(page - 1) * PageSize + i + 1}</td>
              <td>{row.accountName}</td>
              <td>{row.wotId}</td>
              <td>{row.server}</td>
              <td>{new Date(row.date).toLocaleDateString()}</td>
              <td>{row.wins ?? '?'}</td>
              <td>
                {row.tankLevel != null
                  ? toRoman(row.tankLevel)
                  : new Date(row.date).toDateString() === new Date().toDateString()
                  ? '?'
                  : '-'}
              </td>
              <td>{row.parts != null ? row.parts : new Date(row.date).toDateString() === new Date().toDateString() ? '?' : '-'}</td>
            </tr>
          ))}

          {tournamentAccounts.length === 0 && (
            <tr>
              <td colSpan={8} className="text-center">
                {t('TournamentDetails.NoTableRows', 'There are no tournament results yet.')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {recentReward?.tankLevel != null && (
        <SpinWheel winTankLevel={recentReward.tankLevel} onFinished={() => dispatch(setRecentReward(undefined))} />
      )}
    </>
  );
}
