import { useState, useEffect } from 'react';
import { Row, Col, Table, Stack, Button, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectTanks } from '../../tanks/tanksSlice';
import { StarFill } from 'react-bootstrap-icons';
import { ReactComponent as HeavyTankIcon } from '../../../images/heavy-icon.svg';
import { ReactComponent as MedTankIcon } from '../../../images/med-icon.svg';
import { ReactComponent as LightTankIcon } from '../../../images/light-icon.svg';
import { ReactComponent as TdTankIcon } from '../../../images/td-icon.svg';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../filter/Filter';
import { TankTiers, TankTypes } from '../../../services/api/interfaces/tank.interface';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { selectAccountTanksRemainingHpRate } from '../../statistics/redux/remainingHpRateStatisticsSlice';
import { selectAccountTanksBattleLifeTime } from '../../statistics/redux/battleLifeTimeStatisticsSlice';
import { selectAccountTanks } from '../../account/redux/accountTanksSlice';
import { selectFilter } from '../../filter/redux/filterSlice';
import { WotServer } from '../../../utils/wot-server.type';
import { selectAccount } from '../../account/redux/accountsSlice';
import { Helmet } from 'react-helmet';
import { getMaxPossibleTankHp } from '../../tanks/tank.utils';

type TableBackgroundColors = 'success' | 'warning';

export function HangarTable() {
  const { t, i18n } = useTranslation();

  const { wotId, server } = useParams<{ wotId: string; server: WotServer }>();

  const filter = useSelector(selectFilter);

  const accountTanks = useSelector(selectAccountTanks);
  const tanks = useSelector(selectTanks);

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));
  const rhpRates = useSelector(selectAccountTanksRemainingHpRate);
  const battleLifeTimes = useSelector(selectAccountTanksBattleLifeTime);

  const [tableData, setTableData] = useState<
    Array<{
      wotId: number;
      battles: number;
      lastBattleTime: string;
      name?: string;
      tier?: TankTiers;
      type?: TankTypes;
      isPremium?: boolean;
      winRate: number;
      winRateBackgroundColor: TableBackgroundColors;
      avgDamage: number;
      avgDamageBackgroundColor: TableBackgroundColors;
      fragsRate: number;
      fragsRateBackgroundColor: TableBackgroundColors;
      remainingHpRate?: number;
      remainingHpBackgroundColor: TableBackgroundColors;
      mastery?: number;
      masteryBackgroundColor: TableBackgroundColors;
      avgBattleLifeTime?: number;
      avgBattleLifeTimeBackgroundColor: TableBackgroundColors;
    }>
  >([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'tier' | 'date' | 'battles' | 'win' | 'damage' | 'frags' | 'remainingHp' | 'mastery' | 'battleLifeTime';
    desc: boolean;
  }>({
    column: 'date',
    desc: true,
  });

  // prepare table data
  useEffect(() => {
    const calculatedTableData = accountTanks
      .filter((t) => t.snapshots.length > 0)
      .filter((t) => t.snapshots[t.snapshots.length - 1].regular.battles > 0)
      .filter((t) => t.snapshots[t.snapshots.length - 1].regular.battles >= filter.limitTankBattleCount)
      .filter((t) => {
        if (filter.tankTiers.length === 0 || filter.tankTiers.length === 10) {
          return true;
        }

        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        return tank == null || filter.tankTiers.includes(tank.tier);
      })
      .filter((t) => {
        if (filter.tankTypes.length === 0 || filter.tankTypes.length === 4) {
          return true;
        }

        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        return tank == null || filter.tankTypes.includes(tank.type);
      })
      .map((t) => {
        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        const latestSnapshot = t.snapshots[t.snapshots.length - 1];

        return {
          wotId: t.wotId,
          battles: latestSnapshot.regular.battles,
          lastBattleTime: latestSnapshot.lastBattleTime,
          name: i18n.language === 'ru' || i18n.language === 'uk' ? tank?.name.ru ?? tank?.name.en : tank?.name.en,
          tier: tank?.tier,
          type: tank?.type,
          isPremium: tank?.isPremium,
          winRate: latestSnapshot.regular.wins / latestSnapshot.regular.battles,
          winRateBackgroundColor: 'success' as TableBackgroundColors,
          avgDamage: latestSnapshot.regular.damageDealt / latestSnapshot.regular.battles,
          avgDamageBackgroundColor: 'success' as TableBackgroundColors,
          fragsRate: latestSnapshot.regular.frags / latestSnapshot.regular.battles,
          fragsRateBackgroundColor: 'success' as TableBackgroundColors,
          remainingHpRate: rhpRates.find((rhp) => rhp.wotId === t.wotId)?.rhpr,
          remainingHpBackgroundColor: 'success' as TableBackgroundColors,
          mastery:
            latestSnapshot.mastery?.markOfMastery == null || latestSnapshot.mastery?.markOfMastery === 0
              ? undefined
              : latestSnapshot.regular.battles / latestSnapshot.mastery.markOfMastery,
          masteryBackgroundColor: 'success' as TableBackgroundColors,
          avgBattleLifeTime: battleLifeTimes.find((blt) => blt.wotId === t.wotId)?.battleLifeTime,
          avgBattleLifeTimeBackgroundColor: 'success' as TableBackgroundColors,
        };
      })
      .sort((a, b) => {
        switch (sorting.column) {
          case 'date':
            return sorting.desc
              ? (b.lastBattleTime ?? '').localeCompare(a.lastBattleTime ?? '')
              : (a.lastBattleTime ?? '').localeCompare(b.lastBattleTime ?? '');
          case 'battles':
            return sorting.desc ? b.battles - a.battles : a.battles - b.battles;
          case 'damage':
            return sorting.desc ? b.avgDamage - a.avgDamage : a.avgDamage - b.avgDamage;
          case 'name':
            return sorting.desc ? (b.name ?? '').localeCompare(a.name ?? '') : (a.name ?? '').localeCompare(b.name ?? '');
          case 'tier':
            return sorting.desc ? (b.tier ?? 0) - (a.tier ?? 0) : (a.tier ?? 0) - (b.tier ?? 0);
          case 'win':
            return sorting.desc ? b.winRate - a.winRate : a.winRate - b.winRate;
          case 'frags':
            return sorting.desc ? b.fragsRate - a.fragsRate : a.fragsRate - b.fragsRate;
          case 'remainingHp':
            return sorting.desc
              ? (b?.remainingHpRate ?? -1) - (a.remainingHpRate ?? -1)
              : (a.remainingHpRate ?? -1) - (b.remainingHpRate ?? -1);
          case 'mastery':
            return sorting.desc ? (b.mastery ?? 999999) - (a.mastery ?? 999999) : (a.mastery ?? 999999) - (b.mastery ?? 999999);
          case 'battleLifeTime':
            return sorting.desc
              ? (b.avgBattleLifeTime ?? -1) - (a.avgBattleLifeTime ?? -1)
              : (a.avgBattleLifeTime ?? -1) - (b.avgBattleLifeTime ?? -1);
          default:
            return sorting.desc
              ? (b.lastBattleTime ?? '').localeCompare(a.lastBattleTime ?? '')
              : (a.lastBattleTime ?? '').localeCompare(b.lastBattleTime ?? '');
        }
      });

    // set background colors
    calculatedTableData.forEach((t) => {
      const tankServerStats = serverStats?.tanks.find((ts) => ts.wotId === t.wotId)?.statistics;

      if (tankServerStats == null) {
        return;
      }

      const tank = tanks.find((tt) => tt.wotId === t.wotId);

      const winRateServer = tankServerStats.regular.wins / (tankServerStats.regular.battles || 1);
      const avgDamageServer = tankServerStats.regular.damageDealt / (tankServerStats.regular.battles || 1);
      const fragsRateServer = tankServerStats.regular.frags / (tankServerStats.regular.battles || 1);
      const avgBattleLifeTimeServer = tankServerStats.battleLifeTime / (tankServerStats.regular.battles || 1);

      const remainingHpServer =
        tank == null
          ? undefined
          : (getMaxPossibleTankHp(tank) - tankServerStats.regular.damageReceived / (tankServerStats.regular.battles || 1)) /
            (getMaxPossibleTankHp(tank) || 1);

      const battlesPerMasterServer = tankServerStats.regular.battles / tankServerStats.mastery.markOfMastery;

      t.winRateBackgroundColor = winRateServer > t.winRate ? 'warning' : 'success';
      t.avgDamageBackgroundColor = avgDamageServer > t.avgDamage ? 'warning' : 'success';
      t.fragsRateBackgroundColor = fragsRateServer > t.fragsRate ? 'warning' : 'success';
      t.remainingHpBackgroundColor =
        remainingHpServer == null || t.remainingHpRate == null || remainingHpServer < t.remainingHpRate ? 'success' : 'warning';
      t.masteryBackgroundColor =
        t.mastery != null && (battlesPerMasterServer == null || battlesPerMasterServer >= t.mastery) ? 'success' : 'warning';
      t.avgBattleLifeTimeBackgroundColor =
        avgBattleLifeTimeServer == null || t.avgBattleLifeTime == null || avgBattleLifeTimeServer < t.avgBattleLifeTime
          ? 'success'
          : 'warning';
    });

    setTableData(calculatedTableData);
  }, [
    accountTanks,
    battleLifeTimes,
    filter.limitTankBattleCount,
    filter.tankTiers,
    filter.tankTypes,
    i18n.language,
    rhpRates,
    serverStats?.tanks,
    sorting.column,
    sorting.desc,
    tanks,
  ]);

  return (
    <Container fluid>
      <Helmet>
        <title>{t('HangarTable.Title', 'Wargaming WoT Blitz Beast - Hangar tanks statistics and charts')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('HangarTable.Header', 'Hangar')}</h1>
        </Col>
        <Col>
          <Filter hideTimeFilter />
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'tier', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnSpecs', 'Specs')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'date', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnLastBattle', 'Last battle')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battles', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnTotalBattles', 'Total battles')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'win', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnWinRate', 'Win rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'damage', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnAvgDamage', 'Avg. damage')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'frags', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnFragsRate', 'Frags rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'remainingHp', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnRemainingHp', 'Remaining HP rate')}*
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'mastery', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnMastery', 'Battles per Master')}*
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battleLifeTime', desc: !sorting.desc })}>
                    {t('HangarTable.ColumnBattleLifeTime', 'Avg. life time')}*
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                [...tableData].map((accountTank) => (
                  <tr key={accountTank.wotId}>
                    <td>
                      <Link to={`/accounts/${server ?? ''}/${wotId ?? ''}/hangar/${accountTank.wotId}`}>
                        {accountTank.name ?? accountTank.wotId}
                      </Link>
                    </td>
                    <td>
                      <Stack direction="horizontal" gap={1}>
                        {accountTank.type === 'heavyTank' ? (
                          <HeavyTankIcon />
                        ) : accountTank.type === 'mediumTank' ? (
                          <MedTankIcon />
                        ) : accountTank.type === 'lightTank' ? (
                          <LightTankIcon />
                        ) : accountTank.type === 'AT-SPG' ? (
                          <TdTankIcon />
                        ) : undefined}
                        <p className="mb-0">{accountTank.tier}</p>
                        {accountTank.isPremium === true && <StarFill size={15} color="Orange" />}
                      </Stack>
                    </td>
                    <td>{new Date(accountTank.lastBattleTime).toLocaleDateString()}</td>
                    <td>{accountTank.battles}</td>
                    <td className={accountTank.winRateBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}>
                      {(accountTank.winRate * 100).toFixed(2)}%
                    </td>
                    <td className={accountTank.avgDamageBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}>
                      {accountTank.avgDamage.toFixed(0)}
                    </td>
                    <td className={accountTank.fragsRateBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}>
                      {accountTank.fragsRate.toFixed(2)}
                    </td>
                    <td className={accountTank.remainingHpBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}>
                      {accountTank.remainingHpRate == null ? '-' : (accountTank.remainingHpRate * 100).toFixed(2) + '%'}
                    </td>
                    <td className={accountTank.masteryBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}>
                      {accountTank.mastery == null ? '-' : accountTank.mastery.toFixed(0)}
                    </td>
                    <td
                      className={accountTank.avgBattleLifeTimeBackgroundColor === 'warning' ? 'warning-background' : 'success-background'}
                    >
                      {accountTank.avgBattleLifeTime == null
                        ? '-'
                        : accountTank.avgBattleLifeTime > 7 * 60
                        ? '~7m'
                        : `${Math.floor(accountTank.avgBattleLifeTime / 60)}m ${(accountTank.avgBattleLifeTime % 60).toFixed(0)}s`}
                    </td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    {t('HangarTable.NoTableRows', 'There are no vehicles matching the selected filters.')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <span>{t('HangarTable.InaccurateDataDisclaimer', '* the numbers marked with asterisks could be not completely accurate')}</span>
        </Col>
      </Row>
    </Container>
  );
}
