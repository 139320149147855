import fetchApi from './fetch-api';
import { ListResponseApiView, SingleEntityResponseApiView } from './interfaces/response.interface';
import { IAccountApiView, IAccountLinkingApiView, IAccountShortApiView } from './interfaces/account.interface';
import { WotServer } from '../../utils/wot-server.type';

export class AccountApiService {
  async getMy(): Promise<ListResponseApiView<IAccountShortApiView>> {
    const response = await fetchApi('accounts/my', {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<IAccountShortApiView>;

    return body;
  }

  async addMy(payload: IAccountLinkingApiView): Promise<SingleEntityResponseApiView<IAccountApiView>> {
    const response = await fetchApi('accounts/my', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IAccountApiView>;

    return body;
  }

  async getById(wotId: number, server: WotServer): Promise<SingleEntityResponseApiView<IAccountApiView>> {
    const response = await fetchApi(`accounts/${server}/${wotId}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IAccountApiView>;

    return body;
  }

  async add(wotId: number, server: WotServer): Promise<SingleEntityResponseApiView<IAccountApiView>> {
    const response = await fetchApi(`accounts/${server}/${wotId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IAccountApiView>;

    return body;
  }

  async searchKnown(subject: 'name' | 'wotId' | 'trovo', key: string): Promise<ListResponseApiView<IAccountShortApiView>> {
    const response = await fetchApi(`accounts/search?subject=${subject}&key=${key}`, {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<IAccountShortApiView>;

    return body;
  }
}
