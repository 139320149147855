import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { IAccountTankApiView } from '../../../services/api/interfaces/account-tanks.interface';
import { IAccountApiView } from '../../../services/api/interfaces/account.interface';
import { IServerStatisticsApiView } from '../../../services/api/interfaces/server-statistics.interface';
import { FilterState } from '../../filter/redux/filterSlice';

export interface BattleLifeTimeStatisticsState {
  accountBattleLifeTimeAccurate?: boolean;
  accountBattleLifeTime?: number;
  accountBattleLifeTimeFiltered?: number;
  accountBattleLifeTimeServer?: number;
  accountTanksBattleLifeTime: Array<{ wotId: number; battleLifeTime: number; battles: number }>;
}

const InitialState: BattleLifeTimeStatisticsState = {
  accountBattleLifeTimeAccurate: undefined,
  accountBattleLifeTime: undefined,
  accountBattleLifeTimeFiltered: undefined,
  accountBattleLifeTimeServer: undefined,
  accountTanksBattleLifeTime: [],
};

export const battleLifeTimeStatisticsSlice = createSlice({
  name: 'battleLifeTimeStatistics',
  initialState: InitialState,
  reducers: {
    resetBattleLifeTimeStatistics: () => InitialState,
    setBattleLifeTime: (state, action: PayloadAction<{ accountTanks: IAccountTankApiView[]; account: IAccountApiView | undefined }>) => {
      let totalTanksLifeTime = undefined;
      let totalTanksBattles = undefined;

      const accountTanksBattleLifeTime = [];
      for (const accountTank of action.payload.accountTanks) {
        if (accountTank.snapshots.length === 0) {
          continue;
        }

        const battleLifeTime = accountTank.snapshots[accountTank.snapshots.length - 1].battleLifeTime;
        totalTanksLifeTime = totalTanksLifeTime == null ? battleLifeTime : totalTanksLifeTime + battleLifeTime;

        // rating battles should be included but are not
        const battles = accountTank.snapshots[accountTank.snapshots.length - 1].regular.battles;
        totalTanksBattles = totalTanksBattles == null ? battles : totalTanksBattles + battles;

        if (battles === 0) {
          continue;
        }

        accountTanksBattleLifeTime.push({
          wotId: accountTank.wotId,
          battleLifeTime: battleLifeTime / battles,
          battles,
        });
      }
      state.accountTanksBattleLifeTime = accountTanksBattleLifeTime;

      if (action.payload.account == null || action.payload.account.snapshots.length === 0) {
        state.accountBattleLifeTime = undefined;
        state.accountBattleLifeTimeAccurate = undefined;
        return;
      }

      if (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].private?.battleLifeTime == null) {
        if (totalTanksLifeTime != null && totalTanksBattles != null && totalTanksLifeTime !== 0 && totalTanksBattles !== 0) {
          state.accountBattleLifeTime = totalTanksLifeTime / totalTanksBattles;
          state.accountBattleLifeTimeAccurate = false;
        } else {
          state.accountBattleLifeTime = undefined;
          state.accountBattleLifeTimeAccurate = undefined;
        }
      } else {
        state.accountBattleLifeTime =
          (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].private?.battleLifeTime ?? 0) /
          (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].regular.battles +
            (action.payload.account.snapshots[action.payload.account.snapshots.length - 1].rating?.battles ?? 0));
        state.accountBattleLifeTimeAccurate = true;
      }
    },
    setBattleLifeTimeFiltered: (
      state,
      action: PayloadAction<{
        accountTanksFiltered: IAccountTankApiView[];
        accountFiltered: IAccountApiView | undefined;
        filter: FilterState;
      }>,
    ) => {
      const filteredByDate = action.payload.filter.timeRange !== 'all';
      const filteredByTank =
        (action.payload.filter.tankTypes.length !== 0 && action.payload.filter.tankTypes.length !== 4) ||
        (action.payload.filter.tankTiers.length !== 0 && action.payload.filter.tankTiers.length !== 10);

      if (
        action.payload.accountFiltered == null ||
        action.payload.accountFiltered.snapshots.length === 0 ||
        (filteredByDate === true && action.payload.accountFiltered.snapshots.length < 2)
      ) {
        state.accountBattleLifeTimeFiltered = undefined;
        return;
      }

      const firstSnapshot = action.payload.accountFiltered.snapshots[0];
      const lastSnapshot = action.payload.accountFiltered.snapshots[action.payload.accountFiltered.snapshots.length - 1];

      if (filteredByTank === false && filteredByDate === false && lastSnapshot.private?.battleLifeTime != null) {
        state.accountBattleLifeTimeFiltered =
          lastSnapshot.private.battleLifeTime / (lastSnapshot.regular.battles + (lastSnapshot.rating?.battles ?? 0));
      } else if (
        filteredByTank === false &&
        filteredByDate === true &&
        firstSnapshot.private?.battleLifeTime != null &&
        lastSnapshot.private?.battleLifeTime != null
      ) {
        state.accountBattleLifeTimeFiltered =
          (lastSnapshot.private.battleLifeTime - firstSnapshot.private.battleLifeTime) /
          (lastSnapshot.regular.battles -
            firstSnapshot.regular.battles +
            (lastSnapshot.rating?.battles ?? 0) -
            (firstSnapshot.rating?.battles ?? 0));
      } else {
        let totalTanksLifeTime = undefined;
        let totalTanksBattles = undefined;

        for (const accountTank of action.payload.accountTanksFiltered) {
          if (accountTank.snapshots.length === 0 || (filteredByDate === true && accountTank.snapshots.length < 2)) {
            continue;
          }

          const firstSnapshot = accountTank.snapshots[0];
          const lastSnapshot = accountTank.snapshots[accountTank.snapshots.length - 1];

          const battleLifeTime =
            filteredByDate === true ? lastSnapshot.battleLifeTime - firstSnapshot.battleLifeTime : lastSnapshot.battleLifeTime;
          totalTanksLifeTime = totalTanksLifeTime == null ? battleLifeTime : totalTanksLifeTime + battleLifeTime;

          // rating battles should be included but are not
          const battles =
            filteredByDate === true ? lastSnapshot.regular.battles - firstSnapshot.regular.battles : lastSnapshot.regular.battles;
          totalTanksBattles = totalTanksBattles == null ? battles : totalTanksBattles + battles;
        }

        if (totalTanksLifeTime != null && totalTanksBattles != null && totalTanksLifeTime !== 0 && totalTanksBattles !== 0) {
          state.accountBattleLifeTimeFiltered = totalTanksLifeTime / totalTanksBattles;
        } else {
          state.accountBattleLifeTimeFiltered = undefined;
        }
      }
    },
    setBattleLifeTimeServer: (state, action: PayloadAction<{ serverStats: IServerStatisticsApiView | undefined }>) => {
      if (
        action.payload.serverStats == null ||
        action.payload.serverStats.account.statistics.battleLifeTime.time === 0 ||
        action.payload.serverStats.account.statistics.battleLifeTime.battles === 0
      ) {
        state.accountBattleLifeTimeServer = undefined;
        return;
      }

      state.accountBattleLifeTimeServer =
        action.payload.serverStats.account.statistics.battleLifeTime.time /
        action.payload.serverStats.account.statistics.battleLifeTime.battles;
    },
  },
});

export const { resetBattleLifeTimeStatistics, setBattleLifeTime, setBattleLifeTimeServer, setBattleLifeTimeFiltered } =
  battleLifeTimeStatisticsSlice.actions;

export const selectAccountBattleLifeTime = (state: RootState) => state.statistics.battleLifeTimeStatistics.accountBattleLifeTime;
export const selectAccountBattleLifeTimeFiltered = (state: RootState) =>
  state.statistics.battleLifeTimeStatistics.accountBattleLifeTimeFiltered;
export const selectAccountBattleLifeTimeAccurate = (state: RootState) =>
  state.statistics.battleLifeTimeStatistics.accountBattleLifeTimeAccurate;
export const selectAccountBattleLifeTimeServer = (state: RootState) =>
  state.statistics.battleLifeTimeStatistics.accountBattleLifeTimeServer;
export const selectAccountTanksBattleLifeTime = (state: RootState) => state.statistics.battleLifeTimeStatistics.accountTanksBattleLifeTime;

export default battleLifeTimeStatisticsSlice.reducer;
