import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AuthApiService } from '../../services/api/auth-api.service';
import { getUserMeAsync } from '../user/userSlice';
import { LogService } from '../../services/log.service';
import { showToast } from '../toast/redux/toastSlice';
import { useNavigate, useParams } from 'react-router-dom';

export function ResetPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { token } = useParams();

  const [password, setPassword] = useState<string>('');

  const handleResetPassword = async () => {
    const svc = new AuthApiService();
    const response = await svc.resetPassword(token ?? '', password);

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      if (response.data != null) {
        localStorage.setItem('jwt', response.data);
        LogService.debug('Registered');
        dispatch(getUserMeAsync());
        navigate('/');
      }
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t('ResetPassword.CardTitle', 'Reset password')}</Card.Title>

        <Form>
          <Form.Control type="email" className="mb-3" autoComplete="email" hidden />

          <Form.Control
            type="password"
            placeholder={t('ResetPassword.PasswordPlaceholder', 'Password')}
            className="mb-3"
            autoComplete="new-password"
            required
            isInvalid={!password}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button
            variant="primary"
            disabled={!password}
            onClick={() => {
              void handleResetPassword();
            }}
          >
            {t('ResetPassword.ResetButton', 'Reset')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
