import fetchApi from './fetch-api';
import { ListResponseApiView, SingleEntityResponseApiView } from './interfaces/response.interface';
import {
  ITournamentAccountsApiView,
  ITournamentBonusesApiView,
  ITournamentShortApiView,
  TournamentSep2023Stats,
} from './interfaces/tournament.interface';

export class TournamentApiService {
  async getList(active = true): Promise<ListResponseApiView<ITournamentShortApiView>> {
    const response = await fetchApi(`tournaments?active=${active ? 'true' : 'false'}`, {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<ITournamentShortApiView>;
    return body;
  }

  async getAccounts(id: string): Promise<SingleEntityResponseApiView<ITournamentAccountsApiView>> {
    const response = await fetchApi(`tournaments/${id}/accounts`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentAccountsApiView>;
    return body;
  }

  async getBonuses(id: string): Promise<SingleEntityResponseApiView<ITournamentBonusesApiView>> {
    const response = await fetchApi(`tournaments/${id}/bonuses`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITournamentBonusesApiView>;
    return body;
  }

  async getStats(id: string): Promise<SingleEntityResponseApiView<TournamentSep2023Stats>> {
    const response = await fetchApi(`tournaments/${id}/stats`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<TournamentSep2023Stats>;
    return body;
  }
}
