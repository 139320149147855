import { Row, Col, Stack, Button, Tabs, Tab, Image, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TournamentDetailsSep2023Battles } from './TournamentDetailsSep2023Battles';
import { TournamentDetailsSep2023Bonuses } from './TournamentDetailsSep2023Bonuses';
import { TournamentDetailsSep2023Total } from './TournamentDetailsSep2023Total';
import TrovoImg from '../../../../../images/tournament/trovo.png';
import TelegramImg from '../../../../../images/tournament/telegram.png';
import { useEffect, useState } from 'react';
import { selectTournamentAccounts } from '../../../tournamentsSlice';
import { useSelector } from 'react-redux';

export function TournamentDetailsSep2023() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tournamentAccounts = useSelector(selectTournamentAccounts);

  const [bannerType, setBannerType] = useState<'trovo' | 'telegram'>('trovo');

  useEffect(() => {
    setBannerType(Date.now() % 2 === 0 ? 'trovo' : 'telegram');
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Stack direction="horizontal">
            <Button onClick={() => navigate(`/tournaments`)} variant="link">
              <ArrowLeftCircle size={35} />
            </Button>
            <h1 className="ms-3">{tournamentAccounts?.name}</h1>
          </Stack>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Tabs defaultActiveKey="total" className="mb-3" transition={false}>
            <Tab eventKey="total" title={t('TournamentDetails.TotalTab', 'Total')}>
              <TournamentDetailsSep2023Total />
            </Tab>
            <Tab eventKey="battles" title={t('TournamentDetails.BattlesTab', 'Battles')}>
              <TournamentDetailsSep2023Battles />
            </Tab>
            <Tab eventKey="bonuses" title={t('TournamentDetails.BonusesTab', 'Bonuses')}>
              <TournamentDetailsSep2023Bonuses />
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <Row>
        <Col className="tournament-banner-container">
          <a
            href={
              bannerType === 'telegram'
                ? 'https://t.me/BEAST_WB?utm_source=beastwot.com'
                : 'https://trovo.live/Beast_WoT?utm_source=beastwot.com'
            }
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={bannerType === 'telegram' ? TelegramImg : TrovoImg}
              alt={bannerType === 'telegram' ? 'Telegram' : 'Trovo'}
              className="tournament-banner"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
