import '../../Widget.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WotApiService } from '../../../../services/api/wot-api.service';
import { WotServer } from '../../../../utils/wot-server.type';
import { LogService } from '../../../../services/log.service';
import { sleep } from '../../../../utils/sleep.util';
import { useSelector } from 'react-redux';
import { selectSubscriptionValid } from '../../../subscription/redux/subscriptionSlice';
import CrabImg from '../../../../images/crab.gif';

export function WidgetBlitzSession() {
  const { t } = useTranslation();
  const { search } = useLocation();

  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [current, setCurrent] = useState<{ battles: number; wins: number; damage: number }>({ battles: 0, wins: 0, damage: 0 });

  const [session, setSession] = useState<{ battles: number; winRate: number; avgDamage: number }>({
    battles: 0,
    winRate: 0,
    avgDamage: 0,
  });

  const [query, setQuery] = useState<{
    wotAccountId: number;
    wotAccountServer: string;
    battles: number;
    wins: number;
    damage: number;
    showSiteName: boolean;
  }>({ wotAccountId: 0, wotAccountServer: '', battles: 0, wins: 0, damage: 0, showSiteName: false });

  const [showingSiteName, setShowingSiteName] = useState<boolean>(false);

  // parse query string
  useEffect(() => {
    const url = new URLSearchParams(search);

    const wotAccountIdQuery = url.get('wotAccountId');
    const wotAccountServerQuery = url.get('wotAccountServer');
    const battlesQuery = url.get('battles');
    const winsQuery = url.get('wins');
    const damageQuery = url.get('damage');
    const showSiteNameQuery = url.get('showSiteName');

    const battles = battlesQuery == null ? 0 : +battlesQuery;
    const wins = winsQuery == null ? 0 : +winsQuery;
    const damage = damageQuery == null ? 0 : +damageQuery;
    const showSiteName = showSiteNameQuery === 'false' ? false : true;

    setQuery({
      wotAccountId: wotAccountIdQuery == null ? 0 : +wotAccountIdQuery,
      wotAccountServer: wotAccountServerQuery == null ? '' : wotAccountServerQuery,
      battles,
      wins,
      damage,
      showSiteName,
    });

    setCurrent({ battles, wins, damage });
  }, [search]);

  // get stats
  useEffect(() => {
    const getStats = async () => {
      try {
        const svc = new WotApiService();
        const response = await svc.getAccountStats(query.wotAccountId, query.wotAccountServer as WotServer, [
          'statistics.all.battles',
          'statistics.rating.battles',
          'statistics.all.wins',
          'statistics.rating.wins',
          'statistics.all.damage_dealt',
          'statistics.rating.damage_dealt',
        ]);

        if (response.status === 'ok' && response.data != null) {
          setCurrent({
            battles: response.data.statistics.all.battles + (response.data.statistics.rating?.battles ?? 0),
            wins: response.data.statistics.all.wins + (response.data.statistics.rating?.wins ?? 0),
            damage: response.data.statistics.all.damage_dealt + (response.data.statistics.rating?.damage_dealt ?? 0),
          });
        } else {
          LogService.error(`Error getting stats for account ${query.wotAccountId} on server ${query.wotAccountServer}`);
          LogService.error(JSON.stringify(response));
        }
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    const interval = setInterval(() => {
      void getStats();
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [query.wotAccountId, query.wotAccountServer]);

  // update session
  useEffect(() => {
    const battles = current.battles - query.battles;

    if (battles <= 0) {
      return;
    }

    const winsDiff = current.wins - query.wins;
    const winRate = (winsDiff * 100) / battles;

    const damageDiff = current.damage - query.damage;
    const avgDamage = damageDiff / battles;

    setSession({ battles, winRate, avgDamage });
  }, [query.battles, query.damage, query.wins, current.battles, current.damage, current.wins]);

  // show site name
  useEffect(() => {
    const run = async () => {
      if (showingSiteName === true) {
        if (isValidSubscription === true && query.showSiteName === false) {
          setShowingSiteName(false);
        } else {
          await sleep(1000 * 5);
          setShowingSiteName(false);
        }
      }
    };

    void run();
  }, [query.showSiteName, showingSiteName, isValidSubscription]);

  useEffect(() => {
    const run = async () => {
      if (showingSiteName === false) {
        if (isValidSubscription !== true || query.showSiteName === true) {
          await sleep(1000 * 30);
          setShowingSiteName(true);
        }
      }
    };

    void run();
  }, [query.showSiteName, showingSiteName, isValidSubscription]);

  return (
    <div className="widget-container mb-n3">
      {!showingSiteName && (
        <p>
          <span style={{ margin: '1rem' }}>
            {t('WidgetBlitzSession.Battles', 'Battles')}: {session.battles}
          </span>
          <wbr />
          <span style={{ margin: '1rem' }}>
            {t('WidgetBlitzSession.Damage', 'Damage')}: {session.avgDamage.toFixed(0)}
          </span>
          <wbr />
          <span style={{ margin: '1rem' }}>
            {t('WidgetBlitzSession.Wins', 'Wins')}: {session.winRate.toFixed(2)}%
          </span>
        </p>
      )}
      {showingSiteName && (
        <p>
          <img src={CrabImg} alt="crab" className="me-2"></img>
          <span>beastwot.com</span>
          <img src={CrabImg} alt="crab" className="ms-2"></img>
        </p>
      )}
    </div>
  );
}
