import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IUserApiView } from './interfaces/user.interface';

export class UserApiService {
  async getMe(): Promise<SingleEntityResponseApiView<IUserApiView> | null> {
    const response = await fetchApi('users/me', {
      method: 'GET',
    });

    if (response.status === 401) {
      return null;
    }

    const body = (await response.json()) as SingleEntityResponseApiView<IUserApiView>;
    return body;
  }
}
