import { Row, Col, Stack, Button, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTanks } from '../../tanks/tanksSlice';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import {
  selectTankChart,
  selectTankStats,
  selectTankStatsFiltered,
  setTankStats,
  setTankStatsFiltered,
} from '../../statistics/redux/coreStatisticsSlice';
import {
  selectAccountTanks,
  selectAccountTanksFiltered,
  selectSelectedAccountTank,
  setSelectedAccountTank,
} from '../../account/redux/accountTanksSlice';
import { useEffect, useState } from 'react';
import { Filter } from '../../filter/Filter';
import { BattlesCard } from '../../components/statistics-cards/Battles';
import { DamageCard } from '../../components/statistics-cards/Damage';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { BattleStyleCard } from '../../components/statistics-cards/BattleStyle';
import { WinsCard } from '../../components/statistics-cards/Wins';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { IServerTankStatistics } from '../../../services/api/interfaces/server-statistics.interface';
import { selectFilter } from '../../filter/redux/filterSlice';
import { OverviewCard } from '../../components/statistics-cards/Overview';
import { WotServer } from '../../../utils/wot-server.type';
import { selectAccount } from '../../account/redux/accountsSlice';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { TankStatsAd } from './TankStatsAd';

export function HangarTank() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { wotId, server, tankWotId } = useParams<{ wotId: string; server: WotServer; tankWotId: string }>();

  const tankStats = useSelector(selectTankStats);
  const tankStatsFiltered = useSelector(selectTankStatsFiltered);
  const tankChart = useSelector(selectTankChart);

  const filter = useSelector(selectFilter);

  const tanks = useSelector(selectTanks);

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));

  const accountTanks = useSelector(selectAccountTanks);
  const accountTanksFiltered = useSelector(selectAccountTanksFiltered);
  const selectedAccountTank = useSelector(selectSelectedAccountTank);

  const [selectedTank, setSelectedTank] = useState<ITankApiView | undefined>();
  const [selectedTankServerStats, setSelectedTankServerStats] = useState<IServerTankStatistics | undefined>();

  useEffect(() => {
    if (tankWotId == null || tankWotId === '') {
      dispatch(setSelectedAccountTank(undefined));
    } else {
      dispatch(setSelectedAccountTank(accountTanks.find((t) => `${t.wotId}` === tankWotId)));
    }
  }, [accountTanks, dispatch, tankWotId]);

  useEffect(() => {
    dispatch(setTankStats(selectedAccountTank));
  }, [dispatch, selectedAccountTank]);

  useEffect(() => {
    dispatch(
      setTankStatsFiltered({
        accountTank: accountTanksFiltered.find((t) => t.wotId === selectedAccountTank?.wotId),
        filterTimeRange: filter.timeRange,
      }),
    );
  }, [accountTanksFiltered, dispatch, filter.timeRange, selectedAccountTank?.wotId]);

  useEffect(() => {
    if (selectedAccountTank != null) {
      setSelectedTank(tanks.find((t) => t.wotId === selectedAccountTank.wotId));
    } else {
      setSelectedTank(undefined);
    }
  }, [selectedAccountTank, tanks]);

  useEffect(() => {
    if (serverStats != null && selectedAccountTank != null) {
      setSelectedTankServerStats(serverStats.tanks.find((t) => t.wotId === selectedAccountTank.wotId));
    } else {
      setSelectedTankServerStats(undefined);
    }
  }, [selectedAccountTank, serverStats]);

  return (
    <Container fluid>
      <Helmet>
        <title>
          {t('HangarTank.Title', 'Wargaming WoT Blitz Beast - {{tank}} statistics and charts', {
            tank: i18n.language === 'ru' || i18n.language === 'uk' ? selectedTank?.name.ru ?? selectedTank?.name.en : selectedTank?.name.en,
          })}
        </title>
      </Helmet>

      <Row>
        <Col>
          <Stack direction="horizontal">
            <Button onClick={() => navigate(`/accounts/${server ?? ''}/${wotId ?? ''}/hangar`)} variant="link">
              <ArrowLeftCircle size={35} />
            </Button>
            <h1 className="ms-3">
              {i18n.language === 'ru' || i18n.language === 'uk' ? selectedTank?.name.ru ?? selectedTank?.name.en : selectedTank?.name.en}
            </h1>
          </Stack>
        </Col>
        <Col>
          <Filter hideTankFilters hideBattlesCountLimit />
        </Col>
      </Row>

      <Row>
        <Col className="dashboard-column">
          <OverviewCard stats={tankStats.stats} tank={selectedTank} />
          <DamageCard stats={tankStats.stats} statsFiltered={tankStatsFiltered.stats} chart={tankChart.chart} tank={selectedTank} />
        </Col>

        <Col className="dashboard-column">
          <WinsCard
            stats={tankStats.stats}
            statsFiltered={tankStatsFiltered.stats}
            chart={tankChart.chart}
            serverStats={selectedTankServerStats?.statistics}
          />
          <BattlesCard
            stats={tankStats.stats}
            statsFiltered={tankStatsFiltered.stats}
            filteredSnapshotsLength={tankStatsFiltered.snapshotsLength}
            chart={tankChart.chart}
            tank={selectedTank}
          />
        </Col>

        <Col className="dashboard-column">
          <TankStatsAd />
          <BattleStyleCard stats={tankStats.stats} statsFiltered={tankStatsFiltered.stats} chart={tankChart.chart} tank={selectedTank} />
        </Col>
      </Row>
    </Container>
  );
}
