import { Button, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITournamentShortApiView } from '../../../../services/api/interfaces/tournament.interface';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../user/userSlice';
import GoldImage from '../../../../images/tournament/gold5.png';
import { selectTournamentStats } from '../../tournamentsSlice';

export function TournamentsListItem(props: { tournament: ITournamentShortApiView }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const stats = useSelector(selectTournamentStats(props.tournament.id));

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{props.tournament.name}</h2>
      </Card.Header>

      <Card.Body>
        <Card.Img variant="top" src={GoldImage} className="mb-3" />

        <Card.Text>{props.tournament.description}</Card.Text>

        <Card.Text>
          <a href="https://www.youtube.com/watch?v=cUdW349LaAA?utm_source=beastwot.com" target="_blank" rel="noopener noreferrer">
            <strong>{t('TournamentsList.VideoInstruction', 'See the event instruction video!!!')}</strong>
          </a>
        </Card.Text>

        <Card.Text>
          <p>{t('TournamentsList.EventResults', 'Current results: ')}</p>
          <Table bordered hover responsive size="sm" className="table-normal-header">
            <thead>
              <tr>
                <th></th>
                <th>V</th>
                <th>VI</th>
                <th>VII</th>
                <th>VIII</th>
                <th>IX</th>
                <th>X</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('TournamentsList.PartsRow', 'Parts')}</td>
                <td>{stats?.parts5 ?? 0}</td>
                <td>{stats?.parts6 ?? 0}</td>
                <td>{stats?.parts7 ?? 0}</td>
                <td>{stats?.parts8 ?? 0}</td>
                <td>{stats?.parts9 ?? 0}</td>
                <td>{stats?.parts10 ?? 0}</td>
              </tr>
              <tr>
                <td>{t('TournamentsList.TanksRow', 'Tanks')}</td>
                <td>{stats?.tanks5 ?? 0}</td>
                <td>{stats?.tanks6 ?? 0}</td>
                <td>{stats?.tanks7 ?? 0}</td>
                <td>{stats?.tanks8 ?? 0}</td>
                <td>{stats?.tanks9 ?? 0}</td>
                <td>{stats?.tanks10 ?? 0}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Text>

        <Button
          variant="primary"
          onClick={() => {
            if (user) {
              navigate(`/tournaments/${props.tournament.id}`);
            } else {
              navigate(`/login`);
            }
          }}
        >
          {t('TournamentsList.DetailsButton', 'Details')}
        </Button>
      </Card.Body>
    </Card>
  );
}
