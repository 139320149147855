import { WOT_CLIENT_APPLICATION_ID } from '../../configs/config';
import { IWotApiAccountSearch, IWotApiAccountStats, IWotApiAccountTankStats } from './interfaces/wot-api-account-info.interface';
import { IWotApiResponse } from './interfaces/wot-api.interface';
import fetch from 'cross-fetch';
import { WotServer } from '../../utils/wot-server.type';
import { IWotApiTank } from './interfaces/wot-api-tanks.interface';

export class WotApiService {
  constructor(private readonly applicationId = WOT_CLIENT_APPLICATION_ID) {}

  public async getAccountStats(accountId: number, server: WotServer, fields?: string[]): Promise<IWotApiResponse<IWotApiAccountStats>> {
    let url = `https://api.wotblitz.${server}/wotb/account/info/?application_id=${
      this.applicationId ?? ''
    }&account_id=${accountId}&extra=statistics.rating`;

    if (fields && fields.length > 0) {
      url += `&fields=${fields.join(',')}`;
    }

    const response = await fetch(url, {
      method: 'GET',
    });

    const body = (await response.json()) as IWotApiResponse<{ [key: number]: IWotApiAccountStats }>;

    const result = body as unknown as IWotApiResponse<IWotApiAccountStats>;

    if (result.status === 'ok' && body.data != null) {
      result.data = body.data[+accountId];
    }

    return result;
  }

  public async searchAccount(name: string, server: WotServer): Promise<IWotApiResponse<Array<IWotApiAccountSearch>>> {
    const response = await fetch(
      `https://api.wotblitz.${server}/wotb/account/list/?application_id=${this.applicationId ?? ''}&search=${name}`,
      {
        method: 'GET',
      },
    );

    const result = (await response.json()) as IWotApiResponse<Array<IWotApiAccountSearch>>;

    return result;
  }

  public async getAccountTanksStats(
    accountId: number,
    server: WotServer,
    fields?: string[],
  ): Promise<IWotApiResponse<IWotApiAccountTankStats[]>> {
    let url = `https://api.wotblitz.${server}/wotb/tanks/stats/?application_id=${this.applicationId ?? ''}&account_id=${accountId}`;
    if (fields && fields.length > 0) {
      url += `&fields=${fields.join(',')}`;
    }

    const response = await fetch(url, {
      method: 'GET',
    });

    const body = (await response.json()) as IWotApiResponse<{ [key: number]: IWotApiAccountTankStats[] }>;

    const result = body as unknown as IWotApiResponse<IWotApiAccountTankStats[]>;

    if (body.status === 'ok' && body.data != null) {
      result.data = body.data[+accountId];
    }

    return result;
  }

  public async getTanks(server: WotServer, fields?: string[], lang?: 'ru' | 'en'): Promise<IWotApiResponse<IWotApiTank[]>> {
    let url = `https://api.wotblitz.${server}/wotb/encyclopedia/vehicles/?application_id=${this.applicationId ?? ''}`;

    if (fields && fields.length > 0) {
      url += `&fields=${fields.join(',')}`;
    }

    if (lang) {
      url += `&language=${lang}`;
    }

    const response = await fetch(url, {
      method: 'GET',
    });

    const body = (await response.json()) as IWotApiResponse<{ [key: number]: IWotApiTank }>;
    const data: IWotApiTank[] = [];

    if (body.status === 'ok' && body.data != null) {
      for (const key in body.data) {
        data.push(body.data[+key]);
      }
    }

    const result = body as unknown as IWotApiResponse<IWotApiTank[]>;
    result.data = data;

    return result;
  }
}
