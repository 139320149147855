import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectUser } from '../../user/userSlice';
import { useNavigate } from 'react-router-dom';
import { CLIENT_URL, TROVO_CLIENT_ID } from '../../../configs/config';
import { TrovoApiService } from '../../../services/api/trovo-api.service';
import { selectAccount } from '../../account/redux/accountsSlice';
import { LogService } from '../../../services/log.service';

export function TrovoLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);

  useEffect(() => {
    if (user == null || account == null) {
      navigate('/');
    }
  }, [account, navigate, user]);

  const login = async () => {
    if (account == null) {
      return;
    }

    const svc = new TrovoApiService();
    const state = await svc.getUserTokenState(account.server, account.wotId);

    if (state.success === false || state.data == null) {
      LogService.error('Failed to get user token state');
      LogService.error(JSON.stringify(state), true);
      return;
    }

    const url = `https://open.trovo.live/page/login.html?client_id=${
      TROVO_CLIENT_ID ?? ''
    }&response_type=token&scope=user_details_self&redirect_uri=${CLIENT_URL ?? ''}/trovo-callback&state=${state.data}`;

    window.location.replace(url);
  };

  return (
    <Button
      type="button"
      onClick={() => {
        void login();
      }}
    >
      {t('TrovoLogin.Button', 'Login with Trovo')}
    </Button>
  );
}
