import './Footer.scss';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CLIENT_URL, VERSION } from '../../configs/config';
import VisaLogo from '../../images/payments/visa.svg';
import MastercardLogo from '../../images/payments/mastercard.svg';
import GooglePayLogo from '../../images/payments/googlepay.svg';
import ApplePayLogo from '../../images/payments/applepay.svg';
import TelegramLogo from '../../images/social/telegram.svg';
import TikTokLogo from '../../images/social/tiktok.svg';
import YoutubeLogo from '../../images/social/youtube.svg';

export function Footer() {
  const { i18n, t } = useTranslation();

  return (
    <>
      <div className="footer-container">
        <div className="footer-container-item footer-container-item-first">
          <p>{t('Footer.LanguageHeader', 'Language')}</p>

          <p className="mb-0">
            <Button
              variant="link"
              onClick={() => {
                void i18n.changeLanguage('en');
                document.documentElement.lang = 'en';
              }}
            >
              {t('Footer.LanguageEnglish', 'English')}
            </Button>
          </p>

          <p className="mb-0">
            <Button
              variant="link"
              onClick={() => {
                void i18n.changeLanguage('uk');
                document.documentElement.lang = 'uk';
              }}
            >
              {t('Footer.LanguageUkrainian', 'Ukrainian')}
            </Button>
          </p>

          <p className="mb-0">
            <Button
              variant="link"
              onClick={() => {
                void i18n.changeLanguage('ru');
                document.documentElement.lang = 'ru';
              }}
            >
              {t('Footer.LanguageRussian', 'Russian')}
            </Button>
          </p>
        </div>

        <div className="footer-container-item">
          <p>{t('Footer.InfoHeader', 'Info')}</p>

          <p className="mb-4">
            <a target="_blank" href={`${CLIENT_URL ?? ''}/docs/agreement_${i18n.language}.html`} rel="noreferrer">
              {t('Footer.TermsAndConditions', 'Terms and Conditions')}
            </a>
          </p>

          <p className="mb-4">
            <a target="_blank" href={`${CLIENT_URL ?? ''}/docs/policy_${i18n.language}.html`} rel="noreferrer">
              {t('Footer.CookiePolicy', 'Cookie Policy')}
            </a>
          </p>
        </div>

        <div className="footer-container-item">
          <p>{t('Footer.PaymentSystemsHeader', 'Payment Systems')}</p>

          <img src={VisaLogo} className="payment-system-logo" alt={t('Footer.PaymentsVisa', 'Visa')} />
          <img src={MastercardLogo} className="payment-system-logo" alt={t('Footer.PaymentsMastercard', 'Mastercard')} />
          <img src={GooglePayLogo} className="payment-system-logo" alt={t('Footer.PaymentsGooglePay', 'Google Pay')} />
          <img
            src={ApplePayLogo}
            className="payment-system-logo payment-system-logo-last"
            alt={t('Footer.PaymentsApplePay', 'Apple Pay')}
          />
        </div>

        <div className="footer-container-item">
          <p>{t('Footer.ContactsHeader', 'Contacts')}</p>

          <a href="https://t.me/BEAST_WB?utm_source=beastwot.com" target="_blank" rel="noreferrer">
            <img src={TelegramLogo} className="social-media-logo" alt={t('Footer.ContactsTelegram', 'Telegram')} />
          </a>

          <a href="https://www.tiktok.com/@beast_wot?utm_source=beastwot.com" target="_blank" rel="noreferrer">
            <img src={TikTokLogo} className="social-media-logo" alt={t('Footer.ContactsTikTok', 'TikTok')} />
          </a>

          <a href="https://www.youtube.com/BeastWorldoftanksblitz?utm_source=beastwot.com" target="_blank" rel="noreferrer">
            <img src={YoutubeLogo} className="social-media-logo" alt={t('Footer.ContactsYoutube', 'Youtube')} />
          </a>

          <p className="mt-3">
            <a href="mailto:beast.wot.app@gmail.com" target="_blank" rel="noreferrer">
              beast.wot.app@gmail.com
            </a>
          </p>

          <p className="mt-3">
            <a href="https://t.me/+RnwcS2QLOGhhMmVi" target="_blank" rel="noreferrer">
              Help
            </a>
          </p>
        </div>
      </div>

      <p className="version-container fw-lighter">v{VERSION}</p>
    </>
  );
}
