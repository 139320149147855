import { useEffect, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionAsync, selectSubscriptionValid } from './redux/subscriptionSlice';
import { Helmet } from 'react-helmet';
import { sleep } from '../../utils/sleep.util';
import { useNavigate } from 'react-router-dom';

export function SubscriptionWaiting() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [progress, setProgress] = useState(30);

  useEffect(() => {
    const run = async () => {
      while (isValidSubscription !== true) {
        setProgress(progress + 5);
        dispatch(getSubscriptionAsync);
        await sleep(1000);
      }
    };

    void run();
  }, [dispatch, isValidSubscription, progress]);

  useEffect(() => {
    if (isValidSubscription === true) {
      navigate('/');
    }
  }, [isValidSubscription, navigate]);

  return (
    <Container>
      <Helmet>
        <title>{t('SubscriptionWaiting.Title', 'Wargaming WoT Blitz Beast - Subscription waiting')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('SubscriptionWaiting.Header', 'Waiting for payment...')}</h1>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col lg="5">
          <ProgressBar animated now={progress} max={100} />
        </Col>
      </Row>
    </Container>
  );
}
