import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  selectFilter,
  setFilterTankTiers,
  setFilterTankTypes,
  setFilterTimeRange,
  setTankBattleCountLimit,
  TimeRanges,
} from './redux/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TankTiers, TankTypes } from '../../services/api/interfaces/tank.interface';
import { ReactComponent as HtIcon } from '../../images/heavy-icon.svg';
import { ReactComponent as MtIcon } from '../../images/med-icon.svg';
import { ReactComponent as LtIcon } from '../../images/light-icon.svg';
import { ReactComponent as AtIcon } from '../../images/td-icon.svg';
import { selectAccount } from '../account/redux/accountsSlice';

export function Filter(props: { hideTankFilters?: boolean; hideTimeFilter?: boolean; hideBattlesCountLimit?: boolean }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = useSelector(selectAccount);
  const filter = useSelector(selectFilter);

  const handleTankTypeChange = (types: TankTypes[]) => {
    dispatch(setFilterTankTypes(types));
  };

  const handleTankTierChange = (tiers: TankTiers[]) => {
    if (tiers.includes(3)) {
      if (!tiers.includes(2)) {
        tiers.push(2);
      }

      if (!tiers.includes(1)) {
        tiers.push(1);
      }
    } else {
      tiers = tiers.filter((v) => v !== 2 && v !== 1);
    }

    dispatch(setFilterTankTiers(tiers));
  };

  const handleTimeRangeChange = (range: TimeRanges) => {
    dispatch(setFilterTimeRange({ timeRange: range, accountCreatedAt: account?.createdAt ?? new Date(2020, 0, 1).toISOString() }));
  };

  const handleTankBattleLimitChange = (count: number) => {
    dispatch(setTankBattleCountLimit(count));
  };

  return (
    <div className="filter-container">
      {props.hideTimeFilter !== true && (
        <div>
          <Form.Select onChange={(event) => handleTimeRangeChange(event.target.value as TimeRanges)} value={filter.timeRange}>
            <option value="1w">{t('Filter.TimeRange1w', '1 week')}</option>
            <option value="2w">{t('Filter.TimeRange2w', '2 weeks')}</option>
            <option value="1m">{t('Filter.TimeRange1m', '1 month')}</option>
            <option value="3m">{t('Filter.TimeRange3m', '3 months')}</option>
            <option value="6m">{t('Filter.TimeRange6m', '6 months')}</option>
            <option value="1y">{t('Filter.TimeRange1Y', '1 year')}</option>
            <option value="all">{t('Filter.TimeRangeAll', 'All')}</option>
          </Form.Select>
        </div>
      )}

      {props.hideBattlesCountLimit !== true && (
        <div>
          <Form.Select onChange={(event) => handleTankBattleLimitChange(+event.target.value)} value={filter.limitTankBattleCount}>
            <option value="0">{t('Filter.BattlesFilterLabelAll', 'All')}</option>
            <option value="100">{t('Filter.BattlesFilterLabel', '>{{value}} battles', { value: 100 })}</option>
            <option value="1000">{t('TopAccounts.BattlesFilterLabel', '>{{value}} battles', { value: 1000 })}</option>
          </Form.Select>
        </div>
      )}

      {props.hideTankFilters !== true && (
        <div>
          <ToggleButtonGroup type="checkbox" value={filter.tankTiers} onChange={handleTankTierChange}>
            <ToggleButton id="tbg-btn-3" value={3} variant="outline-secondary">
              <span className="filter-tank-tier">{'<'}III</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-4" value={4} variant="outline-secondary">
              <span className="filter-tank-tier">IV</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-5" value={5} variant="outline-secondary">
              <span className="filter-tank-tier"> V</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-6" value={6} variant="outline-secondary">
              <span className="filter-tank-tier">VI</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-7" value={7} variant="outline-secondary">
              <span className="filter-tank-tier">VII</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-8" value={8} variant="outline-secondary">
              <span className="filter-tank-tier">VIII</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-9" value={9} variant="outline-secondary">
              <span className="filter-tank-tier">IX</span>
            </ToggleButton>
            <ToggleButton id="tbg-btn-10" value={10} variant="outline-secondary">
              <span className="filter-tank-tier">X</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      {props.hideTankFilters !== true && (
        <div>
          <ToggleButtonGroup type="checkbox" value={filter.tankTypes} onChange={handleTankTypeChange}>
            <ToggleButton id="tbg-btn-at" value={'AT-SPG'} variant="outline-secondary">
              <AtIcon />
            </ToggleButton>
            <ToggleButton id="tbg-btn-lt" value={'lightTank'} variant="outline-secondary">
              <LtIcon />
            </ToggleButton>
            <ToggleButton id="tbg-btn-mt" value={'mediumTank'} variant="outline-secondary">
              <MtIcon />
            </ToggleButton>
            <ToggleButton id="tbg-btn-ht" value={'heavyTank'} variant="outline-secondary">
              <HtIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
    </div>
  );
}
