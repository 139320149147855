import './Tournaments.scss';
import { Route, Routes } from 'react-router';
import { TournamentDetails } from './details/TournamentDetails';
import { TournamentsList } from './list/TournamentsList';

export function Tournaments() {
  return (
    <Routes>
      <Route path="/" element={<TournamentsList />} />
      <Route path=":id/*" element={<TournamentDetails />} />
    </Routes>
  );
}
