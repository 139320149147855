import { WotServer } from '../../../../../utils/wot-server.type';

export interface BlitzSessionDetailedWidgetBasicStats {
  wotAccountId: number;
  wotAccountServer: WotServer;
  tanks: Array<{
    id: number;
    battles: number;
    wins: number;
    damage: number;
  }>;
}

export function getBlitzSessionDetailedWidgetBasicStats(
  wotAccountId: number,
  wotServer: WotServer,
): BlitzSessionDetailedWidgetBasicStats | undefined {
  const storageValue = localStorage.getItem('blitz-session-widget-tanks');

  if (storageValue == null || storageValue === '') {
    return undefined;
  }

  const storageValueParsed = JSON.parse(storageValue) as BlitzSessionDetailedWidgetBasicStats[];

  const tankStorageValue = storageValueParsed.find((x) => x.wotAccountId === wotAccountId && x.wotAccountServer === wotServer);

  return tankStorageValue;
}

export function setBlitzSessionDetailedWidgetBasicStats(stats: BlitzSessionDetailedWidgetBasicStats) {
  const storageValue = localStorage.getItem('blitz-session-widget-tanks');

  if (storageValue == null || storageValue === '') {
    localStorage.setItem('blitz-session-widget-tanks', JSON.stringify([stats]));
    return;
  }

  const storageValueParsed = JSON.parse(storageValue) as BlitzSessionDetailedWidgetBasicStats[];

  const tankStorageValue = storageValueParsed.find(
    (x) => x.wotAccountId === stats.wotAccountId && x.wotAccountServer === stats.wotAccountServer,
  );

  if (tankStorageValue == null) {
    storageValueParsed.push(stats);
  } else {
    tankStorageValue.tanks = stats.tanks;
  }

  localStorage.setItem('blitz-session-widget-tanks', JSON.stringify(storageValueParsed));
}
