import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { IServerStatisticsApiView } from '../../../services/api/interfaces/server-statistics.interface';
import { ServerStatisticsApiService } from '../../../services/api/server-statistics-api.service';
import { LogService } from '../../../services/log.service';
import { WotServer, WotServerValues } from '../../../utils/wot-server.type';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';

export interface ServerStatisticsState {
  eu?: IServerStatisticsApiView;
  com?: IServerStatisticsApiView;
  asia?: IServerStatisticsApiView;
}

const InitialState: ServerStatisticsState = {
  eu: undefined,
  com: undefined,
  asia: undefined,
};

export const serverStatisticsSlice = createSlice({
  name: 'serverStatistics',
  initialState: InitialState,
  reducers: {
    resetServerStatistics: () => InitialState,
    setServerStatistics: (state, action: PayloadAction<{ stats: IServerStatisticsApiView; server: WotServer }>) => {
      if (WotServerValues.includes(action.payload.server)) {
        state[action.payload.server] = action.payload.stats;
      }
    },
  },
});

export const { resetServerStatistics, setServerStatistics } = serverStatisticsSlice.actions;

export const getServerStatisticsAsync =
  (server: WotServer): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().statistics.serverStatistics[server] != null) {
        return;
      }

      dispatch(startLoading(LoadingParts.ServerStatistics));

      const svc = new ServerStatisticsApiService();
      const response = await svc.get(server);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setServerStatistics({ server, stats: response.data }));
      }
    } catch (e) {
      LogService.error(`Error while loading server statistics`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.ServerStatistics));
    }
  };

export const selectServerStatistics = (server?: WotServer) => (state: RootState) => {
  return server == null ? undefined : state.statistics.serverStatistics[server];
};

export default serverStatisticsSlice.reducer;
