import fetchApi from './fetch-api';
import { ListResponseApiView } from './interfaces/response.interface';
import { ITankApiView } from './interfaces/tank.interface';

export class TanksApiService {
  async get(): Promise<ListResponseApiView<ITankApiView>> {
    const response = await fetchApi('tanks', {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<ITankApiView>;

    return body;
  }
}
