import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ISettingsApiView } from '../../../services/api/interfaces/settings.interface';
import { SettingsApiService } from '../../../services/api/settings-api.service';
import { LogService } from '../../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';

export interface SettingsState {
  item?: ISettingsApiView | null;
}

const InitialState: SettingsState = {
  item: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: InitialState,
  reducers: {
    resetSettings: () => InitialState,
    setSettings: (state, action: PayloadAction<ISettingsApiView | null>) => {
      state.item = action.payload;
    },
  },
});

export const { resetSettings, setSettings } = settingsSlice.actions;

export const getSettingsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.SettingsLoading));

    const svc = new SettingsApiService();
    const response = await svc.get();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    }

    dispatch(setSettings(response.data));
  } catch (e) {
    LogService.error(`Error while loading settings`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.SettingsLoading));
  }
};

export const updateSettingsAsync =
  (item: ISettingsApiView): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.SettingsSaving));

      const svc = new SettingsApiService();
      const response = await svc.update(item);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      }

      dispatch(setSettings(response.data));
    } catch (e) {
      LogService.error(`Error while saving settings`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.SettingsSaving));
    }
  };

export const selectSettings = (state: RootState) => state.settings.item;

export default settingsSlice.reducer;
