import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getTournamentAsync, selectTournamentAccounts } from '../tournamentsSlice';
import { TournamentDetailsSep2023 } from './variants/sep2023/TournamentDetailsSep2023';

export function TournamentDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const tournamentAccounts = useSelector(selectTournamentAccounts);

  useEffect(() => {
    if (id != null) {
      dispatch(getTournamentAsync(id));
    }
  }, [dispatch, id]);

  // refresh tournament details by interval
  useEffect(() => {
    if (id != null) {
      const interval = setInterval(() => {
        dispatch(getTournamentAsync(id));
      }, 1000 * 60 * 10);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>
          {t('TournamentDetails.Title', 'Wargaming WoT Blitz Beast - {{tournament}}', {
            tournament: tournamentAccounts?.name,
          })}
        </title>
      </Helmet>

      {tournamentAccounts != null && tournamentAccounts.mode === 'sep2023' && <TournamentDetailsSep2023 />}
    </>
  );
}
