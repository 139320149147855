import { useEffect, useState } from 'react';
import { Form, Button, InputGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TrovoApiService } from '../../../../services/api/trovo-api.service';
import { LogService } from '../../../../services/log.service';
import { selectUser } from '../../../user/userSlice';

export function WidgetTrovoManaSettings() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [mana, setMana] = useState<number | undefined>(0);
  const [widgetSettings, setWidgetSettings] = useState<{
    streamingTime?: number;
    additionalMana?: number;
    additionalTime?: number;
  }>({ streamingTime: 120, additionalMana: 500, additionalTime: 1 });

  useEffect(() => {
    const run = async () => {
      try {
        let mana = 0;
        if (user?.id != null && user?.id !== '') {
          const svc = new TrovoApiService();
          const response = await svc.getMana(user.id);
          mana = response.data ?? 0;
        }

        setMana(mana);
      } catch (e) {
        LogService.error(e as Error);
      }
    };

    void run();
  }, [user]);

  const run = () => {
    window
      .open(
        `/widgets/run/trovo/mana?startingMana=${mana ?? 0}&streamingTime=${widgetSettings.streamingTime ?? 0}&additionalMana=${
          widgetSettings.additionalMana ?? 0
        }&additionalTime=${widgetSettings.additionalTime ?? 0}&startingTime=${new Date().toISOString()}&userId=${user?.id ?? ''}`,
        '_blank',
      )
      ?.focus();
  };

  const toNumber = (value: string) => {
    return value == null || value === '' ? undefined : +value;
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoManaSettings.ManaLabel', 'Starting amount of mana')}</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder={t('WidgetTrovoManaSettings.ManaPlaceholder', 'starting mana')}
            required
            value={mana}
            onChange={(event) => setMana(toNumber(event.target.value))}
          />
          <InputGroup.Text>{t('WidgetTrovoManaSettings.ManaPostfix', 'mana')}</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoManaSettings.StreamingTimeLabel', 'Default streaming time')}</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            placeholder={t('WidgetTrovoManaSettings.StreamingTimePlaceholder', 'streaming time')}
            required
            value={widgetSettings?.streamingTime}
            onChange={(event) => setWidgetSettings({ ...widgetSettings, streamingTime: toNumber(event.target.value) })}
          />
          <InputGroup.Text>{t('WidgetTrovoManaSettings.StreamingTimePostfix', 'minutes')}</InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetTrovoManaSettings.AdditionalStreamingTimeLabel', 'Additional streaming time')}</Form.Label>

        <Stack gap={3} direction="horizontal">
          <InputGroup>
            <Form.Control
              type="number"
              placeholder={t('WidgetTrovoManaSettings.AdditionalStreamingTimeManaPlaceholder', 'additional mana')}
              required
              value={widgetSettings?.additionalMana}
              onChange={(event) => setWidgetSettings({ ...widgetSettings, additionalMana: toNumber(event.target.value) })}
            />
            <InputGroup.Text>{t('WidgetTrovoManaSettings.AdditionalStreamingTimeManaPostfix', 'mana')}</InputGroup.Text>
          </InputGroup>

          <InputGroup>
            <Form.Control
              type="number"
              placeholder={t('WidgetTrovoManaSettings.AdditionalStreamingTimeMinutesPlaceholder', 'additional minutes')}
              required
              value={widgetSettings?.additionalTime}
              onChange={(event) => setWidgetSettings({ ...widgetSettings, additionalTime: toNumber(event.target.value) })}
            />
            <InputGroup.Text>{t('WidgetTrovoManaSettings.AdditionalStreamingTimeMinutesPostfix', 'minutes')}</InputGroup.Text>
          </InputGroup>
        </Stack>
      </Form.Group>

      <Button variant="primary" type="submit" onClick={() => run()} className="float-end">
        {t('WidgetTrovoManaSettings.StartButton', 'Start')}
      </Button>
    </>
  );
}
