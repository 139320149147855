export const NODE_ENV = process.env.NODE_ENV as 'production' | 'development' | undefined;
export const VERSION = process.env.REACT_APP_VERSION;
export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const WOT_SERVER_APPLICATION_ID = process.env.REACT_APP_WOT_SERVER_APPLICATION_ID;
export const WOT_CLIENT_APPLICATION_ID = process.env.REACT_APP_WOT_CLIENT_APPLICATION_ID;
export const TROVO_CLIENT_ID = process.env.REACT_APP_TROVO_CLIENT_ID;

// features
export const FEATURE_FREE_CLAN_SEARCH = true;
export const FEATURE_FREE_PLATOON_SEARCH = true;
export const FEATURE_ENABLE_OTHER_LOGIN = false;
