import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Tooltip } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export function LineChart(props: {
  labels: string[];
  datasets: Array<{ label: string; data: Array<number | string | undefined>; borderColor: string; backgroundColor: string }>;
}) {
  return (
    <div className="chart-container">
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top' as const,
            },
          },
          maintainAspectRatio: false,
          spanGaps: true,
          scales: {
            xAxis: {
              ticks: {
                maxTicksLimit: 10,
                autoSkip: true,
              },
            },
          },
        }}
        data={{
          labels: props.labels,
          datasets: props.datasets,
        }}
      />
    </div>
  );
}
