import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LogService } from '../../services/log.service';
import { startLoading, LoadingParts, stopLoading } from '../loading/loadingSlice';
import { IAccountShortApiView } from '../../services/api/interfaces/account.interface';
import { AccountApiService } from '../../services/api/account-api.service';

export function SearchKnownAccount(props: {
  subject: 'name' | 'wotId' | 'trovo';
  onSelected: (selected: IAccountShortApiView) => void;
  selected?: IAccountShortApiView;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [results, setResults] = useState<IAccountShortApiView[]>([]);

  const onSearch = async (query: string) => {
    setResults([]);

    if (query.length > 2) {
      try {
        dispatch(startLoading(LoadingParts.SearchingKnownAccount));
        const accountApiSvc = new AccountApiService();

        const apiResults = await accountApiSvc.searchKnown(props.subject, query);

        if (apiResults.success !== true) {
          LogService.error(`Error while searching known account`);
          LogService.error(apiResults.message ?? 'No message provided');
        } else {
          setResults(apiResults.data);
        }
      } catch (e) {
        LogService.error(`Error while searching account`);
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.SearchingKnownAccount));
      }
    }
  };

  return (
    <Typeahead
      id="search-known-account"
      emptyLabel={t('SearchKnownAccount.EmptyLabel', 'Account not found')}
      placeholder={t('SearchKnownAccount.Placeholder', 'Enter a keyword')}
      paginationText={t('SearchKnownAccount.PaginationText', 'Show more')}
      maxResults={7}
      labelKey={(option) => {
        if (props.subject === 'wotId') {
          return `${(option as IAccountShortApiView).wotId}`;
        } else if (props.subject === 'trovo') {
          return `${(option as IAccountShortApiView).trovoUserInfo?.nickName ?? ''}`;
        } else {
          return (option as IAccountShortApiView).name;
        }
      }}
      options={results}
      onChange={(selected) => props.onSelected(selected[0] as IAccountShortApiView)}
      onInputChange={(text) => void onSearch(text)}
      selected={props.selected != null ? [props.selected] : []}
    />
  );
}
