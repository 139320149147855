import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IChannelInfoResponse, ITrovoAccessToken, ITrovoUserInfo } from './interfaces/trovo.interface';

export class TrovoApiService {
  async getChannel(name: string): Promise<SingleEntityResponseApiView<IChannelInfoResponse>> {
    const response = await fetchApi(`trovo/channels/${name}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IChannelInfoResponse>;
    return body;
  }

  async getMana(userId: string): Promise<SingleEntityResponseApiView<number>> {
    const response = await fetchApi(`trovo/mana/${userId}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<number>;
    return body;
  }

  async getUserTokenState(server: WotServer, wotId: number): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi(`trovo/account/${server}/${wotId}/state`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }

  async linkUserToken(token: ITrovoAccessToken): Promise<SingleEntityResponseApiView<ITrovoUserInfo>> {
    const response = await fetchApi(`trovo/link`, {
      method: 'POST',
      body: JSON.stringify(token),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITrovoUserInfo>;
    return body;
  }

  async unlink(): Promise<SingleEntityResponseApiView<void>> {
    const response = await fetchApi(`trovo/link`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<void>;
    return body;
  }
}
