import { Row, Col, Alert, Container } from 'react-bootstrap';
import { AccountStatsAd } from './components/AccountStatsAd';
import { DamageCard } from '../components/statistics-cards/Damage';
import { BattlesCard } from '../components/statistics-cards/Battles';
import { Filter } from '../filter/Filter';
import { useSelector } from 'react-redux';
import { selectAccountChart, selectAcountStats, selectAcountStatsFiltered } from '../statistics/redux/coreStatisticsSlice';
import { useEffect, useState } from 'react';
import { selectAccount } from './redux/accountsSlice';
import { Trans, useTranslation } from 'react-i18next';
import { selectServerStatistics } from '../statistics/redux/serverStatisticsSlice';
import { BattleStyleCard } from '../components/statistics-cards/BattleStyle';
import { WinsCard } from '../components/statistics-cards/Wins';
import { OverviewCard } from '../components/statistics-cards/Overview';
import { Helmet } from 'react-helmet';
import { selectSubscriptionValid } from '../subscription/redux/subscriptionSlice';
import { useNavigate } from 'react-router-dom';

export function Account() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));
  const accountStats = useSelector(selectAcountStats);
  const accountStatsFiltered = useSelector(selectAcountStatsFiltered);
  const accountChart = useSelector(selectAccountChart);
  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [showLowDataWarning, setShowLowDataWarning] = useState(false);
  const [showDataSyncWarning, setShowDataSyncWarning] = useState(false);

  useEffect(() => {
    setShowLowDataWarning(account?.snapshots.length === 1);
  }, [account?.snapshots.length]);

  useEffect(() => {
    setShowDataSyncWarning(account != null && isValidSubscription !== true);
  }, [account, isValidSubscription]);

  return (
    <Container fluid>
      <Helmet>
        <title>{t('Account.Title', 'Wargaming WoT Blitz Beast - Account statistics and charts')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{account == null ? t('Account.Header', 'Account') : account.name}</h1>
        </Col>
        <Col>
          <Filter hideBattlesCountLimit />
        </Col>
      </Row>

      {showDataSyncWarning && (
        <Row>
          <Col>
            <Alert variant="warning" onClose={() => setShowDataSyncWarning(false)} dismissible className="mt-2">
              <Trans t={t} i18nKey="Account.DataSyncWarning">
                Your account data is synced only when you are visiting the site. Please{' '}
                <button className="link-button" onClick={() => navigate('/subscription')}>
                  make a subscription
                </button>{' '}
                if you would like it to be done automatically.
              </Trans>
            </Alert>
          </Col>
        </Row>
      )}

      {!showDataSyncWarning && showLowDataWarning && (
        <Row>
          <Col>
            <Alert variant="warning" onClose={() => setShowLowDataWarning(false)} dismissible className="mt-2">
              {t(
                'Account.LowDataWarning',
                'The amount of data we know about the account is not enough to calculate stats trends and show charts. Please come back tomorrow to see more!',
              )}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col className="dashboard-column">
          <OverviewCard stats={accountStats.stats} />
          <DamageCard stats={accountStats.stats} statsFiltered={accountStatsFiltered.stats} chart={accountChart.chart} />
        </Col>

        <Col className="dashboard-column">
          <WinsCard
            stats={accountStats.stats}
            statsFiltered={accountStatsFiltered.stats}
            chart={accountChart.chart}
            serverStats={serverStats?.account.statistics}
          />
          <BattlesCard
            stats={accountStats.stats}
            statsFiltered={accountStatsFiltered.stats}
            filteredSnapshotsLength={accountStatsFiltered.snapshotsLength}
            chart={accountChart.chart}
          />
        </Col>

        <Col className="dashboard-column">
          <AccountStatsAd />
          <BattleStyleCard stats={accountStats.stats} statsFiltered={accountStatsFiltered.stats} chart={accountChart.chart} />
        </Col>
      </Row>
    </Container>
  );
}
