import './common.scss';
import styles from './App.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from './features/footer/Footer';
import { Header } from './features/header/Header';
import { Main } from './features/Main';
import { getUserMeAsync, selectUser } from './features/user/userSlice';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { WidgetRuns } from './features/widgets/WidgetRuns';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { getSubscriptionAsync, resetSubscription } from './features/subscription/redux/subscriptionSlice';
import 'flag-icons/css/flag-icons.min.css';
import { GoogleAd } from './features/ads/GoogleAd';

export function App() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [searchParams] = useSearchParams();

  const user = useSelector(selectUser);

  // try to load logged user
  useEffect(() => {
    dispatch(getUserMeAsync());
  }, [dispatch]);

  // load user subscription
  // it should be here because subscription is required for widgets
  useEffect(() => {
    if (user != null) {
      dispatch(getSubscriptionAsync());
    } else {
      dispatch(resetSubscription());
    }
  }, [dispatch, user]);

  // switch language if provided
  useEffect(() => {
    const lang = searchParams.get('lang');
    if (lang != null && (lang.toLowerCase() === 'ru' || lang.toLowerCase() === 'en' || lang.toLowerCase() === 'uk')) {
      void i18n.changeLanguage(lang.toLowerCase());
      document.documentElement.lang = lang.toLowerCase();
    }
  }, [i18n, searchParams]);

  const mainElement = (
    <Container fluid className="min-vh-100">
      <Row className="mb-3">
        <Col>
          <Header />
        </Col>
      </Row>

      <Row className="justify-content-center mt-0 mb-3">
        <Col className="m-0 p-0">
          <GoogleAd unit="header" />
        </Col>
      </Row>

      <Row className={styles.mainContainer}>
        <Col className="main-container">
          <Main />
        </Col>
      </Row>

      <Row className="justify-content-center mt-3">
        <Col>
          <GoogleAd unit="footer" />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t(
            'Site.Description',
            'Beast WoT - application for Wargaming Tanks Blitz statistics tracking. Charts and useful metrics. Widgets, tops, and more. Led by Beast game streamer!',
          )}
        />
      </Helmet>

      <Routes>
        <Route path="widgets/run/*" element={<WidgetRuns />} />

        <Route path="accounts/:server/:wotId/*" element={mainElement} />
        <Route path="*" element={mainElement} />
      </Routes>
    </>
  );
}
