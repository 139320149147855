import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { ISettingsApiView } from './interfaces/settings.interface';

export class SettingsApiService {
  async get(): Promise<SingleEntityResponseApiView<ISettingsApiView>> {
    const response = await fetchApi('settings', {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ISettingsApiView>;
    return body;
  }

  async update(payload: ISettingsApiView): Promise<SingleEntityResponseApiView<ISettingsApiView>> {
    const response = await fetchApi('settings', {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ISettingsApiView>;
    return body;
  }
}
