import { useCallback, useState } from 'react';
import { Alert, Card, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { WidgetBlitzCreditsSettings } from './blitz/credits/WidgetBlitzCreditsSettings';
import { WidgetBlitzSessionSettings } from './blitz/session/WidgetBlitzSessionSettings';
import { WidgetTrovoFollowersSettings } from './trovo/followers/WidgetTrovoFollowersSettings';
import { WidgetTrovoManaSettings } from './trovo/mana/WidgetTrovoManaSettings';
import { Helmet } from 'react-helmet';
import { selectSubscriptionValid } from '../subscription/redux/subscriptionSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WidgetBlitzSessionDetailedSettings } from './blitz/session/detailed/WidgetBlitzSessionDetailedSettings';

const WidgetsList = [
  { system: 'Blitz', widgets: ['Session', 'Session Detailed'] }, // 'Credits'
  // { system: 'Trovo', widgets: ['Followers', 'Mana'] },
];

export function WidgetSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [selectedSystem, setSelectedSystem] = useState(WidgetsList[0].system);
  const [selectedWidget, setSelectedWidget] = useState(WidgetsList[0].widgets[0]);

  const getSelectedComponent = useCallback(() => {
    if (selectedSystem === 'Trovo' && selectedWidget === 'Followers') {
      return <WidgetTrovoFollowersSettings />;
    } else if (selectedSystem === 'Trovo' && selectedWidget === 'Mana') {
      return <WidgetTrovoManaSettings />;
    } else if (selectedSystem === 'Blitz' && selectedWidget === 'Session') {
      return <WidgetBlitzSessionSettings />;
    } else if (selectedSystem === 'Blitz' && selectedWidget === 'Session Detailed') {
      return <WidgetBlitzSessionDetailedSettings />;
    } else if (selectedSystem === 'Blitz' && selectedWidget === 'Credits') {
      return <WidgetBlitzCreditsSettings />;
    } else {
      return <h5>{t('Widgets.NotImplemented', 'Not implemented yet')}</h5>;
    }
  }, [selectedSystem, selectedWidget, t]);

  return (
    <Container>
      <Helmet>
        <title>{t('Widgets.Title', 'Wargaming WoT Blitz Beast - Game widgets')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('Widgets.Header', 'Widgets')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          {isValidSubscription !== true && (
            <Alert className="mb-3" variant="warning">
              <Trans t={t} i18nKey="Widgets.SubscriptionWarning">
                Please{' '}
                <button className="link-button" onClick={() => navigate('/subscription')}>
                  make a subscription
                </button>{' '}
                to unlock all available widgets and settings.
              </Trans>
            </Alert>
          )}

          <Card>
            <Card.Body>
              <Stack direction="horizontal" gap={3} className="mb-3">
                <Form.Select
                  onChange={(event) => {
                    if (event.target.value !== selectedSystem) {
                      setSelectedSystem(event.target.value);
                      setSelectedWidget(WidgetsList.find((x) => x.system === event.target.value)?.widgets[0] as string);
                    }
                  }}
                  value={selectedSystem}
                >
                  {WidgetsList.map((item) => (
                    <option key={item.system} value={item.system}>
                      {item.system}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select onChange={(event) => setSelectedWidget(event.target.value)} value={selectedWidget}>
                  {WidgetsList.find((item) => item.system === selectedSystem)?.widgets.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </Stack>

              {getSelectedComponent()}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
