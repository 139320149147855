import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AuthApiService } from '../../services/api/auth-api.service';
import { getUserMeAsync } from '../user/userSlice';
import { LogService } from '../../services/log.service';
import { showToast } from '../toast/redux/toastSlice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IWotAccountAccessToken } from '../../services/api/interfaces/user.interface';
import { WotServer } from '../../utils/wot-server.type';
import { AccountApiService } from '../../services/api/account-api.service';
import { addToAccountsList, setSelectedAccount } from '../account/redux/accountsSlice';
import { LoadingParts, startLoading, stopLoading } from '../loading/loadingSlice';
import { useTranslation } from 'react-i18next';

export function WotCallback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { wotServer, action } = useParams();

  useEffect(() => {
    const run = async () => {
      try {
        dispatch(startLoading(LoadingParts.SettingsLoading));

        const status = searchParams.get('status');
        const access_token = searchParams.get('access_token');
        const account_id = searchParams.get('account_id');
        const expires_at = searchParams.get('expires_at');
        const nickname = searchParams.get('nickname');

        if (
          status !== 'ok' ||
          access_token == null ||
          access_token === '' ||
          account_id == null ||
          account_id === '' ||
          expires_at == null ||
          expires_at === '' ||
          nickname == null ||
          nickname === ''
        ) {
          dispatch(
            showToast({
              title: t('GameCallback.ErrorHeader', 'Error'),
              message: t('GameCallback.ErrorMessage', 'Game authentication is failed...'),
              variant: 'warning',
            }),
          );
          return;
        }

        const token: IWotAccountAccessToken = {
          status,
          access_token,
          account_id: +account_id,
          expires_at,
          nickname,
        };

        if (action === 'login') {
          const svc = new AuthApiService();
          const response = await svc.loginWot(token, (wotServer ?? 'eu') as WotServer);

          if (response.success !== true || response.data == null) {
            dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
          } else {
            localStorage.setItem('jwt', response.data);
            dispatch(getUserMeAsync());
          }
        } else if (action === 'link') {
          const svc = new AccountApiService();
          const response = await svc.addMy({ token, server: wotServer as WotServer });

          if (response.success !== true || response.data == null) {
            dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
          } else {
            dispatch(setSelectedAccount(response.data));
            dispatch(
              addToAccountsList({
                id: response.data.id,
                wotId: response.data.wotId,
                server: response.data.server,
                name: response.data.name,
                my: true,
              }),
            );
          }
        }
      } catch (e) {
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.SettingsLoading));
        navigate('/');
      }
    };

    void run();
  }, [action, dispatch, navigate, searchParams, t, wotServer]);

  return <> </>;
}
