import { Row, Col, Stack, Button, Card, Table, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftCircle, StarFill } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { WotServer } from '../../../utils/wot-server.type';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getWotTanksAsync, selectWotTanks } from '../wotTanksSlice';
import { IWotApiTank } from '../../../services/api/interfaces/wot-api-tanks.interface';
import { ReactComponent as HeavyTankIcon } from '../../../images/heavy-icon.svg';
import { ReactComponent as MedTankIcon } from '../../../images/med-icon.svg';
import { ReactComponent as LightTankIcon } from '../../../images/light-icon.svg';
import { ReactComponent as TdTankIcon } from '../../../images/td-icon.svg';
import SovietFlag from '../../../images/Flag_of_the_Soviet_Union.png';

export function TankopediaTank() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { wotServer, wotTankId } = useParams<{ wotServer: string; wotTankId: string }>();

  const wotTanks = useSelector(selectWotTanks(wotServer as WotServer));
  const [selectedWotTank, setSelectedWotTank] = useState<IWotApiTank | undefined>(undefined);

  useEffect(() => {
    dispatch(getWotTanksAsync(wotServer as WotServer, i18n.language.toLowerCase() === 'en' ? 'en' : 'ru'));
  }, [dispatch, i18n.language, wotServer]);

  useEffect(() => {
    let tank = undefined;
    if (wotTankId != null) {
      tank = wotTanks.find((tank) => tank.tank_id === +wotTankId);
    }

    setSelectedWotTank(tank);
  }, [wotTankId, wotTanks]);

  return (
    <Container fluid>
      <Helmet>
        <title>
          {t('TankopediaTank.Title', 'Wargaming WoT Blitz Beast - {{tank}}', {
            tank: selectedWotTank?.name,
          })}
        </title>
      </Helmet>

      <Row>
        <Col>
          <Stack direction="horizontal">
            <Button onClick={() => navigate(`/tankopedia`)} variant="link">
              <ArrowLeftCircle size={35} />
            </Button>
            <h1 className="ms-3">{selectedWotTank?.name}</h1>
          </Stack>
        </Col>
      </Row>

      <Row>
        <Col className="dashboard-column">
          <Card className="mb-3">
            <Card.Header className="bg-metal">
              <h2>{t('TankopediaTank.OverviewHeader', 'Overview')}</h2>
            </Card.Header>

            <Card.Img variant="top" src={selectedWotTank?.images.normal} />

            <Card.Body>
              <Card.Title>{selectedWotTank?.name}</Card.Title>

              <Card.Title>
                <Stack direction="horizontal" gap={3}>
                  <p>
                    {selectedWotTank?.nation === 'china' ? (
                      <span className="fi fi-cn"></span>
                    ) : selectedWotTank?.nation === 'european' ? (
                      <span className="fi fi-eu"></span>
                    ) : selectedWotTank?.nation === 'france' ? (
                      <span className="fi fi-fr"></span>
                    ) : selectedWotTank?.nation === 'germany' ? (
                      <span className="fi fi-de"></span>
                    ) : selectedWotTank?.nation === 'japan' ? (
                      <span className="fi fi-jp flag-icon-bordered"></span>
                    ) : selectedWotTank?.nation === 'other' ? (
                      <span className="fi fi-xx flag-icon-bordered"></span>
                    ) : selectedWotTank?.nation === 'uk' ? (
                      <span className="fi fi-gb"></span>
                    ) : selectedWotTank?.nation === 'usa' ? (
                      <span className="fi fi-us"></span>
                    ) : selectedWotTank?.nation === 'ussr' ? (
                      <img src={SovietFlag} alt="soviet union flag" className="flag-icon-su" />
                    ) : (
                      <span className="fi fi-xx flag-icon-bordered"></span>
                    )}
                  </p>

                  <p>
                    {selectedWotTank?.type === 'heavyTank' ? (
                      <HeavyTankIcon />
                    ) : selectedWotTank?.type === 'mediumTank' ? (
                      <MedTankIcon />
                    ) : selectedWotTank?.type === 'lightTank' ? (
                      <LightTankIcon />
                    ) : selectedWotTank?.type === 'AT-SPG' ? (
                      <TdTankIcon />
                    ) : undefined}
                  </p>

                  <p>{selectedWotTank?.tier}</p>

                  <p>{selectedWotTank?.is_premium === true && <StarFill size={15} color="Orange" />}</p>
                </Stack>
              </Card.Title>

              <Card.Text>{selectedWotTank?.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col className="dashboard-column">
          <Card className="mb-3">
            <Card.Header className="bg-metal">
              <h2>{t('TankopediaTank.SpecsHeader', 'Specifications')}</h2>
            </Card.Header>

            <Card.Body>
              <Table bordered hover responsive size="sm" className="table-normal-header">
                <tbody>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.MaxWeight', 'Max weight')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.max_weight}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ArmorTurret', 'Armor turret')}</strong>
                    </td>
                    <td>
                      {selectedWotTank?.default_profile.armor.turret.front} / {selectedWotTank?.default_profile.armor.turret.sides} /{' '}
                      {selectedWotTank?.default_profile.armor.turret.rear}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ArmorHull', 'Armor hull')}</strong>
                    </td>
                    <td>
                      {selectedWotTank?.default_profile.armor.hull.front} / {selectedWotTank?.default_profile.armor.hull.sides} /{' '}
                      {selectedWotTank?.default_profile.armor.hull.rear}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.SpeedForward', 'Speed forward')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.speed_forward}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.SpeedBackward', 'Speed backward')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.speed_backward}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.EnginePower', 'Engine power')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.engine.power}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.EngineFireChance', 'Engine fire chance')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.engine.fire_chance}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.Hp', 'HP')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.hp}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ViewRange', 'View range')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.turret.view_range}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.Maneuverability', 'Maneuverability')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.maneuverability}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col className="dashboard-column">
          <Card className="mb-3">
            <Card.Header className="bg-metal">
              <h2>{t('TankopediaTank.GunSpecsHeader', 'Gun specifications')}</h2>
            </Card.Header>

            <Card.Body>
              <Table bordered hover responsive size="sm" className="table-normal-header">
                <tbody>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.Firepower', 'Firepower')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.firepower}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ShotEfficiency', 'Shot efficiency')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.shot_efficiency}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.MaxAmmo', 'Max ammo')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.max_ammo}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.MoveDownArc', 'Move down arc')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.move_down_arc}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.MoveUpArc', 'Move up arc')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.move_up_arc}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.Caliber', 'Caliber')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.caliber}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.FireRate', 'Fire rate')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.fire_rate}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ReloadTime', 'ReloadTime')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.reload_time}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ClipReloadTime', 'Clip reload time')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.clip_reload_time}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.ClipCapacity', 'Clip capacity')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.clip_capacity}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.Dispersion', 'Dispersion')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.dispersion}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{t('TankopediaTank.AimTime', 'Aim time')}</strong>
                    </td>
                    <td>{selectedWotTank?.default_profile.gun.aim_time}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
