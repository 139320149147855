import { Accordion, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { IChartValue, IStatistics } from '../../statistics/interfaces';
import { useEffect, useState } from 'react';
import { ITankApiView } from '../../../services/api/interfaces/tank.interface';
import { LineChart } from '../charts/LineChart';
import { useSelector } from 'react-redux';
import { selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { selectAccount } from '../../account/redux/accountsSlice';

export function DamageCard(props: { stats?: IStatistics; statsFiltered?: IStatistics; chart?: IChartValue[]; tank?: ITankApiView }) {
  const { t } = useTranslation();

  const account = useSelector(selectAccount);
  const serverStats = useSelector(selectServerStatistics(account?.server));

  const [damageRatio, setDamageRatio] = useState<number | undefined>(undefined);
  const [damageRatioFiltered, setDamageRatioFiltered] = useState<number | undefined>(undefined);
  const [damageRatioServer, setDamageRatioServer] = useState<number | undefined>(undefined);

  const [avgDamage, setAvgDamage] = useState<number | undefined>(undefined);
  const [avgDamageFiltered, setAvgDamageFiltered] = useState<number | undefined>(undefined);
  const [avgDamageServer, setAvgDamageServer] = useState<number | undefined>(undefined);

  const [avgDamageReceived, setAvgDamageReceived] = useState<number | undefined>(undefined);
  const [avgDamageReceivedFiltered, setAvgDamageReceivedFiltered] = useState<number | undefined>(undefined);
  const [avgDamageReceivedServer, setAvgDamageReceivedServer] = useState<number | undefined>(undefined);

  const [activeAccordionItemKey, setActiveAccordionItemKey] = useState(
    localStorage.getItem('damage_card_active_accordion_item_key') ?? 'none',
  );

  useEffect(() => {
    if (props.stats == null) {
      setDamageRatio(undefined);
      setAvgDamage(undefined);
      setAvgDamageReceived(undefined);
      return;
    }

    setDamageRatio(props.stats.regular.damageDealt / (props.stats.regular.damageReceived || 1));
    setAvgDamage(props.stats.regular.damageDealt / (props.stats.regular.battles || 1));
    setAvgDamageReceived(props.stats.regular.damageReceived / (props.stats.regular.battles || 1));
  }, [props.stats]);

  useEffect(() => {
    if (props.statsFiltered == null) {
      setDamageRatioFiltered(undefined);
      setAvgDamageFiltered(undefined);
      setAvgDamageReceivedFiltered(undefined);
      return;
    }

    setDamageRatioFiltered(props.statsFiltered.regular.damageDealt / (props.statsFiltered.regular.damageReceived || 1));
    setAvgDamageFiltered(props.statsFiltered.regular.damageDealt / (props.statsFiltered.regular.battles || 1));
    setAvgDamageReceivedFiltered(props.statsFiltered.regular.damageReceived / (props.statsFiltered.regular.battles || 1));
  }, [props.statsFiltered]);

  useEffect(() => {
    if (serverStats == null) {
      setDamageRatioServer(undefined);
      setAvgDamageServer(undefined);
      setAvgDamageReceivedServer(undefined);
      return;
    }

    if (props.tank == null) {
      setDamageRatioServer(
        serverStats.account.statistics.regular.damageDealt / (serverStats.account.statistics.regular.damageReceived || 1),
      );
      setAvgDamageServer(serverStats.account.statistics.regular.damageDealt / (serverStats.account.statistics.regular.battles || 1));
      setAvgDamageReceivedServer(
        serverStats.account.statistics.regular.damageReceived / (serverStats.account.statistics.regular.battles || 1),
      );
    } else {
      const tankStats = serverStats.tanks.find((t) => t.wotId === props.tank?.wotId);
      if (tankStats == null) {
        setDamageRatioServer(undefined);
        setAvgDamageServer(undefined);
        setAvgDamageReceivedServer(undefined);
        return;
      }

      setDamageRatioServer(tankStats.statistics.regular.damageDealt / (tankStats.statistics.regular.damageReceived || 1));
      setAvgDamageServer(tankStats.statistics.regular.damageDealt / (tankStats.statistics.regular.battles || 1));
      setAvgDamageReceivedServer(tankStats.statistics.regular.damageReceived / (tankStats.statistics.regular.battles || 1));
    }
  }, [props.tank, serverStats]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-metal">
        <h2>{t('DamageCard.Header', 'Damage')}</h2>
      </Card.Header>

      <Card.Body>
        <Table bordered hover responsive size="sm" className="table-normal-header">
          <thead>
            <tr>
              <th></th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.TotalTooltip', 'Total value for the account')}</Tooltip>}>
                  <span>
                    <strong>{t('CardTableHeader.Total', 'Total')}</strong>
                  </span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger overlay={<Tooltip>{t('CardTableHeader.FilteredTooltip', 'Filtered value for the account')}</Tooltip>}>
                  <span>{t('CardTableHeader.Filtered', 'Filtered')}</span>
                </OverlayTrigger>
              </th>
              <th>
                <OverlayTrigger
                  overlay={<Tooltip>{t('CardTableHeader.ServerStatsTooltip', 'Average value for the whole server')}</Tooltip>}
                >
                  <span>{t('CardTableHeader.ServerStats', 'Server')}</span>
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t('DamageCard.DamageRatioLabel', 'Damage ratio')}</strong>
              </td>
              <td
                className={
                  damageRatioServer == null || damageRatio == null
                    ? undefined
                    : damageRatioServer < damageRatio
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{damageRatio?.toFixed(3) ?? '-'}</strong>
              </td>
              <td
                className={
                  damageRatioServer == null || damageRatioFiltered == null
                    ? undefined
                    : damageRatioServer < damageRatioFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {damageRatioFiltered?.toFixed(3) ?? '-'}
              </td>
              <td>{damageRatioServer == null ? '-' : damageRatioServer.toFixed(3)}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('DamageCard.AvgDamageLabel', 'Avg. damage')}</strong>
              </td>
              <td
                className={
                  avgDamageServer == null || avgDamage == null
                    ? undefined
                    : avgDamageServer < avgDamage
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{avgDamage?.toFixed(0) ?? '-'}</strong>
              </td>
              <td
                className={
                  avgDamageServer == null || avgDamageFiltered == null
                    ? undefined
                    : avgDamageServer < avgDamageFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {avgDamageFiltered?.toFixed(0) ?? '-'}
              </td>
              <td>{avgDamageServer == null ? '-' : avgDamageServer.toFixed(0)}</td>
            </tr>
            <tr>
              <td>
                <strong>{t('DamageCard.AvgDamageReceivedLabel', 'Avg. damage received')}</strong>
              </td>
              <td
                className={
                  avgDamageReceivedServer == null || avgDamageReceived == null
                    ? undefined
                    : avgDamageReceivedServer > avgDamageReceived
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                <strong className="increase-font-size">{avgDamageReceived?.toFixed(0) ?? '-'}</strong>
              </td>
              <td
                className={
                  avgDamageReceivedServer == null || avgDamageReceivedFiltered == null
                    ? undefined
                    : avgDamageReceivedServer > avgDamageReceivedFiltered
                    ? 'success-background'
                    : 'warning-background'
                }
              >
                {avgDamageReceivedFiltered?.toFixed(0) ?? '-'}
              </td>
              <td>{avgDamageReceivedServer == null ? '-' : avgDamageReceivedServer.toFixed(0)}</td>
            </tr>
            {props.tank != null && (
              <tr>
                <td>
                  <strong>{t('DamageCard.TankHpLabel', 'Tank HP')}</strong>
                </td>
                <td>
                  <strong className="increase-font-size">{props.tank.hp}</strong>
                </td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>

      <Card.Body>
        <LineChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('DamageCard.DamageRatioChartLabel', 'Damage ratio'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : (i.stats.regular.damageDealt / (i.stats.regular.damageReceived || 1)).toFixed(3),
                ) ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
          ]}
        />
      </Card.Body>

      <Card.Body>
        <LineChart
          labels={props.chart?.map((i) => i.label) ?? []}
          datasets={[
            {
              label: t('DamageCard.AvgDamageChartLabel', 'Avgerage damage'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : (i.stats.regular.damageDealt / (i.stats.regular.battles || 1)).toFixed(0),
                ) ?? [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: t('DamageCard.AvgDamageReceivedChartLabel', 'Avgerage damage received'),
              data:
                props.chart?.map((i) =>
                  i.stats == null ? undefined : (i.stats.regular.damageReceived / (i.stats.regular.battles || 1)).toFixed(0),
                ) ?? [],
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ]}
        />

        <Accordion
          className="mt-3"
          activeKey={activeAccordionItemKey}
          onSelect={(eventKey) => {
            setActiveAccordionItemKey(eventKey as string);
            if (eventKey == null) {
              localStorage.setItem('damage_card_active_accordion_item_key', 'none');
            } else {
              localStorage.setItem('damage_card_active_accordion_item_key', eventKey as string);
            }
          }}
        >
          <Accordion.Item eventKey="info">
            <Accordion.Header>{t('DamageCard.InfoHeader', 'Learn more about the chart')}</Accordion.Header>
            <Accordion.Body>
              <Trans t={t} i18nKey="DamageCard.InfoBody">
                <p>
                  In most cases, a battle ends when one of the teams has no more health points. That is why it is so important to deal as
                  much damage as you can while saving your own health points with you.
                </p>
                <p>
                  But this rule has many exceptions though. Sometimes you should take a frag even if the enemy has just a few health points
                  left. Sometimes you have to receive a shot instead of your ally in order to save him or her. But in general, if you are
                  dealing two times more damage than you are receiving it means you are two times better player than your opponents. Or not?
                  ;)
                </p>
                <p>
                  Welcome to{' '}
                  <a href="https://www.youtube.com/BeastWorldoftanksblitz?utm_source=beastwot.com" target="_blank" rel="noreferrer">
                    our videos
                  </a>{' '}
                  to find the answer.
                </p>
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}
