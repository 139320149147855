import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';

export class ClientErrorApiService {
  async log(error: { message: string; name: string; stack?: string }): Promise<SingleEntityResponseApiView<void>> {
    const response = await fetchApi('client-error/log', {
      method: 'POST',
      body: JSON.stringify(error),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<void>;
    return body;
  }
}
