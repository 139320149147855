import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectAccount } from '../../../../account/redux/accountsSlice';
import { selectUser } from '../../../../user/userSlice';
import { toRoman } from '../../../../../utils/number.util';
import { selectTournamentAccounts, selectTournamentBonuses } from '../../../tournamentsSlice';

const PageSize = 100;

export function TournamentDetailsSep2023Total() {
  type TableRow = {
    accountName: string;
    wotId: number;
    server: string;
    tankLevel: number;
    battleParts: number;
    bonusParts: number;
  };

  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);
  const tournamentAccounts = useSelector(selectTournamentAccounts);
  const tournamentBonuses = useSelector(selectTournamentBonuses);

  const [tableRows, setTableRows] = useState<TableRow[]>([]);
  const [page, setPage] = useState(1);

  const [sorting, setSorting] = useState<{
    column: 'accountName' | 'wotId' | 'server' | 'tankLevel' | 'battleParts' | 'bonusParts' | 'totalParts';
    desc: boolean;
  }>({
    column: 'accountName',
    desc: false,
  });

  // calculate total results
  useEffect(() => {
    let accounts = [...(tournamentAccounts?.accounts ?? [])];
    let bonuses = [...(tournamentBonuses?.bonuses ?? [])];

    if (user?.role !== 'admin') {
      accounts = accounts.filter((x) => x.accountId === account?.id);
      bonuses = bonuses.filter((x) => x.accountId === account?.id);
    }

    const results: TableRow[] = [];

    for (const account of accounts) {
      for (const result of account.results) {
        if (result.tankLevel == null || result.parts == null) continue;

        let accountResult = results.find(
          (r) => r.wotId === account.wotId && r.server === account.server && r.tankLevel === result.tankLevel,
        );

        if (accountResult == null) {
          accountResult = {
            accountName: account.name,
            wotId: account.wotId,
            server: account.server,
            tankLevel: result.tankLevel,
            battleParts: 0,
            bonusParts: 0,
          };
          results.push(accountResult);
        }

        accountResult.battleParts = accountResult.battleParts + result.parts;
      }
    }

    for (const bonus of bonuses) {
      let bonusResult = results.find((r) => r.wotId === bonus.wotId && r.server === bonus.server && r.tankLevel === bonus.value.tankLevel);

      if (bonusResult == null) {
        bonusResult = {
          accountName: bonus.name,
          wotId: bonus.wotId,
          server: bonus.server,
          tankLevel: bonus.value.tankLevel,
          battleParts: 0,
          bonusParts: 0,
        };
        results.push(bonusResult);
      }

      bonusResult.bonusParts = bonusResult.bonusParts + bonus.value.parts;
    }

    results.sort((a, b) => {
      switch (sorting.column) {
        case 'accountName':
          return sorting.desc
            ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
            : (a.accountName ?? '').localeCompare(b.accountName ?? '');
        case 'wotId':
          return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
        case 'server':
          return sorting.desc ? (b.server ?? '').localeCompare(a.server ?? '') : (a.server ?? '').localeCompare(b.server ?? '');
        case 'tankLevel':
          return sorting.desc ? b.tankLevel - a.tankLevel : a.tankLevel - b.tankLevel;
        case 'battleParts':
          return sorting.desc ? b.battleParts - a.battleParts : a.battleParts - b.battleParts;
        case 'bonusParts':
          return sorting.desc ? b.bonusParts - a.bonusParts : a.bonusParts - b.bonusParts;
        case 'totalParts':
          return sorting.desc
            ? b.bonusParts + b.battleParts - (a.bonusParts + a.battleParts)
            : a.bonusParts + a.battleParts - (b.bonusParts + b.battleParts);
        default:
          return sorting.desc
            ? (b.accountName ?? '').localeCompare(a.accountName ?? '')
            : (a.accountName ?? '').localeCompare(b.accountName ?? '');
      }
    });

    setTableRows(results);
  }, [account?.id, sorting.column, sorting.desc, tournamentAccounts?.accounts, tournamentBonuses?.bonuses, user?.role]);

  return (
    <>
      <InputGroup className="mb-2">
        <Button
          variant="outline-secondary"
          disabled={page === 1}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        >
          {'<'}
        </Button>
        <Form.Control value={page} disabled className="table-page-input" />
        <Button
          variant="outline-secondary"
          disabled={page * PageSize >= tableRows.length}
          onClick={() => {
            if (page * PageSize < tableRows.length) {
              setPage(page + 1);
            }
          }}
        >
          {'>'}
        </Button>
      </InputGroup>

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'accountName', desc: !sorting.desc })}>
                {t('TournamentDetails.AccountColumn', 'Account')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'wotId', desc: !sorting.desc })}>
                {t('TournamentDetails.IdColumn', 'ID')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'server', desc: !sorting.desc })}>
                {t('TournamentDetails.ServerColumn', 'Server')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'tankLevel', desc: !sorting.desc })}>
                {t('TournamentDetails.TankLevelColumn', 'Tank level')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'battleParts', desc: !sorting.desc })}>
                {t('TournamentDetails.BattlePartsColumn', 'Battle parts')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'bonusParts', desc: !sorting.desc })}>
                {t('TournamentDetails.BonusPartsColumn', 'Bonus parts')}
              </Button>
            </th>
            <th>
              <Button variant="link" onClick={() => setSorting({ column: 'totalParts', desc: !sorting.desc })}>
                {t('TournamentDetails.TotalPartsColumn', 'Total parts')}
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRows.slice((page - 1) * PageSize, page * PageSize).map((row, i) => (
            <tr key={i} className={row.battleParts + row.bonusParts >= 10 ? 'label-success' : undefined}>
              <td>{(page - 1) * PageSize + i + 1}</td>
              <td>{row.accountName}</td>
              <td>{row.wotId}</td>
              <td>{row.server}</td>
              <td>{toRoman(row.tankLevel)}</td>
              <td>{row.battleParts}</td>
              <td>{row.bonusParts}</td>
              <td>{row.battleParts + row.bonusParts}</td>
            </tr>
          ))}

          {tableRows.length === 0 && (
            <tr>
              <td colSpan={7} className="text-center">
                {t('TournamentDetails.NoTableRows', 'There are no tournament results yet.')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
