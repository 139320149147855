import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { LogService } from '../../../services/log.service';
import { showToast } from '../../toast/redux/toastSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setSelectedAccountTrovoUserInfo } from '../../account/redux/accountsSlice';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { useTranslation } from 'react-i18next';
import { ITrovoAccessToken } from '../../../services/api/interfaces/trovo.interface';
import { TrovoApiService } from '../../../services/api/trovo-api.service';

export function TrovoCallback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const run = async () => {
      try {
        dispatch(startLoading(LoadingParts.SettingsLoading));

        const access_token = searchParams.get('access_token');
        const expires_in = searchParams.get('expires_in');
        const token_type = searchParams.get('token_type');
        const scope = searchParams.get('scope');
        const client_id = searchParams.get('client_id');
        const language = searchParams.get('language');
        const redirect_url = searchParams.get('redirect_url');
        const response_type = searchParams.get('response_type');
        const state = searchParams.get('state');

        if (access_token == null || access_token === '') {
          dispatch(
            showToast({
              title: t('TrovoCallback.ErrorHeader', 'Error'),
              message: t('TrovoCallback.ErrorMessage', 'Trovo authentication is failed...'),
              variant: 'warning',
            }),
          );
          return;
        }

        const token: ITrovoAccessToken = {
          access_token,
          expires_in: Number(expires_in),
          token_type,
          scope,
          client_id,
          language,
          redirect_url,
          response_type,
          state,
        };

        const svc = new TrovoApiService();
        const trovoUserInfo = await svc.linkUserToken(token);

        if (trovoUserInfo.success !== true || trovoUserInfo.data == null) {
          dispatch(showToast({ title: 'Error', message: trovoUserInfo.message ?? 'Unknown error occurred...', variant: 'danger' }));
        } else {
          dispatch(setSelectedAccountTrovoUserInfo(trovoUserInfo.data));
        }
      } catch (e) {
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.SettingsLoading));
        navigate('/settings');
      }
    };

    void run();
  }, [dispatch, navigate, searchParams, t]);

  return <> </>;
}
