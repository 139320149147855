import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  return (
    <Container fluid="lg">
      <Row>
        <Col>
          <p>{t('ErrorFallback.Header', 'Something went wrong:')}</p>
          <p>{error.message}</p>
          <Button onClick={() => resetErrorBoundary()}>{t('ErrorFallback.Button', 'Try again')}</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Image src="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" fluid />
        </Col>
      </Row>
    </Container>
  );
}
