import { combineReducers } from '@reduxjs/toolkit';
import loadingReducer from '../features/loading/loadingSlice';
import tanksReducer from '../features/tanks/tanksSlice';
import userReducer from '../features/user/userSlice';
import settingsReducer from '../features/settings/redux/settingsSlice';
import toastReducer from '../features/toast/redux/toastSlice';
import accountsReducer from '../features/account/redux/accountsSlice';
import accountTanksReducer from '../features/account/redux/accountTanksSlice';
import filterReducer from '../features/filter/redux/filterSlice';
import statisticsReducer from '../features/statistics/redux/statisticsReducer';
import topAccountsReducer from '../features/top/accounts/topAccountsSlice';
import subscriptionReducer from '../features/subscription/redux/subscriptionSlice';
import clansReducer from '../features/find/clan/clansSlice';
import platoonsReducer from '../features/find/platoon/platoonsSlice';
import wotTanksReducer from '../features/tankopedia/wotTanksSlice';
import tournamentsReducer from '../features/tournaments/tournamentsSlice';

const rootReducer = combineReducers({
  loading: loadingReducer,
  toast: toastReducer,
  user: userReducer,
  settings: settingsReducer,
  filter: filterReducer,
  tanks: tanksReducer,
  wotTanks: wotTanksReducer,
  accounts: accountsReducer,
  accountTanks: accountTanksReducer,
  statistics: statisticsReducer,
  topAccounts: topAccountsReducer,
  subscription: subscriptionReducer,
  clans: clansReducer,
  platoons: platoonsReducer,
  tournaments: tournamentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
