import { useEffect, useState } from 'react';
import { Button, Form, Card, Col, Row, Alert, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettings, updateSettingsAsync } from './redux/settingsSlice';
import { useTranslation } from 'react-i18next';
import { ISettingsApiView } from '../../services/api/interfaces/settings.interface';
import { LinkAccount } from './components/LinkAccount';
import { selectUser } from '../user/userSlice';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { changeSubscriptionStatusAsync, selectSubscription, selectSubscriptionValid } from '../subscription/redux/subscriptionSlice';
import { TrovoLogin } from '../login/trovo/TrovoLogin';
import { selectAccount, unlinkTrovoAccountAsync } from '../account/redux/accountsSlice';

export function Settings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const settings = useSelector(selectSettings);
  const subscription = useSelector(selectSubscription);
  const isValidSubscription = useSelector(selectSubscriptionValid);
  const account = useSelector(selectAccount);

  const [dirty, setDirty] = useState(false);
  const [localSettings, setLocalSettings] = useState<ISettingsApiView>();

  useEffect(() => {
    if (settings != null) {
      setLocalSettings({ ...settings });
      setDirty(false);
    }
  }, [settings]);

  useEffect(() => {
    if (user == null) {
      navigate('/');
    }
  }, [navigate, user]);

  return (
    <Container fluid>
      <Helmet>
        <title>{t('Settings.Title', 'Wargaming WoT Blitz Beast - Account settings')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('Settings.Header', 'Settings')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          {false && (
            <Card className="mb-3">
              <Card.Body>
                <Form.Check
                  type="checkbox"
                  label={t('Settings.OnlyAvailabelTanksLabel', 'Show only available tanks')}
                  onChange={(e) => {
                    setDirty(true);
                    setLocalSettings({ ...localSettings, showAvailableTanksOnly: e.target.checked });
                  }}
                  checked={localSettings?.showAvailableTanksOnly}
                />
              </Card.Body>
            </Card>
          )}

          {false && (
            <Card className="mb-3">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Settings.TrovoManaTitle', 'Trovo mana')}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t('Settings.TrovoManaPlaceholder', 'Trovo mana')}
                    value={localSettings?.trovoMana}
                    onChange={(event) => {
                      setDirty(true);
                      setLocalSettings({ ...localSettings, trovoMana: +event.target.value });
                    }}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          )}

          {false && <LinkAccount />}

          {subscription?.provider === 'fondy' && (
            <Card className="mb-3">
              <Card.Body>
                <p>{t('Settings.SubscriptionStatus', 'Change subscription status')}</p>
                <Button
                  variant={subscription?.status === 'active' ? 'warning' : 'success'}
                  type="button"
                  onClick={() => {
                    if (subscription == null || isValidSubscription === false) {
                      navigate('/subscription');
                    } else {
                      dispatch(changeSubscriptionStatusAsync(subscription?.status === 'active' ? 'deactivated' : 'active'));
                    }
                  }}
                >
                  {subscription == null || isValidSubscription === false
                    ? t('Settings.CreateSubscriptionButton', 'Create')
                    : subscription.status === 'active'
                    ? t('Settings.DeactivateSubscriptionButton', 'Deactivate')
                    : t('Settings.ActivateSubscriptionButton', 'Activate')}
                </Button>
              </Card.Body>
            </Card>
          )}

          <Card className="mb-3">
            <Card.Body>
              <p>{t('Settings.TrovoAccount', 'Link Trovo account')}</p>

              {account?.my !== true && (
                <Alert variant="warning">
                  {t(
                    'Settings.TrovoAccountWarning',
                    'You can link Trovo account only to your own account. Please select your account in the top right corner.',
                  )}
                </Alert>
              )}

              {account?.my === true && account?.trovoUserInfo == null && <TrovoLogin />}

              {account?.my === true && account?.trovoUserInfo != null && (
                <>
                  <Card.Text>
                    <strong>
                      {t('Settings.TrovoAccountInfo', 'Trovo account: {{nickName}}', { nickName: account.trovoUserInfo.nickName })}
                    </strong>
                  </Card.Text>

                  <Button
                    variant="outline-danger"
                    type="button"
                    onClick={() => {
                      dispatch(unlinkTrovoAccountAsync());
                    }}
                  >
                    {t('Settings.UnlinkTrovoAccountButton', 'Unlink Trovo account')}
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>

          {false && (
            <div>
              <Button
                variant="primary"
                type="button"
                disabled={dirty === false || localSettings == null}
                onClick={() => dispatch(updateSettingsAsync(localSettings as ISettingsApiView))}
              >
                {t('Settings.SaveButton', 'Save')}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
