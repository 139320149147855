import '../../Widget.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WotServer } from '../../../../utils/wot-server.type';
import { LogService } from '../../../../services/log.service';
import { AccountApiService } from '../../../../services/api/account-api.service';

export function WidgetBlitzCredits() {
  const { search } = useLocation();

  const [current, setCurrent] = useState<{ credits: number }>({
    credits: 0,
  });

  const [format, setFormat] = useState<'M' | 'K' | undefined>(undefined);

  const [query, setQuery] = useState<{
    wotAccountId: number;
    wotAccountServer: string;
    credits: number;
    targetCredits: number;
    debug: boolean;
  }>({ wotAccountId: 0, wotAccountServer: '', credits: 0, targetCredits: 0, debug: false });

  // parse query string
  useEffect(() => {
    const url = new URLSearchParams(search);

    const wotAccountIdQuery = url.get('wotAccountId');
    const wotAccountServerQuery = url.get('wotAccountServer');
    const creditsQuery = url.get('credits');
    const targetCreditsQuery = url.get('targetCredits');
    const debugQuery = url.get('debug');

    const credits = creditsQuery == null ? 0 : +creditsQuery;
    const targetCredits = targetCreditsQuery == null ? 0 : +targetCreditsQuery;
    const debug = debugQuery == null ? false : debugQuery === 'true' ? true : false;

    setQuery({
      wotAccountId: wotAccountIdQuery == null ? 0 : +wotAccountIdQuery,
      wotAccountServer: wotAccountServerQuery == null ? '' : wotAccountServerQuery,
      credits,
      targetCredits,
      debug,
    });

    setCurrent({ credits });

    if (targetCredits % 1000000 === 0) {
      setFormat('M');
    } else if (targetCredits % 1000 === 0) {
      setFormat('K');
    } else {
      setFormat(undefined);
    }

    if (debug) {
      LogService.debug(
        `Query: ${JSON.stringify({
          wotAccountId: wotAccountIdQuery == null ? 0 : +wotAccountIdQuery,
          wotAccountServer: wotAccountServerQuery == null ? '' : wotAccountServerQuery,
          credits,
          targetCredits,
          debug,
        })}`,
      );
    }
  }, [search]);

  // get stats
  useEffect(() => {
    const getCredits = async () => {
      const svc = new AccountApiService();
      const response = await svc.add(query.wotAccountId, query.wotAccountServer as WotServer);

      if (response.success === true && response.data != null) {
        if (response.data.snapshots.length > 0 && response.data.snapshots[response.data.snapshots.length - 1].private?.credits != null) {
          setCurrent({ credits: response.data.snapshots[response.data.snapshots.length - 1].private?.credits ?? 0 });
        }

        if (query.debug) {
          LogService.debug(
            `Current: ${JSON.stringify({
              credits: response.data.snapshots[response.data.snapshots.length - 1].private?.credits ?? 0,
            })}`,
          );
        }
      } else {
        LogService.error(`Error getting credits for account ${query.wotAccountId} on server ${query.wotAccountServer}`);
        LogService.error(JSON.stringify(response));
      }
    };

    const interval = setInterval(() => {
      if (query.debug) {
        LogService.debug(`Geting credits by interval`);
      }
      void getCredits();
    }, 1000 * 10);

    return () => {
      if (query.debug) {
        LogService.debug(`Clear interval`);
      }
      clearInterval(interval);
    };
  }, [query.debug, query.wotAccountId, query.wotAccountServer]);

  function formatValue(value: number, decimals: boolean): string {
    if (format === 'M') {
      return decimals === true ? `${(value / 1000000).toFixed(2)}M` : `${(value / 1000000).toFixed(0)}M`;
    } else if (format === 'K') {
      return decimals === true ? `${(value / 1000).toFixed(2)}K` : `${(value / 1000).toFixed(0)}K`;
    } else {
      return `${value}`;
    }
  }

  return (
    <div className="widget-container">
      <p>
        <span>{formatValue(current.credits, true)}</span>
        <span style={{ margin: '1rem' }}>/</span>
        <span>{formatValue(query.targetCredits, false)}</span>
      </p>
    </div>
  );
}
