import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { IAccountApiView, IAccountShortApiView } from '../../../services/api/interfaces/account.interface';
import { AccountApiService } from '../../../services/api/account-api.service';
import { LogService } from '../../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';
import { WotServer } from '../../../utils/wot-server.type';
import { FilterState } from '../../filter/redux/filterSlice';
import { ITrovoUserInfo } from '../../../services/api/interfaces/trovo.interface';
import { TrovoApiService } from '../../../services/api/trovo-api.service';

export interface AccountsState {
  list: Array<IAccountShortApiView & { my: boolean }>;
  selected?: IAccountApiView & { my: boolean };
  selectedFiltered?: IAccountApiView;
}

const InitialState: AccountsState = {
  list: [],
  selected: undefined,
  selectedFiltered: undefined,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: InitialState,
  reducers: {
    resetAccounts: () => InitialState,
    addToAccountsList: (state, action: PayloadAction<IAccountShortApiView & { my: boolean }>) => {
      const accountInList = state.list.find((x) => x.wotId === action.payload.wotId);
      if (accountInList == null) {
        state.list = [...state.list, action.payload];
      } else if (accountInList.my === false && action.payload.my === true) {
        accountInList.my = true;
      }
    },
    setSelectedAccount: (state, action: PayloadAction<IAccountApiView | undefined>) => {
      if (action.payload == null) {
        state.selected = undefined;
        return;
      }

      const accountInList = state.list.find((x) => x.wotId === action.payload?.wotId);
      state.selected = { ...action.payload, my: accountInList?.my ?? false };
    },
    setSelectedAccountFiltered: (state, action: PayloadAction<{ account: IAccountApiView | undefined; filter: FilterState }>) => {
      if (action.payload.account == null || action.payload.filter.timeRange === 'all') {
        state.selectedFiltered = action.payload.account;
        return;
      }

      state.selectedFiltered = {
        ...action.payload.account,
        snapshots: action.payload.account.snapshots
          .filter((x) => x.lastBattleTime >= action.payload.filter.startDate)
          .sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime)),
      };
    },
    setSelectedAccountTrovoUserInfo: (state, action: PayloadAction<ITrovoUserInfo | undefined>) => {
      if (state.selected == null) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.selected.trovoUserInfo = action.payload;
    },
  },
});

export const { resetAccounts, setSelectedAccount, addToAccountsList, setSelectedAccountFiltered, setSelectedAccountTrovoUserInfo } =
  accountsSlice.actions;

export const getMyAccountsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.AccountsLoading));

    const svc = new AccountApiService();
    const response = await svc.getMy();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      for (const account of response.data) {
        dispatch(addToAccountsList({ id: account.id, wotId: account.wotId, server: account.server, name: account.name, my: true }));
      }
    }
  } catch (e) {
    LogService.error(`Error while loading my account`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.AccountsLoading));
  }
};

export const addAccountAsync =
  (wotId: number, server: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.AccountsLoading));

      const svc = new AccountApiService();
      const response = await svc.add(wotId, server);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        if (response.data != null) {
          response.data?.snapshots.sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime));
          dispatch(setSelectedAccount(response.data));
          dispatch(
            addToAccountsList({
              id: response.data.id,
              wotId: response.data.wotId,
              server: response.data.server,
              name: response.data.name,
              my: false,
            }),
          );
        }
      }
    } catch (e) {
      LogService.error(`Error while loading an account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.AccountsLoading));
    }
  };

export const unlinkTrovoAccountAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.AccountsLoading));

    const svc = new TrovoApiService();
    const response = await svc.unlink();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      dispatch(setSelectedAccountTrovoUserInfo(undefined));
    }
  } catch (e) {
    LogService.error(`Error while unlinking trovo account`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.AccountsLoading));
  }
};

export const selectAccount = (state: RootState) => state.accounts.selected;
export const selectAccountFiltered = (state: RootState) => state.accounts.selectedFiltered;
export const selectAccountsList = (state: RootState) => state.accounts.list;

export default accountsSlice.reducer;
