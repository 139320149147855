import { useEffect, useState } from 'react';
import { Button, Form, Card, Col, Row, FormGroup, Alert, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { selectAccountsList } from '../../account/redux/accountsSlice';
import { IAccountShortApiView } from '../../../services/api/interfaces/account.interface';
import { getClanAsync, selectClan, setClan, updateClanAsync } from './clansSlice';
import { useNavigate } from 'react-router-dom';
import { selectSubscriptionValid } from '../../subscription/redux/subscriptionSlice';
import { selectUser } from '../../user/userSlice';
import { FEATURE_FREE_CLAN_SEARCH } from '../../../configs/config';

export function YourClan() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isValidSubscription = useSelector(selectSubscriptionValid);
  const accountsList = useSelector(selectAccountsList);
  const clan = useSelector(selectClan);

  const [account, setAccount] = useState<IAccountShortApiView | undefined>(undefined);
  const [recruitingStatus, setRecruitingStatus] = useState<'active' | 'inactive' | undefined>(undefined);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (!FEATURE_FREE_CLAN_SEARCH && isValidSubscription !== true) {
      navigate('/subscription');
    }
  }, [navigate, isValidSubscription]);

  useEffect(() => {
    if (account == null && accountsList.filter((a) => a.my === true).length > 0) {
      setAccount(accountsList.filter((a) => a.my === true)[0]);
    }
  }, [account, accountsList]);

  useEffect(() => {
    if (account != null) {
      dispatch(getClanAsync(account.wotId, account.server));
    } else {
      dispatch(setClan(undefined));
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (clan != null) {
      setRecruitingStatus(clan.recruiting.status);
    } else {
      setRecruitingStatus(undefined);
    }
  }, [clan]);

  return (
    <Container>
      <Helmet>
        <title>{t('YourClan.Title', 'Wargaming WoT Blitz Beast - Your clan')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('YourClan.Header', 'Your clan')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h2>{t('YourClan.AccountHeader', 'Account')}</h2>

              <FormGroup as={Row}>
                <Form.Label column sm={3}>
                  {t('YourClan.AccountName', 'Name')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    onChange={(event) => {
                      const account = accountsList.find((a) => a.wotId === +event.target.value);
                      setAccount(account);
                    }}
                    value={account?.wotId}
                  >
                    {accountsList
                      .filter((a) => a.my === true)
                      .map((a) => (
                        <option key={a.wotId} value={a.wotId}>
                          ({a.server}) {a.name}
                        </option>
                      ))}
                  </Form.Select>

                  {clan == null && (
                    <Alert className="mt-3 mb-0" variant="warning">
                      {t('YourClan.NoClanWarning', 'This account does not belong to any clan')}
                    </Alert>
                  )}
                </Col>
              </FormGroup>
            </Card.Body>

            <Card.Body>
              <h2>{t('YourClan.Clan', 'Clan')}</h2>

              <FormGroup as={Row}>
                <Form.Label column sm={3}>
                  {t('YourClan.RecruitingStatus', 'Recruiting status')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Select
                    onChange={(event) => {
                      setRecruitingStatus(event.target.value as 'active' | 'inactive');
                    }}
                    value={recruitingStatus}
                    className={`${recruitingStatus === 'active' ? 'label-success' : 'label-warning'}`}
                    disabled={clan == null}
                  >
                    <option key="inactive" value="inactive">
                      {t('YourClan.RecruitingStatusInactive', 'Inactive')}
                    </option>
                    <option key="active" value="active">
                      {t('YourClan.RecruitingStatusActive', 'Active')}
                    </option>
                  </Form.Select>
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanName', 'Name')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.name ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanTag', 'Tag')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.tag ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanMotto', 'Motto')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.motto ?? ''} readOnly />
                </Col>
              </FormGroup>
            </Card.Body>

            <Card.Body>
              <h2>{t('YourClan.RecruitingCriteria', 'Recruiting criteria')}</h2>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanRecruitingBattles', 'Battles')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.recruiting.battles ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanRecruitingDamage', 'Avg. damage')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.recruiting.avgDamage ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.ClanRecruitingWinRate', 'Win rate')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.recruiting.winRate ?? ''} readOnly />
                </Col>
              </FormGroup>
            </Card.Body>

            <Card.Body>
              <h2>{t('YourClan.Members', 'Members')}</h2>

              <FormGroup as={Row}>
                <Form.Label column sm={3}>
                  {t('YourClan.MembersCount', 'Count')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.members.count ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.MembersBattles', 'Avg. battles')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.members.avgBattles ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.MembersDamage', 'Avg. damage')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.members.avgDamage ?? ''} readOnly />
                </Col>
              </FormGroup>

              <FormGroup as={Row} className="mt-2">
                <Form.Label column sm={3}>
                  {t('YourClan.MembersWinRate', 'Avg. win rate')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control type="text" value={clan?.members.avgWinRate ?? ''} readOnly />
                </Col>
              </FormGroup>

              <Button
                className="mt-3 float-end"
                variant="primary"
                type="button"
                disabled={account == null || clan == null || recruitingStatus == null}
                onClick={() => {
                  if (account != null && clan != null && recruitingStatus != null) {
                    dispatch(updateClanAsync(account.wotId, account.server, clan.wotId, recruitingStatus));
                    navigate('/find/clan');
                  }
                }}
              >
                {t('YourClan.SaveButton', 'Save')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
