import { API_URL } from '../../configs/config';
import fetch from 'cross-fetch';

export default async function fetchApi(url: string, options?: RequestInit) {
  if (options == null) {
    options = {};
  }

  if (options.headers == null) {
    options.headers = {};
  }

  options.headers = { ...options.headers, Authorization: localStorage.getItem('jwt') as string };

  return await fetch(formatApiUrl(url), options);
}

export function formatApiUrl(path: string): string {
  return new URL(path, API_URL).href;
}
