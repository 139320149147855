import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ITopAccountsApiView } from '../../../services/api/interfaces/top-accounts.interface';
import { TopAccountsApiService } from '../../../services/api/top-accounts-api.service';
import { LogService } from '../../../services/log.service';
import { WotServer, WotServerValues } from '../../../utils/wot-server.type';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';

export interface TopAccountsState {
  eu?: ITopAccountsApiView;
  com?: ITopAccountsApiView;
  asia?: ITopAccountsApiView;
}

const InitialState: TopAccountsState = {
  eu: undefined,
  com: undefined,
  asia: undefined,
};

export const topAccountsSlice = createSlice({
  name: 'topAccounts',
  initialState: InitialState,
  reducers: {
    resetTopAccounts: () => InitialState,
    setTopAccounts: (state, action: PayloadAction<{ top: ITopAccountsApiView; server: WotServer }>) => {
      if (WotServerValues.includes(action.payload.server)) {
        state[action.payload.server] = action.payload.top;
      }
    },
  },
});

export const { resetTopAccounts, setTopAccounts } = topAccountsSlice.actions;

export const getTopAccountsAsync =
  (server: WotServer): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().topAccounts[server] != null) {
        return;
      }

      dispatch(startLoading(LoadingParts.TopAccounts));

      const svc = new TopAccountsApiService();
      const response = await svc.get(server);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setTopAccounts({ server, top: response.data }));
      }
    } catch (e) {
      LogService.error(`Error while loading top accounts`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TopAccounts));
    }
  };

export const selectTopAccounts = (server?: WotServer) => (state: RootState) => {
  return server == null ? undefined : state.topAccounts[server];
};

export default topAccountsSlice.reducer;
