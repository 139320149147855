import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { ITopAccountsApiView } from './interfaces/top-accounts.interface';

export class TopAccountsApiService {
  async get(server: WotServer): Promise<SingleEntityResponseApiView<ITopAccountsApiView>> {
    const response = await fetchApi(`top/accounts/${server}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<ITopAccountsApiView>;

    return body;
  }
}
