import { Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import Image from './images/3.jpeg';

export function Blog03() {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet>
        <title>
          {t('Blog.3.Title', 'Wargaming WoT Blitz Beast - Mastering Crew Skills in World of Tanks Blitz: Enhance Your Tank Performance')}
        </title>
      </Helmet>

      <Row>
        <Col>
          <h1 className="mb-3">{t('Blog.3.Header', 'Mastering Crew Skills in World of Tanks Blitz: Enhance Your Tank Performance')}</h1>

          <Card>
            <Card.Img variant="top" src={Image} />
            <Card.Body>
              <Trans t={t} i18nKey="Blog.3.Body">
                <h2>Introduction</h2>
                <p>
                  In the exhilarating world of World of Tanks Blitz, having a skilled and experienced crew can significantly impact your
                  tank's performance and your chances of victory. Understanding the importance of crew skills and allocating them wisely is
                  essential for maximizing your tank's capabilities. In this blog post, we will delve into the various crew skills available
                  in Wargaming's World of Tanks Blitz, providing insights into their effects and optimization strategies.
                </p>

                <h2>Repairs</h2>
                <p>
                  The Repairs skill enhances your tank's ability to repair damaged modules and injured crew members more swiftly. Investing
                  points in this skill reduces the downtime caused by critical damage, allowing you to return to the battle with minimal
                  delay. Repairs is particularly beneficial for heavy tanks and frontline brawlers who often face intense combat situations.
                </p>

                <h2>Camouflage</h2>
                <p>
                  The Camouflage skill improves your tank's ability to remain hidden, reducing its visibility to enemy vehicles. Investing
                  in Camouflage increases the effectiveness of your tank's natural cover, making it harder for opponents to spot and target
                  you. This skill is particularly valuable for tank destroyers and scouts that rely on stealth and ambush tactics.
                </p>

                <h2>Brothers in Arms</h2>
                <p>
                  Brothers in Arms is a unique crew skill that provides a universal boost to all crew members' skills when activated. By
                  allocating points to Brothers in Arms, you elevate the overall performance of your crew, enhancing their individual skills
                  simultaneously. This skill is highly versatile and can benefit any tank class, contributing to increased firepower,
                  survivability, and overall effectiveness.
                </p>

                <h2>Sixth Sense</h2>
                <p>
                  The Sixth Sense skill acts as an invaluable early warning system, alerting you when you have been detected by enemy
                  vehicles. It significantly improves your situational awareness, allowing you to take appropriate action and avoid
                  potentially lethal situations. Investing in Sixth Sense can give you a crucial advantage by providing critical information
                  about enemy presence.
                </p>

                <h2>Firefighting</h2>
                <p>
                  The Firefighting skill reduces the chances of your tank catching fire and improves the efficiency of extinguishing flames
                  when they occur. It minimizes the damage caused by fires, allowing you to remain in the battle longer. Firefighting is
                  particularly beneficial for tanks with weak armor or vulnerable engine compartments.
                </p>

                <h2>Snapshot and Smooth Ride</h2>
                <p>
                  Snapshot and Smooth Ride are skills that enhance your tank's accuracy while moving (Smooth Ride) and traversing the turret
                  (Snapshot). By investing in these skills, you can improve your tank's gunnery performance, especially during dynamic
                  combat scenarios. Tanks that rely on mobility and quick engagements, such as mediums and light tanks, benefit greatly from
                  these skills.
                </p>

                <h2>Optimizing Crew Skills Strategy</h2>
                <p>To optimize your crew skills strategy, consider the following tips:</p>

                <p>
                  1. Specialization: Allocate crew skills based on your tank's role and playstyle to enhance its strengths and compensate
                  for weaknesses. For example, if you prefer playing heavy tanks, prioritize skills like Repairs and Firefighting to
                  increase survivability in close-quarter combat.
                </p>

                <p>
                  2. Priority: Prioritize essential crew skills like Sixth Sense and Repairs for early development, as they provide
                  immediate benefits on the battlefield. These skills enhance your awareness and ability to stay in the fight, improving
                  your overall performance.
                </p>

                <p>
                  3. Balance: Aim for a balanced crew skill distribution across your crew members to maximize overall effectiveness.
                  Consider allocating skills like Camouflage and Brothers in Arms to multiple crew members to enhance the tank's stealth and
                  combat capabilities.
                </p>

                <p>
                  4. Focus: As crew skills are cumulative, focus on advancing key skills to higher levels for greater impact. Invest in
                  upgrading skills that directly affect your tank's performance, such as improving the Repair speed or reducing the chance
                  of fire with Firefighting.
                </p>

                <p>
                  5. Continuous Training: Regularly train and retrain crew members to ensure they are proficient in their respective roles
                  and to adapt to tank upgrades. When upgrading your tank or switching to a new tank, invest time in retraining crew members
                  to maintain optimal performance.
                </p>

                <h2>Conclusion</h2>
                <p>
                  By understanding and leveraging the diverse crew skills available in World of Tanks Blitz, you can fine-tune your tanks
                  for optimal performance, increasing your chances of victory on the battlefield. Whether it's repairing critical damage,
                  staying hidden, improving overall crew performance, or enhancing accuracy, crew skills play a vital role in shaping your
                  tank's capabilities. With strategic allocation and continuous training, you can unlock the full potential of your crew and
                  dominate the Blitz battlefield.
                </p>
              </Trans>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
