import { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WotApiService } from '../../../../services/api/wot-api.service';
import { selectAccount } from '../../../account/redux/accountsSlice';
import { showToast } from '../../../toast/redux/toastSlice';
import { selectSubscriptionValid } from '../../../subscription/redux/subscriptionSlice';

export function WidgetBlitzSessionSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAccount = useSelector(selectAccount);
  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [url, setUrl] = useState('');
  const [showSiteName, setShowSiteName] = useState(true);

  useEffect(() => {
    setUrl('');

    const run = async () => {
      if (selectedAccount == null) {
        return;
      }

      try {
        const svc = new WotApiService();

        const accountStats = await svc.getAccountStats(selectedAccount.wotId, selectedAccount.server, [
          'statistics.all.battles',
          'statistics.rating.battles',
          'statistics.all.wins',
          'statistics.rating.wins',
          'statistics.all.damage_dealt',
          'statistics.rating.damage_dealt',
        ]);

        if (accountStats.status !== 'ok' || accountStats.data == null) {
          dispatch(showToast({ title: 'Error', message: accountStats.error?.message ?? 'Unknown error occurred...', variant: 'danger' }));
          return;
        }

        const battles = accountStats.data.statistics.all.battles + (accountStats.data.statistics.rating?.battles ?? 0);
        const wins = accountStats.data.statistics.all.wins + (accountStats.data.statistics.rating?.wins ?? 0);
        const damage = accountStats.data.statistics.all.damage_dealt + (accountStats.data.statistics.rating?.damage_dealt ?? 0);

        const newUrl = `/widgets/run/blitz/session?wotAccountId=${selectedAccount.wotId}&wotAccountServer=${
          selectedAccount.server
        }&battles=${battles}&wins=${wins}&damage=${damage}&showSiteName=${showSiteName ? 'true' : 'false'}`;

        setUrl(newUrl);
      } catch (e) {
        dispatch(showToast({ title: 'Error', message: (e as Error)?.message ?? 'Unknown error occurred...', variant: 'danger' }));
        return;
      }
    };

    void run();
  }, [dispatch, selectedAccount, showSiteName]);

  return (
    <>
      <Alert variant="info">
        <Trans t={t} i18nKey="WidgetBlitzSessionSettings.Info">
          The widget calculates battle stats from <strong>Regular and Rating</strong> modes. The stats are presented as a{' '}
          <strong>total</strong> for the selected account.
        </Trans>
      </Alert>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetBlitzSessionSettings.GameAccount', 'Game Account')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('WidgetBlitzSessionSettings.GameAccountPlaceholder', 'Please select an account at the top...')}
          required
          readOnly={true}
          isInvalid={selectedAccount == null}
          value={selectedAccount == null ? '' : `(${selectedAccount.server}) ${selectedAccount.name}`}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label={t('WidgetBlitzSessionSettings.ShowSiteName', 'Show the site name')}
          disabled={isValidSubscription !== true}
          checked={showSiteName}
          onChange={() => {
            setShowSiteName(!showSiteName);
          }}
        />
      </Form.Group>

      {(selectedAccount == null || url === '') && (
        <Button variant="primary" type="submit" disabled className="float-end">
          {t('WidgetBlitzSessionSettings.StartButton', 'Start')}
        </Button>
      )}

      {selectedAccount != null && url !== '' && (
        <Button
          variant="primary"
          type="submit"
          href={url}
          target="_blank"
          disabled={selectedAccount == null || url === ''}
          className="float-end"
        >
          {t('WidgetBlitzSessionSettings.StartButton', 'Start')}
        </Button>
      )}
    </>
  );
}
