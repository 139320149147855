import './Hangar.scss';
import { Route, Routes } from 'react-router';
import { HangarTank } from './tank/HangarTank';
import { HangarTable } from './table/HangarTable';

export function Hangar() {
  return (
    <Routes>
      <Route path="/" element={<HangarTable />} />
      <Route path=":tankWotId" element={<HangarTank />} />
    </Routes>
  );
}
