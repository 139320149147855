import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ISubscriptionApiView } from '../../../services/api/interfaces/subscription.interface';
import { SubscriptionApiService } from '../../../services/api/subscription-api.service';
import { LogService } from '../../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';

export interface SubscriptionState {
  item?: ISubscriptionApiView | null;
  valid?: boolean;
}

const InitialState: SubscriptionState = {
  item: undefined,
  valid: undefined,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: InitialState,
  reducers: {
    resetSubscription: () => InitialState,
    setSubscription: (state, action: PayloadAction<ISubscriptionApiView | null>) => {
      state.item = action.payload;
      state.valid = action.payload == null ? false : action.payload.status !== 'expired';
    },
  },
});

export const { resetSubscription, setSubscription } = subscriptionSlice.actions;

export const getSubscriptionAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.SubscriptionLoading));

    const svc = new SubscriptionApiService();
    const response = await svc.get();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    }

    dispatch(setSubscription(response.data));
  } catch (e) {
    LogService.error(`Error while loading subscription`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.SubscriptionLoading));
  }
};

export const changeSubscriptionStatusAsync =
  (status: 'active' | 'deactivated'): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.SubscriptionLoading));

      const svc = new SubscriptionApiService();
      const response = await svc.changeFondyStatus(status);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      }

      dispatch(setSubscription(response.data));
    } catch (e) {
      LogService.error(`Error while changing subscription status`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.SubscriptionLoading));
    }
  };

export const checkBmcSubscriptionAsync =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.SubscriptionLoading));

      const svc = new SubscriptionApiService();
      const response = await svc.checkBmc(email);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Warning', message: response.message ?? 'Unknown error occurred...', variant: 'warning' }));
      }

      dispatch(setSubscription(response.data));
    } catch (e) {
      LogService.error(`Error while checking BMC subscription`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.SubscriptionLoading));
    }
  };

export const selectSubscription = (state: RootState) => state.subscription.item;
export const selectSubscriptionValid = (state: RootState) => state.subscription.valid;

export default subscriptionSlice.reducer;
