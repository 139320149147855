import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkBmcSubscriptionAsync, selectSubscription, selectSubscriptionValid } from './redux/subscriptionSlice';
import ValidSubscriptionImg from '../../images/subscription-success.jpeg';
import BmcImg from './components/bmc/orange-button.png';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../user/userSlice';
import { LoadingParts, selectLoadingState } from '../loading/loadingSlice';

export function Subscription() {
  const BaseBmcUrl = 'https://www.buymeacoffee.com/beastwot';

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector(selectLoadingState);
  const user = useSelector(selectUser);
  const subscription = useSelector(selectSubscription);
  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [subscriptionEmail, setSubscriptionEmail] = useState<string>('');
  const [bmcMembershipUrl, setBmcMembershipUrl] = useState<string>(BaseBmcUrl + '/membership');
  const [bmcUrl, setBmcUrl] = useState<string>(BaseBmcUrl);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (i18n.language === 'uk') {
      setBmcMembershipUrl(`${BaseBmcUrl}/membership?l=uk`);
      setBmcUrl(`${BaseBmcUrl}?l=uk`);
    } else if (i18n.language === 'en') {
      setBmcMembershipUrl(`${BaseBmcUrl}/membership?l=en`);
      setBmcUrl(`${BaseBmcUrl}?l=en`);
    } else {
      setBmcMembershipUrl(BaseBmcUrl + '/membership');
      setBmcUrl(BaseBmcUrl);
    }
  }, [i18n.language]);

  const handleCheckSubscription = () => {
    dispatch(checkBmcSubscriptionAsync(subscriptionEmail));
  };

  return (
    <Container>
      <Helmet>
        <title>{t('Subscription.Title', 'Wargaming WoT Blitz Beast - Subscription')}</title>
      </Helmet>

      <Row>
        <Col>
          <h1>{t('Subscription.Header', 'Subscription')}</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          {isValidSubscription === true && (
            <Card>
              <Card.Img variant="top" src={ValidSubscriptionImg} />
              <Card.Body>
                <Alert variant="success" className="mb-0">
                  {t(
                    'Subscription.ValidSubscriptionMessage',
                    'Your subscription is valid until {{date}}. All features of the site are available to you. Thank you for your support!',
                    {
                      date: new Date(subscription?.expiresAt ?? '').toLocaleDateString(),
                    },
                  )}
                </Alert>

                {subscription?.status === 'deactivated' && (
                  <Alert variant="warning" className="mt-3">
                    <p>
                      {t(
                        'Subscription.DeactivatedSubscriptionMessage',
                        "Your subscription deactivated and won't be continued automatically. Please go to the Settings and reactivate it.",
                      )}
                    </p>
                    <div className="d-flex justify-content-end">
                      <Button onClick={() => navigate('/settings')} variant="outline-success">
                        {t('Subscription.DeactivatedSubscriptionButton', 'Go to Settings')}
                      </Button>
                    </div>
                  </Alert>
                )}

                <Alert variant="warning" className="mt-3">
                  {t(
                    'Subscription.DonateBmc',
                    'Please feel free to donate any amount if you would like to support us even more. We appreciate your help!',
                  )}
                </Alert>

                <div className="text-center">
                  <a target="_blank" href={bmcUrl} rel="noreferrer">
                    <img src={BmcImg} alt="Buy me a coffee" style={{ width: '75%' }} />
                  </a>
                </div>
              </Card.Body>
            </Card>
          )}

          {isValidSubscription !== true && (
            <ListGroup className="mb-5 mt-3">
              <ListGroup.Item className="p-3">
                <Alert variant="info" className="pb-0 mb-0">
                  <p>
                    {t(
                      'Subscription.MotivationMessage',
                      'Please subscribe to remove ads, keep your account data in sync, and unlock all the site features. We are working hard to make the project better and more useful. Your support is very important to us.',
                    )}
                  </p>
                  <p>{t('Subscription.MotivationMessageThanks', 'Thank you for considering a subscription! ♥️')}</p>
                </Alert>
              </ListGroup.Item>

              <ListGroup.Item className="p-3">
                <Alert variant="warning">
                  {t(
                    'Subscription.SubscribeBmcInstruction',
                    'Please create a member subscription at Buy Me a Coffee and provide us with the email you used in the form below. After that, you will get access to all the site features. Thank you!',
                  )}
                </Alert>

                <div className="text-center">
                  <a target="_blank" href={bmcMembershipUrl} rel="noreferrer">
                    <img src={BmcImg} alt="Buy me a coffee" style={{ width: '75%' }} />
                  </a>
                </div>
              </ListGroup.Item>

              <ListGroup.Item className="p-3">
                <Form>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      placeholder={t('Subscription.BmcEmailPlaceholder', 'Email you used for subscription')}
                      autoComplete="email"
                      required
                      value={subscriptionEmail}
                      onChange={(event) => setSubscriptionEmail(event.target.value)}
                    />

                    <Button
                      variant="outline-success"
                      disabled={!subscriptionEmail || loading.some((l) => l.key === LoadingParts.SubscriptionLoading && l.value === true)}
                      onClick={() => {
                        handleCheckSubscription();
                      }}
                    >
                      {t('Subscription.CheckSubscriptionButton', 'Check subscription')}
                    </Button>
                  </InputGroup>
                </Form>
              </ListGroup.Item>

              <ListGroup.Item className="p-3">
                <p>
                  <Trans t={t} i18nKey="Subscription.PaymentOtherMessage">
                    Please{' '}
                    <a href="mailto:beast.wot.app@gmail.com" target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>{' '}
                    if you would like to pay in some other way. We support a variety of payment methods such as PayPal, Payoneer,
                    cryptocurrency, and more.
                  </Trans>
                </p>
              </ListGroup.Item>
            </ListGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
}
