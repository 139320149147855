import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IWotAccountAccessToken } from './interfaces/user.interface';

export class AuthApiService {
  async loginWot(token: IWotAccountAccessToken, server: WotServer): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi('auth/login/wot', {
      method: 'POST',
      body: JSON.stringify({ token, server }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }

  async loginLocal(email: string, password: string): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi('auth/login/local', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }

  async register(email: string, password: string): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi('auth/register', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }

  async refresh(): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi('auth/refresh', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }

  async requestPasswordReset(email: string): Promise<SingleEntityResponseApiView<void>> {
    const response = await fetchApi('auth/reset-password/request', {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<void>;
    return body;
  }

  async resetPassword(token: string, password: string): Promise<SingleEntityResponseApiView<string>> {
    const response = await fetchApi('auth/reset-password', {
      method: 'POST',
      body: JSON.stringify({ token, password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<string>;
    return body;
  }
}
