import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/rootReducer';
import { AppThunk } from '../../redux/store';
import { ITankApiView } from '../../services/api/interfaces/tank.interface';
import { TanksApiService } from '../../services/api/tanks-api.service';
import { LogService } from '../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../loading/loadingSlice';
import { showToast } from '../toast/redux/toastSlice';

export interface TanksState {
  items: ITankApiView[];
}

const InitialState: TanksState = {
  items: [],
};

export const tanksSlice = createSlice({
  name: 'tanks',
  initialState: InitialState,
  reducers: {
    setTanks: (state, action: PayloadAction<ITankApiView[]>) => {
      state.items = action.payload;
    },
  },
});

export const { setTanks } = tanksSlice.actions;

export const getTanksAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.TanksLoading));

    const svc = new TanksApiService();
    const response = await svc.get();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      dispatch(setTanks(response.data));
    }
  } catch (e) {
    LogService.error(`Error while loading tanks`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.TanksLoading));
  }
};

export const selectTanks = (state: RootState) => state.tanks.items;

export default tanksSlice.reducer;
