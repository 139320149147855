import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getTournamentsListAsync, selectTournaments } from '../tournamentsSlice';
import { useEffect } from 'react';
import { TournamentsListItem } from './components/TournamentsListItem';

export function TournamentsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tournaments = useSelector(selectTournaments);

  useEffect(() => {
    dispatch(getTournamentsListAsync());
  }, [dispatch]);

  return (
    <Container>
      <Helmet>
        <title>{t('TournamentsList.Title', 'Wargaming WoT Blitz Beast - Tournaments list')}</title>
      </Helmet>

      <Row className="mb-3">
        <Col>
          <h1>{t('TournamentsList.Header', 'Tournaments')}</h1>
        </Col>
      </Row>

      <Row>
        <Col className="dashboard-column tournament-list-column">
          {tournaments.map((tournament, index) => {
            if (index % 3 === 0) {
              return <TournamentsListItem tournament={tournament} />;
            }
            return <></>;
          })}
        </Col>

        <Col className="dashboard-column tournament-list-column">
          {tournaments.map((tournament, index) => {
            if (index % 3 === 1) {
              return <TournamentsListItem tournament={tournament} />;
            }
            return <></>;
          })}
        </Col>

        <Col className="dashboard-column tournament-list-column">
          {tournaments.map((tournament, index) => {
            if (index % 3 === 2) {
              return <TournamentsListItem tournament={tournament} />;
            }
            return <></>;
          })}
        </Col>
      </Row>

      {tournaments.length === 0 && (
        <Row className="justify-content-center">
          <Col>
            <p className="text-center">{t('TournamentsList.NoTableRows', 'There are no tournaments yet...')}</p>
          </Col>
        </Row>
      )}
    </Container>
  );
}
