import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { IAccountTankApiView, IAccountTankSnapshotApiView } from '../../../services/api/interfaces/account-tanks.interface';
import { IAccountApiView, IAccountSnapshotApiView } from '../../../services/api/interfaces/account.interface';
import { FilterState, TimeRanges } from '../../filter/redux/filterSlice';
import { IChartValue, IStatistics } from '../interfaces';

export interface CoreStatisticsState {
  accountStats: { wotId?: number; stats?: IStatistics };
  accountStatsFiltered: { wotId?: number; stats?: IStatistics; snapshotsLength?: number };
  accountChart: { wotId?: number; chart?: Array<IChartValue> };
  tankStats: { wotId?: number; stats?: IStatistics };
  tankStatsFiltered: { wotId?: number; stats?: IStatistics; snapshotsLength?: number };
  tankChart: { wotId?: number; chart?: Array<IChartValue> };
}

const InitialState: CoreStatisticsState = {
  accountStats: { wotId: undefined, stats: undefined },
  accountStatsFiltered: { wotId: undefined, stats: undefined, snapshotsLength: 0 },
  accountChart: { wotId: undefined, chart: undefined },
  tankStats: { wotId: undefined, stats: undefined },
  tankStatsFiltered: { wotId: undefined, stats: undefined, snapshotsLength: 0 },
  tankChart: { wotId: undefined, chart: undefined },
};

export const coreStatisticsSlice = createSlice({
  name: 'coreStatistics',
  initialState: InitialState,
  reducers: {
    resetCoreStatistics: () => InitialState,
    setAccountStats: (state, action: PayloadAction<IAccountApiView | undefined>) => {
      state.accountStats = { wotId: action.payload?.wotId, stats: calculateStats(action.payload?.snapshots, false) };
    },
    setAccountStatsFiltered: (
      state,
      action: PayloadAction<{
        filter: FilterState;
        accountFiltered: IAccountApiView | undefined;
        accountTanksFiltered: Array<IAccountTankApiView>;
      }>,
    ) => {
      const filteredByTank =
        (action.payload.filter.tankTypes.length !== 0 && action.payload.filter.tankTypes.length !== 4) ||
        (action.payload.filter.tankTiers.length !== 0 && action.payload.filter.tankTiers.length !== 10);
      const filteredByDate = action.payload.filter.timeRange !== 'all';

      if (!filteredByTank) {
        // calculate from account snapshots
        if (
          action.payload.accountFiltered == null ||
          action.payload.accountFiltered.snapshots.length === 0 ||
          (filteredByDate === true && action.payload.accountFiltered.snapshots.length < 2)
        ) {
          state.accountStatsFiltered = InitialState.accountStatsFiltered;
          state.accountChart = InitialState.accountChart;
          return;
        }

        state.accountStatsFiltered = {
          wotId: action.payload.accountFiltered.wotId,
          stats: calculateStats(action.payload.accountFiltered.snapshots, filteredByDate),
          snapshotsLength: action.payload.accountFiltered.snapshots.length,
        };

        state.accountChart = {
          wotId: action.payload.accountFiltered.wotId,
          chart: calculateChartData(action.payload.accountFiltered.snapshots, filteredByDate),
        };
      } else {
        // Calculate from tanks snapshots
        const tanksStatistics = Array<{ wotId: number; snapshots: Array<IAccountTankSnapshotApiView>; stats?: IStatistics }>();

        for (const accountTank of action.payload.accountTanksFiltered) {
          if (accountTank.snapshots.length === 0 || (filteredByDate === true && accountTank.snapshots.length < 2)) {
            continue;
          }

          const accountTankStatistics = calculateStats(accountTank.snapshots, filteredByDate);
          tanksStatistics.push({ wotId: accountTank.wotId, snapshots: accountTank.snapshots, stats: accountTankStatistics });
        }

        if (tanksStatistics.length === 0) {
          state.accountStatsFiltered = InitialState.accountStatsFiltered;
          state.accountChart = InitialState.accountChart;
          return;
        }

        // calculate stats for the whole account
        const accountStatistics: IStatistics = {
          lastBattleTime: tanksStatistics
            .flatMap((ts) => ts.snapshots)
            .reduce((prev, current) => (current.lastBattleTime > prev ? current.lastBattleTime : prev), ''),
          regular: {
            battles: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.battles ?? 0), 0),
            wins: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.wins ?? 0), 0),
            losses: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.losses ?? 0), 0),
            survivedBattles: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.survivedBattles ?? 0), 0),
            damageDealt: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.damageDealt ?? 0), 0),
            damageReceived: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.damageReceived ?? 0), 0),
            spotted: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.spotted ?? 0), 0),
            frags: tanksStatistics.reduce((prev, current) => prev + (current.stats?.regular.frags ?? 0), 0),
          },
          rating: undefined,
        };

        state.accountStatsFiltered = {
          wotId: action.payload.accountFiltered?.wotId,
          stats: accountStatistics,
          snapshotsLength: tanksStatistics.reduce(
            (prev, current) => (prev > current.snapshots.length ? prev : current.snapshots.length),
            0,
          ),
        };

        // calculate chart data
        const accountChart = new Array<{ value: IChartValue; date: Date }>();

        if (tanksStatistics.flatMap((ts) => ts.snapshots).length > 0) {
          // start with the first date
          const date = new Date(
            tanksStatistics
              .flatMap((ts) => ts.snapshots)
              .reduce((prev, current) => (prev.lastBattleTime < current.lastBattleTime ? prev : current)).lastBattleTime,
          );

          const endDate = new Date();
          endDate.setHours(23, 59, 59, 999);

          // set all dates to undefined
          while (date.getTime() <= endDate.getTime()) {
            accountChart.push({ value: { label: date.toLocaleDateString() }, date: new Date(date) });
            date.setDate(date.getDate() + 1);
          }

          // calculate chart data for each tank
          const tanksCharts = tanksStatistics.map((ts) => {
            return { wotId: ts.wotId, chart: calculateChartData(ts.snapshots, filteredByDate) };
          });

          for (const accountChartItem of accountChart) {
            // skip the date if all tanks have no data for this date
            if (tanksCharts.every((tc) => tc.chart.find((v) => v.label === accountChartItem.value.label)?.stats == null)) {
              continue;
            }

            for (const tankChart of tanksCharts) {
              let tankChartValue = tankChart.chart.find((v) => v.label === accountChartItem.value.label);

              if (tankChartValue == null) {
                continue;
              }

              if (tankChartValue.stats == null) {
                // find the closest date before the current item date
                tankChartValue = tankChart.chart.reduce<IChartValue | undefined>((prev, current) => {
                  if (
                    current.stats != null &&
                    current.stats.lastBattleTime < accountChartItem.date.toISOString() &&
                    (prev == null || prev.stats == null || prev.stats.lastBattleTime < current.stats.lastBattleTime)
                  ) {
                    return current;
                  } else {
                    return prev;
                  }
                }, undefined);
              }

              // accumulate stats
              if (tankChartValue != null && tankChartValue.stats != null) {
                if (accountChartItem.value.stats == null) {
                  accountChartItem.value.stats = { ...tankChartValue.stats, regular: { ...tankChartValue.stats.regular } };
                } else {
                  accountChartItem.value.stats.lastBattleTime =
                    accountChartItem.value.stats.lastBattleTime > tankChartValue.stats.lastBattleTime
                      ? accountChartItem.value.stats.lastBattleTime
                      : tankChartValue.stats.lastBattleTime;
                  accountChartItem.value.stats.regular.battles += tankChartValue.stats.regular.battles;
                  accountChartItem.value.stats.regular.wins += tankChartValue.stats.regular.wins;
                  accountChartItem.value.stats.regular.losses += tankChartValue.stats.regular.losses;
                  accountChartItem.value.stats.regular.survivedBattles += tankChartValue.stats.regular.survivedBattles;
                  accountChartItem.value.stats.regular.damageDealt += tankChartValue.stats.regular.damageDealt;
                  accountChartItem.value.stats.regular.damageReceived += tankChartValue.stats.regular.damageReceived;
                  accountChartItem.value.stats.regular.spotted += tankChartValue.stats.regular.spotted;
                  accountChartItem.value.stats.regular.frags += tankChartValue.stats.regular.frags;
                }
              }
            }
          }
        }

        state.accountChart = { wotId: action.payload.accountFiltered?.wotId, chart: accountChart.map((v) => v.value) };
      }
    },
    setTankStats: (state, action: PayloadAction<IAccountTankApiView | undefined>) => {
      state.tankStats = { wotId: action.payload?.wotId, stats: calculateStats(action.payload?.snapshots, false) };
    },
    setTankStatsFiltered: (state, action: PayloadAction<{ accountTank: IAccountTankApiView | undefined; filterTimeRange: TimeRanges }>) => {
      const filteredByDate = action.payload.filterTimeRange !== 'all';

      if (
        action.payload.accountTank == null ||
        action.payload.accountTank.snapshots.length === 0 ||
        (filteredByDate === true && action.payload.accountTank.snapshots.length < 2)
      ) {
        state.tankStatsFiltered = InitialState.tankStatsFiltered;
        state.tankChart = InitialState.tankChart;
        return;
      }

      state.tankStatsFiltered = {
        wotId: action.payload.accountTank.wotId,
        stats: calculateStats(action.payload.accountTank.snapshots, filteredByDate),
        snapshotsLength: action.payload.accountTank.snapshots.length,
      };

      state.tankChart = {
        wotId: action.payload.accountTank.wotId,
        chart: calculateChartData(action.payload.accountTank.snapshots, filteredByDate),
      };
    },
  },
});

export const { resetCoreStatistics, setAccountStats, setAccountStatsFiltered, setTankStats, setTankStatsFiltered } =
  coreStatisticsSlice.actions;

function calculateChartData(
  snapshots: Array<IAccountSnapshotApiView | IAccountTankSnapshotApiView> | undefined,
  useDiff: boolean,
): Array<IChartValue> {
  if (snapshots == null || snapshots.length === 0 || (useDiff === true && snapshots.length < 2)) {
    return [];
  }

  const sortedSnapshots = [...snapshots].sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime));
  const firstSnapshot = sortedSnapshots[0];

  const date = new Date(firstSnapshot.lastBattleTime);
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  const dayliRecordsMap = new Map<string, IChartValue>();

  // set all dates to undefined
  while (date.getTime() <= endDate.getTime()) {
    dayliRecordsMap.set(date.toLocaleDateString(), { label: date.toLocaleDateString() });
    date.setDate(date.getDate() + 1);
  }

  // fill the dates where we have data
  // since the records are ordered we will use the latest value for a day
  for (let i = 0; i < sortedSnapshots.length; i++) {
    dayliRecordsMap.set(new Date(sortedSnapshots[i].lastBattleTime).toLocaleDateString(), {
      label: new Date(sortedSnapshots[i].lastBattleTime).toLocaleDateString(),
      stats: useDiff === true ? (i === 0 ? undefined : getSnapshotsDiff(sortedSnapshots[i], firstSnapshot)) : sortedSnapshots[i],
    });
  }

  return Array.from(dayliRecordsMap.values());
}

function calculateStats(
  snapshots: Array<IAccountSnapshotApiView | IAccountTankSnapshotApiView> | undefined,
  useDiff: boolean,
): IStatistics | undefined {
  if (snapshots == null || snapshots.length === 0 || (useDiff === true && snapshots.length < 2)) {
    return undefined;
  }

  const sortedSnapshots = [...snapshots].sort((a, b) => a.lastBattleTime.localeCompare(b.lastBattleTime));
  const firstSnapshot = sortedSnapshots[0];
  const latestSnapshot = sortedSnapshots[sortedSnapshots.length - 1];

  return useDiff === true ? getSnapshotsDiff(latestSnapshot, firstSnapshot) : latestSnapshot;
}

function getSnapshotsDiff(
  a: IAccountSnapshotApiView | IAccountTankSnapshotApiView,
  b: IAccountSnapshotApiView | IAccountTankSnapshotApiView,
): IAccountSnapshotApiView | IAccountTankSnapshotApiView {
  return {
    lastBattleTime: a.lastBattleTime,
    regular: {
      battles: a.regular.battles - b.regular.battles,
      wins: a.regular.wins - b.regular.wins,
      losses: a.regular.losses - b.regular.losses,
      survivedBattles: a.regular.survivedBattles - b.regular.survivedBattles,
      damageDealt: a.regular.damageDealt - b.regular.damageDealt,
      damageReceived: a.regular.damageReceived - b.regular.damageReceived,
      spotted: a.regular.spotted - b.regular.spotted,
      frags: a.regular.frags - b.regular.frags,
    },
    rating:
      (a as IAccountSnapshotApiView).rating != null && (b as IAccountSnapshotApiView).rating != null
        ? {
            battles: ((a as IAccountSnapshotApiView).rating?.battles ?? 0) - ((b as IAccountSnapshotApiView).rating?.battles ?? 0),
          }
        : undefined,
  };
}

export const selectAcountStats = (state: RootState) => state.statistics.coreStatistics.accountStats;
export const selectAcountStatsFiltered = (state: RootState) => state.statistics.coreStatistics.accountStatsFiltered;
export const selectAccountChart = (state: RootState) => state.statistics.coreStatistics.accountChart;
export const selectTankStats = (state: RootState) => state.statistics.coreStatistics.tankStats;
export const selectTankStatsFiltered = (state: RootState) => state.statistics.coreStatistics.tankStatsFiltered;
export const selectTankChart = (state: RootState) => state.statistics.coreStatistics.tankChart;

export default coreStatisticsSlice.reducer;
