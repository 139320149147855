import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/rootReducer';
import { AppThunk } from '../../redux/store';
import { LogService } from '../../services/log.service';
import { LoadingParts, startLoading, stopLoading } from '../loading/loadingSlice';
import { showToast } from '../toast/redux/toastSlice';
import {
  ITournamentAccountBonusSep2023ApiView,
  ITournamentAccountDailyResultSep2023ApiView,
  ITournamentAccountsApiView,
  ITournamentBonusesApiView,
  ITournamentShortApiView,
  TournamentSep2023Stats,
} from '../../services/api/interfaces/tournament.interface';
import { TournamentApiService } from '../../services/api/tournament-api.service';
import { TournamentSep2023ApiService } from '../../services/api/tournament-sep-2023-api.service';

export interface TournamentsState {
  list: ITournamentShortApiView[];
  stats: Record<string, TournamentSep2023Stats>;
  accounts?: ITournamentAccountsApiView;
  bonuses?: ITournamentBonusesApiView;
  recentReward?: ITournamentAccountDailyResultSep2023ApiView | undefined;
}

const InitialState: TournamentsState = {
  list: [],
  stats: {},
  accounts: undefined,
  bonuses: undefined,
  recentReward: undefined,
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState: InitialState,
  reducers: {
    setList: (state, action: PayloadAction<ITournamentShortApiView[]>) => {
      state.list = action.payload;
    },
    setStats: (state, action: PayloadAction<{ id: string; stats: TournamentSep2023Stats }>) => {
      state.stats[action.payload.id] = action.payload.stats;
    },
    setAccounts: (state, action: PayloadAction<ITournamentAccountsApiView | undefined>) => {
      state.accounts = action.payload;
    },
    setBonuses: (state, action: PayloadAction<ITournamentBonusesApiView | undefined>) => {
      state.bonuses = action.payload;
    },
    addBonus: (state, action: PayloadAction<ITournamentAccountBonusSep2023ApiView>) => {
      state.bonuses?.bonuses.push(action.payload);
    },
    deleteBonus: (state, action: PayloadAction<string>) => {
      state.bonuses?.bonuses.splice(
        state.bonuses?.bonuses.findIndex((x) => x.id === action.payload),
        1,
      );
    },
    setRecentReward: (state, action: PayloadAction<ITournamentAccountDailyResultSep2023ApiView | undefined>) => {
      state.recentReward = action.payload;
    },
  },
});

export const { setList, setAccounts, setBonuses, setRecentReward, setStats, addBonus, deleteBonus } = tournamentsSlice.actions;

export const getTournamentsListAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.TournamentsListLoading));

    const svc = new TournamentApiService();
    const response = await svc.getList();

    if (response.success !== true) {
      dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
    } else {
      dispatch(setList(response.data));

      for (const tournament of response.data) {
        const statsResponse = await svc.getStats(tournament.id);
        if (statsResponse.success === true && statsResponse.data) {
          dispatch(setStats({ id: tournament.id, stats: statsResponse.data }));
        }
      }
    }
  } catch (e) {
    LogService.error(`Error while loading tournaments list`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.TournamentsListLoading));
  }
};

export const getTournamentAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentApiService();

      const accountsResponse = await svc.getAccounts(id);
      const bonusesResponse = await svc.getBonuses(id);

      if (accountsResponse.success !== true) {
        dispatch(showToast({ title: 'Error', message: accountsResponse.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else if (bonusesResponse.success !== true) {
        dispatch(showToast({ title: 'Error', message: bonusesResponse.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setAccounts(accountsResponse.data ?? undefined));
        dispatch(setBonuses(bonusesResponse.data ?? undefined));
      }
    } catch (e) {
      LogService.error(`Error while loading a tournament`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const participateSep2023Async =
  (tournamentId: string, accountId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentSep2023ApiService();
      const response = await svc.participate(tournamentId, accountId);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setAccounts(response.data ?? undefined));
      }
    } catch (e) {
      LogService.error(`Error while participating in a tournament`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const checkSep2023Async =
  (tournamentId: string, accountId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentSep2023ApiService();
      const response = await svc.check(tournamentId, accountId);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setAccounts(response.data ?? undefined));
      }
    } catch (e) {
      LogService.error(`Error while checking a tournament`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const rewardSep2023Async =
  (tournamentId: string, accountId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentSep2023ApiService();
      const response = await svc.reward(tournamentId, accountId);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setAccounts(response.data ?? undefined));

        // it is important to set the recent reward only after getting reward action
        dispatch(
          setRecentReward(
            response.data?.accounts
              .find((x) => x.accountId === accountId)
              ?.results.find((x) => new Date(x.date).toDateString() === new Date().toDateString()),
          ),
        );
      }
    } catch (e) {
      LogService.error(`Error while getting a tournament reward`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const addTournamentAccountBonusAsync =
  (
    tournamentId: string,
    accountId: string,
    payload: { date: Date; source: 'trovo' | 'manual'; comment: string; tankLevel: 5 | 6 | 7 | 8 | 9 | 10; parts: number },
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentSep2023ApiService();
      const response = await svc.addBonus(tournamentId, accountId, payload);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        if (response.data != null) {
          dispatch(addBonus(response.data));
        }
      }
    } catch (e) {
      LogService.error(`Error while adding a tournament bonus`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const deleteTournamentAccountBonusAsync =
  (tournamentId: string, accountId: string, bonusId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.TournamentLoading));

      const svc = new TournamentSep2023ApiService();
      const response = await svc.deleteBonus(tournamentId, accountId, bonusId);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(deleteBonus(bonusId));
      }
    } catch (e) {
      LogService.error(`Error while deleting a tournament bonus`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.TournamentLoading));
    }
  };

export const selectTournaments = (state: RootState) => {
  return state.tournaments.list;
};

export const selectTournamentAccounts = (state: RootState) => {
  return state.tournaments.accounts;
};

export const selectTournamentBonuses = (state: RootState) => {
  return state.tournaments.bonuses;
};

export const selectRecentReward = (state: RootState) => {
  return state.tournaments.recentReward;
};

export const selectTournamentStats =
  (id: string) =>
  (state: RootState): TournamentSep2023Stats | undefined => {
    return state.tournaments.stats[id];
  };

export default tournamentsSlice.reducer;
