import { Route, Routes } from 'react-router-dom';
import { WidgetBlitzCredits } from './blitz/credits/WidgetBlitzCredits';
import { WidgetBlitzSession } from './blitz/session/WidgetBlitzSession';
import { WidgetTrovoFollowers } from './trovo/followers/WidgetTrovoFollowers';
import { WidgetTrovoMana } from './trovo/mana/WidgetTrovoMana';
import { WidgetBlitzSessionDetailed } from './blitz/session/detailed/WidgetBlitzSessionDetailed';
import { Col, Container, Row } from 'react-bootstrap';
import { GoogleAd } from '../ads/GoogleAd';

export function WidgetRuns() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <GoogleAd unit="widget-header" />
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <Col>
          <Routes>
            <Route path="trovo/followers" element={<WidgetTrovoFollowers />} />
            <Route path="trovo/mana" element={<WidgetTrovoMana />} />
            <Route path="blitz/session" element={<WidgetBlitzSession />} />
            <Route path="blitz/session-detailed" element={<WidgetBlitzSessionDetailed />} />
            <Route path="blitz/credits" element={<WidgetBlitzCredits />} />
          </Routes>
        </Col>
      </Row>

      <Row>
        <Col>
          <GoogleAd unit="widget-footer" />
        </Col>
      </Row>
    </Container>
  );
}
