import './SearchAccount.scss';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { WotApiService } from '../../../services/api/wot-api.service';
import { IWotApiAccountSearch } from '../../../services/api/interfaces/wot-api-account-info.interface';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { LogService } from '../../../services/log.service';
import { WotServer } from '../../../utils/wot-server.type';
import { selectAccountsList } from '../../account/redux/accountsSlice';
import { useNavigate } from 'react-router-dom';

export interface ISearchAccountResult {
  name: string;
  server: WotServer;
  id: number;
}

export function SearchAccount(props: { onSelected?: (selected: ISearchAccountResult) => void }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountsList = useSelector(selectAccountsList);

  const [results, setResults] = useState<ISearchAccountResult[]>([]);

  const onSearch = async (query: string) => {
    setResults([]);

    if (query.length > 2) {
      try {
        dispatch(startLoading(LoadingParts.SearchingAccount));
        const wotApiSvc = new WotApiService();

        const euResults = wotApiSvc.searchAccount(query, 'eu');
        const comResults = wotApiSvc.searchAccount(query, 'com');
        const asiaResults = wotApiSvc.searchAccount(query, 'asia');

        const wotApiResults = await Promise.allSettled([euResults, comResults, asiaResults]);

        setResults([
          ...(wotApiResults[0].status === 'fulfilled'
            ? wotApiResults[0].value.data?.map((r: IWotApiAccountSearch) => ({
                name: r.nickname,
                server: 'eu' as WotServer,
                id: r.account_id,
              })) || []
            : []),
          ...(wotApiResults[1].status === 'fulfilled'
            ? wotApiResults[1].value.data?.map((r: IWotApiAccountSearch) => ({
                name: r.nickname,
                server: 'com' as WotServer,
                id: r.account_id,
              })) || []
            : []),
          ...(wotApiResults[2].status === 'fulfilled'
            ? wotApiResults[2].value.data?.map((r: IWotApiAccountSearch) => ({
                name: r.nickname,
                server: 'asia' as WotServer,
                id: r.account_id,
              })) || []
            : []),
        ]);
      } catch (e) {
        LogService.error(`Error while searching account`);
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.SearchingAccount));
      }
    }
  };

  const onSelected = (selected: ISearchAccountResult) => {
    navigate(`/accounts/${selected.server}/${selected.id}`);
    if (props.onSelected) props.onSelected(selected);
  };

  return (
    <Typeahead
      id="search-account"
      className={`account-search${accountsList.length > 0 ? '' : ' account-search-glowing'}`}
      emptyLabel={t('Header.SearchAccount.EmptyLabel', 'Account not found')}
      placeholder={t('Header.SearchAccount.Placeholder', 'Enter account name')}
      paginationText={t('Header.SearchAccount.PaginationText', 'Show more')}
      maxResults={7}
      labelKey={(option) => `(${(option as ISearchAccountResult).server}) ${(option as ISearchAccountResult).name}`}
      options={results}
      onChange={(selected) => onSelected(selected[0] as ISearchAccountResult)}
      onInputChange={(text) => void onSearch(text)}
      selected={[]}
    />
  );
}
