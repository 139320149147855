import { useState, useEffect } from 'react';
import { Row, Col, Table, Stack, Button, Form, ToggleButtonGroup, ToggleButton, Alert, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectTanks } from '../../tanks/tanksSlice';
import { StarFill } from 'react-bootstrap-icons';
import { ReactComponent as HeavyTankIcon } from '../../../images/heavy-icon.svg';
import { ReactComponent as MedTankIcon } from '../../../images/med-icon.svg';
import { ReactComponent as LightTankIcon } from '../../../images/light-icon.svg';
import { ReactComponent as TdTankIcon } from '../../../images/td-icon.svg';
import { useTranslation } from 'react-i18next';
import { TankTiers, TankTypes } from '../../../services/api/interfaces/tank.interface';
import { getServerStatisticsAsync, selectServerStatistics } from '../../statistics/redux/serverStatisticsSlice';
import { WotServer } from '../../../utils/wot-server.type';
import { Helmet } from 'react-helmet';
import { TimeRanges } from '../../filter/redux/filterSlice';
import { IServerTankStatistics, ITankStatisticsData } from '../../../services/api/interfaces/server-statistics.interface';
import { getMaxPossibleTankHp } from '../../tanks/tank.utils';
import { getTransparency } from '../../../utils/get-transparency.util';

export function TopTanks() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [wotServer, setWotServer] = useState<WotServer>('eu');
  const [tiers, setTiers] = useState<TankTiers[]>([]);
  const [types, setTypes] = useState<TankTypes[]>([]);
  const [battles, setBattles] = useState<number>(50000);
  const [timeRange, setTimeRange] = useState<TimeRanges>('3m');

  const serverStats = useSelector(selectServerStatistics(wotServer));

  const tanks = useSelector(selectTanks);

  const [tableData, setTableData] = useState<
    Array<{
      wotId: number;
      battles: number;
      battlesBackgroundColor?: string;
      name?: string;
      tier?: TankTiers;
      type?: TankTypes;
      isPremium?: boolean;
      winRate: number;
      winRateBackgroundColor?: string;
      avgDamage: number;
      avgDamageBackgroundColor?: string;
      fragsRate: number;
      fragsRateBackgroundColor?: string;
      remainingHpRate?: number;
      remainingHpRateBackgroundColor?: string;
      mastery?: number;
      masteryBackgroundColor?: string;
      avgBattleLifeTime?: number;
      avgBattleLifeTimeBackgroundColor?: string;
    }>
  >([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'tier' | 'battles' | 'win' | 'damage' | 'frags' | 'remainingHp' | 'mastery' | 'battleLifeTime';
    desc: boolean;
  }>({
    column: 'win',
    desc: true,
  });

  const [showRules, setShowRules] = useState(true);

  useEffect(() => {
    dispatch(getServerStatisticsAsync(wotServer));
  }, [dispatch, wotServer]);

  // prepare table data
  useEffect(() => {
    const calculatedTableData = serverStats?.tanks
      .filter((t) => {
        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        return tank != null;
      })
      .filter((t) => {
        if (tiers.length === 0 || tiers.length === 10) {
          return true;
        }

        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        return tank == null || tiers.includes(tank.tier);
      })
      .filter((t) => {
        if (types.length === 0 || types.length === 4) {
          return true;
        }

        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        return tank == null || types.includes(tank.type);
      })
      .filter((t) => {
        const stats = chooseStatsData(t, timeRange);
        return stats.regular.battles >= battles && stats.regular.battles > 0;
      })
      .map((t) => {
        const tank = tanks.find((tt) => tt.wotId === t.wotId);
        const stats = chooseStatsData(t, timeRange);

        const remainingHpRate =
          (getMaxPossibleTankHp(tank) - stats.regular.damageReceived / (stats.regular.battles || 1)) / getMaxPossibleTankHp(tank);

        const avgBattleLifeTime = stats.battleLifeTime / (stats.regular.battles || 1);

        const result = {
          wotId: t.wotId,
          battles: stats.regular.battles,
          battlesBackgroundColor: undefined as string | undefined,
          name: i18n.language === 'ru' || i18n.language === 'uk' ? tank?.name.ru ?? tank?.name.en : tank?.name.en,
          tier: tank?.tier,
          type: tank?.type,
          isPremium: tank?.isPremium,
          winRate: stats.regular.wins / (stats.regular.battles || 1),
          winRateBackgroundColor: undefined as string | undefined,
          avgDamage: stats.regular.damageDealt / (stats.regular.battles || 1),
          avgDamageBackgroundColor: undefined as string | undefined,
          fragsRate: stats.regular.frags / (stats.regular.battles || 1),
          fragsRateBackgroundColor: undefined as string | undefined,
          remainingHpRate,
          remainingHpRateBackgroundColor: undefined as string | undefined,
          mastery:
            stats.mastery?.markOfMastery == null || stats.mastery?.markOfMastery === 0
              ? undefined
              : stats.regular.battles / stats.mastery.markOfMastery,
          masteryBackgroundColor: undefined as string | undefined,
          avgBattleLifeTime,
          avgBattleLifeTimeBackgroundColor: undefined as string | undefined,
        };

        // sanitize result
        if (result.winRate < 0) {
          result.winRate = 0;
        }
        if (result.winRate > 1) {
          result.winRate = 1;
        }
        if (result.avgDamage < 0) {
          result.avgDamage = 0;
        }
        if (result.fragsRate < 0) {
          result.fragsRate = 0;
        }
        if (result.remainingHpRate < 0) {
          result.remainingHpRate = 0;
        }
        if (result.remainingHpRate > 1) {
          result.remainingHpRate = 1;
        }
        if (result.mastery != null && result.mastery < 1) {
          result.mastery = 1;
        }
        if (result.avgBattleLifeTime < 0) {
          result.avgBattleLifeTime = 0;
        }

        return result;
      })
      .sort((a, b) => {
        switch (sorting.column) {
          case 'battles':
            return sorting.desc ? b.battles - a.battles : a.battles - b.battles;
          case 'damage':
            return sorting.desc ? b.avgDamage - a.avgDamage : a.avgDamage - b.avgDamage;
          case 'name':
            return sorting.desc ? (b.name ?? '').localeCompare(a.name ?? '') : (a.name ?? '').localeCompare(b.name ?? '');
          case 'tier':
            return sorting.desc ? (b.tier ?? 0) - (a.tier ?? 0) : (a.tier ?? 0) - (b.tier ?? 0);
          case 'win':
            return sorting.desc ? b.winRate - a.winRate : a.winRate - b.winRate;
          case 'frags':
            return sorting.desc ? b.fragsRate - a.fragsRate : a.fragsRate - b.fragsRate;
          case 'remainingHp':
            return sorting.desc
              ? (b?.remainingHpRate ?? -1) - (a.remainingHpRate ?? -1)
              : (a.remainingHpRate ?? -1) - (b.remainingHpRate ?? -1);
          case 'mastery':
            return sorting.desc ? (b.mastery ?? 999999) - (a.mastery ?? 999999) : (a.mastery ?? 999999) - (b.mastery ?? 999999);
          case 'battleLifeTime':
            return sorting.desc
              ? (b.avgBattleLifeTime ?? -1) - (a.avgBattleLifeTime ?? -1)
              : (a.avgBattleLifeTime ?? -1) - (b.avgBattleLifeTime ?? -1);
          default:
            return sorting.desc ? (b.tier ?? 0) - (a.tier ?? 0) : (a.tier ?? 0) - (b.tier ?? 0);
        }
      });

    // set background colors
    const limits = {
      minBattle: undefined as number | undefined,
      maxBattle: undefined as number | undefined,
      minWin: undefined as number | undefined,
      maxWin: undefined as number | undefined,
      minDamage: undefined as number | undefined,
      maxDamage: undefined as number | undefined,
      minFrags: undefined as number | undefined,
      maxFrags: undefined as number | undefined,
      minRemainingHp: undefined as number | undefined,
      maxRemainingHp: undefined as number | undefined,
      minMastery: undefined as number | undefined,
      maxMastery: undefined as number | undefined,
      minBattleLifeTime: undefined as number | undefined,
      maxBattleLifeTime: undefined as number | undefined,
    };
    calculatedTableData?.forEach((t) => {
      if (limits.minBattle == null || t.battles < limits.minBattle) {
        limits.minBattle = t.battles;
      }
      if (limits.maxBattle == null || t.battles > limits.maxBattle) {
        limits.maxBattle = t.battles;
      }
      if (limits.minWin == null || t.winRate < limits.minWin) {
        limits.minWin = t.winRate;
      }
      if (limits.maxWin == null || t.winRate > limits.maxWin) {
        limits.maxWin = t.winRate;
      }
      if (limits.minDamage == null || t.avgDamage < limits.minDamage) {
        limits.minDamage = t.avgDamage;
      }
      if (limits.maxDamage == null || t.avgDamage > limits.maxDamage) {
        limits.maxDamage = t.avgDamage;
      }
      if (limits.minFrags == null || t.fragsRate < limits.minFrags) {
        limits.minFrags = t.fragsRate;
      }
      if (limits.maxFrags == null || t.fragsRate > limits.maxFrags) {
        limits.maxFrags = t.fragsRate;
      }
      if (limits.minRemainingHp == null || t.remainingHpRate < limits.minRemainingHp) {
        limits.minRemainingHp = t.remainingHpRate;
      }
      if (limits.maxRemainingHp == null || t.remainingHpRate > limits.maxRemainingHp) {
        limits.maxRemainingHp = t.remainingHpRate;
      }
      if (limits.minMastery == null || (t.mastery != null && t.mastery < limits.minMastery)) {
        limits.minMastery = t.mastery;
      }
      if (limits.maxMastery == null || (t.mastery != null && t.mastery > limits.maxMastery)) {
        limits.maxMastery = t.mastery;
      }
      if (limits.minBattleLifeTime == null || t.avgBattleLifeTime < limits.minBattleLifeTime) {
        limits.minBattleLifeTime = t.avgBattleLifeTime;
      }
      if (limits.maxBattleLifeTime == null || t.avgBattleLifeTime > limits.maxBattleLifeTime) {
        limits.maxBattleLifeTime = t.avgBattleLifeTime;
      }
    });

    calculatedTableData?.forEach((t) => {
      t.battlesBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.battles, limits.minBattle ?? 0, limits.maxBattle ?? 0)})`;
      t.winRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.winRate, limits.minWin ?? 0, limits.maxWin ?? 0)})`;
      t.avgDamageBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgDamage, limits.minDamage ?? 0, limits.maxDamage ?? 0)})`;
      t.fragsRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.fragsRate, limits.minFrags ?? 0, limits.maxFrags ?? 0)})`;
      t.remainingHpRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(
        t.remainingHpRate,
        limits.minRemainingHp ?? 0,
        limits.maxRemainingHp ?? 0,
      )})`;
      t.masteryBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.mastery, limits.minMastery ?? 0, limits.maxMastery ?? 0, true)})`;
      t.avgBattleLifeTimeBackgroundColor = `rgba(89, 207, 70, ${
        getTransparency(t.avgBattleLifeTime, limits.minBattleLifeTime ?? 0, limits.maxBattleLifeTime ?? 0) ?? 0
      })`;
    });

    setTableData(calculatedTableData ?? []);
  }, [battles, i18n.language, serverStats?.tanks, sorting, tanks, tiers, timeRange, types]);

  const chooseStatsData = (stats: IServerTankStatistics, timeRange: TimeRanges): ITankStatisticsData => {
    switch (timeRange) {
      case '1w':
        return stats.timedStatistics.oneWeek;
      case '2w':
        return stats.timedStatistics.twoWeeks;
      case '1m':
        return stats.timedStatistics.oneMonth;
      case '3m':
        return stats.timedStatistics.threeMonths;
      case '6m':
        return stats.timedStatistics.sixMonths;
      case '1y':
        return stats.timedStatistics.oneYear;
      default:
        return stats.statistics;
    }
  };

  const handleTankTierChange = (tiers: TankTiers[]) => {
    if (tiers.includes(3)) {
      if (!tiers.includes(2)) {
        tiers.push(2);
      }

      if (!tiers.includes(1)) {
        tiers.push(1);
      }
    } else {
      tiers = tiers.filter((v) => v !== 2 && v !== 1);
    }

    setTiers(tiers);
  };

  const handleTankTypeChange = (types: TankTypes[]) => {
    setTypes(types);
  };

  return (
    <Container fluid>
      <Helmet>
        <title>{t('TopTanks.Title', 'Wargaming WoT Blitz Beast - Top tanks statistics')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('TopTanks.Header', 'Top tanks')}</h1>
        </Col>
        <Col>
          <div className="filter-container">
            <div>
              <Form.Select onChange={(event) => setTimeRange(event.target.value as TimeRanges)} value={timeRange}>
                <option value="1w">{t('TopTanks.TimeRangeFilterLabel1w', '1 week')}</option>
                <option value="2w">{t('TopTanks.TimeRangeFilterLabel2w', '2 weeks')}</option>
                <option value="1m">{t('TopTanks.TimeRangeFilterLabel1m', '1 month')}</option>
                <option value="3m">{t('TopTanks.TimeRangeFilterLabel3m', '3 months')}</option>
                <option value="6m">{t('TopTanks.TimeRangeFilterLabel6m', '6 months')}</option>
                <option value="1y">{t('TopTanks.TimeRangeFilterLabel1y', '1 year')}</option>
                <option value="all">{t('TopTanks.TimeRangeFilterLabelAll', 'All')}</option>
              </Form.Select>
            </div>

            <div>
              <Form.Select onChange={(event) => setBattles(+event.target.value)} value={battles}>
                <option value="0">{t('TopTanks.BattlesFilterLabelAll', 'All')}</option>
                <option value="1000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '1K' })}</option>
                <option value="5000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '5K' })}</option>
                <option value="10000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '10K' })}</option>
                <option value="20000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '20K' })}</option>
                <option value="50000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '50K' })}</option>
                <option value="100000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '100K' })}</option>
                <option value="200000">{t('TopTanks.BattlesFilterLabel', '>{{value}} battles', { value: '200K' })}</option>
              </Form.Select>
            </div>

            <div>
              <ToggleButtonGroup type="checkbox" value={tiers} onChange={handleTankTierChange}>
                <ToggleButton id="tbg-btn-3" value={3} variant="outline-secondary">
                  <span className="filter-tank-tier">{'<'}III</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-4" value={4} variant="outline-secondary">
                  <span className="filter-tank-tier">IV</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-5" value={5} variant="outline-secondary">
                  <span className="filter-tank-tier"> V</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-6" value={6} variant="outline-secondary">
                  <span className="filter-tank-tier">VI</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-7" value={7} variant="outline-secondary">
                  <span className="filter-tank-tier">VII</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-8" value={8} variant="outline-secondary">
                  <span className="filter-tank-tier">VIII</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-9" value={9} variant="outline-secondary">
                  <span className="filter-tank-tier">IX</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-10" value={10} variant="outline-secondary">
                  <span className="filter-tank-tier">X</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <ToggleButtonGroup type="checkbox" value={types} onChange={handleTankTypeChange}>
                <ToggleButton id="tbg-btn-at" value={'AT-SPG'} variant="outline-secondary">
                  <TdTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-lt" value={'lightTank'} variant="outline-secondary">
                  <LightTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-mt" value={'mediumTank'} variant="outline-secondary">
                  <MedTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-ht" value={'heavyTank'} variant="outline-secondary">
                  <HeavyTankIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <Form.Select onChange={(event) => setWotServer(event.target.value as WotServer)} value={wotServer}>
                <option value="eu">EU</option>
                <option value="com">NA</option>
                <option value="asia">ASIA</option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>

      {showRules && (
        <Row>
          <Col>
            <Alert className="mt-2" variant="info" onClose={() => setShowRules(false)} dismissible>
              {t(
                'TopTanks.RulesAlert',
                'Top tanks data is calculated from all accounts registered on the site. The data is being updated daily.',
              )}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'tier', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnSpecs', 'Specs')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battles', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnTotalBattles', 'Total battles')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'win', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnWinRate', 'Win rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'damage', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnAvgDamage', 'Avg. damage')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'frags', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnFragsRate', 'Frags rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'remainingHp', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnRemainingHp', 'Remaining HP rate')}*
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'mastery', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnMastery', 'Battles per Master')}*
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'battleLifeTime', desc: !sorting.desc })}>
                    {t('TopTanks.ColumnBattleLifeTime', 'Avg. life time')}*
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                [...tableData].map((tank, i) => (
                  <tr key={tank.wotId}>
                    <td>{i + 1}</td>
                    <td>
                      <strong>{tank.name ?? tank.wotId}</strong>
                    </td>
                    <td>
                      <Stack direction="horizontal" gap={1}>
                        {tank.type === 'heavyTank' ? (
                          <HeavyTankIcon />
                        ) : tank.type === 'mediumTank' ? (
                          <MedTankIcon />
                        ) : tank.type === 'lightTank' ? (
                          <LightTankIcon />
                        ) : tank.type === 'AT-SPG' ? (
                          <TdTankIcon />
                        ) : undefined}
                        <p className="mb-0">{tank.tier}</p>
                        {tank.isPremium === true && <StarFill size={15} color="Orange" />}
                      </Stack>
                    </td>
                    <td style={{ backgroundColor: tank.battlesBackgroundColor }}>{tank.battles}</td>
                    <td style={{ backgroundColor: tank.winRateBackgroundColor }}>{(tank.winRate * 100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: tank.avgDamageBackgroundColor }}>{tank.avgDamage.toFixed(0)}</td>
                    <td style={{ backgroundColor: tank.fragsRateBackgroundColor }}>{tank.fragsRate.toFixed(2)}</td>
                    <td style={{ backgroundColor: tank.remainingHpRateBackgroundColor }}>
                      {tank.remainingHpRate == null ? '-' : (tank.remainingHpRate * 100).toFixed(2) + '%'}
                    </td>
                    <td style={{ backgroundColor: tank.masteryBackgroundColor }}>{tank.mastery == null ? '-' : tank.mastery.toFixed(0)}</td>
                    <td style={{ backgroundColor: tank.avgBattleLifeTimeBackgroundColor }}>
                      {tank.avgBattleLifeTime == null
                        ? '-'
                        : tank.avgBattleLifeTime > 7 * 60
                        ? '~7m'
                        : `${Math.floor(tank.avgBattleLifeTime / 60)}m ${(tank.avgBattleLifeTime % 60).toFixed(0)}s`}
                    </td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    {t('TopTanks.NoTableRows', 'There are no vehicles matching the selected filters.')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <span>{t('TopTanks.InaccurateDataDisclaimer', '* the numbers marked with asterisks could be not completely accurate')}</span>
        </Col>
      </Row>
    </Container>
  );
}
