import './Tankopedia.scss';
import { Route, Routes } from 'react-router';
import { TankopediaTank } from './tank/TankopediaTank';
import { TankopediaTable } from './table/TankopediaTable';

export function Tankopedia() {
  return (
    <Routes>
      <Route path="/" element={<TankopediaTable />} />
      <Route path=":wotServer/:wotTankId/*" element={<TankopediaTank />} />
    </Routes>
  );
}
