import { useEffect, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WotApiService } from '../../../../../services/api/wot-api.service';
import { selectAccount } from '../../../../account/redux/accountsSlice';
import { showToast } from '../../../../toast/redux/toastSlice';
import { selectSubscriptionValid } from '../../../../subscription/redux/subscriptionSlice';
import { setBlitzSessionDetailedWidgetBasicStats } from './widget-blitz-session-detailed.util';

export function WidgetBlitzSessionDetailedSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedAccount = useSelector(selectAccount);
  const isValidSubscription = useSelector(selectSubscriptionValid);

  const [url, setUrl] = useState('');
  const [showSiteName, setShowSiteName] = useState(true);

  useEffect(() => {
    setUrl('');

    const run = async () => {
      if (selectedAccount == null) {
        return;
      }

      try {
        const svc = new WotApiService();

        const tanksStats = await svc.getAccountTanksStats(selectedAccount.wotId, selectedAccount.server, [
          'tank_id',
          'all.battles',
          'all.wins',
          'all.damage_dealt',
        ]);

        if (tanksStats.status !== 'ok' || tanksStats.data == null) {
          dispatch(showToast({ title: 'Error', message: tanksStats.error?.message ?? 'Unknown error occurred...', variant: 'danger' }));
          return;
        }

        const tanksStatsData = tanksStats.data.map((x) => {
          return {
            id: x.tank_id,
            battles: x.all.battles,
            wins: x.all.wins,
            damage: x.all.damage_dealt,
          };
        });

        // save tanks stats to local storage
        setBlitzSessionDetailedWidgetBasicStats({
          wotAccountId: selectedAccount.wotId,
          wotAccountServer: selectedAccount.server,
          tanks: tanksStatsData,
        });

        const newUrl = `/widgets/run/blitz/session-detailed?wotAccountId=${selectedAccount.wotId}&wotAccountServer=${
          selectedAccount.server
        }&showSiteName=${showSiteName ? 'true' : 'false'}`;

        setUrl(newUrl);
      } catch (e) {
        dispatch(showToast({ title: 'Error', message: (e as Error)?.message ?? 'Unknown error occurred...', variant: 'danger' }));
        return;
      }
    };

    void run();
  }, [dispatch, selectedAccount, showSiteName]);

  return (
    <>
      <Alert variant="info">
        <Trans t={t} i18nKey="WidgetBlitzSessionDetailedSettings.Info">
          The widget calculates battle stats only from <strong>Regular</strong> mode. The stats are presented as a <strong>total</strong>{' '}
          for the selected account <strong>plus a breakdown</strong> of all tanks played during the session.
        </Trans>
      </Alert>

      <Form.Group className="mb-3">
        <Form.Label>{t('WidgetBlitzSessionDetailedSettings.GameAccount', 'Game Account')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t('WidgetBlitzSessionDetailedSettings.GameAccountPlaceholder', 'Please select an account at the top...')}
          required
          readOnly={true}
          isInvalid={selectedAccount == null}
          value={selectedAccount == null ? '' : `(${selectedAccount.server}) ${selectedAccount.name}`}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label={t('WidgetBlitzSessionDetailedSettings.ShowSiteName', 'Show the site name')}
          disabled={isValidSubscription !== true}
          checked={showSiteName}
          onChange={() => {
            setShowSiteName(!showSiteName);
          }}
        />
      </Form.Group>

      {(selectedAccount == null || url === '') && (
        <Button variant="primary" type="submit" disabled className="float-end">
          {t('WidgetBlitzSessionDetailedSettings.StartButton', 'Start')}
        </Button>
      )}

      {selectedAccount != null && url !== '' && (
        <Button
          variant="primary"
          type="submit"
          href={url}
          target="_blank"
          disabled={selectedAccount == null || url === ''}
          className="float-end"
        >
          {t('WidgetBlitzSessionDetailedSettings.StartButton', 'Start')}
        </Button>
      )}
    </>
  );
}
