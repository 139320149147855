import { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Form, InputGroup, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getClansListAsync, resetClans, selectClansList } from './clansSlice';
import { selectAccountsList } from '../../account/redux/accountsSlice';
import { Helmet } from 'react-helmet';
import { IAccountShortApiView } from '../../../services/api/interfaces/account.interface';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../user/userSlice';
import { selectSubscriptionValid } from '../../subscription/redux/subscriptionSlice';
import { getTransparency } from '../../../utils/get-transparency.util';
import { FEATURE_FREE_CLAN_SEARCH } from '../../../configs/config';

interface ITableRow {
  wotId: number;
  name: string;
  tag: string;
  membersCount: number;
  membersCountBackgroundColor?: string;
  avgBattles: number;
  avgBattlesBackgroundColor?: string;
  avgDamage: number;
  avgDamageBackgroundColor?: string;
  avgWinRate: number;
  avgWinRateBackgroundColor?: string;
  motto: string;
}

const PageSize = 100;

export function FindClan() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isValidSubscription = useSelector(selectSubscriptionValid);
  const accountsList = useSelector(selectAccountsList);
  const clans = useSelector(selectClansList);

  const [page, setPage] = useState(1);

  const [tableData, setTableData] = useState<Array<ITableRow>>([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'tag' | 'membersCount' | 'avgBattles' | 'avgDamage' | 'avgWinRate';
    desc: boolean;
  }>({
    column: 'avgWinRate',
    desc: true,
  });

  const [account, setAccount] = useState<IAccountShortApiView | undefined>(undefined);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (account == null && accountsList.filter((a) => a.my === true).length > 0) {
      setAccount(accountsList.filter((a) => a.my === true)[0]);
    }
  }, [account, accountsList]);

  useEffect(() => {
    if (account != null) {
      dispatch(getClansListAsync(account.wotId, account.server));
    } else {
      dispatch(resetClans());
    }
  }, [account, dispatch]);

  // prepare table data
  useEffect(() => {
    const calculatedTableData: ITableRow[] = clans.map((c) => {
      return {
        wotId: c.wotId,
        name: c.name,
        tag: c.tag,
        membersCount: c.members.count,
        avgBattles: +c.members.avgBattles.toFixed(0),
        avgDamage: +c.members.avgDamage.toFixed(0),
        avgWinRate: c.members.avgWinRate,
        motto: c.motto,
      };
    });

    // set background colors
    const limits = {
      minBattles: undefined as number | undefined,
      maxBattles: undefined as number | undefined,
      minWins: undefined as number | undefined,
      maxWins: undefined as number | undefined,
      minDamage: undefined as number | undefined,
      maxDamage: undefined as number | undefined,
      minMembers: undefined as number | undefined,
      maxMembers: undefined as number | undefined,
    };
    calculatedTableData?.forEach((t) => {
      if (limits.minBattles == null || t.avgBattles < limits.minBattles) {
        limits.minBattles = t.avgBattles;
      }
      if (limits.maxBattles == null || t.avgBattles > limits.maxBattles) {
        limits.maxBattles = t.avgBattles;
      }
      if (limits.minWins == null || t.avgWinRate < limits.minWins) {
        limits.minWins = t.avgWinRate;
      }
      if (limits.maxWins == null || t.avgWinRate > limits.maxWins) {
        limits.maxWins = t.avgWinRate;
      }
      if (limits.minDamage == null || t.avgDamage < limits.minDamage) {
        limits.minDamage = t.avgDamage;
      }
      if (limits.maxDamage == null || t.avgDamage > limits.maxDamage) {
        limits.maxDamage = t.avgDamage;
      }
      if (limits.minMembers == null || t.membersCount < limits.minMembers) {
        limits.minMembers = t.membersCount;
      }
      if (limits.maxMembers == null || t.membersCount > limits.maxMembers) {
        limits.maxMembers = t.membersCount;
      }
    });

    calculatedTableData?.forEach((t) => {
      t.avgBattlesBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgBattles, limits.minBattles ?? 0, limits.maxBattles ?? 0)})`;
      t.avgWinRateBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgWinRate, limits.minWins ?? 0, limits.maxWins ?? 0)})`;
      t.avgDamageBackgroundColor = `rgba(89, 207, 70, ${getTransparency(t.avgDamage, limits.minDamage ?? 0, limits.maxDamage ?? 0)})`;
      t.membersCountBackgroundColor = `rgba(89, 207, 70, ${getTransparency(
        t.membersCount,
        limits.minMembers ?? 0,
        limits.maxMembers ?? 0,
      )})`;
    });

    const sortedTableData: ITableRow[] = calculatedTableData.sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        case 'tag':
          return sorting.desc ? b.tag.localeCompare(a.tag) : a.tag.localeCompare(b.tag);
        case 'membersCount':
          return sorting.desc ? b.membersCount - a.membersCount : a.membersCount - b.membersCount;
        case 'avgBattles':
          return sorting.desc ? b.avgBattles - a.avgBattles : a.avgBattles - b.avgBattles;
        case 'avgDamage':
          return sorting.desc ? b.avgDamage - a.avgDamage : a.avgDamage - b.avgDamage;
        case 'avgWinRate':
          return sorting.desc ? b.avgWinRate - a.avgWinRate : a.avgWinRate - b.avgWinRate;
        default:
          return sorting.desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
      }
    });

    setTableData(sortedTableData);
  }, [clans, sorting.column, sorting.desc]);

  return (
    <Container fluid>
      <Helmet>
        <title>{t('FindClans.Title', 'Wargaming WoT Blitz Beast - Find clan')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('FindClans.Header', 'Find clan')}</h1>
        </Col>
        <Col>
          <div className="filter-container">
            <div>
              <Form.Select
                onChange={(event) => {
                  const account = accountsList.find((a) => a.wotId === +event.target.value);
                  setAccount(account);
                }}
                value={account?.wotId}
              >
                {accountsList
                  .filter((a) => a.my === true)
                  .map((a) => (
                    <option key={a.wotId} value={a.wotId}>
                      ({a.server}) {a.name}
                    </option>
                  ))}
              </Form.Select>
            </div>

            <div>
              <InputGroup>
                <Button
                  variant="outline-secondary"
                  disabled={page === 1}
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  {'<'}
                </Button>
                <Form.Control value={page} disabled className="table-page-input" />
                <Button
                  variant="outline-secondary"
                  disabled={page * PageSize >= tableData.length}
                  onClick={() => {
                    if (page * PageSize < tableData.length) {
                      setPage(page + 1);
                    }
                  }}
                >
                  {'>'}
                </Button>
              </InputGroup>
            </div>

            <div>
              <Button
                variant="success"
                onClick={() => {
                  if (FEATURE_FREE_CLAN_SEARCH || isValidSubscription === true) {
                    navigate('/find/clan/your');
                  } else {
                    navigate('/subscription');
                  }
                }}
              >
                {t('FindClans.YourClan', 'Your Clan')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('FindClans.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'tag', desc: !sorting.desc })}>
                    {t('FindClans.ColumnTag', 'Tag')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'membersCount', desc: !sorting.desc })}>
                    {t('FindClans.ColumnMembers', 'Members')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'avgBattles', desc: !sorting.desc })}>
                    {t('FindClans.ColumnBattles', 'Avg. battles')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'avgDamage', desc: !sorting.desc })}>
                    {t('FindClans.ColumnDamage', 'Avg. damage')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'avgWinRate', desc: !sorting.desc })}>
                    {t('FindClans.ColumnWinRate', 'Avg. win rate')}
                  </Button>
                </th>
                <th style={{ fontWeight: 'normal' }}>{t('FindClans.ColumnMotto', 'Motto')}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                [...tableData].slice((page - 1) * PageSize, page * PageSize).map((clan, i) => (
                  <tr key={clan.wotId}>
                    <td>{(page - 1) * PageSize + i + 1}</td>
                    <td>{clan.name}</td>
                    <td>{clan.tag}</td>
                    <td style={{ backgroundColor: clan.membersCountBackgroundColor }}>{clan.membersCount}/50</td>
                    <td style={{ backgroundColor: clan.avgBattlesBackgroundColor }}>{clan.avgBattles}</td>
                    <td style={{ backgroundColor: clan.avgDamageBackgroundColor }}>{clan.avgDamage}</td>
                    <td style={{ backgroundColor: clan.avgWinRateBackgroundColor }}>{clan.avgWinRate}</td>
                    <td>{clan.motto}</td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={8} className="text-center">
                    {t('FindClans.NoTableRows', 'There are no clans available for your account. Try to add your own!')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
