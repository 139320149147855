import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { IClanApiView } from './interfaces/clan.interfacets';
import { ListResponseApiView, SingleEntityResponseApiView } from './interfaces/response.interface';

export class ClansApiService {
  async getList(wotAccountId: number, wotServer: WotServer): Promise<ListResponseApiView<IClanApiView>> {
    const response = await fetchApi(`clans/list?wotAccountId=${wotAccountId}&wotServer=${wotServer}`, {
      method: 'GET',
    });

    const body = (await response.json()) as ListResponseApiView<IClanApiView>;
    return body;
  }

  async update(
    wotAccountId: number,
    wotServer: WotServer,
    wotClanId: number,
    recruitingStatus: 'active' | 'inactive',
  ): Promise<SingleEntityResponseApiView<IClanApiView>> {
    const response = await fetchApi('clans', {
      method: 'POST',
      body: JSON.stringify({ wotAccountId, wotServer, wotClanId, recruitingStatus }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IClanApiView>;
    return body;
  }

  async get(wotAccountId: number, wotServer: WotServer): Promise<SingleEntityResponseApiView<IClanApiView | undefined>> {
    const response = await fetchApi(`clans?wotAccountId=${wotAccountId}&wotServer=${wotServer}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IClanApiView | undefined>;
    return body;
  }
}
