import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { LogService } from '../../../services/log.service';
import { WotServer } from '../../../utils/wot-server.type';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';
import { IClanApiView } from '../../../services/api/interfaces/clan.interfacets';
import { ClansApiService } from '../../../services/api/clans-api.service';

export interface ClansState {
  list: IClanApiView[];
  item?: IClanApiView;
}

const InitialState: ClansState = {
  list: [],
  item: undefined,
};

export const clansSlice = createSlice({
  name: 'clans',
  initialState: InitialState,
  reducers: {
    resetClans: () => InitialState,
    setClansList: (state, action: PayloadAction<IClanApiView[]>) => {
      state.list = action.payload;
    },
    setClan: (state, action: PayloadAction<IClanApiView | undefined>) => {
      state.item = action.payload;
    },
  },
});

export const { resetClans, setClansList, setClan } = clansSlice.actions;

export const getClansListAsync =
  (wotAccountId: number, wotServer: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.ClansLoading));

      const svc = new ClansApiService();
      const response = await svc.getList(wotAccountId, wotServer);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setClansList(response.data));
      }
    } catch (e) {
      LogService.error(`Error while loading clans`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.ClansLoading));
    }
  };

export const getClanAsync =
  (wotAccountId: number, wotServer: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.ClansLoading));

      const svc = new ClansApiService();
      const response = await svc.get(wotAccountId, wotServer);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setClan(response.data ?? undefined));
      }
    } catch (e) {
      LogService.error(`Error while loading a clan`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.ClansLoading));
    }
  };

export const updateClanAsync =
  (wotAccountId: number, wotServer: WotServer, wotClanId: number, recruitingStatus: 'active' | 'inactive'): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.ClanUpdating));

      const svc = new ClansApiService();
      const response = await svc.update(wotAccountId, wotServer, wotClanId, recruitingStatus);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setClan(response.data));
        dispatch(getClansListAsync(wotAccountId, wotServer));
      }
    } catch (e) {
      LogService.error(`Error while updating a clan`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.ClanUpdating));
    }
  };

export const selectClansList = (state: RootState) => state.clans.list;
export const selectClan = (state: RootState) => state.clans.item;

export default clansSlice.reducer;
