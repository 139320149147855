import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IAccountTanksApiView } from './interfaces/account-tanks.interface';
import { WotServer } from '../../utils/wot-server.type';

export class AccountTanksApiService {
  async get(wotId: number, server: WotServer): Promise<SingleEntityResponseApiView<IAccountTanksApiView>> {
    const response = await fetchApi(`accounts/${server}/${wotId}/tanks`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IAccountTanksApiView>;

    return body;
  }
}
