import '../../Widget.scss';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TrovoApiService } from '../../../../services/api/trovo-api.service';
import { useTranslation } from 'react-i18next';

export function WidgetTrovoFollowers() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [label, setLabel] = useState<string>();
  const [followers, setFollowers] = useState<number>(0);
  const [completed, setCompleted] = useState<boolean>(false);

  const [query, setQuery] = useState<{
    channelName: string;
    startingFollowers: number;
    streamingTime: number;
    additionalFollowers: number;
    additionalTime: number;
    startingTime: Date;
  }>({ channelName: '', startingFollowers: 0, streamingTime: 0, additionalFollowers: 0, additionalTime: 0, startingTime: new Date() });

  useEffect(() => {
    const url = new URLSearchParams(search);

    const channelNameQuery = url.get('channelName');
    const startingFollowersQuery = url.get('startingFollowers');
    const streamingTimeQuery = url.get('streamingTime');
    const additionalFollowersQuery = url.get('additionalFollowers');
    const additionalTimeQuery = url.get('additionalTime');
    const startingTimeQuery = url.get('startingTime');

    setQuery({
      channelName: channelNameQuery == null ? '' : channelNameQuery,
      startingFollowers: startingFollowersQuery == null ? 0 : +startingFollowersQuery,
      streamingTime: streamingTimeQuery == null ? 0 : +streamingTimeQuery,
      additionalFollowers: additionalFollowersQuery == null ? 0 : +additionalFollowersQuery,
      additionalTime: additionalTimeQuery == null ? 0 : +additionalTimeQuery,
      startingTime: startingTimeQuery == null ? new Date() : new Date(startingTimeQuery),
    });

    setFollowers(startingFollowersQuery == null ? 0 : +startingFollowersQuery);
  }, [search]);

  const getStreamEndTime = useCallback(() => {
    const end = new Date(query.startingTime);
    end.setMinutes(end.getMinutes() + query.streamingTime);

    const followersDiff = followers - query.startingFollowers;
    end.setMinutes(end.getMinutes() + (followersDiff / query.additionalFollowers) * query.additionalTime);

    return end;
  }, [followers, query.additionalFollowers, query.additionalTime, query.startingFollowers, query.startingTime, query.streamingTime]);

  useEffect(() => {
    const getFollowers = async () => {
      const svc = new TrovoApiService();
      const response = await svc.getChannel(query.channelName);

      if (response.data != null) {
        setFollowers(response.data.followers);
      }
    };

    const interval = setInterval(() => {
      void getFollowers();
    }, 1000 * 5);

    return () => {
      clearInterval(interval);
    };
  }, [query.channelName]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (completed === true) {
        return;
      }

      const end = getStreamEndTime();

      let diff = end.getTime() - Date.now();
      diff = diff < 0 ? 0 : diff;

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / (1000 * 60));
      const seconds = Math.floor((diff - hours * 1000 * 60 * 60 - minutes * 1000 * 60) / 1000);

      if (hours === 0 && minutes === 0 && seconds === 0) {
        setCompleted(true);
      }

      setLabel(
        `${t('WidgetTrovoFollowers.Label', 'End in')} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
          seconds < 10 ? '0' : ''
        }${seconds}`,
      );
    }, 1000 * 1);

    return () => {
      clearInterval(interval);
    };
  }, [completed, getStreamEndTime, t]);

  return (
    <div className="widget-container">
      <p>{label}</p>
    </div>
  );
}
