import { useState, useEffect } from 'react';
import { Row, Col, Table, Stack, Button, Form, ToggleButton, ToggleButtonGroup, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StarFill } from 'react-bootstrap-icons';
import { ReactComponent as HeavyTankIcon } from '../../../images/heavy-icon.svg';
import { ReactComponent as MedTankIcon } from '../../../images/med-icon.svg';
import { ReactComponent as LightTankIcon } from '../../../images/light-icon.svg';
import { ReactComponent as TdTankIcon } from '../../../images/td-icon.svg';
import { useTranslation } from 'react-i18next';
import { TankNation, TankTiers, TankTypes } from '../../../services/api/interfaces/tank.interface';
import { WotServer } from '../../../utils/wot-server.type';
import { Helmet } from 'react-helmet';
import { getWotTanksAsync, selectWotTanks } from '../wotTanksSlice';
import { IWotApiTank } from '../../../services/api/interfaces/wot-api-tanks.interface';
import SovietFlag from '../../../images/Flag_of_the_Soviet_Union.png';

export function TankopediaTable() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [wotServer, setWotServer] = useState<WotServer>('eu');
  const [tanTtiers, setTankTiers] = useState<TankTiers[]>([]);
  const [tankTypes, setTankTypes] = useState<TankTypes[]>([]);
  const [tankNation, setTankNation] = useState<TankNation | 'all'>('all');

  const wotTanks = useSelector(selectWotTanks(wotServer));

  const [tableData, setTableData] = useState<IWotApiTank[]>([]);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'nation' | 'tier' | 'hp' | 'damage' | 'rate' | 'dpm' | 'penetration' | 'speed' | 'armor' | 'viewRange';
    desc: boolean;
  }>({
    column: 'name',
    desc: false,
  });

  useEffect(() => {
    dispatch(getWotTanksAsync(wotServer, i18n.language.toLowerCase() === 'en' ? 'en' : 'ru'));
  }, [dispatch, i18n.language, wotServer]);

  // prepare table data
  useEffect(() => {
    const filteredTableData = wotTanks
      .filter((t) => {
        if (tanTtiers.length === 0 || tanTtiers.length === 10) {
          return true;
        }

        return tanTtiers.includes(t.tier);
      })
      .filter((t) => {
        if (tankTypes.length === 0 || tankTypes.length === 4) {
          return true;
        }

        return tankTypes.includes(t.type);
      })
      .filter((t) => {
        if (tankNation === 'all') {
          return true;
        }

        return t.nation === tankNation;
      })
      .sort((a, b) => {
        switch (sorting.column) {
          case 'name':
            return sorting.desc ? (b.name ?? '').localeCompare(a.name ?? '') : (a.name ?? '').localeCompare(b.name ?? '');
          case 'nation':
            return sorting.desc ? (b.nation ?? '').localeCompare(a.nation ?? '') : (a.nation ?? '').localeCompare(b.nation ?? '');
          case 'tier':
            return sorting.desc ? (b.tier ?? 0) - (a.tier ?? 0) : (a.tier ?? 0) - (b.tier ?? 0);
          case 'hp':
            return sorting.desc
              ? (b.default_profile.hp ?? 0) - (a.default_profile.hp ?? 0)
              : (a.default_profile.hp ?? 0) - (b.default_profile.hp ?? 0);
          case 'damage':
            return sorting.desc
              ? (b.default_profile.shells[0].damage ?? 0) - (a.default_profile.shells[0].damage ?? 0)
              : (a.default_profile.shells[0].damage ?? 0) - (b.default_profile.shells[0].damage ?? 0);
          case 'rate':
            return sorting.desc
              ? (b.default_profile.gun.fire_rate ?? 0) - (a.default_profile.gun.fire_rate ?? 0)
              : (a.default_profile.gun.fire_rate ?? 0) - (b.default_profile.gun.fire_rate ?? 0);
          case 'dpm':
            return sorting.desc ? calculateDpms(b)[0] - calculateDpms(a)[0] : calculateDpms(a)[0] - calculateDpms(b)[0];
          case 'penetration':
            return sorting.desc
              ? (b.default_profile.shells[0].penetration ?? 0) - (a.default_profile.shells[0].penetration ?? 0)
              : (a.default_profile.shells[0].penetration ?? 0) - (b.default_profile.shells[0].penetration ?? 0);
          case 'speed':
            return sorting.desc
              ? (b.default_profile.speed_forward ?? 0) - (a.default_profile.speed_forward ?? 0)
              : (a.default_profile.speed_forward ?? 0) - (b.default_profile.speed_forward ?? 0);
          case 'armor':
            return sorting.desc
              ? (b.default_profile.armor.hull.front ?? 0) - (a.default_profile.armor.hull.front ?? 0)
              : (a.default_profile.armor.hull.front ?? 0) - (b.default_profile.armor.hull.front ?? 0);
          case 'viewRange':
            return sorting.desc
              ? (b.default_profile.turret.view_range ?? 0) - (a.default_profile.turret.view_range ?? 0)
              : (a.default_profile.turret.view_range ?? 0) - (b.default_profile.turret.view_range ?? 0);
          default:
            return sorting.desc ? (b.name ?? '').localeCompare(a.name ?? '') : (a.name ?? '').localeCompare(b.name ?? '');
        }
      });

    setTableData(filteredTableData);
  }, [sorting.column, sorting.desc, tanTtiers, tankNation, tankTypes, wotTanks]);

  const handleTankTierChange = (tiers: TankTiers[]) => {
    if (tiers.includes(3)) {
      if (!tiers.includes(2)) {
        tiers.push(2);
      }

      if (!tiers.includes(1)) {
        tiers.push(1);
      }
    } else {
      tiers = tiers.filter((v) => v !== 2 && v !== 1);
    }

    setTankTiers(tiers);
  };

  const handleTankTypeChange = (types: TankTypes[]) => {
    setTankTypes(types);
  };

  const calculateDpms = (tank: IWotApiTank) => {
    const dpms: number[] = [];

    for (const shell of tank.default_profile.shells) {
      if (tank.default_profile.gun.clip_capacity === 1) {
        dpms.push(shell.damage * tank.default_profile.gun.fire_rate);
        continue;
      }

      let damage = 0;
      let shellIndex = 0;
      let time = 0;

      while (time <= 60) {
        damage += shell.damage;
        time += tank.default_profile.gun.clip_reload_time;
        shellIndex++;

        if (shellIndex % tank.default_profile.gun.clip_capacity === 0) {
          time += tank.default_profile.gun.reload_time;
        }
      }

      dpms.push(damage);
    }

    return dpms;
  };

  return (
    <Container fluid>
      <Helmet>
        <title>{t('TankopediaTable.Title', 'Wargaming WoT Blitz Beast - Tankopedia')}</title>
      </Helmet>

      <Row>
        <Col md={2}>
          <h1>{t('TankopediaTable.Header', 'Tankopedia')}</h1>
        </Col>

        <Col>
          <div className="filter-container">
            <div>
              <Form.Select onChange={(event) => setTankNation(event.target.value as TankNation | 'all')} value={tankNation}>
                <option value="all">{t('TankopediaTable.NationAll', 'All')}</option>
                <option value="european">{t('TankopediaTable.NationEuropean', 'European')}</option>
                <option value="germany">{t('TankopediaTable.NationGermany', 'Germany')}</option>
                <option value="usa">{t('TankopediaTable.NationUsa', 'USA')}</option>
                <option value="uk">{t('TankopediaTable.NationUk', 'UK')}</option>
                <option value="china">{t('TankopediaTable.NationChina', 'China')}</option>
                <option value="france">{t('TankopediaTable.NationFrance', 'France')}</option>
                <option value="japan">{t('TankopediaTable.NationJapan', 'Japan')}</option>
                <option value="ussr">{t('TankopediaTable.NationUssr', 'USSR')}</option>
                <option value="other">{t('TankopediaTable.NationOther', 'Other')}</option>
              </Form.Select>
            </div>

            <div>
              <ToggleButtonGroup type="checkbox" value={tanTtiers} onChange={handleTankTierChange}>
                <ToggleButton id="tbg-btn-3" value={3} variant="outline-secondary">
                  <span className="filter-tank-tier">{'<'}III</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-4" value={4} variant="outline-secondary">
                  <span className="filter-tank-tier">IV</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-5" value={5} variant="outline-secondary">
                  <span className="filter-tank-tier"> V</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-6" value={6} variant="outline-secondary">
                  <span className="filter-tank-tier">VI</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-7" value={7} variant="outline-secondary">
                  <span className="filter-tank-tier">VII</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-8" value={8} variant="outline-secondary">
                  <span className="filter-tank-tier">VIII</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-9" value={9} variant="outline-secondary">
                  <span className="filter-tank-tier">IX</span>
                </ToggleButton>
                <ToggleButton id="tbg-btn-10" value={10} variant="outline-secondary">
                  <span className="filter-tank-tier">X</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <ToggleButtonGroup type="checkbox" value={tankTypes} onChange={handleTankTypeChange}>
                <ToggleButton id="tbg-btn-at" value={'AT-SPG'} variant="outline-secondary">
                  <TdTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-lt" value={'lightTank'} variant="outline-secondary">
                  <LightTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-mt" value={'mediumTank'} variant="outline-secondary">
                  <MedTankIcon />
                </ToggleButton>
                <ToggleButton id="tbg-btn-ht" value={'heavyTank'} variant="outline-secondary">
                  <HeavyTankIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <Form.Select onChange={(event) => setWotServer(event.target.value as WotServer)} value={wotServer}>
                <option value="eu">EU</option>
                <option value="com">NA</option>
                <option value="asia">ASIA</option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'name', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'nation', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnNation', 'Nation')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'tier', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnSpecs', 'Specs')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'hp', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnHp', 'HP')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'damage', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnDamage', 'Damage')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'rate', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnFireRate', 'Fire rate')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'dpm', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnDpm', 'DPM')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'penetration', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnPenetration', 'Penetration')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'speed', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnSpeed', 'Speed')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'armor', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnArmor', 'Armor')}
                  </Button>
                </th>
                <th>
                  <Button variant="link" onClick={() => setSorting({ column: 'viewRange', desc: !sorting.desc })}>
                    {t('TankopediaTable.ColumnViewRange', 'View range')}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {wotTanks.length > 0 &&
                [...tableData].map((wotTank, i) => (
                  <tr key={wotTank.tank_id}>
                    <td>{i + 1}</td>
                    <td>
                      <Link to={`/tankopedia/${wotServer}/${wotTank.tank_id}/${encodeURIComponent(wotTank.name)}`}>
                        {wotTank.name ?? wotTank.tank_id}
                      </Link>
                    </td>
                    <td>
                      {wotTank.nation === 'china' ? (
                        <span className="fi fi-cn"></span>
                      ) : wotTank.nation === 'european' ? (
                        <span className="fi fi-eu"></span>
                      ) : wotTank.nation === 'france' ? (
                        <span className="fi fi-fr"></span>
                      ) : wotTank.nation === 'germany' ? (
                        <span className="fi fi-de"></span>
                      ) : wotTank.nation === 'japan' ? (
                        <span className="fi fi-jp flag-icon-bordered"></span>
                      ) : wotTank.nation === 'other' ? (
                        <span className="fi fi-xx flag-icon-bordered"></span>
                      ) : wotTank.nation === 'uk' ? (
                        <span className="fi fi-gb"></span>
                      ) : wotTank.nation === 'usa' ? (
                        <span className="fi fi-us"></span>
                      ) : wotTank.nation === 'ussr' ? (
                        <img src={SovietFlag} alt="soviet union flag" className="flag-icon-su" />
                      ) : (
                        <span className="fi fi-xx flag-icon-bordered"></span>
                      )}
                    </td>
                    <td>
                      <Stack direction="horizontal" gap={1}>
                        {wotTank.type === 'heavyTank' ? (
                          <HeavyTankIcon />
                        ) : wotTank.type === 'mediumTank' ? (
                          <MedTankIcon />
                        ) : wotTank.type === 'lightTank' ? (
                          <LightTankIcon />
                        ) : wotTank.type === 'AT-SPG' ? (
                          <TdTankIcon />
                        ) : undefined}
                        <p className="mb-0">{wotTank.tier}</p>
                        {wotTank.is_premium === true && <StarFill size={15} color="Orange" />}
                      </Stack>
                    </td>
                    <td>{wotTank.default_profile.hp}</td>
                    <td>{wotTank.default_profile.shells.map((s) => s.damage).join(' / ')}</td>
                    <td>{wotTank.default_profile.gun.fire_rate}</td>
                    <td>
                      {calculateDpms(wotTank)
                        .map((dpm) => dpm.toFixed(0))
                        .join(' / ')}
                    </td>
                    <td>{wotTank.default_profile.shells.map((s) => s.penetration).join(' / ')}</td>
                    <td>
                      {wotTank.default_profile.speed_forward} / {wotTank.default_profile.speed_backward}
                    </td>
                    <td>
                      {wotTank.default_profile.armor.hull.front} / {wotTank.default_profile.armor.turret.front}
                    </td>
                    <td>{wotTank.default_profile.turret.view_range}</td>
                  </tr>
                ))}

              {tableData.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    {t('TankopediaTable.NoTableRows', 'There are no vehicles matching the selected filters.')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <span>
            {t(
              'TankopediaTable.InaccurateDataDisclaimer',
              '* the numbers might not be accurate due to equipment setup and the latest game rebalances',
            )}
          </span>
        </Col>
      </Row>
    </Container>
  );
}
