import { ITankApiView } from '../../services/api/interfaces/tank.interface';

export function getMaxPossibleTankHp(tank?: ITankApiView) {
  if (tank == null) {
    return 0;
  }

  let result = tank.hp;
  result = result * 1.06; // improved assembly

  if (tank.consumables.EnhancedSandbagArmor === true) {
    result = result * 1.06;
  } else if (tank.consumables.SandbagArmor === true) {
    result = result * 1.03;
  }

  return result;
}
