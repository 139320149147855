import { WotServer } from '../../utils/wot-server.type';
import fetchApi from './fetch-api';
import { SingleEntityResponseApiView } from './interfaces/response.interface';
import { IServerStatisticsApiView } from './interfaces/server-statistics.interface';

export class ServerStatisticsApiService {
  async get(server: WotServer): Promise<SingleEntityResponseApiView<IServerStatisticsApiView>> {
    const response = await fetchApi(`statistics/${server}`, {
      method: 'GET',
    });

    const body = (await response.json()) as SingleEntityResponseApiView<IServerStatisticsApiView>;

    return body;
  }
}
