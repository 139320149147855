import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { LogService } from '../../../services/log.service';
import { WotServer } from '../../../utils/wot-server.type';
import { LoadingParts, startLoading, stopLoading } from '../../loading/loadingSlice';
import { showToast } from '../../toast/redux/toastSlice';
import { IPlatoonApiView } from '../../../services/api/interfaces/platoon.interfacets';
import { PlatoonsApiService } from '../../../services/api/platoons-api.service';

export interface PlatoonState {
  list: IPlatoonApiView[];
  item?: IPlatoonApiView;
}

const InitialState: PlatoonState = {
  list: [],
  item: undefined,
};

export const platoonsSlice = createSlice({
  name: 'platoons',
  initialState: InitialState,
  reducers: {
    resetPlatoons: () => InitialState,
    setPlatoonsList: (state, action: PayloadAction<IPlatoonApiView[]>) => {
      state.list = action.payload;
    },
    setPlatoon: (state, action: PayloadAction<IPlatoonApiView | undefined>) => {
      state.item = action.payload;
    },
  },
});

export const { resetPlatoons, setPlatoonsList, setPlatoon } = platoonsSlice.actions;

export const getPlatoonsListAsync =
  (wotAccountId: number, wotServer: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.PlatoonsLoading));

      const svc = new PlatoonsApiService();
      const response = await svc.getList(wotAccountId, wotServer);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setPlatoonsList(response.data));
      }
    } catch (e) {
      LogService.error(`Error while loading platoons`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.PlatoonsLoading));
    }
  };

export const getPlatoonAsync =
  (wotAccountId: number, wotServer: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.PlatoonsLoading));

      const svc = new PlatoonsApiService();
      const response = await svc.get(wotAccountId, wotServer);

      if (response.success !== true) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setPlatoon(response.data ?? undefined));
      }
    } catch (e) {
      LogService.error(`Error while loading a platoon`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.PlatoonsLoading));
    }
  };

export const updatePlatoonAsync =
  (
    wotAccountId: number,
    wotServer: WotServer,
    expiresAt: Date,
    recruiting: { battles?: number; winRate?: number; avgDamage?: number; comment?: string },
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.PlatoonUpdating));

      const svc = new PlatoonsApiService();
      const response = await svc.update(wotAccountId, wotServer, expiresAt, recruiting);

      if (response.success !== true || response.data == null) {
        dispatch(showToast({ title: 'Error', message: response.message ?? 'Unknown error occurred...', variant: 'danger' }));
      } else {
        dispatch(setPlatoon(response.data));
        dispatch(getPlatoonsListAsync(wotAccountId, wotServer));
      }
    } catch (e) {
      LogService.error(`Error while updating a platoon`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.PlatoonUpdating));
    }
  };

export const selectPlatoonsList = (state: RootState) => state.platoons.list;
export const selectPlatoon = (state: RootState) => state.platoons.item;

export default platoonsSlice.reducer;
